import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const REACT_PROXYURL = "";

// for making unique id for every browser
if (!localStorage.getItem(process.env.REACT_APP_DEVICE_ID_KEY)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(
    process.env.REACT_APP_DEVICE_ID_KEY,
    uint32.toString(32)
  );
}

// for making unique token for every browser
if (!localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN_KEY)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(
    process.env.REACT_APP_DEVICE_TOKEN_KEY,
    uint32.toString(32)
  );
}

/** API Class
 * static clase trying together method used to get/send to the API.
 * There shouldn't be any frontend-specific stuff here, and there  shouldn't be any API-aware stuff else hre in the frontend...
 */
class CitransApi {
  //token which interact with the API will be stored here.
  static token;

  //required common header for each api calling.
  static commonHeaders = {
    accept: "application/json",
    "api-key": process.env.REACT_APP_API_KEY_PAIR,
    "device-token": localStorage.getItem(
      process.env.REACT_APP_DEVICE_TOKEN_KEY
    ),
    "device-id": localStorage.getItem(process.env.REACT_APP_DEVICE_ID_KEY),
    "device-type": process.env.REACT_APP_DEVICE_TYPE,
  };

  static async request(endpoint, data = {}, method = "get", header) {
    const url = `${REACT_PROXYURL}${BASE_URL}${endpoint}`;
    const headers = { ...CitransApi.commonHeaders, ...header };

    const params = method === "get" ? data : {};
    try {
      const result = await axios({ url, method, data, params, headers });
      return result ? result.data : "There is no return for this route";
    } catch (err) {
      return err.response;
    }
  }

  /* ------SIGNUP API -----*/
  static async signUp(data) {
    let res = await this.request(`/sign-up`, data, "post");
    return res;
  }

  /* ------VERIFY EMAIL API -----*/
  static async verifyEmail(data) {
    let res = await this.request(`/verify-email`, data, "post");
    return res;
  }

  /* ------LOGIN API -----*/
  static async login(data) {
    let res = await this.request(`/company/login`, data, "post");
    return res;
  }

  /* ------CHANGE PASSWORD API -----*/
  static async changePassword(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/changePassword`, data, "put", header);
    return res;
  }

  /* ------FORGOT PASSWORD API -----*/
  static async forgotPassword(data) {
    let res = await this.request(`/company/forgot-password`, data, "post");
    return res;
  }

  /* ------RESET PASSWORD API -----*/
  static async resetPassword(data) {
    let res = await this.request(`/company/reset-password`, data, "post");
    return res;
  }

  /* ------VERIFY FORGOT PASSWORD API -----*/
  static async verifyForgotPassword(data) {
    let res = await this.request(`/verify-forgot-password`, data, "post");
    return res;
  }

  /* ------GET INVENTORY DATA API -----*/
  static async getInventoryData(authToken, pageNumber) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/inventory?page_no=${pageNumber}`,
      "",
      "get",
      header
    );
    return res;
  }
  static async searchgetInventoryData(authToken, searchKey) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/inventory?search=${searchKey}`,
      "",
      "get",
      header
    );
    return res;
  }

  static async getInventoryDataByUnit(authToken, pageNumber, unit) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/inventory?page_no=${pageNumber}&unit_of_inventory=${unit}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------POST INVENTORY DATA API -----*/
  static async postInventoryData(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/inventory`, data, "post", header);
    return res;
  }

  /* ------UPDATE INVENTORY DATA API -----*/
  static async updateInventoryData(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/inventory`, data, "post", header);
    return res;
  }

  // ------------UPDATE INVENTORY DATA------------
  static async UpdateInventoryStatus(authToken, data, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/inventory/${id}/changeStatus`,
      data,
      "put",
      header
    );
    return res;
  }
  /* ------GET TRAFFIC LIST DATA API -----*/
  static async getTrafficListData(authToken, pageNumber) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/traffic?page_no=${pageNumber}`,
      "",
      "get",
      header
    );
    return res;
  }

  static async searchTrafficList(authToken, searchKey) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/traffic?search=${searchKey}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------GET TRAFFIC  DETAIL API -----*/
  static async getTrafficDetail(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/traffic/${id}`, "", "get", header);
    return res;
  }

  /* ------POST DOCUMENT IN TRAFFIC  DETAIL API -----*/
  static async postDocumentInTraffic(authToken, id, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/customs/${id}/uploadDocument`,
      data,
      "post",
      header
    );
    return res;
  }

  // ------------ADD SUPPLIERS DATA------------
  static async addSuppliersData(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/supplier`, data, "post", header);
    return res;
  }

  // ------------GET SUPPLIERS DATA------------
  static async getSuppliersData(authToken, page_no) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/supplier?page_no=${page_no}`,
      {},
      "get",
      header
    );
    return res;
  }

  // ------------SEARCH SUPPLIERS DATA------------
  static async searchSuppliersData(authToken, searchKey) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/supplier?search=${searchKey}`,
      {},
      "get",
      header
    );
    return res;
  }

  // ------------UPDATE SUPPLIERS STATUS------------
  static async updateSuppliersStatus(authToken, data, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/supplier/${id}/changeStatus`,
      data,
      "put",
      header
    );
    return res;
  }

  // ------------GET SUPPLIERS DETAILS------------
  static async getSuppliersDetails(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/supplier/${id}`, {}, "get", header);
    return res;
  }

  // ------------ADD STAFF DATA------------
  static async addStaffData(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/staff`, data, "post", header);
    return res;
  }
  // ------------GET STAFF DATA------------
  static async getStaffDetails(authToken, pageNumber) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/staff?page_no=${pageNumber}`,
      {},
      "get",
      header
    );
    return res;
  }
  static async searchStaffDetails(authToken, searchKey) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/staff?search=${searchKey}`,
      {},
      "get",
      header
    );
    return res;
  }
  // ------------DELETE STAFF DATA------------
  static async deleteStaffDetails(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/staff/${id}`, {}, "delete", header);
    return res;
  }
  // ------------RESEND INVITATION------------
  static async resendInvitation(authToken, id) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/staff/${id}/resendInvitation`,
      {},
      "post",
      header
    );
    return res;
  }

  // ------------GET ORDER  DATA------------
  static async getOrderDetails(authToken, pageNumber) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/order?page_no=${pageNumber}`,
      {},
      "get",
      header
    );
    return res;
  }

  static async searchOrderDetails(authToken, searchKey) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/order?search=${searchKey}`,
      {},
      "get",
      header
    );
    return res;
  }

  // ------------GET ORDER ITEMS------------
  static async getOrderItems(authToken, order_id) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/order/${order_id}/items`, {}, "get", header);
    return res;
  }

  /* ------GET ORDER  BILL API -----*/
  static async getOrderBill(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/order/${id}/reportData`, "", "get", header);
    return res;
  }

  // ------------ADD STAFF DATA------------
  static async addOrderData(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/order`, data, "post", header);
    return res;
  }
  // ------------GET SINGLE ORDER  DATA------------
  static async getSingleOrderDetails(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/order/${id}`, {}, "get", header);
    return res;
  }

  // ------------GET SIGNATURE------------
  static async getSignature(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/profile/signature`, {}, "get", header);
    return res;
  }

  // ------------DELETE ORDER  DATA------------
  static async deleteOrderDetails(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/order/${id}`, {}, "delete", header);
    return res;
  }

  // ------------ SAVE TEMPORARY SHIPMENT ------------
  static async saveTemporaryShipment(authToken, order_id, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/order/${order_id}/shipment`,
      data,
      "post",
      header
    );
    return res;
  }

  // ------------ SUBMIT SHIPMENT ------------
  static async submitShipment(authToken, order_id, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/order/${order_id}/submit`,
      data,
      "post",
      header
    );
    return res;
  }

  // ------------ DELETE AN SHIPMENT ------------
  static async deleteAnShipment(authToken, order_id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/order/${order_id}/shipment`,
      {},
      "delete",
      header
    );
    return res;
  }

  // ------------ADD EMAIL DATA------------
  static async addEmailData(authToken, data) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/emails`, data, "post", header);
    return res;
  }
  // ------------GET EMAIL DATA------------
  static async getEmailDetails(authToken, pageNumber) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/emails?page_no=${pageNumber}`,
      {},
      "get",
      header
    );
    return res;
  }

  // ------------DELETE EMAIL ACCOUNT------------
  static async deleteEmailAccount(authToken, email_id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/emails/${email_id}`, {}, "delete", header);
    return res;
  }

  static async searchEmailDetails(authToken, searchKey) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/emails?search=${searchKey}`,
      {},
      "get",
      header
    );
    return res;
  }

  // ------------GET CUSTOM LIST  DATA------------
  static async getCustomList(authToken, pageNumber) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/customs?page_no=${pageNumber}`,
      {},
      "get",
      header
    );
    return res;
  }

  static async searchCustomList(authToken, searchKey) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/customs?search=${searchKey}`,
      {},
      "get",
      header
    );
    return res;
  }

  // ------------GET CUSTOM DETAIL  DATA------------
  static async getCustomDetail(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/customs/${id}`, {}, "get", header);
    return res;
  }

  // ------------APPROVE DOC CUSTOM DETAIL  DATA------------
  static async approveDocument(authToken, data, id) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/customs/${id}/changeDocumentStatus`,
      data,
      "put",
      header
    );
    return res;
  }

  // ------------GENERATE BILL------------
  static async generateBill(authToken, data, id) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/customs/${id}/paymentDetails`,
      data,
      "post",
      header
    );
    return res;
  }

  // ------------GET TRANSPORT LIST  DATA------------
  static async getTransportList(authToken, pageNumber) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/transportation?page_no=${pageNumber}`,
      {},
      "get",
      header
    );
    return res;
  }

  static async searchTransportList(authToken, searchKey) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/transportation?search=${searchKey}`,
      {},
      "get",
      header
    );
    return res;
  }

  // ------------GET TRANSPORT DETAIL  DATA------------
  static async getTransportDetail(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/transportation/${id}`, {}, "get", header);
    return res;
  }

  // ------------ SAVE TRANSPORT DETAILS ------------
  static async saveTransportationDetails(authToken, id, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/transportation/${id}/transportationDetails`,
      data,
      "post",
      header
    );
    return res;
  }

  // ------------FETCH PROFILE DATA------------
  static async getProfileData(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/profile`, data, "get", header);
    return res;
  }

  // ------------UPDATE PROFILE DATA------------
  static async updateProfileData(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/profile`, data, "put", header);
    return res;
  }

  // ------------POST PROFILE SIGNATURE------------
  static async postProfileSignature(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/profile/signature`, data, "post", header);
    return res;
  }

  // ------------GET PROFILE SIGNATURE------------
  static async getProfileSignature(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/profile/signature`, data, "get", header);
    return res;
  }

  // ------------FETCH META DATA------------
  static async getMetaData(data) {
    const params = {
      meta_keys: data,
    };

    let res = await this.request(`/meta`, params, "get");
    return res;
  }

  // ------------POST SIGNUP DATA------------
  static async postSignUpData(data) {
    let res = await this.request(`/company/signup`, data, "post");
    return res;
  }

  /* ------GET DASHBOARD PURCHASE LIST DATA API -----*/
  static async getDashboardPurchaseList(authToken, pageNumber) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/dashboard/purchaseList?page_no=${pageNumber}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------GET DASHBOARD CUSTOM LIST DATA API -----*/
  static async getDashboardCustomList(authToken, pageNumber) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/dashboard/customsList?page_no=${pageNumber}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------GET DASHBOARD CUSTOM LIST DATA API -----*/
  static async getDashboardTrafficList(authToken, pageNumber) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/dashboard/trafficList?page_no=${pageNumber}`,
      "",
      "get",
      header
    );
    return res;
  }

  static async getDashboardTransportList(authToken, pageNumber) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/dashboard/transportationList?page_no=${pageNumber}`,
      "",
      "get",
      header
    );
    return res;
  }
}

export default CitransApi;
