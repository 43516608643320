import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { IoMdCloseCircle } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { validationSchema } from "../../Common/ValidationSchema";
import { useForm } from "react-hook-form";
import { MetaDetaKey } from "../../metaDataKey/MetaDetaKey";
import CitransApi from "../../helpers/Api";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import Loader from "../../Common/LoadingSpinner";

export default function AddMore({
  show,
  handleClose,
  isEdit,
  data,
  setReFetch,
}) {
  const { t } = useTranslation();

  const { register, handleSubmit, formState, reset, setValue } = useForm({
    mode: "onChange",
  });

  const isAuthenticated = useSelector((state) => state.user.userToken);

  const metaDataApiKey = `${MetaDetaKey.POSITION}`;

  const dispatch = useDispatch();

  const [position, setPosition] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleIconClose = () => {
    reset();
    handleClose();
  };

  const getMetaData = () => {
    let res = CitransApi.getMetaData(metaDataApiKey);
    res.then((data) => {
      if (
        data.code == STATUS_CODES.INVALID_TOKEN ||
        data.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        setPosition(data.data.position);
      }
    });
  };

  const handelSubmit = (formData) => {
    setIsLoading(true);

    let Postdata = new FormData();
    {
      isEdit && Postdata.append("email_account_id", data.id);
    }
    Postdata.append("name", formData.Emailname);
    Postdata.append("email", formData.email);
    Postdata.append("position", formData.Position);
    Postdata.append("location", formData.address);

    let res = CitransApi.addEmailData(isAuthenticated, Postdata);
    res.then((response) => {
      setIsLoading(false);

      const ResponseCode = STATUS_MSG[response.data?.code];
      if (
        response.code == STATUS_CODES.INVALID_TOKEN ||
        response.data?.code == STATUS_CODES.INVALID_TOKEN
      ) {
        handleClose();
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        if (response.code === STATUS_CODES.SUCCESS) {
          Toster(isEdit ? t("EMAIL_UPDATE") : t("EMAIL_ADDED"), "success");
          setReFetch(true);
          handleClose();
          reset();
        } else {
          if (
            response.code == STATUS_CODES.VALIDATION_ERROR ||
            response.data.code == STATUS_CODES.VALIDATION_ERROR
          ) {
            Toster(response.data.message, "error");
            handleClose();
          } else {
            Toster(t(ResponseCode), "error");
            handleClose();
          }
        }
      }
    });
  };

  useEffect(() => {
    getMetaData();
  }, []);

  useEffect(() => {
    if (isEdit) {
      setValue("Emailname", data.name);
      setValue("email", data.email);
      setValue("Position", data.position);
      setValue("address", data.location);
    }
  }, [data, isEdit]);

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <Modal show={show} onHide={handleIconClose} size="lg">
        <div className="addMorePopup">
          <Modal.Header>
            <Modal.Title>
              {isEdit ? t("UPDATE_ACCOUNT_EMAIL") : t("ADD_ACCOUNT_EMAIL")}
            </Modal.Title>
            <IoMdCloseCircle onClick={handleIconClose} />
          </Modal.Header>
          <Form noValidate onSubmit={handleSubmit(handelSubmit)}>
            <Modal.Body>
              <div className="addMoreSectionField">
                <Row>
                  <Col lg={6}>
                    <Form.Group className="LoginInput">
                      <Form.Label>{t("NAME")}</Form.Label>
                      <Form.Control
                        {...register("Emailname", validationSchema.Emailname)}
                        isInvalid={!!formState.errors.Emailname}
                        type="text"
                        placeholder={t("ENTER_NAME")}
                        autoComplete="off"
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.Emailname?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="LoginInput">
                      <Form.Label>{t("EMAIL")}</Form.Label>
                      <Form.Control
                        {...register("email", validationSchema.email)}
                        isInvalid={!!formState.errors.email}
                        type="text"
                        placeholder={t("ENTER_EMAIL")}
                        autoComplete="off"
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.email?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="LoginInput">
                      <Form.Label>{t("Position")}</Form.Label>
                      <Form.Select
                        {...register("Position", validationSchema.Position)}
                        isInvalid={!!formState.errors.Position}
                      >
                        <option value="" selected>
                          {t("SELECT_POSITION")}
                        </option>
                        {position?.map((val, index) => {
                          return (
                            <option value={val.value_code} key={index}>
                              {val.value_desc}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.Position?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="LoginInput">
                      <Form.Label>{t("ADDRESS")}</Form.Label>
                      <Form.Control
                        {...register("address", validationSchema.address)}
                        isInvalid={!!formState.errors.address}
                        type="text"
                        placeholder={t("ENTER_ADDRESS")}
                        autoComplete="off"
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.address?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit">
                {isEdit ? t("UPDATE") : t("ADD_MORE")}
              </Button>
            </Modal.Footer>
          </Form>
        </div>
      </Modal>
    </>
  );
}
