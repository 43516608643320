import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { IoMdCloseCircle } from "react-icons/io";

function ConfirmModal({ title, show, handleCloseConfirm, confirmClose }) {
  return (
    <>
      <Modal show={show} onHide={confirmClose} centered>
        <div className="confirmModal">
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
            <IoMdCloseCircle color="grey" size={"30"} onClick={confirmClose} />
          </Modal.Header>
          <Modal.Footer>
            <Button variant="secondary" onClick={confirmClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleCloseConfirm}>
              Confirm
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

export default ConfirmModal;
