import { useContext } from "react";
import { Container, Row, Form, Col } from "react-bootstrap";
import styles from "../authComponent.module.css";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import TopHeader from "../../appComponent/TopHeader/TopHeader";
import BottomHeader from "../../appComponent/BottomHeader/BottomHeader";
import Footer from "../../appComponent/Footer/Footer";
import { MdOutlineMail } from "react-icons/md";
import { BsBuildings, BsCheckLg, BsFileEarmarkBarGraph } from "react-icons/bs";
import { FiUser } from "react-icons/fi";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import CustomsTreatment from "../TreatmentPopUp/TreatmentPopUp";
import CustomsRegime from "../RegimePopUp/RegimePopUp";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { validationSchema } from "../../Common/ValidationSchema";
import { toast } from "react-toastify";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { LanguageContext } from "../../Context/LanguageContext";
import CitransApi from "../../helpers/Api.js";
import LoadingSpinner from "../../Common/LoadingSpinner.js";
import { useSelector, useDispatch } from "react-redux";
import { userSignup } from "../../Redux/Slices/userSlice.js";
import Loader from "../../Common/LoadingSpinner.js";
import Toster from "../../Utils/Toster.js";
import { STATUS_CODES } from "../../Utils/StatusCode.js";
import { STATUS_MSG } from "../../Utils/StatusMsg.js";
import { MetaDetaKey } from "../../metaDataKey/MetaDetaKey.js";

function SignUpPage() {
  const { register, handleSubmit, setValue, formState, setError, getValues } =
    useForm({
      mode: "onChange",
    });

  const userDetails = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [treatmentPopUp, setTreatmentPopUp] = useState(false);
  const [regimePopUp, setRegimePopUp] = useState(false);
  const { setSelectedItem } = useContext(LanguageContext);

  const handleTreatmentClose = () => setTreatmentPopUp(false);
  const handleTreatmentShow = () => setTreatmentPopUp(true);

  const handleRegimeClose = () => setRegimePopUp(false);
  const handleRegimeShow = () => setRegimePopUp(true);
  const [confirmPass, setconfirmPass] = useState("");
  const [customs, setCustoms] = useState({ treatment: [], regime: [] });
  const [phone, setPhone] = useState("");
  const [pass, setPass] = useState("");

  const [customRegime, setCustomRegime] = useState([]);
  const [regimefield, setRegimefield] = useState("");

  const [customfield, setCustomfield] = useState("");
  const [customTreat, setCustomTreat] = useState([]);
  const [regimevalue, setRegimevalue] = useState();
  const [treatvalue, setTreatvalue] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const handelPhoneChange = (phone) => {
    setPhone(phone);
    setValue("phoneNumber", phone);
  };

  useEffect(() => {
    setValue("customsTreatment", customs.treatment);
    setValue("customsRegime", customs.regime);
    setSelectedItem("LOGIN | CREATE ACCOUNT");
  }, [customs]);

  const HandlegetMetaData = (data) => {
    const dataKey = data;
    // console.log("chala");
    let res = CitransApi.getMetaData(dataKey);
    res.then((data) => {
      if (dataKey == MetaDetaKey.CUSTOM_TREATMENT) {
        setCustomTreat(data?.data.customs_treatment);
        setTreatmentPopUp(true);
      } else if (dataKey == MetaDetaKey.customs_regime) {
        setCustomRegime(data?.data.customs_regime);
        setRegimePopUp(true);
      }
    });
  };

  const handelSubmit = (Inputdata) => {
    // setIsloading(true);
    let Postdata = new FormData();
    Postdata.append("cname", Inputdata.companyName);
    Postdata.append("username", Inputdata.userName);
    Postdata.append("email", Inputdata.email);
    Postdata.append("dialcd", "91");
    Postdata.append("phn", Inputdata.phoneNumber);
    Postdata.append("ctreatment", treatvalue);
    Postdata.append("cregime", regimevalue);
    Postdata.append("txid", Inputdata.taxId);
    Postdata.append("occupation", Inputdata.occupation);
    Postdata.append("address", Inputdata.address);

    Postdata.append("latitude", 22);
    Postdata.append("longitude", 75);

    Postdata.append("password", Inputdata.password);
    Postdata.append("cnfpassword", Inputdata.confirmPassword);

    if (regimefield !== "" && customfield !== "") {
      dispatch(userSignup(Postdata)).then((res) => {
        const response = res.payload;

        const msg = response.data.message;

        const ResponseCode = STATUS_MSG[response.data.code];
        if (response.code === STATUS_CODES.SUCCESS) {
          Toster(t("SIGNUP_SUCCESSFULLY"), "success");
        } else {
          if (response.data.code === 105) {
            Toster(msg, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <>
          <LoadingSpinner />
        </>
      ) : null}
      <div>
        <TopHeader />
        <BottomHeader />
      </div>
      <div className="SignupWrapper">
        <Container>
          <Row>
            <div className={styles.SignupSection}>
              <div
                onClick={() => {
                  navigate(-1);
                }}
                className={styles.BackBtn}
              >
                <IoArrowBackCircleSharp />
                <span>{t("BACK")}</span>
              </div>
              <div className={styles.SignupForm}>
                <h3>{t("SIGNUP_FOR_CITRANS")}</h3>
                <Form noValidate onSubmit={handleSubmit(handelSubmit)}>
                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      <Form.Group className="LoginInput">
                        <Form.Label>{t("COMPANY_NAME")}</Form.Label>
                        <Form.Control
                          {...register(
                            "companyName",
                            validationSchema.companyName
                          )}
                          type="text"
                          placeholder={`${t("ENTER")} ${t("COMPANY_NAME")}`}
                          autoComplete="off"
                          isInvalid={!!formState.errors.companyName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.companyName?.message)}
                        </Form.Control.Feedback>
                        <BsBuildings />
                      </Form.Group>
                    </Col>

                    <Col lg={6} md={6} sm={12}>
                      <Form.Group className="LoginInput">
                        <Form.Label>{t("USER_NAME")}</Form.Label>
                        <Form.Control
                          {...register("userName", validationSchema.userName)}
                          isInvalid={!!formState.errors.userName}
                          type="text"
                          placeholder={`${t("ENTER")} ${t("USER_NAME")}`}
                          autoComplete="off"
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.userName?.message)}
                        </Form.Control.Feedback>
                        <FiUser />
                      </Form.Group>
                    </Col>

                    <Col lg={6} md={6} sm={12}>
                      <Form.Group className="LoginInput">
                        <Form.Label>{t("EMAIL_ID")}</Form.Label>
                        <Form.Control
                          {...register("email", validationSchema.email)}
                          isInvalid={!!formState.errors.email}
                          placeholder={`${t("ENTER")} ${t("EMAIL_ID")}`}
                          autoComplete="off"
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.email?.message)}
                        </Form.Control.Feedback>
                        <MdOutlineMail />
                      </Form.Group>
                    </Col>

                    <Col lg={6} md={6} sm={12}>
                      <Form.Group className="LoginInput" required>
                        <Form.Label>{t("PHONE_NUMBER")}</Form.Label>
                        <PhoneInput
                          {...register(
                            "phoneNumber",
                            validationSchema.phoneNumber
                          )}
                          country={"us"}
                          onChange={(phone) => handelPhoneChange(phone)}
                        />
                        <div className="validateError">
                          {!phone && t(formState.errors.phoneNumber?.message)}
                          {phone &&
                            phone.length <= 4 &&
                            t("PHONE_ATLEAST_5_CH")}
                        </div>
                        <Icon
                          icon="solar:phone-outline"
                          color="rgba(130, 130, 130, 0.8509803921568627)"
                          width="22"
                          height="22"
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={6} md={6} sm={12}>
                      <Form.Group className="LoginInput">
                        <div className={styles.customsSection}>
                          <Form.Label>{t("CUSTOMS_TREATMENT")}</Form.Label>

                          <p
                            onClick={() => {
                              HandlegetMetaData(MetaDetaKey.CUSTOM_TREATMENT);
                            }}
                          >
                            {customfield === "" ? (
                              <>
                                {t("SELECT")} {t("CUSTOMS_TREATMENT")}
                              </>
                            ) : (
                              customfield
                            )}
                          </p>
                          {customfield === "" && formState.isSubmitted && (
                            <div className="validateError">
                              {t("SELECT_CUSTOM_TREATMENT")}
                            </div>
                          )}
                          <Icon
                            icon="bx:up-arrow"
                            color="rgba(130, 130, 130, 0.8509803921568627)"
                            width="22"
                            height="22"
                            vFlip={true}
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg={6} md={6} sm={12}>
                      <Form.Group className="LoginInput">
                        <div className={styles.customsSection}>
                          <Form.Label>{t("CUSTOMS_REGIME")}</Form.Label>
                          <p
                            onClick={() => {
                              HandlegetMetaData(MetaDetaKey.customs_regime);
                            }}
                          >
                            {regimefield === "" ? (
                              <>
                                {t("SELECT")} {t("CUSTOMS_REGIME")}
                              </>
                            ) : (
                              regimefield
                            )}
                          </p>
                          {regimefield === "" && formState.isSubmitted && (
                            <div className="validateError">
                              {t("SELECT_CUSTOM_REGIME")}
                            </div>
                          )}
                          <Icon
                            icon="bx:up-arrow"
                            color="rgba(130, 130, 130, 0.8509803921568627)"
                            width="22"
                            height="22"
                            vFlip={true}
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg={6} md={6} sm={12}>
                      <Form.Group className="LoginInput">
                        <Form.Label>{t("TAX_ID")}</Form.Label>
                        <Form.Control
                          {...register("taxId", validationSchema.taxId)}
                          isInvalid={!!formState.errors.taxId}
                          required
                          type="text"
                          placeholder={`${t("ENTER")} ${t("TAX_ID")}`}
                          autoComplete="off"
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.taxId?.message)}
                        </Form.Control.Feedback>
                        <BsFileEarmarkBarGraph />
                      </Form.Group>
                    </Col>

                    <Col lg={6} md={6} sm={12}>
                      <Form.Group className="LoginInput">
                        <Form.Label>{t("OCCUPATION")}</Form.Label>
                        <Form.Control
                          {...register(
                            "occupation",
                            validationSchema.occupation
                          )}
                          isInvalid={!!formState.errors.occupation}
                          required
                          type="text"
                          placeholder={`${t("ENTER")} ${t("OCCUPATION")}`}
                          autoComplete="off"
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.occupation?.message)}
                        </Form.Control.Feedback>
                        <Icon
                          icon="mdi:user-search-outline"
                          color="rgba(130, 130, 130, 0.8509803921568627)"
                          width="25"
                          height="25"
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={6} md={6} sm={12}>
                      <Form.Group className="LoginInput">
                        <Form.Label>{t("ADDRESS")}</Form.Label>
                        <Form.Control
                          {...register("address", validationSchema.address)}
                          isInvalid={!!formState.errors.address}
                          required
                          type="text"
                          placeholder={`${t("ENTER")} ${t("ADDRESS")}`}
                          autoComplete="off"
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.address?.message)}
                        </Form.Control.Feedback>
                        <Icon
                          icon="carbon:location"
                          color="rgba(130, 130, 130, 0.8509803921568627)"
                          width="25"
                          height="25"
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={6} md={6} sm={12}>
                      <Form.Group className="LoginInput">
                        <Form.Label>{t("PASSWORD")}</Form.Label>
                        <Form.Control
                          {...register("password", validationSchema.password)}
                          isInvalid={!!formState.errors.password}
                          required
                          type={isVisible ? "text" : "password"}
                          onChange={(e) => {
                            setPass(e.target.value.replace(/\s/g, ""));
                            setValue(
                              "password",
                              e.target.value.replace(/\s/g, ""),
                              {
                                shouldValidate: true,
                              }
                            );
                          }}
                          placeholder={`${t("ENTER")} ${t("PASSWORD")}`}
                          autoComplete="off"
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.password?.message)}
                        </Form.Control.Feedback>
                        {isVisible ? (
                          <FaRegEye onClick={() => setIsVisible(false)} />
                        ) : (
                          <FaRegEyeSlash onClick={() => setIsVisible(true)} />
                        )}
                      </Form.Group>
                    </Col>

                    <Col lg={6} md={6} sm={12}>
                      <Form.Group className="LoginInput">
                        <Form.Label>{t("CONFIRM_PASSWORD")}</Form.Label>
                        <Form.Control
                          {...register(
                            "confirmPassword",
                            validationSchema.confirmPassword
                          )}
                          isInvalid={pass !== confirmPass}
                          type={isVisible2 ? "text" : "password"}
                          onChange={(e) => {
                            setconfirmPass(e.target.value.replace(/\s/g, ""));
                            setValue(
                              "confirmPassword",
                              e.target.value.replace(/\s/g, ""),
                              {
                                shouldValidate: true,
                              }
                            );
                          }}
                          placeholder={`${t("ENTER")} ${t("CONFIRM_PASSWORD")}`}
                          autoComplete="off"
                        />
                        <Form.Control.Feedback type="invalid">
                          {pass !== confirmPass &&
                            formState.isSubmitted &&
                            "Password not matched."}
                        </Form.Control.Feedback>
                        {isVisible2 ? (
                          <FaRegEye onClick={() => setIsVisible2(false)} />
                        ) : (
                          <FaRegEyeSlash onClick={() => setIsVisible2(true)} />
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className={styles.SignUpBtn}>
                    <Row className={styles.signUpRow}>
                      <Col lg={6} md={6} sm={12}>
                        <button
                          onClick={() => {
                            navigate("/login");
                          }}
                          className={styles.BackToLgnBtn}
                        >
                          {t("BACK_TO_LOGIN")}
                        </button>
                      </Col>
                      <Col lg={6} md={6} sm={12}>
                        <button type="submit" className={styles.CreateBtn}>
                          {t("CREATE_ACCOUNT")}
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div>
        <Footer />
      </div>
      <CustomsTreatment
        customs={customs}
        setCustoms={setCustoms}
        treatmentPopUp={treatmentPopUp}
        handleTreatmentClose={handleTreatmentClose}
        customTreat={customTreat}
        treatvalue={treatvalue}
        setTreatvalue={setTreatvalue}
        customfield={customfield}
        setCustomfield={setCustomfield}
      />
      <CustomsRegime
        customs={customs}
        setCustoms={setCustoms}
        regimePopUp={regimePopUp}
        handleRegimeClose={handleRegimeClose}
        customRegime={customRegime}
        regimevalue={regimevalue}
        setRegimevalue={setRegimevalue}
        regimefield={regimefield}
        setRegimefield={setRegimefield}
      />

      {userDetails.isLoading && <Loader />}
    </>
  );
}

export default SignUpPage;
