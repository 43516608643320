export const MetaDetaKey = {
  KEY_FOR_MATERIAL_LOCATION_UNIT: "material_type,location,unit_of_inventory",
  UNIT_OF_INVENTORY: "unit_of_inventory",
  KEY_FOR_LOCATION: "location",
  CUSTOM_TREATMENT: "customs_treatment",
  customs_regime: "customs_regime",
  TYPE_OF_ORDER: "type_of_order",
  APPLICANT_AREA: "applicant_area",
  CURRENCY_OF_ORDER: "currency_of_order",
  POSITION: "position",
  SHIPPING_METHOD: "shipping_method",
  CONSOLIDATING_COMPANY: "consolidating_shipping_company",
  VERIFICATION_DEPOSIT: "verification_deposit",
  SHIPPING_CLERK: "shipping_clerk",
  TRANSPORTATION_COMPANY: "transportation_company_name",
  TRANSPORT_DRIVER: "transportation_driver_name",
  TRANSPORT_RECEIVER: "transportation_receiver_name",
};
