import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { IoMdCloseCircle } from "react-icons/io";
import styles from "../popUp.module.css";

function CustomsRegime({
  regimePopUp,
  handleRegimeClose,
  customRegime,
  regimevalue,
  setRegimevalue,
  setRegimefield,
}) {
  const handleCheckboxChange = (value, code) => {
    if (code == regimevalue) {
      setRegimefield("");
      setRegimevalue();
      handleRegimeClose();
    } else {
      setRegimefield(value);
      setRegimevalue(code);
      handleRegimeClose();
    }
  };

  return (
    <>
      <Modal show={regimePopUp} onHide={handleRegimeClose}>
        <div className="SignupPopup">
          <Modal.Header>
            <Modal.Title>Customs Regime</Modal.Title>
            <IoMdCloseCircle onClick={handleRegimeClose} />
          </Modal.Header>

          <Modal.Body>
            {customRegime?.map((checkbox) => (
              <div
                key={checkbox.value_code}
                className="signupCheckbox"
                onClick={(e) => {
                  handleCheckboxChange(
                    checkbox.value_desc,
                    checkbox.value_code
                  );
                }}
              >
                <label>{checkbox.value_desc}</label>
                <input
                  type="checkbox"
                  checked={regimevalue === checkbox.value_code}
                />
              </div>
            ))}
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default CustomsRegime;
