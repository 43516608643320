import Modal from "react-bootstrap/Modal";
import styles from "./Alert.module.css";
import AlertCross from "../../Assests/Images/AlertCross.svg";

import { useTranslation } from "react-i18next";
import Toster from "../../Utils/Toster";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { useSelector } from "react-redux";
import CitransApi from "../../helpers/Api";
import { useState } from "react";
import Loader from "../../Common/LoadingSpinner";
import { Navigate, useNavigate } from "react-router-dom";

function Alert({
  approve,
  docKey,
  handleShow,
  handleClose,
  show,
  shipment_id,
  handleGetCustomDetail,
}) {
  const { t } = useTranslation();

  const isAuthenticated = useSelector((state) => state.user.userToken);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleApproveDocument = () => {
    setIsLoading(true);
    let Postdata = new FormData();
    Postdata.append("status", approve);
    Postdata.append("docKeyName", docKey);

    let res = CitransApi.approveDocument(
      isAuthenticated,
      Postdata,
      shipment_id
    );

    res.then((response) => {
      const ResponseCode = STATUS_MSG[response?.data?.code];

      if (response.code === STATUS_CODES.SUCCESS) {
        setIsLoading(false);
        Toster(t("DOCUMENT_STATUS_UPDATED"), "success");
        handleGetCustomDetail();
        if (response.data.all_documents_approved === 1) {
          navigate("/payment", {
            state: { shipment_id: shipment_id },
          });
        }

        handleClose();
      } else {
        if (
          response?.code == STATUS_CODES.VALIDATION_ERROR ||
          response?.data.code == STATUS_CODES.VALIDATION_ERROR
        ) {
          setIsLoading(false);
          Toster(response?.data.message, "error");
          handleClose();
        } else {
          setIsLoading(false);
          Toster(t(ResponseCode), "error");
          handleClose();
        }
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <Modal show={show} onHide={handleClose}>
        <div className={styles.MainBox}>
          <div className={styles.header}>
            <p>{t("ALERT")}</p>
            <img src={AlertCross} onClick={handleClose}></img>
          </div>

          <div className={styles.devider}></div>

          {approve === 1 ? (
            <div className={styles.alertLabel}>
              <p>{t("ARE_YOU_SURE_TO_APPROVE_DOC")}.</p>
            </div>
          ) : (
            <div className={styles.alertLabel}>
              <p>{t("ARE_YOU_SURE_TO_CANCEL_DOC")}.</p>
            </div>
          )}

          <div className={styles.buttonContainer}>
            <button className={styles.buttonNo} onClick={handleClose}>
              {t("NO")}
            </button>
            <button
              className={styles.buttonYes}
              onClick={() => {
                handleApproveDocument();
              }}
            >
              {t("YES")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Alert;
