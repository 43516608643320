import styles from "../Home.module.css";
import AboutComapny from "../../../Assests/Images/Rectangle 10.png";
import { Row, Col, Container } from "react-bootstrap";
import ExpImg from "../../../Assests/Images/Rectangle 11.png";
import logistics1 from "../../../Assests/Images/logistics-delivery 1.png";
import logistics2 from "../../../Assests/Images/logistics-delivery 2.png";
import EllipseMan from "../../../Assests/Images/Ellipse 6.png";
import Plan from "../../../Assests/Images/plane.png";
import Truck from "../../../Assests/Images/truck.png";
import Ship from "../../../Assests/Images/ship.png";
import Rail from "../../../Assests/Images/rail.png";
import { FiArrowRight } from "react-icons/fi";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

function AboutCompany() {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.AboutCompanyWrapper}>
        <div className="HomeAboutSection">
          <Row>
            <Col lg={5}>
              <div className={styles.AboutImg}>
                <img src={AboutComapny} alt="man" />
              </div>
            </Col>
            <Col lg={7}>
              <div className={styles.AboutDetail}>
                <div className={styles.AboutTextWrapper}>
                  <h6>
                    {" "}
                    <Icon
                      icon="carbon:text-short-paragraph"
                      color="#1A3B6D"
                      width="40"
                      height="40"
                      rotate={2}
                    />{" "}
                    {t("ABOUT_COMPANY")}
                  </h6>
                  <h3>{t("LOGISTIC_SOLUTIONS_DELIVER_EXCELLENCE")}</h3>
                  <p>
                    Logistics features can refer to the various aspects and
                    components of the supply chain that are involved in the
                    transportation, warehousing, and distribution of goods. Here
                    are some key logistics features:
                  </p>
                  <div className={styles.LogisticDesc}>
                    <div className={styles.LogisticImg}>
                      <span>{t("YEARS_EXPERIENCE")}</span>
                    </div>
                    <div className={styles.LogisticDetailWrapper}>
                      <div className={styles.LogisticDetail}>
                        <img src={logistics1} />
                        <div>
                          <h5>{t("REVERSE_LOGISTICS")}</h5>
                          <p>{t("INVOLVES_PROCESS_RECEIVING")}</p>
                        </div>
                      </div>
                      <div className={styles.LogisticDetail}>
                        <img src={logistics2} />
                        <div>
                          {" "}
                          <h5>{t("TRACKING_VISIBILITY")}</h5>
                          <p>{t("INVOLVES_PROCESS_RECEIVING")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.LoadMoreWrapper}>
                    <button>
                      {t("LOAD_MORE")} <FiArrowRight />
                    </button>
                    <div className={styles.UserProfile}>
                      <div className={styles.UserProfileImg}>
                        <img src={EllipseMan} />
                      </div>
                      <div>
                        <h6>{t("ADAM_WORLACK")}</h6>
                        <p>{t("FOUNDER_CEO")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {/* <Container>
          <Row>
            <div className={styles.AboutCardSection}>
              <div className={styles.AboutTextWrapper}>
                <h6>
                  {" "}
                  <Icon
                    icon="carbon:text-short-paragraph"
                    color="#1A3B6D"
                    width="40"
                    height="40"
                    rotate={2}
                  />{" "}
                  {t("ABOUT_COMPANY")}
                </h6>
                <h3>{t("POPULAR_LOGISTICS_SERVICES")}</h3>
              </div>
            </div>
            <Col lg={3}>
              <div className={styles.AboutCompanyCards}>
                <img src={Plan} />
                <div className={styles.AboutCardsDes}>
                  <h6>{t("AIR_FREIGHT")}</h6>
                  <p>Phasellus placerat dui sed metus luctus, vel hendrerit</p>
                </div>
                <div className={styles.ReadMore}>
                  <span>{t("READ_MORE")}</span>
                  <FiArrowRight />
                </div>
              </div>
            </Col>
            <Col lg={3}>
              {" "}
              <div className={styles.AboutCompanyCards}>
                <img src={Ship} />
                <div className={styles.AboutCardsDes}>
                  <h6>{t("OCEAN_FREIGHT")}</h6>
                  <p>Phasellus placerat dui sed metus luctus, vel hendrerit</p>
                </div>
                <div className={styles.ReadMore}>
                  <span>{t("READ_MORE")}</span>
                  <FiArrowRight />
                </div>
              </div>
            </Col>
            <Col lg={3}>
              {" "}
              <div className={styles.AboutCompanyCards}>
                <img src={Truck} />
                <div className={styles.AboutCardsDes}>
                  <h6>{t("ROAD_FREIGHT")}</h6>
                  <p>Phasellus placerat dui sed metus luctus, vel hendrerit</p>
                </div>
                <div className={styles.ReadMore}>
                  <span>{t("READ_MORE")}</span>
                  <FiArrowRight />
                </div>
              </div>
            </Col>
            <Col lg={3}>
              {" "}
              <div className={styles.AboutCompanyCards}>
                <img src={Rail} />
                <div className={styles.AboutCardsDes}>
                  <h6>{t("RAIL_FREIGHT")}</h6>
                  <p>Phasellus placerat dui sed metus luctus, vel hendrerit</p>
                </div>
                <div className={styles.ReadMore}>
                  <span>{t("READ_MORE")}</span>
                  <FiArrowRight />
                </div>
              </div>
            </Col>
          </Row>
        </Container> */}
      </div>
    </>
  );
}

export default AboutCompany;
