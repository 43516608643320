// LanguageContext.js
import React, { createContext, useState, useEffect } from "react";

export const LanguageContext = createContext();
export const LanguageProvider = ({ children }) => {
  const storedLanguage = localStorage.getItem("selectedLanguage");
  const [selectedLanguage, setSelectedLanguage] = useState(
    storedLanguage || "en"
  );
  const [selectedItem, setSelectedItem] = useState("");
  const [generate, setGenerate] = useState(0);
  const [detail, setDetail] = useState();

  const [purchaseOrderList, setPurchaseOrderList] = useState(2);
  const [purchaseOrder_isedit, setPurchaseOrder_isedit] = useState();

  const changeLanguage = (newLanguage) => {
    setSelectedLanguage(newLanguage);
    localStorage.setItem("selectedLanguage", newLanguage);
  };

  useEffect(() => {
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    }
  }, [storedLanguage]);

  return (
    <LanguageContext.Provider
      value={{
        selectedLanguage,
        changeLanguage,
        selectedItem,
        setSelectedItem,
        purchaseOrderList,
        setPurchaseOrderList,
        purchaseOrder_isedit,
        setPurchaseOrder_isedit,
        generate,
        setGenerate,
        detail,
        setDetail,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
