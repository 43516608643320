export const sanitizeHtmlTags = () => ({
  validate: (value) =>
    !/<(\/)?[a-z][\s\S]*>/i.test(value) || "HTML tags are not allowed.",
});

export const validationSchema = {
  companyName: {
    required: "PLEASE_PROVIDE_COMPANY_NAME",
    // minLength: {
    //   value: 5,
    //   message: "Company name must be at least 5 characters.",
    // },
    maxLength: {
      value: 50,
      message: "COMPANY_NAME_MUST_NOT_LESS_THAN_50_CHARACTER",
    },
    ...sanitizeHtmlTags(),
  },
  name: {
    required: "Please provide a name.",
    minLength: {
      value: 5,
      message: "NAME_MORE_THAN_5_CH",
    },
    maxLength: {
      value: 40,
      message: "NAME_LESS_THAN_40_CH",
    },
    ...sanitizeHtmlTags(),
  },
  userName: {
    required: "PROVIDE_USER_NAME",
    // minLength: {
    //   value: 2,
    //   message: "Username must be at least 2 characters.",
    // },
    maxLength: {
      value: 30,
      message: "USERNAME_NAME_MUST_LESS_THAN_50_CH",
    },
    ...sanitizeHtmlTags(),
  },
  email: {
    required: "PLEASE_ENTER_EMAIL",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: "PLEASE_PROVIDE_A_VALID_EMAIL_ADDRESS",
    },
    minLength: {
      value: 5,
      message: "EMAIL_MUST_BE_5_CHARACTER",
    },
    maxLength: {
      value: 100,
      message: "EMAIL_NOT_LESS_THAN_100_CHARACTER",
    },
  },
  phoneNumber: {
    required: "PROVIDE_PHONE_NO",
    minLength: {
      value: 5,
      message: "PHONE_ATLEAST_5_CH",
    },
    maxLength: {
      value: 15,
      message: "PHONE_NOT_MORE_THAN_100_CH",
    },
  },
  perUnitPrice: {
    required: "PROVIDE_PER_UNIT_PRICE",
    validate: {
      notNegative: (value) => {
        if (value <= 0) {
          return "PRICE_NOTE_NEGETIVE_OR_LESS_THAN_0";
        }
        return true;
      },
    },
  },
  taxId: {
    required: "PROVIDE_TAX_ID",
    maxLength: {
      value: 20,
      message: "TAX_ID_MUST_LESS_THAN_20_CH",
    },
  },
  occupation: {
    required: "PROVIDE_OCCUPATION",
    minLength: {
      value: 5,
      message: "OCCUPATION_MUST_5_CH",
    },
    maxLength: {
      value: 50,
      message: "OCCUPATION_MUST_LESS_THAN_50_CH",
    },
  },
  address: {
    required: "PROVIDE_ADDRESS",
    // minLength: {
    //   value: 5,
    //   message: "ADDRESS_MUST_5_CH",
    // },
    maxLength: {
      value: 250,
      message: "ADDRESS_NOT_MORE_THAN_250_CH",
    },
    ...sanitizeHtmlTags(),
  },

  password: {
    required: "ENTER_PASSWORD",
    minLength: {
      value: 6,
      message: "PASSWORD_MUST_6_CH",
    },
    maxLength: {
      value: 12,
      message: "PASSWORD_NOT_MORE_THAN_12_CH",
    },
    pattern: {
      value: /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).+$/,
      message: "PASSWORD_MUST_CONTAIN",
    },
  },
  OLD_PASSWORD: {
    required: "ENTER_PASSWORD",
    minLength: {
      value: 6,
      message: "PASSWORD_MUST_6_CH",
    },
    maxLength: {
      value: 12,
      message: "PASSWORD_NOT_MORE_THAN_12_CH",
    },
    pattern: {
      value: /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).+$/,
      message: "PASSWORD_MUST_CONTAIN",
    },
  },
  passwordForLogin: {
    required: "PLEASE_ENTER_PASSWORD",
  },
  confirmPassword: {
    required: "PASSWORD_NOT_MATCHED",
    minLength: {
      value: 6,
      message: "PASSWORD_MUST_7_CH",
    },
    maxLength: {
      value: 12,
      message: "PASSWORD_LESS_THAN_20_CH",
    },
    pattern: {
      value: /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).+$/,
      message: "PASSWORD_MUST_CONTAIN",
    },
  },
  verificationCode: {
    required: "PROVIDE_VERIFICATION_CODE",
    pattern: {
      value: /^\d{4}$/,
      message: "CODE_IF_OF_4_CH",
    },
  },
  PRODUCT_USE: {
    required: "PROVIDE_PRODUCT_USE",
    // minLength: {
    //   value: 2,
    //   message: "Product use must be at least 2 characters.",
    // },
    maxLength: {
      value: 150,
      message: "PRODUCT_NOT_MORE_THAN_15_CH",
    },
    ...sanitizeHtmlTags(),
  },
  MATERIAL_TYPE: {
    required: "SELECT_MATERIAL_TYPE",
  },
  MATERIAL_REFERENCE: {
    required: "PROVIDE_MATERIAL_REFERENCE",
    // minLength: {
    //   value: 5,
    //   message: "Material reference must be at least 5 characters.",
    // },
    maxLength: {
      value: 100,
      message: "MATERIAL_REF_NOT_MORE_THAN_100_CH",
    },
    ...sanitizeHtmlTags(),
  },
  PRODUCT_CODE: {
    required: "PROVIDE_PRODUCT_CODE",
    // minLength: {
    //   value: 5,
    //   message: "Product code must be at least 5 characters.",
    // },
    maxLength: {
      value: 50,
      message: "PRODUCT_CODE_NOT_MORE_THAN_50_CH",
    },
  },
  Location: {
    required: "SELECT_LOCATION",
  },
  Position: {
    required: "SELECT_POSITION",
  },
  Inventory_Unit: {
    required: "INVENTORY_UNIT",
  },
  PRODUCT_DESCRIPTION: {
    required: "PROVIDE_DESCRIPTION",
    // minLength: {
    //   value: 2,
    //   message: "Product description must be at least 2 characters.",
    // },
    maxLength: {
      value: 200,
      message: "PRODUCT_DESCRIPTION_NOT_MORE_THAN_200_CH",
    },

    ...sanitizeHtmlTags(),
  },
  SelectOrderId: {
    required: "PROVIDE_ORDER_ID",
    minLength: {
      value: 5,
      message: "ORDER_ID_SHOULD_BE_MIN_5_CH",
    },
    maxLength: {
      value: 20,
      message: "ORDER_ID_NOT_MORE_THAN_20_CH",
    },
  },
  Inventory_Details: {
    required: "SELECT_A_OPTION",
  },
  OrderType: {
    required: "SELECT_A_OPTION",
  },
  Registered_user_company: {
    required: "PLEASE_PROVIDE_USER_COMPANY",
    maxLength: {
      value: 50,
      message: "REGISTERED_COMPANY_NOT_MORE_THAN_50_CH",
    },
  },
  Applicant_area: {
    required: "SELECT_A_OPTION",
  },
  Supplier_detail: {
    required: "SELECT_A_OPTION",
  },
  Order_Status: {
    required: "SELECT_A_OPTION",
  },
  Quantity_unit: {
    required: "SELECT_A_OPTION",
  },
  totalquantity: {
    required: "SELECT_A_OPTION",
  },
  OrderCurrency: {
    required: "CURRENCY_IS_REQUIRED",
    validate: {
      notNegative: (value) => {
        if (value <= 0) {
          return "AMOUNT_NOT_NEGATIVE_OR_LESS_THAN_0";
        }
        return true;
      },
    },
  },
  OrderAmount: {
    required: "Amount is require",
    validate: {
      notNegative: (value) => {
        if (value <= 0) {
          return "AMOUNT_NOT_NEGATIVE_OR_LESS_THAN_0";
        }
        return true;
      },
    },
  },
  TotalQuantity: {
    required: "Quantity is require",
    validate: {
      notNegative: (value) => {
        if (value <= 0) {
          return "QUANTITY_NOT_NEGATIVE_OR_LESS_THAN_0";
        }
        return true;
      },
    },
  },
  SpecialNote: {
    required: "Note is require",
    maxLength: {
      value: 500,
      message: "NOTE_NOT_MORE_THAN_500_CH",
    },
  },

  // ==================STAFF================
  StaffName: {
    required: "PROVIDE_STAFF_NAME",
    maxLength: {
      value: 50,
      message: "STAFF_NOT_MORE_THAN_50_CH",
    },
  },
  StaffEmail: {
    required: "PLEASE_ENTER_EMAIL",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: "PLEASE_PROVIDE_A_VALID_EMAIL_ADDRESS",
    },
  },
  StaffContact: {
    required: "PROVIDE_PHONE_NO",
    minLength: {
      value: 5,
      message: "PHONE_ATLEAST_5_CH",
    },
    maxLength: {
      value: 15,
      message: "PHONE_NOT_MORE_THAN_15_CH", // Adjusted to match the message key
    },
  },
  SupplierName: {
    required: "SELECT_SUPPLIER",
  },

  Emailname: {
    required: "PROVIDE_NAME",

    maxLength: {
      value: 30,
      message: "NAME_LESS_THAN_30_CH",
    },
    ...sanitizeHtmlTags(),
  },
  EmailHolderAddress: {
    required: "PROVIDE_ADDRESS",
    // minLength: {
    //   value: 5,
    //   message: "ADDRESS_MUST_5_CH",
    // },
    maxLength: {
      value: 250,
      message: "ADDRESS_LESS_THAN_250_CH",
    },
    ...sanitizeHtmlTags(),
  },

  // ================PAYMENT============
  DeclarationNo: {
    required: "DECLARATION_NO_REQUIRE",
    // minLength: {
    //   value: 5,
    //   message: "ADDRESS_MUST_5_CH",
    // },
    maxLength: {
      value: 30,
      message: "DECLARATION_LESS_THAN_30_CH",
    },
    ...sanitizeHtmlTags(),
  },
  SettlementNo: {
    required: "SETTLEMENT_NO_REQUIRE",
    // minLength: {
    //   value: 5,
    //   message: "ADDRESS_MUST_5_CH",
    // },
    maxLength: {
      value: 30,
      message: "SETTLEMENT_LESS_THAN_30_CH",
    },
    ...sanitizeHtmlTags(),
  },
  IgraNo: {
    required: "IGRA_REQUIRED",
    // minLength: {
    //   value: 5,
    //   message: "ADDRESS_MUST_5_CH",
    // },
    maxLength: {
      value: 20,
      message: "IGRA_LESS_THAN_20_CH",
    },
    ...sanitizeHtmlTags(),
  },
  VerificationDate: {
    required: "VERIFICATION_DATE_IS_REQUIRED",

    ...sanitizeHtmlTags(),
  },
  CustomAmount: {
    required: "AMOUNT_IS_REQUIRED",
    validate: {
      notNegative: (value) => {
        if (value <= 0) {
          return "AMOUNT_NOT_NEGATIVE_OR_LESS_THAN_0";
        }
        return true;
      },
    },
  },
  CustomPin: {
    required: "PIN_IS_REQUIRED",

    ...sanitizeHtmlTags(),
  },
  CustomQuotation: {
    required: "QUOTATION_IS_REQUIRED",

    ...sanitizeHtmlTags(),
  },
  CustomPay: {
    required: "INVOICE_IS_REQUIRED",

    ...sanitizeHtmlTags(),
  },

  VerificationAmount: {
    required: "AMOUNT_IS_REQUIRED",
    validate: {
      notNegative: (value) => {
        if (value <= 0) {
          return "AMOUNT_NOT_NEGATIVE_OR_LESS_THAN_0";
        }
        return true;
      },
    },
  },
  VerificationPin: {
    required: "PIN_IS_REQUIRED",

    ...sanitizeHtmlTags(),
  },
  VerificationQuotation: {
    required: "QUOTATION_IS_REQUIRED",

    ...sanitizeHtmlTags(),
  },
  VerificationPay: {
    required: "INVOICE_IS_REQUIRED",

    ...sanitizeHtmlTags(),
  },

  ClerkAmount: {
    required: "AMOUNT_IS_REQUIRED",

    validate: {
      notNegative: (value) => {
        if (value <= 0) {
          return "AMOUNT_NOT_NEGATIVE_OR_LESS_THAN_0";
        }
        return true;
      },
    },
  },
  ClerkPin: {
    required: "PIN_IS_REQUIRED",

    ...sanitizeHtmlTags(),
  },
  ClerkQuotation: {
    required: "QUOTATION_IS_REQUIRED",

    ...sanitizeHtmlTags(),
  },
  ClerkPay: {
    required: "INVOICE_IS_REQUIRED",

    ...sanitizeHtmlTags(),
  },

  ConsolidatingAmount: {
    required: "AMOUNT_IS_REQUIRED",

    validate: {
      notNegative: (value) => {
        if (value <= 0) {
          return "AMOUNT_NOT_NEGATIVE_OR_LESS_THAN_0";
        }
        return true;
      },
    },
  },
  ConsolidatingPin: {
    required: "PIN_IS_REQUIRED",

    ...sanitizeHtmlTags(),
  },
  ConsolidatingQuotation: {
    required: "QUOTATION_IS_REQUIRED",

    ...sanitizeHtmlTags(),
  },
  ConsolidatingPay: {
    required: "INVOICE_IS_REQUIRED",

    ...sanitizeHtmlTags(),
  },

  VaceAmount: {
    required: "AMOUNT_IS_REQUIRED",

    validate: {
      notNegative: (value) => {
        if (value <= 0) {
          return "AMOUNT_NOT_NEGATIVE_OR_LESS_THAN_0";
        }
        return true;
      },
    },
  },
  VacePin: {
    required: "PIN_IS_REQUIRED",

    ...sanitizeHtmlTags(),
  },
  VaceQuotation: {
    required: "QUOTATION_IS_REQUIRED",

    ...sanitizeHtmlTags(),
  },
  VacePay: {
    required: "INVOICE_IS_REQUIRED",

    ...sanitizeHtmlTags(),
  },

  ChassisAmount: {
    required: "AMOUNT_IS_REQUIRED",

    validate: {
      notNegative: (value) => {
        if (value <= 0) {
          return "AMOUNT_NOT_NEGATIVE_OR_LESS_THAN_0";
        }
        return true;
      },
    },
  },
  ChassisPin: {
    required: "PIN_IS_REQUIRED",

    ...sanitizeHtmlTags(),
  },
  ChassisQuotation: {
    required: "QUOTATION_IS_REQUIRED",

    ...sanitizeHtmlTags(),
  },
  ChassisPay: {
    required: "INVOICE_IS_REQUIRED",

    ...sanitizeHtmlTags(),
  },
  VerificationDeposit: {
    required: "FIELD_IS_REQUIRED",
  },
  ShippingClerk: {
    required: "FIELD_IS_REQUIRED",
  },
  Consolidating: {
    required: "FIELD_IS_REQUIRED",
  },
  CustomCurrency: {
    required: "SELECT_CURRENCY",
  },
  VerificationCurrency: {
    required: "SELECT_CURRENCY",
  },
  ShippingClerkCurrency: {
    required: "SELECT_CURRENCY",
  },
  ConsolidatingCurrency: {
    required: "SELECT_CURRENCY",
  },
  VaceCurrency: {
    required: "SELECT_CURRENCY",
  },
  ChassisCurrency: {
    required: "SELECT_CURRENCY",
  },

  VehicleLicence: {
    required: "LICENSE_PLATE",

    maxLength: {
      value: 20,
      message: "LICENSE_PLATE_LESS_THAN_20_CH",
    },
    ...sanitizeHtmlTags(),
  },
  VehicleLable: {
    required: "VEHICLE_LABEL",

    maxLength: {
      value: 20,
      message: "LABEL_SHORT_THAN_20_CH",
    },
    ...sanitizeHtmlTags(),
  },
};
