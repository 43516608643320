import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "../../Customs/Alert.module.css";
import AlertCross from "../../../Assests/Images/AlertCross.svg";
import { useSelector } from "react-redux";
import CitransApi from "../../../helpers/Api.js";
import { STATUS_CODES } from "../../../Utils/StatusCode.js";
import { STATUS_MSG } from "../../../Utils/StatusMsg.js";
import Loader from "../../../Common/LoadingSpinner.js";
import Toster from "../../../Utils/Toster.js";

import { useTranslation } from "react-i18next";

function DeleteAlert({
  staffId,
  show,
  handleClose,
  handleGetStaffDetail,
  pageNumber,
  setSearchKey,
}) {
  const isAuthenticated = useSelector((state) => state.user.userToken);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteStaffData = () => {
    setIsLoading(true);

    let res = CitransApi.deleteStaffDetails(isAuthenticated, staffId);
    res.then((response) => {
      // console.log(response);
      // console.log("response of delete ", response);
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response.data?.code];
      if (response.code === STATUS_CODES.SUCCESS) {
        setIsLoading(false);
        Toster(t("DELETE_SUCCESSFULLY"), "success");
        handleGetStaffDetail(pageNumber);
        setSearchKey("");
        handleClose();
      } else {
        setIsLoading(false);
        Toster(t(ResponseCode), "error");
        handleGetStaffDetail(pageNumber);
        setSearchKey("");
        handleClose();
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <Modal show={show} onHide={handleClose}>
        <div className={styles.MainBox}>
          <div className={styles.header}>
            <p>{t("ALERT")}</p>
            <img src={AlertCross} onClick={handleClose}></img>
          </div>

          <div className={styles.devider}></div>

          <div className={styles.alertLabel}>
            <p>{t("ARE_YOU_SURE")}.</p>
          </div>

          <div className={styles.buttonContainer}>
            <button className={styles.buttonNo} onClick={handleClose}>
              {t("NO")}
            </button>
            <button
              className={styles.buttonYes}
              onClick={handleDeleteStaffData}
            >
              {t("YES")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DeleteAlert;
