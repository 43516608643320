import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import styles from "../Home.module.css";
import TopImg from "../../../Assests/Images/Rectangle.png";
import TopImg2 from "../../../Assests/Images/logistics-transport.jpg";
import { FcNext, FcPrevious } from "react-icons/fc";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <>
      <FcPrevious
        className={`${styles.PrevSlickArrow} slick-arrow`}
        onClick={onClick}
        style={{
          fontSize: "10px",
          cursor: "pointer",
          border: "1px solid #f5f5f5",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          padding: "6px",
          background: "#ffffff",
          boxShadow: "0px 0px 4px 0px #00000026",
        }}
      />
    </>
  );
}

function NextArrow(props) {
  const { onClick } = props;
  return (
    <>
      <FcNext
        className={`${styles.NextSlickArrow} slick-arrow`}
        onClick={onClick}
        style={{
          fontSize: "10px",
          cursor: "pointer",
          border: "1px solid #f5f5f5",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          padding: "6px",
          background: "#ffffff",
          boxShadow: "0px 0px 4px 0px #00000026",
        }}
      />
    </>
  );
}

function HomeTopSection() {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    // responsive: [
    //   {
    //     breakpoint: 320,
    //     settings: {
    //       arrows: true,
    //       slidesToShow: 1,
    //     },
    //   },

    //   {
    //     breakpoint: 480,
    //     settings: {
    //       arrows: true,
    //       slidesToShow: 1,
    //     },
    //   },

    //   {
    //     breakpoint: 567,
    //     settings: {
    //       arrows: true,
    //       slidesToShow: 1,
    //       width: 100,
    //     },
    //   },
    //   {
    //     breakpoint: 768,
    //     settings: {
    //       arrows: true,
    //       slidesToShow: 1,
    //     },
    //   },

    //   {
    //     breakpoint: 991,
    //     settings: {
    //       arrows: true,
    //       slidesToShow: 1,
    //     },
    //   },
    // ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      <div className={styles.HomeTopSection}>
        <div className={styles.TextCircle}>
          <div className={styles.TopText}>
            <div className={styles.TopTextHead}>
              <p>
                {" "}
                <Icon
                  icon="carbon:text-short-paragraph"
                  color="white"
                  width="40"
                  height="40"
                  rotate={2}
                />{" "}
                {t("BEST_LOGISTIC_OFFER")}
              </p>
            </div>
            <div className={styles.TopDescription}>
              <h1> {t("BEST_LOGISTIC_NETWORK")}</h1>
            </div>
          </div>
        </div>
        <div className={styles.TopImgWrapper}>
          <Slider {...settings}>
            <img src={TopImg} alt="truck" />
            <img src={TopImg2} alt="truck" />
            {/* {VehicleService.ExploreData.map((item, index) => (
              <div key={index}>
                <div className={styles.exploreData}>
                  <img src={item.image2} alt={`Slide ${index + 1}`} />
                  <h1>{item.heading}</h1>
                  <p>{item.text}</p>
                </div>
              </div>
            ))} */}
          </Slider>
        </div>
      </div>
    </>
  );
}

export default HomeTopSection;
