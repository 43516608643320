export const navigate = [
  {
    id: "1",
    name: "ABOUT_PORTX",
    link: "/",
  },
  {
    id: "2",
    name: "MEET_THE_TEAM",
    link: "",
  },
  {
    id: "3",
    name: "NEWS_&_MEDIA",
    link: "",
  },
  {
    id: "4",
    name: "OUR_PROJECTS",
    link: "",
  },
  {
    id: "5",
    name: "CONTACT",
    link: "",
  },
];
