import React, { useEffect, useState } from "react";
import styles from "./TrafficDetail.module.css";
import TopHeader from "../TopHeader/TopHeader";
import Sidebar from "../Sidebar/Sidebar";
import { Icon } from "@iconify/react";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import FileImg from "../../Assests/Images/Rectangle56.png";
import DateImg from "../../Assests/Images/DateImg.svg";
import RightImg from "../../Assests/Images/RightImg.svg";
import CrossImg from "../../Assests/Images/CrossImg.svg";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CitransApi from "../../helpers/Api";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import Toster from "../../Utils/Toster";
import Loader from "../../Common/LoadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import {
  ShippingClerks,
  companyOptions,
  verificationDeposits,
} from "../../mockData";
import { DOCUMENT_APPROVE } from "../../Utils/Constants";

function TrafficDetail() {
  const isAuthenticated = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();

  const shipment_id = location.state.shipment_id;

  const [isloading, setIsloading] = useState(true);
  const [detail, setDetail] = useState();
  const [commercialInvoice, setCommercialInvoice] = useState(null);
  const [packingList, setPackingList] = useState(null);
  const [permit, setPermit] = useState(null);
  const [blOrBooking, setBlOrBooking] = useState(null);
  const [blShipment, setBlShipment] = useState(null);
  const [certification, setCertification] = useState(null);
  const [otherDoc, setOtherDoc] = useState(null);
  const [document, setDocument] = useState();

  // =======FORMAT DELIVERY DATE======
  const originalDeliveryDate = detail?.delivery_date;
  const deliveridate = new Date(originalDeliveryDate);
  const daydelivery = deliveridate.getUTCDate();
  const monthdelivery = deliveridate.getUTCMonth() + 1;
  const yeardelivery = deliveridate.getUTCFullYear();
  const formattedDeliveryDate = `${daydelivery}/${monthdelivery}/${yeardelivery}`;

  // =======FORMAT ETA DATE======
  const originalETADate = detail?.eta_date;
  const ETAdate = new Date(originalETADate);
  const dayETA = ETAdate.getUTCDate();
  const monthETA = ETAdate.getUTCMonth() + 1;
  const yearETA = ETAdate.getUTCFullYear();
  const formattedETADate = `${dayETA}/${monthETA}/${yearETA}`;

  // =======FORMAT ETD DATE======
  const originalETDDate = detail?.etd_date;
  const ETDdate = new Date(originalETDDate);
  const dayETD = ETDdate.getUTCDate();
  const monthETD = ETDdate.getUTCMonth() + 1;
  const yearETD = ETDdate.getUTCFullYear();
  const formattedETDDate = `${dayETD}/${monthETD}/${yearETD}`;

  const handleFileChangeForCommDocs = (event, key) => {
    const file = event.target.files[0];
    setCommercialInvoice(file);
    handleUploadDocument(key, file);
  };

  const handleFileChangeForPacking = (event, key) => {
    // console.log("handleFileChangeForPacking ", key);
    const file = event.target.files[0];
    setPackingList(file);
    handleUploadDocument(key, file);
  };

  const handleFileChangeForPermit = (event, key) => {
    // console.log("handleFileChangeForPacking ", key);
    const file = event.target.files[0];
    setPermit(file);
    handleUploadDocument(key, file);
  };

  const handleFileChangeForBlOrBooking = (event, key) => {
    // console.log("handleFileChangeForPacking ", key);
    const file = event.target.files[0];
    setBlOrBooking(file);
    handleUploadDocument(key, file);
  };

  const handleFileChangeForBlShipment = (event, key) => {
    // console.log("handleFileChangeForPacking ", key);
    const file = event.target.files[0];
    setBlShipment(file);
    handleUploadDocument(key, file);
  };

  const handleFileChangeForCertification = (event, key) => {
    // console.log("handleFileChangeForPacking ", key);
    const file = event.target.files[0];
    setCertification(file);
    handleUploadDocument(key, file);
  };

  const handleFileChangeForOtherDoc = (event, key) => {
    // console.log("handleFileChangeForPacking ", key);
    const file = event.target.files[0];
    setOtherDoc(file);
    handleUploadDocument(key, file);
  };

  const handleGetTrafficDetail = () => {
    setIsloading(false);
    let res = CitransApi.getTrafficDetail(isAuthenticated, shipment_id);

    res.then((data) => {
      if (
        data.code == STATUS_CODES.INVALID_TOKEN ||
        data.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        // console.log("handleGetTrafficDetail ", data);
        setDetail(data.data.trafficDetails);
        setDocument(data.data.trafficDetails.document);
      }
    });
  };

  useEffect(() => {
    handleGetTrafficDetail();
  }, []);

  const handleUploadDocument = (key, value) => {
    setIsloading(true);

    let Postdata = new FormData();

    Postdata.append(`${key}`, value);

    let res = CitransApi.postDocumentInTraffic(
      isAuthenticated,
      shipment_id,
      Postdata
    );
    res.then((response) => {
      setIsloading(false);
      const ResponseCode = STATUS_MSG[response.data?.code];
      if (response.code === STATUS_CODES.SUCCESS) {
        Toster(t("DOCUMENT_UPLOADED"), "success");
        if (key == "commercial_invoice_file") {
          setCommercialInvoice(response.data.document.commercial_invoice_file);
        } else if (key == "packing_list_file") {
          setPackingList(response.data.document.packing_list_file);
        } else if (key == "permit_file") {
          // setPackingList(null);
          setPermit(response.data.document.permit_file);
        } else if (key == "bl_of_booking_file") {
          setBlOrBooking(response.data.document.bl_of_booking_file);
        } else if (key == "bl_of_shipment_file") {
          setBlShipment(response.data.document.bl_of_shipment_file);
        } else if (key == "certificates_of_origin_file") {
          setCertification(response.data.document.certificates_of_origin_file);
        } else if (key == "other_documents_file") {
          setOtherDoc(response.data.document.other_documents_file);
        }
      } else {
        if (
          response.code == STATUS_CODES.VALIDATION_ERROR ||
          response.data.code == STATUS_CODES.VALIDATION_ERROR
        ) {
          Toster(response.data.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <section>
      {isloading ? <Loader /> : null}
      <div>
        <TopHeader />
      </div>

      <div className={styles.mainBox}>
        <div className={styles.innerBox}>
          <div className={styles.heading}>
            <div className={styles.iconsClass}>
              <p
                className={styles.iconsClassReload}
                onClick={() => navigate("/traffic")}
              >
                <Icon icon="ep:back" color="white" width="24" height="24" />
              </p>
            </div>
            <div className={styles.HeadingLabel}>
              <p>{t("TRAFFIC_DETAILS")}</p>
            </div>
          </div>

          <div className={styles.billing}>
            <div className={styles.orderAndMethod}>
              {/* <div className={styles.orderAndMethodBlocks}>
                <div className={styles.orderType}>
                  <p>{t("ORDER_TYPE")}</p>
                </div>
                <div className={styles.optionButton}>
                  <Button className={styles.optionButtonButton1}>
                    {t("COMPLETE")}
                  </Button>
                  <Button className={styles.optionButtonButton2}>
                    {t("SPLIT")}
                  </Button>
                </div>
              </div> */}

              <div className={styles.orderAndMethodBlocks}>
                <div className={styles.orderType}>
                  <p>{t("SHIPPING_METHOD")}</p>
                </div>
                <div className={styles.optionButton}>
                  <Button className={styles.optionButtonButton1}>
                    {t("SEA")}
                  </Button>
                  {/* <Button className={styles.optionButtonButton2}>
                    {t("AIR")}
                  </Button> */}
                </div>
              </div>
            </div>

            <div className={styles.ShippingDetails}>
              <p>{t("SHIPPING_DETAILS")}</p>
            </div>

            {/* First Bill */}
            <div className={styles.billingBox}>
              <div className={styles.bookingDetail}>
                <div className={styles.bookingDetailLeft}>
                  <div className={styles.BLorbooking}>
                    <p>
                      {t("BL_OR_BOOKING")} : {detail?.bl_number}
                    </p>
                  </div>

                  <Stack direction="horizontal" gap={1}>
                    <p className={styles.BLorbooking_key}>{t("POL")} :</p>
                    <p className={styles.BLorbooking_value}>{detail?.pol}</p>
                  </Stack>
                  <Stack direction="horizontal" gap={1}>
                    <p className={styles.BLorbooking_key}>{t("VESSEL")} : </p>
                    <p className={styles.BLorbooking_value}>{detail?.vessel}</p>
                  </Stack>
                  <Stack direction="horizontal" gap={1}>
                    <p className={styles.BLorbooking_key}>
                      {t("DELEVERY_DATE")} :{" "}
                    </p>
                    <p className={styles.BLorbooking_value}>
                      {formattedDeliveryDate}
                    </p>
                    <img
                      src={DateImg}
                      className={styles.bookingDetailRightFlexImgLeft}
                    ></img>
                  </Stack>
                  <Stack direction="horizontal" gap={1}>
                    <p className={styles.BLorbooking_key}>{t("ETA_DATE")} :</p>
                    <p className={styles.BLorbooking_value}>
                      {formattedETADate}
                    </p>
                    <img
                      src={DateImg}
                      width="14px"
                      height="14px"
                      className={styles.bookingDetailRightFlexImgLeft}
                    ></img>
                  </Stack>
                </div>

                <div className={styles.bookingDetailRight}>
                  <div className={styles.bookingDetailRightFlex}>
                    <p className={styles.BLorbooking_key}>
                      {t("QUANTITY_OF_CONTAINERS")} :
                    </p>
                    <p className={styles.BLorbooking_value_right}>
                      {detail?.quantity_of_container}
                    </p>
                  </div>

                  <div className={styles.bookingDetailRightFlex}>
                    <p className={styles.BLorbooking_key}>
                      {t("SHIPPING_LINE")} :
                    </p>
                    <p className={styles.BLorbooking_value_right}>
                      {detail?.shipping_line}
                    </p>
                  </div>

                  <div className={styles.bookingDetailRightFlex}>
                    <p className={styles.BLorbooking_key}>{t("ETD_DATE")} :</p>
                    <p className={styles.BLorbooking_value_right}>
                      {formattedETDDate}
                    </p>
                    <img
                      src={DateImg}
                      width="14px"
                      height="14px"
                      className={styles.bookingDetailRightFlexImgRight}
                    ></img>
                  </div>

                  <div className={styles.bookingDetailRightFlex}>
                    <p className={styles.BLorbooking_key}> {t("POD")} : </p>
                    <p className={styles.BLorbooking_value_right}>
                      {detail?.pod}
                    </p>
                  </div>
                </div>
              </div>

              <div className={styles.devider}></div>

              {detail?.containers?.map((product, index) => {
                var container_no = product.container_number;
                var uppercaseContainer_no = container_no.toUpperCase();
                return (
                  <div className={styles.container_holds_product} key={index}>
                    <div className={styles.productBox}>
                      <div className={styles.productId1}>
                        <p className={styles.BLorbooking_value}>
                          {uppercaseContainer_no}
                        </p>
                      </div>
                      <div className={styles.productId2}>
                        <Stack direction="horizontal" gap={2}>
                          <p className={styles.BLorbooking_key}>
                            {t("BATCH_NO")}.{" "}
                          </p>
                          <p className={styles.BLorbooking_value}>
                            {product.batch_number}
                          </p>
                        </Stack>
                      </div>
                      <div className={styles.productId3}>
                        <Stack direction="horizontal" gap={2}>
                          <p className={styles.BLorbooking_key}>
                            {t("LOCATION")}
                          </p>
                          <p className={styles.BLorbooking_value}>
                            {product.location}
                          </p>
                        </Stack>
                      </div>
                    </div>

                    {product?.containerItemsDetails?.map((info, index) => {
                      var BL_no = info.bl_number;
                      var uppercaseBL_no = BL_no.toUpperCase();
                      return (
                        <React.Fragment key={index}>
                          <div className={styles.productBox2}>
                            <div className={styles.booking}>
                              <p className={styles.productTitle}>
                                {t("BL_OR_BOOKING")} {uppercaseBL_no}
                              </p>
                            </div>
                            <div className={styles.quantity}>
                              <p className={styles.productQuantity}>
                                {t("PRODUCT")} {index + 1} ({t("QUANTITY")})
                              </p>
                              <p className={styles.productTitle}>
                                {info.product_quantity}
                              </p>
                            </div>
                            <div className={styles.balance}>
                              <p className={styles.productQuantity}>
                                {t("PRODUCT")} {index + 1} ({t("BALANCE")})
                              </p>
                              <p className={styles.productTitle}>
                                {info.remaining_quantity}
                              </p>
                            </div>
                            <div className={styles.balance}>
                              <p className={styles.productQuantity}>
                                {t("ORDER_ID")}
                              </p>
                              <p className={styles.productTitle}>
                                {info.custom_order_id}
                              </p>
                            </div>
                          </div>

                          {product?.containerItemsDetails.length - 1 !==
                          index ? (
                            <div className={styles.devider}></div>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                );
              })}

              <div className={styles.devider}></div>

              <div className={styles.documentHolder}>
                <div className={styles.documentHeadind}>
                  <p>{t("ANNEX_DOCUMENTS")}</p>
                </div>

                <Row className="gx-1 gy-3">
                  <Col xs={12} sm={6}>
                    <div className={styles.documentColumn}>
                      {document?.commercial_invoice_approve_status ===
                      DOCUMENT_APPROVE.APPROVE ? (
                        <div
                          className={styles.imgSet}
                          onClick={() =>
                            window.open(document?.commercial_invoice_file)
                          }
                        >
                          <img
                            src={FileImg}
                            width="50px"
                            height="50px"
                            alt="File Icon"
                          />
                        </div>
                      ) : (
                        <div className={styles.imgSet}>
                          <label htmlFor="fileInput">
                            <img
                              src={FileImg}
                              width="50px"
                              height="50px"
                              alt="File Icon"
                              style={{ cursor: "pointer" }}
                            />
                          </label>
                          <input
                            type="file"
                            id="fileInput"
                            accept="image/*" // Specify the accepted file types if needed
                            onChange={(e) => {
                              handleFileChangeForCommDocs(
                                e,
                                "commercial_invoice_file"
                              );
                            }}
                            style={{ display: "none" }}
                          />
                        </div>
                      )}

                      <div className={styles.documentName}>
                        <div>
                          {t("DOCUMENT_ARR_0")}

                          {document?.commercial_invoice_approve_status ===
                          DOCUMENT_APPROVE.APPROVE ? (
                            <div style={{ fontSize: "10px", color: "green" }}>
                              {" "}
                              approved{" "}
                            </div>
                          ) : document?.commercial_invoice_approve_status ===
                            DOCUMENT_APPROVE.CANCEL ? (
                            <div style={{ fontSize: "10px", color: "red" }}>
                              {" "}
                              rejected. please re upload doc.{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {commercialInvoice ? (
                        <div
                          className={styles.documentUploaded}
                          onClick={() => {
                            window.open(commercialInvoice);
                          }}
                        >
                          <span title="click to preview">
                            <Icon
                              icon="mdi:file-outline"
                              color="grey"
                              width="30"
                              height="30"
                            />
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      {!commercialInvoice ? (
                        <React.Fragment>
                          {document?.commercial_invoice_file ? (
                            <div
                              className={styles.documentUploaded}
                              onClick={() =>
                                window.open(document.commercial_invoice_file)
                              }
                            >
                              <span title="click to preview">
                                <Icon
                                  icon="mdi:file-outline"
                                  color="grey"
                                  width="30"
                                  height="30"
                                />
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>

                  <Col xs={12} sm={6}>
                    <div className={styles.documentColumn}>
                      {document?.packing_list_approve_status ===
                      DOCUMENT_APPROVE.APPROVE ? (
                        <div
                          className={styles.imgSet}
                          onClick={() =>
                            window.open(document?.packing_list_file)
                          }
                        >
                          <img
                            src={FileImg}
                            width="50px"
                            height="50px"
                            alt="File Icon"
                          />
                        </div>
                      ) : (
                        <div className={styles.imgSet}>
                          <label htmlFor="fileInput12">
                            <img
                              src={FileImg}
                              width="50px"
                              height="50px"
                              alt="File Icon"
                              style={{ cursor: "pointer" }}
                            />
                          </label>
                          <input
                            type="file"
                            id="fileInput12"
                            accept="image/*" // Specify the accepted file types if needed
                            onChange={(e) =>
                              handleFileChangeForPacking(e, "packing_list_file")
                            }
                            style={{ display: "none" }}
                          />
                        </div>
                      )}

                      <div className={styles.documentName}>
                        <div>
                          {t("DOCUMENT_ARR_1")}
                          {document?.packing_list_approve_status ===
                          DOCUMENT_APPROVE.APPROVE ? (
                            <div style={{ fontSize: "10px", color: "green" }}>
                              {" "}
                              approved{" "}
                            </div>
                          ) : document?.packing_list_approve_status ===
                            DOCUMENT_APPROVE.CANCEL ? (
                            <div style={{ fontSize: "10px", color: "red" }}>
                              {" "}
                              rejected. please re upload doc.{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {packingList ? (
                        <div
                          className={styles.documentUploaded}
                          onClick={() => window.open(packingList)}
                        >
                          <span title="click to preview">
                            <Icon
                              icon="mdi:file-outline"
                              color="grey"
                              width="30"
                              height="30"
                            />
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      {!packingList ? (
                        <React.Fragment>
                          {document?.packing_list_file ? (
                            <div
                              className={styles.documentUploaded}
                              onClick={() =>
                                window.open(document.packing_list_file)
                              }
                            >
                              <span title="click to preview">
                                <Icon
                                  icon="mdi:file-outline"
                                  color="grey"
                                  width="30"
                                  height="30"
                                />
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>

                  <Col xs={12} sm={6}>
                    <div className={styles.documentColumn}>
                      {document?.permit_approve_status ===
                      DOCUMENT_APPROVE.APPROVE ? (
                        <div
                          className={styles.imgSet}
                          onClick={() => window.open(document?.permit_file)}
                        >
                          <img
                            src={FileImg}
                            width="50px"
                            height="50px"
                            alt="File Icon"
                          />
                        </div>
                      ) : (
                        <div className={styles.imgSet}>
                          <label htmlFor="fileInput123">
                            <img
                              src={FileImg}
                              width="50px"
                              height="50px"
                              alt="File Icon"
                              style={{ cursor: "pointer" }}
                            />
                          </label>
                          <input
                            type="file"
                            id="fileInput123"
                            accept="image/*" // Specify the accepted file types if needed
                            onChange={(e) =>
                              handleFileChangeForPermit(e, "permit_file")
                            }
                            style={{ display: "none" }}
                          />
                        </div>
                      )}

                      <div className={styles.documentName}>
                        <div>
                          {t("DOCUMENT_ARR_2")}
                          {document?.permit_approve_status ===
                          DOCUMENT_APPROVE.APPROVE ? (
                            <div style={{ fontSize: "10px", color: "green" }}>
                              {" "}
                              approved{" "}
                            </div>
                          ) : document?.permit_approve_status ===
                            DOCUMENT_APPROVE.CANCEL ? (
                            <div style={{ fontSize: "10px", color: "red" }}>
                              {" "}
                              rejected. please re upload doc.{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {permit ? (
                        <div
                          className={styles.documentUploaded}
                          onClick={() => window.open(permit)}
                        >
                          <span title="click to preview">
                            <Icon
                              icon="mdi:file-outline"
                              color="grey"
                              width="30"
                              height="30"
                            />
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      {!permit ? (
                        <React.Fragment>
                          {document?.permit_file ? (
                            <div
                              className={styles.documentUploaded}
                              onClick={() => window.open(document.permit_file)}
                            >
                              <span title="click to preview">
                                <Icon
                                  icon="mdi:file-outline"
                                  color="grey"
                                  width="30"
                                  height="30"
                                />
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>

                  <Col xs={12} sm={6}>
                    <div className={styles.documentColumn}>
                      {document?.bl_of_booking_approve_status ===
                      DOCUMENT_APPROVE.APPROVE ? (
                        <div
                          className={styles.imgSet}
                          onClick={() =>
                            window.open(document?.bl_of_booking_file)
                          }
                        >
                          <img
                            src={FileImg}
                            width="50px"
                            height="50px"
                            alt="File Icon"
                          />
                        </div>
                      ) : (
                        <div className={styles.imgSet}>
                          <label htmlFor="fileInput124">
                            <img
                              src={FileImg}
                              width="50px"
                              height="50px"
                              alt="File Icon"
                              style={{ cursor: "pointer" }}
                            />
                          </label>
                          <input
                            type="file"
                            id="fileInput124"
                            accept="image/*" // Specify the accepted file types if needed
                            onChange={(e) =>
                              handleFileChangeForBlOrBooking(
                                e,
                                "bl_of_booking_file"
                              )
                            }
                            style={{ display: "none" }}
                          />
                        </div>
                      )}

                      <div className={styles.documentName}>
                        <div>
                          {t("DOCUMENT_ARR_3")}
                          {document?.bl_of_booking_approve_status ===
                          DOCUMENT_APPROVE.APPROVE ? (
                            <div style={{ fontSize: "10px", color: "green" }}>
                              {" "}
                              approved{" "}
                            </div>
                          ) : document?.bl_of_booking_approve_status ===
                            DOCUMENT_APPROVE.CANCEL ? (
                            <div style={{ fontSize: "10px", color: "red" }}>
                              {" "}
                              rejected. please re upload doc.{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {blOrBooking ? (
                        <div
                          className={styles.documentUploaded}
                          onClick={() => window.open(blOrBooking)}
                        >
                          <span title="click to preview">
                            <Icon
                              icon="mdi:file-outline"
                              color="grey"
                              width="30"
                              height="30"
                            />
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      {!blOrBooking ? (
                        <React.Fragment>
                          {document?.bl_of_booking_file ? (
                            <div
                              className={styles.documentUploaded}
                              onClick={() =>
                                window.open(document.bl_of_booking_file)
                              }
                            >
                              <span title="click to preview">
                                <Icon
                                  icon="mdi:file-outline"
                                  color="grey"
                                  width="30"
                                  height="30"
                                />
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>

                  <Col xs={12} sm={6}>
                    <div className={styles.documentColumn}>
                      {document?.bl_of_shipment_approve_status ===
                      DOCUMENT_APPROVE.APPROVE ? (
                        <div
                          className={styles.imgSet}
                          onClick={() =>
                            window.open(document?.bl_of_shipment_file)
                          }
                        >
                          <img
                            src={FileImg}
                            width="50px"
                            height="50px"
                            alt="File Icon"
                          />
                        </div>
                      ) : (
                        <div className={styles.imgSet}>
                          <label htmlFor="fileInput125">
                            <img
                              src={FileImg}
                              width="50px"
                              height="50px"
                              alt="File Icon"
                              style={{ cursor: "pointer" }}
                            />
                          </label>
                          <input
                            type="file"
                            id="fileInput125"
                            accept="image/*" // Specify the accepted file types if needed
                            onChange={(e) =>
                              handleFileChangeForBlShipment(
                                e,
                                "bl_of_shipment_file"
                              )
                            }
                            style={{ display: "none" }}
                          />
                        </div>
                      )}

                      <div className={styles.documentName}>
                        <div>
                          {t("DOCUMENT_ARR_4")}
                          {document?.bl_of_shipment_approve_status ===
                          DOCUMENT_APPROVE.APPROVE ? (
                            <div style={{ fontSize: "10px", color: "green" }}>
                              {" "}
                              approved{" "}
                            </div>
                          ) : document?.bl_of_shipment_approve_status ===
                            DOCUMENT_APPROVE.CANCEL ? (
                            <div style={{ fontSize: "10px", color: "red" }}>
                              {" "}
                              rejected. please re upload doc.{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {blShipment ? (
                        <div
                          className={styles.documentUploaded}
                          onClick={() => window.open(blShipment)}
                        >
                          <span title="click to preview">
                            <Icon
                              icon="mdi:file-outline"
                              color="grey"
                              width="30"
                              height="30"
                            />
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      {!blShipment ? (
                        <React.Fragment>
                          {document?.bl_of_shipment_file ? (
                            <div
                              className={styles.documentUploaded}
                              onClick={() =>
                                window.open(document.bl_of_shipment_file)
                              }
                            >
                              <span title="click to preview">
                                <Icon
                                  icon="mdi:file-outline"
                                  color="grey"
                                  width="30"
                                  height="30"
                                />
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>

                  <Col xs={12} sm={6}>
                    <div className={styles.documentColumn}>
                      {document?.certificates_of_origin_approve_status ===
                      DOCUMENT_APPROVE.APPROVE ? (
                        <div
                          className={styles.imgSet}
                          onClick={() =>
                            window.open(document?.certificates_of_origin_file)
                          }
                        >
                          <img
                            src={FileImg}
                            width="50px"
                            height="50px"
                            alt="File Icon"
                          />
                        </div>
                      ) : (
                        <div className={styles.imgSet}>
                          <label htmlFor="fileInput126">
                            <img
                              src={FileImg}
                              width="50px"
                              height="50px"
                              alt="File Icon"
                              style={{ cursor: "pointer" }}
                            />
                          </label>
                          <input
                            type="file"
                            id="fileInput126"
                            accept="image/*" // Specify the accepted file types if needed
                            onChange={(e) =>
                              handleFileChangeForCertification(
                                e,
                                "certificates_of_origin_file"
                              )
                            }
                            style={{ display: "none" }}
                          />
                        </div>
                      )}

                      <div className={styles.documentName}>
                        <div>
                          {t("DOCUMENT_ARR_5")}
                          {document?.certificates_of_origin_approve_status ===
                          DOCUMENT_APPROVE.APPROVE ? (
                            <div style={{ fontSize: "10px", color: "green" }}>
                              {" "}
                              approved{" "}
                            </div>
                          ) : document?.certificates_of_origin_approve_status ===
                            DOCUMENT_APPROVE.CANCEL ? (
                            <div style={{ fontSize: "10px", color: "red" }}>
                              {" "}
                              rejected. please re upload doc.{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {certification ? (
                        <div
                          className={styles.documentUploaded}
                          onClick={() => window.open(certification)}
                        >
                          <span title="click to preview">
                            <Icon
                              icon="mdi:file-outline"
                              color="grey"
                              width="30"
                              height="30"
                            />
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      {!certification ? (
                        <React.Fragment>
                          {document?.certificates_of_origin_file ? (
                            <div
                              className={styles.documentUploaded}
                              onClick={() =>
                                window.open(
                                  document.certificates_of_origin_file
                                )
                              }
                            >
                              <span title="click to preview">
                                <Icon
                                  icon="mdi:file-outline"
                                  color="grey"
                                  width="30"
                                  height="30"
                                />
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>

                  <Col xs={12} sm={6}>
                    <div className={styles.documentColumn}>
                      {document?.other_documents_approve_status ===
                      DOCUMENT_APPROVE.APPROVE ? (
                        <div
                          className={styles.imgSet}
                          onClick={() =>
                            window.open(document?.other_documents_file)
                          }
                        >
                          <img
                            src={FileImg}
                            width="50px"
                            height="50px"
                            alt="File Icon"
                          />
                        </div>
                      ) : (
                        <div className={styles.imgSet}>
                          <label htmlFor="fileInput127">
                            <img
                              src={FileImg}
                              width="50px"
                              height="50px"
                              alt="File Icon"
                              style={{ cursor: "pointer" }}
                            />
                          </label>
                          <input
                            type="file"
                            id="fileInput127"
                            accept="image/*" // Specify the accepted file types if needed
                            onChange={(e) =>
                              handleFileChangeForOtherDoc(
                                e,
                                "other_documents_file"
                              )
                            }
                            style={{ display: "none" }}
                          />
                        </div>
                      )}

                      <div className={styles.documentName}>
                        <div>
                          {t("DOCUMENT_ARR_6")}
                          {document?.other_documents_approve_status ===
                          DOCUMENT_APPROVE.APPROVE ? (
                            <div style={{ fontSize: "10px", color: "green" }}>
                              {" "}
                              approved{" "}
                            </div>
                          ) : document?.other_documents_approve_status ===
                            DOCUMENT_APPROVE.CANCEL ? (
                            <div style={{ fontSize: "10px", color: "red" }}>
                              {" "}
                              rejected. please re upload doc.{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {otherDoc ? (
                        <div
                          className={styles.documentUploaded}
                          onClick={() => window.open(otherDoc)}
                        >
                          <span title="click to preview">
                            <Icon
                              icon="mdi:file-outline"
                              color="grey"
                              width="30"
                              height="30"
                            />
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      {!otherDoc ? (
                        <React.Fragment>
                          {document?.other_documents_file ? (
                            <div
                              className={styles.documentUploaded}
                              onClick={() =>
                                window.open(document.other_documents_file)
                              }
                            >
                              <span title="click to preview">
                                <Icon
                                  icon="mdi:file-outline"
                                  color="grey"
                                  width="30"
                                  height="30"
                                />
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </section>
  );
}

export default TrafficDetail;
