import React, { useState, useEffect, useCallback } from "react";
import styles from "./Profile.module.css";
import ReactPaginate from "react-paginate";
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Toster from "../../Utils/Toster";
import Loader from "../../Common/LoadingSpinner";
import CitransApi from "../../helpers/Api";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { PAGINATE } from "../../Utils/Constants";
import _, { debounce } from "lodash";
import { Icon } from "@iconify/react";
import AddMore from "./AddMore";

export default function AccountEmailList({ reFetch, setReFetch }) {
  const { t } = useTranslation();

  const isAuthenticated = useSelector((state) => state.user.userToken);

  const dispatch = useDispatch();

  const itemsPerPage = PAGINATE.PER_PAGE;

  const [isloading, setIsloading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState();
  const [searchKey, setSearchKey] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [editData, setEditData] = useState();

  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNumber(newPageNumber);
  };

  const handleGetEmailDetail = (pageNumber) => {
    setIsloading(true);
    let res = CitransApi.getEmailDetails(isAuthenticated, pageNumber);

    res.then((data) => {
      if (
        data.code == STATUS_CODES.INVALID_TOKEN ||
        data.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        setIsloading(false);
        setEmailList(data.data.emailList);
        setTotalRecords(data.data.count);
        setReFetch(false);
      }
    });
  };

  const searchEmailDetail = (event) => {
    let res = CitransApi.searchEmailDetails(isAuthenticated, event);

    res.then((data) => {
      if (
        data.code == STATUS_CODES.INVALID_TOKEN ||
        data.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        setEmailList(data?.data.emailList);
      }
    });
  };

  useEffect(() => {
    handleGetEmailDetail(pageNumber);
  }, [pageNumber, reFetch]);

  const delayedSearch = useCallback(
    debounce((event) => {
      searchEmailDetail(event);
    }, 300),
    []
  );

  const handleChange = (event) => {
    setSearchKey(event);
    delayedSearch(event);
  };

  const handleClose = () => {
    setShowEdit(false);
  };

  const handelEditEmail = (data) => {
    setShowEdit(true);
    setEditData(data);
  };

  const handelDeleteEmail = (email_account_id) => {
    let res = CitransApi.deleteEmailAccount(isAuthenticated, email_account_id);

    res.then((data) => {
      if (
        data.code == STATUS_CODES.INVALID_TOKEN ||
        data.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        Toster(t("EMAIL_DELETE"), "success");
        handleGetEmailDetail(pageNumber);
      }
    });
  };

  useEffect(() => {});

  return (
    <>
      <div>
        {isloading ? <Loader /> : null}
        {!_.isEmpty(emailList) && (
          <Row>
            <Col lg={6}>
              <Form>
                <Form.Group className="LoginInput">
                  <Form.Label>{t("SEARCH")}</Form.Label>
                  <Form.Control
                    // className={styles.SearchField}
                    type="search"
                    placeholder={t("SEARCH_BY_EMAIL_NAME_LOCATION")}
                    onChange={(e) => {
                      handleChange(e.target.value);
                    }}
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
        )}
        <Row>
          {Array.isArray(emailList) &&
            emailList?.map((card, index) => (
              <Col lg={6} key={index}>
                <div className={styles.CardBody}>
                  <h5>{card.name}</h5>
                  <p>{card.email}</p>
                  <p>
                    {t("POSITION")} : <span>{card.position_desc}</span>
                  </p>
                  <p>
                    {t("LOCATION")} : <span>{card.location}</span>
                  </p>
                  <div className={styles.emailFooter}>
                    <p className={styles.iconsClassEdit}>
                      <Icon
                        icon="ic:round-edit"
                        color="#626262"
                        width="24"
                        height="24"
                        onClick={() => handelEditEmail(card)}
                      />
                    </p>
                    <p className={styles.iconsClassDelete}>
                      <Icon
                        icon="ic:baseline-delete"
                        color="red"
                        width="24"
                        height="24"
                        onClick={() => handelDeleteEmail(card.id)}
                      />
                    </p>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
        {totalRecords === 0 ? <div>{t("RESULT_NOT_FOUND")}</div> : null}
        {searchKey === "" ? (
          <div className={styles.tableDetail}>
            <div className={styles.totalRecord}>
              {/* {totalRecords !== 0 ? (
                <p>
                  {t("SHOWING")} {1 + pageNumber * 10 - 10}-
                  {(pageNumber - 1) * 10 + emailList?.length} of {totalRecords}{" "}
                  {t("RECORDS")}
                </p>
              ) : (
                ""
              )} */}
            </div>
            <div>
              {totalRecords > itemsPerPage && (
                <ReactPaginate
                  previousLabel="Previous"
                  nextLabel="Next"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  pageCount={Math.ceil(totalRecords / itemsPerPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <AddMore
        show={showEdit}
        handleClose={handleClose}
        isEdit={showEdit}
        data={editData}
        handleGetEmailDetail={handleGetEmailDetail}
        pageNumber={pageNumber}
        setReFetch={setReFetch}
      />
    </>
  );
}
