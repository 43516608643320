import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ResetPsw from "../../Assests/Images/resetpassword 1.png";
import { Form } from "react-bootstrap";
import { MdOutlineMail } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { validationSchema } from "../../Common/ValidationSchema";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CitransApi from "../../helpers/Api";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { useState } from "react";
import Loader from "../../Common/LoadingSpinner";
import ResetPassword from "../ResetPassword/ResetPassword";

function ForgotPassword({ show, setShow, handleClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [resetData, setResetData] = useState({ email: "", token: "" });
  const { register, handleSubmit, setValue, formState, reset } = useForm({
    mode: "onChange",
  });
  const closeClick = () => {
    handleClose();
    reset();
  };
  const handelSubmit = (data) => {
    if (data) {
      var userEmail = data.email;
      setIsLoading(true);
      const response = CitransApi.forgotPassword(data);
      response.then((data) => {
        setResetData({ userEmail, token: data.data.token });
        setIsLoading(false);
        if (data.code === STATUS_CODES.SUCCESS) {
          setShow(false);
          setShowReset(true);
          toast.success("Reset code send successfully!", {
            autoClose: 2000,
          });
        } else if (data.data.code === 112) {
          toast.error("Account not found, please enter the correct email!", {
            autoClose: 2000,
          });
        } else if (data.data.code === 123) {
          toast.error("Failed to send email!", {
            autoClose: 2000,
          });
        }
      });
      response.catch((err) => {
        setIsLoading(false);
      });
      setValue("email", "");
    }
  };
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <div className="ForgotPsw">
          <Modal.Header>
            <img src={ResetPsw} />
            <Modal.Title>{t("RESET_YOUR_PASSWORD")}</Modal.Title>
          </Modal.Header>
          <Form noValidate onSubmit={handleSubmit(handelSubmit)}>
            <Modal.Body>
              <p>{t("RESET_LINK_TEXT")}</p>
              <Form.Group className="LoginInput">
                <Form.Label>{t("EMAIL_ID")}</Form.Label>
                <Form.Control
                  {...register("email", validationSchema.email)}
                  isInvalid={!!formState.errors.email}
                  type="text"
                  name="email"
                  placeholder={`${t("ENTER")} ${t("EMAIL_ID")}`}
                  onChange={(e) => {
                    setValue("email", e.target.value.replace(/\s/g, ""), {
                      shouldValidate: true,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {t(formState.errors.email?.message)}
                </Form.Control.Feedback>
                <MdOutlineMail />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => closeClick()}>
                {t("CLOSE")}
              </Button>
              <Button variant="primary" type="submit">
                {t("RESET")}
              </Button>
            </Modal.Footer>
          </Form>
        </div>
      </Modal>
      <ResetPassword
        show={showReset}
        setShowReset={setShowReset}
        resetData={resetData}
      />
      {isLoading && <Loader />}
    </>
  );
}

export default ForgotPassword;
