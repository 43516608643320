import React from "react";
import navbarLogo from "../../Assests/Images/navbarLogo.png";
import navContactImg from "../../Assests/Images/navContactImg.png";
import navMailImg from "../../Assests/Images/navMailImg.png";
import navTimeImg from "../../Assests/Images/navTimeImg.png";
import styles from "./TopHeader.module.css";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ButtonForLanChange from "../../i18n/component/ButtonForLanChange";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ClientDetail } from "../../clientDetail/ClientDetail";

function TopHeader() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isAuthenticated = useSelector((state) => state.user.userToken);

  const onLogoClick = () => {
    return isAuthenticated ? null : navigate("/");
  };

  return (
    <>
      <div className={styles.main}>
        <div className={styles.mainNav}>
          <Row className={styles.noneMergingClass}>
            <Col xl={4} lg={3} md={12} sm={12} className={styles.navLogoImg}>
              <div className={styles.topNavLogo} onClick={() => onLogoClick()}>
                <img alt="" src={navbarLogo} className={styles.navbarLogoImg} />
              </div>
            </Col>
            <Col xl={8} lg={9} md={12} sm={12} className={styles.allImg}>
              <Row className={styles.topNavContainer}>
                <Col lg={3} md={3} sm={12}>
                  <div className={styles.imageText}>
                    <img
                      alt="Not found"
                      src={navContactImg}
                      className={styles.topNavImg}
                    />
                    <div className={styles.topNavText}>
                      <p className={styles.topText}>{t("SUNDAY_CLOSED")}</p>
                      <p className={styles.topText}>
                        {t("MON-SAT")} 09.00 - 18.00
                      </p>
                    </div>
                  </div>
                </Col>

                <Col lg={3} md={3} sm={12}>
                  <div className={styles.imageText}>
                    <img
                      alt="Not found"
                      src={navMailImg}
                      className={styles.topNavImg}
                    />
                    <div className={styles.topNavText}>
                      <p className={styles.topText}>{t("EMAIL")}</p>

                      <p className={styles.topText}>
                        {ClientDetail.ClientEmail}
                      </p>
                    </div>
                  </div>
                </Col>

                <Col lg={3} md={3} sm={12}>
                  <div className={styles.imageText}>
                    <img
                      alt="Not found"
                      src={navTimeImg}
                      className={styles.topNavImg}
                    />
                    <div className={styles.topNavText}>
                      <p className={styles.topText}>{t("CALL_US")}</p>
                      <p className={styles.topText}>
                        {ClientDetail.ClientContact}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={3} md={3} sm={12} className={styles.localization}>
                  <ButtonForLanChange />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default TopHeader;
