import React from "react";
import { useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import styles from "./Inventory.module.css";
import TopHeader from "../TopHeader/TopHeader";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import InventoryModal from "./InventoryModal.js/InventoryModal";
import ConfirmModal from "../../Common/ConfirmModal";
import CitransApi from "../../helpers/Api.js";
import Loader from "../../Common/LoadingSpinner.js";
import Toster from "../../Utils/Toster.js";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { useDispatch } from "react-redux";
import { STATUS_CODES } from "../../Utils/StatusCode.js";
import { STATUS_MSG } from "../../Utils/StatusMsg.js";
import CrossImg from "../../Assests/Images/CrossImg.svg";
import RightImg from "../../Assests/Images/RightImg.svg";
import ReactPaginate from "react-paginate";
import { debounce } from "lodash";
import { PAGINATE, STATUS } from "../../Utils/Constants.js";

function InventoryList() {
  const isAuthenticated = useSelector((state) => state.user.userToken);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const itemsPerPage = PAGINATE.PER_PAGE;

  const [selectedItem, setSelectedItem] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isloading, setIsloading] = useState(true);
  const [inventoryListarr, setInventoryListarr] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [totalRecords, setTotalRecords] = useState();

  const [show, setShow] = useState(false);

  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNumber(newPageNumber);
  };

  const [isDeleting, setIsDeleting] = useState({
    status: false,
    deleteId: "",
  });

  const handelEdit = (item) => {
    setIsEdit(true);
    setSelectedItem(item);
    setShow(true);
  };

  const handelView = (item) => {
    setIsView(true);
    setSelectedItem(item);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setIsEdit(false);
    setIsView(false);
    setSelectedItem(null);
  };
  const handleShow = () => setShow(true);
  const confirmClose = () => setIsDeleting(false);

  const handleGetInventoryList = (pageNumber) => {
    setIsloading(true);
    let res = CitransApi.getInventoryData(isAuthenticated, pageNumber);
    res.then((data) => {
      if (
        data?.code == STATUS_CODES.INVALID_TOKEN ||
        data?.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        setInventoryListarr(data?.data.inventoryList);
        setIsloading(false);
        setTotalRecords(data?.data.count);
      }
    });
  };

  const searchInventoryList = (event) => {
    let res = CitransApi.searchgetInventoryData(isAuthenticated, event);
    res.then((data) => {
      if (
        data.code == STATUS_CODES.INVALID_TOKEN ||
        data.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        setInventoryListarr(data.data.inventoryList);
        setIsloading(false);
      }
    });
  };

  useEffect(() => {
    handleGetInventoryList(pageNumber);
  }, [pageNumber]);

  const handleUpdateStatus = (status, id) => {
    setIsloading(true);
    let Postdata = new FormData();
    Postdata.append("status", status);

    let res = CitransApi.UpdateInventoryStatus(isAuthenticated, Postdata, id);
    res.then((response) => {
      setIsloading(false);

      const ResponseCode = STATUS_MSG[response.data.code];
      if (response.code === STATUS_CODES.SUCCESS) {
        if (status == 0) {
          Toster(t("STATUS_INAVTIVE"), "success");
        } else {
          Toster(t("STATUS_UPDATED"), "success");
        }
        handleGetInventoryList(pageNumber);
        handleClose();
      } else {
        Toster(t(ResponseCode), "error");
        handleGetInventoryList();
        handleClose(pageNumber);
      }
    });
  };

  const delayedSearch = useCallback(
    debounce((event) => {
      searchInventoryList(event);
    }, 600),
    []
  );

  const handleChange = (event) => {
    setSearchKey(event);
    delayedSearch(event);
  };

  return (
    <>
      {isloading ? <Loader /> : null}

      <div>
        <TopHeader />
      </div>
      <div className="MainPageWrapper">
        <div>
          <Sidebar />
        </div>
        <div className="PageContent">
          <div className="PageContentHead">
            <h3>{t("INVENTORY_LIST")}</h3>
            <div onClick={handleShow} className="ContentAddBtn">
              <Icon
                icon="octicon:feed-plus-16"
                color="white"
                width="22"
                height="22"
              />
              <span>{t("Add_INVENTORY")}</span>
            </div>
          </div>
          <div className="ContentBody">
            <Row>
              <Col lg={6}>
                <Form.Group className="SearchInput LoginInput">
                  <Form.Label>{t("SEARCH")}</Form.Label>
                  <Form.Control
                    type="search"
                    name="searchBox"
                    placeholder={t("SEARCH_FIELD")}
                    autoComplete="off"
                    value={searchKey}
                    onChange={(e) => {
                      handleChange(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            {inventoryListarr?.length > 0 ? (
              <>
                {inventoryListarr?.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {item.status == 0 ? (
                        <div className={styles.InventoryCard}>
                          <div className={styles.InventoryCardHead}>
                            <span>{t(item.product_use)}</span>
                            <p>
                              {t("MATERIAL_TYPE")} :{" "}
                              <span>{item.meta_material_type_desc}</span>
                            </p>
                          </div>
                          <div className={styles.InventoryCardHead}>
                            <p>
                              {t("MATERIAL_REFERENCE")} :{" "}
                              <span>{item.material_reference}</span>
                            </p>
                            <p>
                              {t("PRODUCT_CODE")} :{" "}
                              <span>{item.product_code}</span>
                            </p>
                          </div>
                          <div className={styles.InventoryCardDes}>
                            <p>{t(item.product_description)}</p>
                          </div>
                          <div className={styles.iconsClass}>
                            <p className={styles.iconsClassDetails}>
                              <Icon
                                icon="tabler:list-details"
                                color="#626262"
                                width="24"
                                height="24"
                                onClick={() => handelView(item)}
                              />
                            </p>

                            <p className={styles.iconsClassEdit}>
                              <Icon
                                icon="ic:round-edit"
                                color="#626262"
                                width="24"
                                height="24"
                                onClick={() => handelEdit(item)}
                              />
                            </p>

                            <p className={styles.iconsClassEdit}>
                              <img
                                src={CrossImg}
                                width="35px"
                                height="35px"
                                title={t("CLICK_TO_ACTIVE")}
                                onClick={() => {
                                  handleUpdateStatus(
                                    STATUS.ACTIVE,
                                    item.inventory_id
                                  );
                                }}
                              ></img>
                            </p>
                          </div>
                          <hr />
                        </div>
                      ) : (
                        <div className={styles.InventoryCard}>
                          <div className={styles.InventoryCardHead}>
                            <span>{t(item.product_use)}</span>
                            <p>
                              {t("MATERIAL_TYPE")} :{" "}
                              <span>{item.meta_material_type_desc}</span>
                            </p>
                          </div>
                          <div className={styles.InventoryCardHead}>
                            <p>
                              {t("MATERIAL_REFERENCE")} :{" "}
                              <span>{item.material_reference}</span>
                            </p>
                            <p>
                              {t("PRODUCT_CODE")} :{" "}
                              <span>{item.product_code}</span>
                            </p>
                          </div>
                          <div className={styles.InventoryCardDes}>
                            <p>{t(item.product_description)}</p>
                          </div>
                          <div className={styles.iconsClass}>
                            <p className={styles.iconsClassDetails}>
                              <Icon
                                icon="tabler:list-details"
                                color="#626262"
                                width="24"
                                height="24"
                                onClick={() => handelView(item)}
                              />
                            </p>
                            <p className={styles.iconsClassEdit}>
                              <Icon
                                icon="ic:round-edit"
                                color="#626262"
                                width="24"
                                height="24"
                                onClick={() => handelEdit(item)}
                              />
                            </p>

                            <p className={styles.iconsClassEdit}>
                              <img
                                src={RightImg}
                                width="35px"
                                height="35px"
                                title={t("CLICK_TO_INACTIVE")}
                                onClick={() => {
                                  handleUpdateStatus(
                                    STATUS.IN_ACTIVE,
                                    item.inventory_id
                                  );
                                }}
                              ></img>
                            </p>
                          </div>
                          <hr />
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </>
            ) : null}
            {totalRecords == 0 ? <div>{t("RESULT_NOT_FOUND")}</div> : null}
            {searchKey === "" ? (
              <div className={styles.tableDetail}>
                {totalRecords ? (
                  <div className={styles.totalRecord}>
                    {t("SHOWING")} {1 + pageNumber * 10 - 10}-
                    {(pageNumber - 1) * 10 + inventoryListarr.length} of{" "}
                    {totalRecords} {t("RECORDS")}
                  </div>
                ) : (
                  ""
                )}

                <div className={styles.pagination}>
                  {totalRecords > itemsPerPage && (
                    <ReactPaginate
                      previousLabel="Previous"
                      nextLabel="Next"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      pageCount={Math.ceil(totalRecords / itemsPerPage)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
      <InventoryModal
        isEdit={isEdit}
        isView={isView}
        handleClose={handleClose}
        show={show}
        data={selectedItem}
        inventoryListarr={inventoryListarr}
        handleGetInventoryList={handleGetInventoryList}
        pageNumber={pageNumber}
        setSearchKey={setSearchKey}
      />
      <ConfirmModal
        title={`${t("SURE_TO_DELETE")}!`}
        show={isDeleting.status}
        confirmClose={confirmClose}
      />
    </>
  );
}

export default InventoryList;
