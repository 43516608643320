import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CitransApi from "../../helpers/Api";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Toster from "../../Utils/Toster";

const initialState = {
  currentUser: {},
  isLoading: false,
  userToken: null,
  success: false,
  errorCode: null,
  error: null,
  user_type: null,
};

// Thunk for user login
export const userLogin = createAsyncThunk(
  "user/userLogin",
  async (data, { rejectWithValue }) => {
    // console.log("login credential ", data);
    try {
      const response = await CitransApi.login(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for user signup
export const userSignup = createAsyncThunk(
  "user/userSignup",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CitransApi.postSignUpData(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogoutClear: (state, action) => {
      state.currentUser = {};
      state.isLoading = false;
      state.userToken = null;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    // user login===============
    builder.addCase(userLogin.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userLogin.fulfilled, (state, action) => {
      const response = action.payload;
      const responseErrorCode = response.data.code;
      // console.log("response of login => ", response);

      state.isLoading = false;

      if (response.code === STATUS_CODES.SUCCESS) {
        state.userToken = response.data.token;
        state.currentUser = response.data;
        state.errorCode = response.data.code;
        state.success = true;
      } else {
        // Toster("LOGIN_SUCCESSFULLY", "error");
        state.userToken = null;
        state.success = false;
        state.errorCode = response.data.code;
      }
    });

    builder.addCase(userLogin.rejected, (state, action) => {
      state.isLoading = false;
    });

    // user signup

    builder.addCase(userSignup.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(userSignup.fulfilled, (state, action) => {
      const response = action.payload;

      state.isLoading = false;

      if (response.code === STATUS_CODES.SUCCESS) {
        state.userToken = response.data.token;
        state.currentUser = response.data;
        state.errorCode = response.data.code;
        state.success = true;
      } else {
        state.userToken = null;
        state.success = false;
        state.errorCode = response.data.code;
      }
    });

    builder.addCase(userSignup.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { userLogoutClear } = userSlice.actions;
export default userSlice.reducer;
