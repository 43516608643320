import "./App.css";
import { Router } from "./routes/Routes";
import { LanguageProvider } from "./Context/LanguageContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DownloadPDF } from "./appComponent/PurchaseOrder/PDFDownload";

function App() {
  return (
    <>
      <LanguageProvider>
        <Router />
        <DownloadPDF />
      </LanguageProvider>
      <ToastContainer />
    </>
  );
}
export default App;
