import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "../appComponent/Home/Home";
import LoginPage from "../authComponent/LoginPage/LoginPage";
import SignUpPage from "../authComponent/SignupPage/SignupPage";
import InventoryList from "../appComponent/Inventory/InventoryList";
import NotFound from "../appComponent/NotFound/NotFound";
import StaffList from "../appComponent/Staff/Staff";
import Suppliers from "../appComponent/Suppliers/Suppliers";
import PurchaseOrderList from "../appComponent/PurchaseOrder/PurchaseOrderList";
import PurchaseOrder from "../appComponent/PurchaseOrder/PurchaseOrder";
import Profile from "../appComponent/Profile/Profile";
import TrafficList from "../appComponent/Traffic/TrafficList";
import TrafficDetail from "../appComponent/Traffic/TrafficDetail";
import PurchaseOrderDetails from "../appComponent/PurchaseOrder/PurchaseOrderDetails";
import Transportation from "../appComponent/Transportation/Transportation";
import TransportationDetails from "../appComponent/Transportation/TransportationDetails";
import CustomsList from "../appComponent/Customs/CustomsList";
import CustomsDetail from "../appComponent/Customs/CustomsDetail";
import PaymentDetail from "../appComponent/Customs/PaymentDetail";
import TransportationSubmit from "../appComponent/Transportation/TransportationSubmit";
import Dashboard from "../appComponent/Dashboard/Dashboard";

import { USER_TYPE } from "../Utils/Constants";

export const Router = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "instant" });
    }, [pathname]);

    return null;
  };

  const isAuthenticated = useSelector((state) => state.user.userToken);
  const userDetail = useSelector((state) => state.user.currentUser);

  const PublicRoute = ({ children }) => {
    return !isAuthenticated ? children : <Navigate to="/dashboard" />;
  };

  const PrivateRoute = ({ children }) => {
    return isAuthenticated ? children : <Navigate to="/login" />;
  };

  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<PublicRoute>{<Home />}</PublicRoute>} />
        <Route
          exact
          path="/login"
          element={<PublicRoute>{<LoginPage />}</PublicRoute>}
        />
        <Route
          exact
          path="/signup"
          element={<PublicRoute>{<SignUpPage />}</PublicRoute>}
        />
        <Route
          exact
          path="/inventory"
          element={<PrivateRoute>{<InventoryList />}</PrivateRoute>}
        />
        <Route
          exact
          path="/payment"
          element={<PrivateRoute>{<PaymentDetail />}</PrivateRoute>}
        />
        {userDetail.user_type !== USER_TYPE.IS_STAFF ? (
          <Route
            exact
            path="/staff"
            element={<PrivateRoute>{<StaffList />}</PrivateRoute>}
          />
        ) : (
          ""
        )}
        {/* <Route
          exact
          path="/staff"
          element={<PrivateRoute>{<StaffList />}</PrivateRoute>}
        /> */}
        <Route
          exact
          path="/suppliers"
          element={<PrivateRoute>{<Suppliers />}</PrivateRoute>}
        />
        <Route
          exact
          path="/purchase"
          element={<PrivateRoute>{<PurchaseOrderList />}</PrivateRoute>}
        />
        <Route
          exact
          path="/purchase-order-details"
          element={<PrivateRoute>{<PurchaseOrderDetails />}</PrivateRoute>}
        />
        <Route
          exact
          path="/purchase-order"
          element={<PrivateRoute>{<PurchaseOrder />}</PrivateRoute>}
        />
        <Route
          exact
          path="/profile"
          element={<PrivateRoute>{<Profile />}</PrivateRoute>}
        />
        <Route
          exact
          path="/traffic"
          element={<PrivateRoute>{<TrafficList />}</PrivateRoute>}
        />
        <Route
          exact
          path="/traffic-detail"
          element={<PrivateRoute>{<TrafficDetail />}</PrivateRoute>}
        />
        <Route
          exact
          path="/transportation"
          element={<PrivateRoute>{<Transportation />}</PrivateRoute>}
        />
        <Route
          exact
          path="/transportation-details"
          element={<TransportationDetails />}
        />
        <Route
          exact
          path="/customs"
          element={<PrivateRoute>{<CustomsList />}</PrivateRoute>}
        />
        <Route
          exact
          path="/customs-detail"
          element={<PrivateRoute>{<CustomsDetail />}</PrivateRoute>}
        />
        <Route
          exact
          path="/payment-detail"
          element={<PrivateRoute>{<PaymentDetail />}</PrivateRoute>}
        />
        <Route
          exact
          path="/transportation-submit"
          element={<PrivateRoute>{<TransportationSubmit />}</PrivateRoute>}
        />
        <Route
          exact
          path="/dashboard"
          element={<PrivateRoute>{<Dashboard />}</PrivateRoute>}
        />
        <Route exact path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
