import { Col, Container, Row } from "react-bootstrap";
import styles from "../Home.module.css";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

function LogisticService() {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <div className={styles.LogisticServiceWrapper}>
          <Row>
            <Col lg={2}></Col>
            <Col lg={8}>
              {" "}
              <div className={styles.ServiceHeading}>
                <h5>
                  {" "}
                  <Icon
                    icon="carbon:text-short-paragraph"
                    color="#0F2343"
                    width="40"
                    height="40"
                    rotate={2}
                  />
                  {t("LOGISTICS_SERVICES")}
                  <Icon
                    icon="carbon:text-short-paragraph"
                    color="#0F2343"
                    width="40"
                    height="40"
                    vFlip={true}
                  />
                </h5>
                <h3>{t("FREIGHT_AND_LOGISTICS_SERVICES")}</h3>
              </div>
            </Col>
            <Col lg={2}></Col>
          </Row>
          <Row>
            <Col lg={4}>
              <div className={styles.ServiceCards}>
                <h6>{t("IMPORT_EXPORT_FORWARDING")}</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text
                </p>
                <pre></pre>
                <div className={styles.ServiceReadMore}>
                  <span>{t("READ_MORE")}</span>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className={styles.ServiceCards}>
                <h6>{t("FASTEST_DELIVERY_NETWORK")}</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text
                </p>
                <pre></pre>
                <div className={styles.ServiceReadMore}>
                  <span>{t("READ_MORE")}</span>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className={styles.ServiceCards}>
                <h6>{t("SOLUTION_WITH_TECHNIQUE")}</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text
                </p>
                <pre></pre>
                <div className={styles.ServiceReadMore}>
                  <span>{t("READ_MORE")}</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}

export default LogisticService;
