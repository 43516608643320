import { useEffect, useContext } from "react";
import { Container, Form } from "react-bootstrap";
import BottomHeader from "../../appComponent/BottomHeader/BottomHeader";
import Footer from "../../appComponent/Footer/Footer";
import TopHeader from "../../appComponent/TopHeader/TopHeader";
import styles from "../authComponent.module.css";
import { MdOutlineMail } from "react-icons/md";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa6";
import Logo from "../../Assests/Images/logofinal 2.png";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { validationSchema } from "../../Common/ValidationSchema";
import { LanguageContext } from "../../Context/LanguageContext";
import { useSelector, useDispatch } from "react-redux";
import { userLogin } from "../../Redux/Slices/userSlice.js";
import Loader from "../../Common/LoadingSpinner.js";
import Toster from "../../Utils/Toster.js";
import { STATUS_CODES } from "../../Utils/StatusCode.js";
import { STATUS_MSG } from "../../Utils/StatusMsg.js";

function LoginPage() {
  const userDetails = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, formState, setError, getValues } =
    useForm({
      mode: "onChange",
    });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { setSelectedItem } = useContext(LanguageContext);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handelSubmit = (data) => {
    // console.log("input data ", data);

    const PostData = {
      email: data.email,
      password: data.passwordForLogin,
    };
    dispatch(userLogin(PostData)).then((res) => {
      const response = res.payload;
      // console.log("handle submit CODE", response.data.code);
      const ResponseCode = STATUS_MSG[response.data.code];
      if (response.code === STATUS_CODES.SUCCESS) {
        Toster(t("LOGIN_SUCCESSFULLY"), "success");
        // navigate("/dashboard");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  useEffect(() => {
    setSelectedItem("LOGIN | CREATE ACCOUNT");
  }, []);

  return (
    <>
      <div>
        <TopHeader />
        <BottomHeader />
      </div>
      <div className="LoginWrapper">
        <Container>
          <div className={styles.LogingSection}>
            <div className={styles.LoginImgSec}>
              <div className={styles.LoginImgBackDrop}>
                <div className={styles.LogoImg}>
                  <img src={Logo} alt="alt" />
                </div>
                <h1>{t("WELCOME_TO_CITRANS")}</h1>
                <div className={styles.CreateBtn}>
                  <button
                    onClick={() => {
                      navigate("/signup");
                    }}
                  >
                    {t("CREATE_ACCOUNT")}
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.LoginSection}>
              <h3>{t("LOGIN_TO_YOUR_ACCOUNT")}</h3>
              <Form noValidate onSubmit={handleSubmit(handelSubmit)}>
                <Form.Group className="LoginInput">
                  <Form.Label>{t("EMAIL_ID")}</Form.Label>
                  <Form.Control
                    {...register("email", validationSchema.email)}
                    isInvalid={!!formState.errors.email}
                    type="text"
                    placeholder={`${t("ENTER")} ${t("EMAIL_ID")}`}
                    onChange={(e) => {
                      setValue("email", e.target.value.replace(/\s/g, ""), {
                        shouldValidate: true,
                      });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t(formState.errors.email?.message)}
                  </Form.Control.Feedback>
                  <MdOutlineMail />
                </Form.Group>
                <Form.Group className="LoginInput">
                  <Form.Label>{t("PASSWORD")}</Form.Label>
                  <Form.Control
                    {...register(
                      "passwordForLogin",
                      validationSchema.passwordForLogin
                    )}
                    isInvalid={!!formState.errors.passwordForLogin}
                    type={isVisible ? "text" : "password"}
                    placeholder={`${t("ENTER")} ${t("PASSWORD")}`}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t(formState.errors.passwordForLogin?.message)}
                  </Form.Control.Feedback>
                  {isVisible ? (
                    <FaRegEye onClick={() => setIsVisible(false)} />
                  ) : (
                    <FaRegEyeSlash onClick={() => setIsVisible(true)} />
                  )}
                </Form.Group>
                <p className={styles.ForgotText}>
                  <span onClick={handleShow}>{t("FORGET_PASSWORD")}</span>
                </p>
                <div>
                  <button
                    type="submit"
                    className={styles.LoginBtn}
                    onClick={() => handelSubmit}
                  >
                    {t("LOGIN")}
                  </button>
                </div>
              </Form>
              <div className={styles.CreateNewTxt}>
                <p>
                  {t("DON'T_HAVE_AN_ACCOUNT")}{" "}
                  <span
                    onClick={() => {
                      navigate("/signup");
                    }}
                  >
                    {t("CREATE_AN_ACCOUNT")}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div>
        <Footer />
      </div>
      <ForgotPassword show={show} setShow={setShow} handleClose={handleClose} />
      {userDetails.isLoading && <Loader />}
    </>
  );
}

export default LoginPage;
