import { useState, useEffect } from "react";
import { useMemo } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, Row, Col } from "react-bootstrap";
import { IoMdCloseCircle } from "react-icons/io";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import { Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import CitransApi from "../../../helpers/Api.js";
import { validationSchema } from "../../../Common/ValidationSchema";
import { MetaDetaKey } from "../../../metaDataKey/MetaDetaKey.js";
import { STATUS_CODES } from "../../../Utils/StatusCode.js";
import { STATUS_MSG } from "../../../Utils/StatusMsg.js";
import Loader from "../../../Common/LoadingSpinner.js";
import Toster from "../../../Utils/Toster.js";

function AddStaff({
  show,
  handleClose,
  editIndex,
  dataForUpdate,
  isView,
  handleGetStaffDetail,
  setSearchKey,
  pageNumber,
}) {
  const { register, handleSubmit, setValue, reset, formState } = useForm({
    mode: "onChange",
  });

  const { t } = useTranslation();

  const isAuthenticated = useSelector((state) => state.user.userToken);

  const [isLoading, setIsLoading] = useState(false);

  const [phone, setPhone] = useState("");
  const [dialcode, setDialcode] = useState();
  const [locationList, setLocationList] = useState([]);
  const [location, setLocation] = useState();

  const handelPhoneChange = (phone, country) => {
    setPhone(phone);
    setValue("phoneNumber", phone);
    setDialcode(country.dialCode);
  };

  useMemo(() => {
    let res = CitransApi.getMetaData(MetaDetaKey.KEY_FOR_LOCATION);
    res.then((data) => {
      setLocationList(data.data.location);
    });
  }, []);

  const handleAddItem = (data) => {
    if (editIndex == 1) {
      handlePostStaffData(data);
    } else if (editIndex == -1) {
      alert("edited");
    } else {
      handleClose();
      reset();
    }
  };

  const handlePostStaffData = (formData) => {
    setIsLoading(true);
    let Postdata = new FormData();
    Postdata.append("name", formData.StaffName);
    Postdata.append("email", formData.StaffEmail);
    Postdata.append("dialcd", dialcode);
    Postdata.append("phn", formData.phoneNumber);
    Postdata.append("location", location);

    // console.log("post data ", Postdata);

    let res = CitransApi.addStaffData(isAuthenticated, Postdata);
    res.then((response) => {
      // console.log("addStaffData ", response);
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response.data?.code];
      if (response.code === STATUS_CODES.SUCCESS) {
        setIsLoading(false);
        Toster(t("STAFF_POST"), "success");
        handleGetStaffDetail(pageNumber);
        handleClose();
        setSearchKey("");
        reset();
        setLocation();
      } else {
        if (
          response.code == STATUS_CODES.VALIDATION_ERROR ||
          response.data.code == STATUS_CODES.VALIDATION_ERROR
        ) {
          setIsLoading(false);
          Toster(response.data.message, "error");
          // handleClose();
          // setLocation();
          // handleGetStaffDetail(pageNumber);
          setSearchKey("");
          // reset();
        } else {
          setIsLoading(false);
          Toster(t(ResponseCode), "error");
          // handleClose();
          // setLocation();
          // handleGetStaffDetail(pageNumber);
          setSearchKey("");
          // reset();
        }
      }
    });
  };

  useEffect(() => {
    if (editIndex == 0 || editIndex == -1) {
      setValue("StaffName", dataForUpdate?.name);
      setValue("StaffEmail", dataForUpdate?.email);
      setValue("phoneNumber", dataForUpdate?.phn);
      setValue("Location", dataForUpdate?.location);
      setPhone(dataForUpdate?.location);
    }
  }, [dataForUpdate, editIndex]);

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          handleClose(setValue);
          reset();
          setPhone();
        }}
      >
        <div className="SignupPopup AddPopUp">
          <Modal.Header>
            <Modal.Title>
              {editIndex === 1 ? (
                <>{t("ADD_STAFF")}</>
              ) : editIndex === -1 ? (
                <>{t("UPDATE_STAFF")}</>
              ) : (
                <>{t("STAFF_DETAIL")}</>
              )}
            </Modal.Title>
            <IoMdCloseCircle
              onClick={() => {
                handleClose(setValue);
                setPhone();
                reset();
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <Form noValidate onSubmit={handleSubmit(handleAddItem)}>
              <Row>
                <Col lg={6}>
                  <Form.Group
                    className="LoginInput"
                    controlId="validationCustom01"
                  >
                    <Form.Label>{t("STAFF_NAME")}</Form.Label>
                    <Form.Control
                      {...register("StaffName", validationSchema.StaffName)}
                      isInvalid={!!formState.errors.StaffName}
                      disabled={isView}
                      type="text"
                      placeholder={`${t("ENTER")} ${t("STAFF_NAME")}`}
                      autoComplete="off"
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.StaffName?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="LoginInput">
                    <Form.Label>{t("EMAIL")}</Form.Label>
                    <Form.Control
                      {...register("StaffEmail", validationSchema.StaffEmail)}
                      isInvalid={!!formState.errors.StaffEmail}
                      disabled={isView}
                      type="email"
                      placeholder={`${t("ENTER")} ${t("EMAIL")}`}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.StaffEmail?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="LoginInput" required>
                    <Form.Label>{t("PHONE_NUMBER")}</Form.Label>
                    <PhoneInput
                      {...register("phoneNumber", validationSchema.phoneNumber)}
                      disabled={isView}
                      country={"do"}
                      value={dataForUpdate?.phn}
                      onChange={(phone, country) => {
                        handelPhoneChange(phone, country);
                      }}
                    />
                    <div className="validateError">
                      {!phone && t(formState.errors.phoneNumber?.message)}
                      {phone && phone.length <= 4 && t("PHONE_ATLEAST_5_CH")}
                    </div>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="LoginInput">
                    <Form.Label>{t("LOCATION")}</Form.Label>
                    <Form.Select
                      {...register("Location", validationSchema.Location)}
                      isInvalid={
                        !!formState.errors.Location &&
                        (!location || location === "")
                      }
                      type="text"
                      disabled={isView}
                      onChange={(e) => setLocation(e.target.value)}
                    >
                      <option value="" defaultValue>
                        {`${t("SELECT")} ${t("LOCATION")}`}
                      </option>
                      {locationList?.map((val) => {
                        return (
                          <option value={val.value_code} key={val.value_code}>
                            {val.value_desc}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.Location?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Stack direction="horizontal" gap={3}>
                  <div className="p-2 ms-auto">
                    <Button
                      type="submit"
                      variant="primary"
                      style={{
                        width: "200px",
                        height: "50px",
                        marginTop: "20px",
                      }}
                    >
                      {editIndex === 1 ? (
                        <>{t("ADD_STAFF")}</>
                      ) : editIndex === -1 ? (
                        <>{t("UPDATE_STAFF")}</>
                      ) : (
                        <>{t("CLOSE")}</>
                      )}
                    </Button>
                  </div>
                </Stack>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

export default AddStaff;
