import React, { useContext, useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import styles from "./PurchaseOrderDetails.module.css";
import TopHeader from "../TopHeader/TopHeader";
import { Icon } from "@iconify/react";
import { Container, Row, Form, Col, Button } from "react-bootstrap";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import ShippingMethod from "./ShippingMethod";
import Shipment from "./Shipment";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CitransApi from "../../helpers/Api";
import Toster from "../../Utils/Toster";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { LanguageContext } from "../../Context/LanguageContext";

import Loader from "../../Common/LoadingSpinner";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import DeleteOrder from "./DeleteOrder";
import { MetaDetaKey } from "../../metaDataKey/MetaDetaKey";
import {
  addShipments,
  deleteShipmentById,
  setInitialShipments,
  updateOrderType,
} from "../../Redux/Slices/shipmentSlice";
// import { DownloadPDF } from "./PDFDownload";
import { PdfViewForStyling } from "./PdfViewForStyling";
import { ISEDIT } from "../../Utils/Constants";
import AddShipment from "./Shipment/AddShipment";

export default function PurchaseOrderDetails() {
  const location = useLocation();
  const navigate = useNavigate();

  const order_id = location.state.orderid;

  const isAuthenticated = useSelector((state) => state.user.userToken);
  const shipmentData = useSelector((state) => state.shipment);
  const isOneSubmitted = shipmentData.shipments.some((val) => val.isSubmitted);

  const { generate, setGenerate, detail, setDetail } =
    useContext(LanguageContext);

  const { purchaseOrderList, setPurchaseOrderList, setPurchaseOrder_isedit } =
    useContext(LanguageContext);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  let date = new Date().getTime();

  const [showshiping, setshowshiping] = useState(false);
  const [showshipment, setshowShipment] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState();
  const handleMethodClose = () => setshowshiping(false);
  const handleShipmentClose = () => setshowShipment(false);
  const handleShow = () => setshowshiping(true);

  const handleShipmentShow = (ship) => {
    setSelectedShipment(ship);
    setshowShipment(true);
  };
  const [shipmentCount, setshipmentCount] = useState(1);
  const [noOfShipment, setNoOfShipment] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [orderId, setOrderId] = useState();
  const OrderType = shipmentData.type;
  // const [orderedItems, setOrderedItems] = useState([]);
  const [methods, setMethods] = useState([]);
  const [selectedShipment, setSelectedShipment] = useState();
  const [inventoryItems, setInventoryItems] = useState([]);

  const getOrderDetail = () => {
    let res = CitransApi.getSingleOrderDetails(isAuthenticated, order_id);

    res.then((data) => {
      if (
        data.code == STATUS_CODES.INVALID_TOKEN ||
        data.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        setPurchaseOrderList(data.data);
        setInventoryItems(data?.data?.inventoryItems);
        setPurchaseOrder_isedit(1);
        setOrderId(data.data.order_id);
      }
    });
  };

  useEffect(() => {
    if (location.pathname !== "/purchase-order-details")
      dispatch(
        setInitialShipments([{ id: date, isSubmitted: false, data: {} }])
      );
  }, [location]);

  const handleGetBill = () => {
    let res = CitransApi.getOrderBill(isAuthenticated, order_id);
    // console.log("}}}}}}}}}}}}}} ", orderId);

    res.then((data) => {
      if (
        data?.code == STATUS_CODES.INVALID_TOKEN ||
        data?.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        // console.log("handleGetCustomList ", data);
        setDetail(data?.data.orderReport);
      }
    });
  };

  useEffect(() => {
    getOrderDetail();
    handleAddShipment(1, 10);
    handleGetBill();
  }, []);

  useEffect(() => {
    let res = CitransApi.getMetaData(MetaDetaKey.SHIPPING_METHOD);
    res.then((data) => {
      setMethods(data.data.shipping_method);
    });
  }, []);

  const handleUpdateOrder = (isedit) => {
    let res = CitransApi.getSingleOrderDetails(isAuthenticated, order_id);

    res.then((data) => {
      if (
        data.code == STATUS_CODES.INVALID_TOKEN ||
        data.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        navigate("/purchase-order", {
          state: { purchaseOrderList: data.data, purchaseOrder_isedit: isedit },
        });
      }
    });
  };

  const handleAddShipment = (input, integer) => {
    const count = parseInt(input, integer);
    const newArray = Array.from({ length: count }, (_, index) => index);

    setNoOfShipment(newArray);
  };

  const handleShowDelete = () => setShowDelete(true);

  const handleCloseDelete = () => {
    setShowDelete(false);
  };

  const handleDeleteItem = () => {
    setShowDelete(false);
  };

  const backTo = () => {
    dispatch(setInitialShipments([{ id: date, isSubmitted: false, data: {} }]));
    setIsLoading(true);
    let res = CitransApi.deleteAnShipment(isAuthenticated, order_id);
    res
      .then((data) => {
        setIsLoading(false);
        if (
          data.code == STATUS_CODES.INVALID_TOKEN ||
          data?.data?.code == STATUS_CODES.INVALID_TOKEN
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
        } else {
          navigate("/purchase");
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const submitShipment = () => {
    setIsLoading(true);
    let postData = new FormData();
    const orderTypeValue = OrderType === "Complete" ? 1 : 2;
    postData.append("shipment_method", selectedMethod?.value_code);
    postData.append("order_type", orderTypeValue);
    postData.append("number_of_shipment", shipmentData.shipments.length);
    if (!selectedMethod?.value_desc) {
      Toster("Please select shipping method!", "error");
      setIsLoading(false);
    } else if (!isOneSubmitted) {
      Toster("Please sumbit at least one shipment!", "error");
      setIsLoading(false);
    } else {
      let res = CitransApi.submitShipment(isAuthenticated, order_id, postData);
      res.then((data) => {
        console.log(data, "reponse data of submitShipment");
        setIsLoading(false);
        {
          data.code == 200 &&
            Toster("Shipment submitted successfully!", "success");
          navigate("/traffic");
        }
      });
    }
  };

  const addShipment = () => {
    let date = new Date().getTime();
    dispatch(addShipments({ id: date, isSubmitted: false }));
  };

  const removeShipment = (id) => {
    dispatch(deleteShipmentById(id));
  };

  const handleTypeChange = (type) => {
    if (isOneSubmitted) {
      return;
    } else {
      dispatch(updateOrderType(type));
      dispatch(
        setInitialShipments([{ id: date, isSubmitted: false, data: {} }])
      );
    }
  };

  const getTotalQuantity = () => {
    let sum = 0;
    let unit = "";
    purchaseOrderList?.inventoryItems?.map((val) => {
      sum = sum + val.product_quantity;
      unit = val.meta_unit_of_inventory_desc;
    });
    return sum?.toFixed(2) + " " + unit;
  };

  const handleGenerate = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setGenerate(!generate);
    }, 1000);
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <section>
        <div>
          <TopHeader />
        </div>
        <div className={styles.OrderDetailsWrapper}>
          <Container>
            <Row>
              <div className={styles.OrderDetailsSection}>
                <div className={styles.BackToList}>
                  <span className={styles.backSection}>
                    <IoArrowBackCircleSharp
                      onClick={() => {
                        backTo();
                      }}
                    />
                    <span
                      className={styles.headerText}
                      onClick={() => {
                        backTo();
                      }}
                    >
                      {t("PURCHASE_ORDER_DETAILS")}
                    </span>
                  </span>
                </div>

                <div className={styles.OrderDetails}>
                  <h5 className={styles.OrderDetailsHeader}>
                    {t("PURCHASE_ORDER_DETAILS")}
                  </h5>
                  <div className={styles.headerSection}>
                    <div className={styles.listHeader}>
                      <h6 className={styles.subHeader}>
                        {purchaseOrderList?.registered_user_company}
                      </h6>
                      <p>
                        {t("APPLICANT_AREA")} :{" "}
                        <span>
                          {purchaseOrderList?.meta_applicant_area_desc}
                        </span>
                      </p>
                      {/* <p>
                        {t("QUANTITY_UNIT")} : <span>50 kg</span>
                      </p> */}
                      <p>
                        {t("TYPE_OF_ORDER")} :{" "}
                        <span>
                          {purchaseOrderList?.meta_type_of_order_desc}
                        </span>
                      </p>
                      <p>
                        {t("CURRENCY_OF_THE_ORDER")} :{" "}
                        <span>
                          {purchaseOrderList?.meta_currency_of_order_desc}
                        </span>
                      </p>
                    </div>
                    <div className={styles.pendingWrapper}>
                      {/* TAB FOR UPDATE / DELETE / CLONE */}
                      {purchaseOrderList.status !== 1 ? (
                        <div className={styles.iconsClass}>
                          {!isOneSubmitted && (
                            <>
                              {/* DOWNLOAD PDF */}
                              <p
                                className={styles.iconsClassReload}
                                title="Download invoice"
                              >
                                <Icon
                                  icon="material-symbols:download"
                                  color="#3270a0"
                                  width="24"
                                  height="24"
                                  onClick={() => {
                                    handleGenerate();
                                  }}
                                />
                              </p>
                              {/* CLONE OPTION */}
                              <p
                                className={styles.iconsClassReload}
                                onClick={() => {
                                  handleUpdateOrder(ISEDIT.ISEDIT_FALSE);
                                }}
                                title="Make clone"
                              >
                                <Icon
                                  icon="ooui:reload"
                                  color="#3270a0"
                                  width="24"
                                  height="24"
                                />
                              </p>

                              {/* UPDATE OPTION */}
                              <p
                                className={styles.iconsClassEdit}
                                onClick={() => {
                                  handleUpdateOrder(ISEDIT.ISEDIT_TRUE);
                                }}
                                title="Update order"
                              >
                                <Icon
                                  icon="ic:round-edit"
                                  color="#626262"
                                  width="20"
                                  height="20"
                                />
                              </p>

                              {/* DELETE OPTION */}
                              <p
                                className={styles.iconsClassDelete}
                                onClick={() => {
                                  handleShowDelete();
                                }}
                                title="Delete order"
                              >
                                <Icon
                                  icon="ic:baseline-delete"
                                  color="red"
                                  width="20"
                                  height="20"
                                />
                              </p>
                            </>
                          )}
                        </div>
                      ) : (
                        ""
                      )}

                      <div className={styles.orderAmount}>
                        {purchaseOrderList?.meta_currency_of_order_desc}{" "}
                        {purchaseOrderList?.order_amount}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <h5 className={styles.inventorySectionHead}>
                    {t("INVENTORY_INFO")}
                  </h5>
                  <div className={styles.inventorySectionTop}>
                    {purchaseOrderList?.inventoryItems?.map((item, index) => {
                      return (
                        <Row key={index}>
                          <Col>
                            <div className={styles.productTitle}>
                              Material Referance
                            </div>
                            <div className={styles.productValue}>
                              {item.material_reference}
                            </div>
                          </Col>
                          <Col>
                            <div className={styles.productTitle}>
                              Product quantity
                            </div>
                            <div className={styles.productValue}>
                              {item.product_quantity}{" "}
                              {item.meta_unit_of_inventory_desc}
                            </div>
                          </Col>
                          <Col>
                            <div className={styles.productTitle}>
                              Per unit price
                            </div>
                            <div className={styles.productValue}>
                              {purchaseOrderList?.meta_currency_of_order_desc}{" "}
                              {item.unit_price}
                            </div>
                          </Col>
                          <hr />
                        </Row>
                      );
                    })}
                  </div>
                  <div className={styles.SuppliersInfoTop}>
                    <h5 className={styles.SuppliersInfoHeader}>
                      {t("SUPPLIER_INFO")}
                    </h5>
                    <div className={styles.SuppliersInfo}>
                      <span>
                        {purchaseOrderList?.supplier_name &&
                          purchaseOrderList.supplier_name
                            .charAt(0)
                            .toUpperCase() +
                            purchaseOrderList.supplier_name.slice(1)}
                      </span>
                    </div>
                  </div>
                  <div className={styles.OrderTypeTop}>
                    <h5 className={styles.OrderTypeHeader}>
                      {t("ORDER_TYPE")}
                    </h5>
                    <div className={styles.OrderType}>
                      {OrderType === "Complete" ? (
                        <>
                          <span
                            className={styles.orderComplete}
                            onClick={() => {
                              handleAddShipment(1, 10);
                              // setOrderType("Complete");
                              handleTypeChange("Complete");
                              setshipmentCount(1);
                            }}
                          >
                            {t("COMPLETE")}
                          </span>
                          <span
                            className={styles.orderSplit}
                            onClick={() => {
                              // setOrderType("Split");
                              handleTypeChange("Split");
                            }}
                          >
                            {t("Split")}
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className={styles.orderSplit}
                            onClick={() => {
                              handleTypeChange("Complete");
                              // handleAddShipment(1, 10);
                              // setOrderType("Complete");
                              // setshipmentCount(1);
                            }}
                          >
                            {t("COMPLETE")}
                          </span>
                          <span
                            className={styles.orderComplete}
                            onClick={() => {
                              // setOrderType("Split");
                              handleTypeChange("Split");
                            }}
                          >
                            {t("Split")}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className={styles.variationSectionTop}>
                    <div className={styles.variationSectionContainer}>
                      <div className={styles.variationSection}>
                        <div>
                          <h6 className={styles.variationSubtitle}>
                            {t("VARIATIONS_OF_PASSAGES")}
                          </h6>
                          <p>
                            {t("QUANTITY")} : <span>{getTotalQuantity()}</span>
                          </p>
                          <p>
                            {t("TOTAL_PRICE")} :{" "}
                            <span>
                              {purchaseOrderList?.meta_currency_of_order_desc}{" "}
                              {purchaseOrderList?.order_amount}
                            </span>
                          </p>
                        </div>
                      </div>
                      <hr />
                      {/* ++++++++++++++++++PROBLEMETIC CODE START++++++++++++++++++ */}
                      <div className={styles.InputSection}>
                        <div className="InputSectionField">
                          <Row>
                            <Col lg={6}>
                              <Form.Group
                                className="LoginInput"
                                onClick={() => handleShow()}
                              >
                                <p>
                                  {selectedMethod
                                    ? selectedMethod.value_desc
                                    : "Select Shipping Method"}
                                </p>
                                <Icon
                                  icon="bxs:up-arrow"
                                  color="rgba(130, 130, 130, 0.8509803921568627)"
                                  width="18"
                                  height="18"
                                  vFlip={true}
                                  onClick={() => handleShow()}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              {OrderType == "Split" && (
                                <Button
                                  className={styles.addShipmentBtn}
                                  onClick={() => addShipment()}
                                  disabled={shipmentData.shipments.length >= 4}
                                >
                                  + Add Shipment
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <Row className={styles.shipmentType} lg={6}>
                        {shipmentData.shipments?.map((shipment, index, arr) => {
                          return (
                            <Col key={index} className={styles.selectShipment}>
                              <div
                                onClick={() => handleShipmentShow(shipment)}
                                className={styles.shipmentText}
                              >
                                {t("SHIPMENT")} {index + 1}{" "}
                              </div>
                              <div>
                                {!shipment.isSubmitted && arr.length > 1 && (
                                  <Icon
                                    icon="ic:baseline-delete"
                                    color="white"
                                    width="20"
                                    height="20"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => removeShipment(shipment.id)}
                                  />
                                )}
                                {shipment.isSubmitted && (
                                  <Icon
                                    icon="mingcute:check-fill"
                                    color="white"
                                    width="20"
                                    height="20"
                                  />
                                )}
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                      {/* ++++++++++++++++++PROBLEMETIC CODE FINISH++++++++++++++ */}

                      {/* ++++++++++++++++++++TRY TO RESOLVE PROBLEMETIC CODE */}
                      {/* <AddShipment
                        inventoryItems={inventoryItems}
                        setInventoryItems={setInventoryItems}
                      /> */}
                      {/* ++++++++++++++++++++++++++++++++++++++++++++++++++ */}
                      <div className={styles.submitSection}>
                        <Button
                          className={styles.submitButton}
                          onClick={() => submitShipment()}
                        >
                          {t("SUBMIT")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
          {/* <DownloadPDF order_id={orderId} detail={detail} /> */}
        </div>
        <div>
          <Footer />

          {/* <PdfViewForStyling /> */}
        </div>
      </section>
      <ShippingMethod
        show={showshiping}
        handleClose={handleMethodClose}
        selectedMethod={selectedMethod}
        setSelectedMethod={setSelectedMethod}
        methods={methods}
      />
      <Shipment
        show={showshipment}
        handleClose={handleShipmentClose}
        order_id={order_id}
        selectedShipment={selectedShipment}
      />
      <DeleteOrder
        show={showDelete}
        handleClose={handleCloseDelete}
        handleDeleteItem={handleDeleteItem}
        orderId={orderId}
      />
    </>
  );
}
