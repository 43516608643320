import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TopHeader from "../TopHeader/TopHeader";
import Footer from "../Footer/Footer";
import styles from "./TransportationDetails.module.css";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import FileImg from "../../Assests/Images/Rectangle56.png";
import DateImg from "../../Assests/Images/DateImg.svg";
import RightImg from "../../Assests/Images/RightImg.svg";
import { DOCUMENT_KEY, SHIPMENT_STATUS } from "../../Utils/Constants";
import { DOCUMENT_APPROVE } from "../../Utils/Constants";
import CrossImg from "../../Assests/Images/CrossImg.svg";
import {
  ShippingClerks,
  companyOptions,
  verificationDeposits,
} from "../../mockData";

import { useTranslation } from "react-i18next";
import CitransApi from "../../helpers/Api";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Toster from "../../Utils/Toster";
import Loader from "../../Common/LoadingSpinner";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { useDispatch, useSelector } from "react-redux";

export default function TransportationDetails() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.user.userToken);
  const shipment_id = location.state.shipment_id;

  const [isloading, setIsloading] = useState(true);

  const [detail, setDetail] = useState();

  const documents_arr = [
    t("DOCUMENT_ARR_0"),
    t("DOCUMENT_ARR_1"),
    t("DOCUMENT_ARR_2"),
    t("DOCUMENT_ARR_3"),
    t("DOCUMENT_ARR_4"),
    t("DOCUMENT_ARR_5"),
    t("DOCUMENT_ARR_6"),
  ];

  // =======FORMAT DELIVERY DATE======
  const originalDeliveryDate = detail?.delivery_date;
  const deliveridate = new Date(originalDeliveryDate);
  const daydelivery = deliveridate.getUTCDate();
  const monthdelivery = deliveridate.getUTCMonth() + 1;
  const yeardelivery = deliveridate.getUTCFullYear();
  const formattedDeliveryDate = `${daydelivery}/${monthdelivery}/${yeardelivery}`;

  // =======FORMAT ETA DATE======
  const originalETADate = detail?.eta_date;
  const ETAdate = new Date(originalETADate);
  const dayETA = ETAdate.getUTCDate();
  const monthETA = ETAdate.getUTCMonth() + 1;
  const yearETA = ETAdate.getUTCFullYear();
  const formattedETADate = `${dayETA}/${monthETA}/${yearETA}`;

  // =======FORMAT ETD DATE======
  const originalETDDate = detail?.etd_date;
  const ETDdate = new Date(originalETDDate);
  const dayETD = ETDdate.getUTCDate();
  const monthETD = ETDdate.getUTCMonth() + 1;
  const yearETD = ETDdate.getUTCFullYear();
  const formattedETDDate = `${dayETD}/${monthETD}/${yearETD}`;
  const goForTransportation = () =>
    navigate("/transportation-submit", { state: { shipment_id: shipment_id } });

  // =======VERIFICATION DATE======
  const originalVerificationDate = detail?.paymentDetails.est_verification_date;
  const verificationdate = new Date(originalVerificationDate);
  const dayVerification = verificationdate.getUTCDate();
  const monthVerification = verificationdate.getUTCMonth() + 1;
  const yearVerification = verificationdate.getUTCFullYear();
  const formattedVerification = `${dayVerification}/${monthVerification}/${yearVerification}`;

  // detail?.paymentDetails.est_verification_date

  const handleGetTransportDetail = () => {
    setIsloading(false);
    let res = CitransApi.getTransportDetail(isAuthenticated, shipment_id);

    res.then((data) => {
      if (
        data?.code == STATUS_CODES.INVALID_TOKEN ||
        data?.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        setDetail(data?.data.transportationDetails);
      }
    });
  };

  useEffect(() => {
    handleGetTransportDetail();
  }, []);

  return (
    <section>
      {isloading ? <Loader /> : null}
      <div>
        <TopHeader />
      </div>

      <div className={styles.mainBox}>
        <div className={styles.innerBox}>
          <div className={styles.heading}>
            <div className={styles.iconsClass}>
              <p className={styles.iconsClassBack} onClick={() => navigate(-1)}>
                <Icon icon="ep:back" color="white" width="24" height="24" />
              </p>
            </div>
            <div className={styles.HeadingLabel}>
              <p>{t("TRANSPORTATION_DETAILS")}</p>
            </div>
          </div>

          <div className={styles.billing}>
            <div className={styles.orderAndMethod}>
              {/* <div className={styles.orderAndMethodBlocks}>
                <div className={styles.orderType}>
                  <p>{t("ORDER_TYPE")}</p>
                </div>
                <div className={styles.optionButton}>
                  <Button className={styles.optionButtonButton1}>
                    {t("COMPLETE")}
                  </Button>
                  <Button className={styles.optionButtonButton2}>
                    {t("SPLIT")}
                  </Button>
                </div>
              </div> */}

              <div className={styles.orderAndMethodBlocks}>
                <div className={styles.orderType}>
                  <p>{t("SHIPPING_METHOD")}</p>
                </div>
                <div className={styles.optionButton}>
                  <Button className={styles.optionButtonButton1}>
                    {t("SEA")}
                  </Button>
                  {/* <Button className={styles.optionButtonButton2}>
                    {t("AIR")}
                  </Button> */}
                </div>
              </div>
            </div>

            <div className={styles.ShippingDetails}>
              <p>{t("SHIPPING_DETAILS")}</p>
            </div>

            {/* First Bill */}
            <div className={styles.billingBox}>
              <div className={styles.bookingDetail}>
                <div className={styles.bookingDetailLeft}>
                  <div className={styles.BLorbooking}>
                    <p>
                      {t("BL_OR_BOOKING")} : {detail?.bl_number}
                    </p>
                  </div>

                  <Stack direction="horizontal" gap={1}>
                    <p className={styles.BLorbooking_key}>{t("POL")} :</p>
                    <p className={styles.BLorbooking_value}>{detail?.pol}</p>
                  </Stack>
                  <Stack direction="horizontal" gap={1}>
                    <p className={styles.BLorbooking_key}>{t("VESSEL")} : </p>
                    <p className={styles.BLorbooking_value}>{detail?.vessel}</p>
                  </Stack>
                  <Stack direction="horizontal" gap={1}>
                    <p className={styles.BLorbooking_key}>
                      {t("DELEVERY_DATE")} :{" "}
                    </p>
                    <p className={styles.BLorbooking_value}>
                      {formattedDeliveryDate}
                    </p>
                    <img
                      src={DateImg}
                      width="14px"
                      height="14px"
                      className={styles.bookingDetailRightFlexImgLeft}
                    ></img>
                  </Stack>
                  <Stack direction="horizontal" gap={1}>
                    <p className={styles.BLorbooking_key}>{t("ETA_DATE")} :</p>
                    <p className={styles.BLorbooking_value}>
                      {formattedETADate}
                    </p>
                    <img
                      src={DateImg}
                      width="14px"
                      height="14px"
                      className={styles.bookingDetailRightFlexImgLeft}
                    ></img>
                  </Stack>
                </div>

                <div className={styles.bookingDetailRight}>
                  <div className={styles.bookingDetailRightFlex}>
                    <p className={styles.BLorbooking_key}>
                      {t("QUANTITY_OF_CONTAINERS")} :
                    </p>
                    <p className={styles.BLorbooking_value_right}>
                      {" "}
                      {detail?.quantity_of_container}
                    </p>
                  </div>

                  <div className={styles.bookingDetailRightFlex}>
                    <p className={styles.BLorbooking_key}>
                      {t("SHIPPING_LINE")} :
                    </p>
                    <p className={styles.BLorbooking_value_right}>
                      {detail?.shipping_line}
                    </p>
                  </div>

                  <div className={styles.bookingDetailRightFlex}>
                    <p className={styles.BLorbooking_key}>{t("ETD_DATE")} :</p>
                    <p className={styles.BLorbooking_value_right}>
                      {formattedETDDate}
                    </p>
                    <img
                      src={DateImg}
                      width="14px"
                      height="14px"
                      className={styles.bookingDetailRightFlexImgRight}
                    ></img>
                  </div>

                  <div className={styles.bookingDetailRightFlex}>
                    <p className={styles.BLorbooking_key}> {t("POD")} : </p>
                    <p className={styles.BLorbooking_value_right}>
                      {detail?.pod}
                    </p>
                  </div>
                </div>
              </div>

              <div className={styles.devider}></div>

              {detail?.containers.map((inf, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className={styles.container_holds_product}>
                      <div className={styles.productBox}>
                        <div className={styles.productId1}>
                          <p>{inf.container_number}</p>
                        </div>
                        <div className={styles.productId2}>
                          <Stack direction="horizontal" gap={2}>
                            <p className={styles.BLorbooking_key}>
                              {t("BATCH_NO")}.{" "}
                            </p>
                            <p className={styles.BLorbooking_value}>
                              {inf.batch_number}
                            </p>
                          </Stack>
                        </div>
                        <div className={styles.productId3}>
                          <Stack direction="horizontal" gap={2}>
                            <p className={styles.BLorbooking_key}>
                              {t("LOCATION")}
                            </p>
                            <p className={styles.BLorbooking_value}>
                              {inf.location}
                            </p>
                          </Stack>
                        </div>
                      </div>

                      {inf.containerItemsDetails.map((product, index) => {
                        return (
                          <div className={styles.productBox2} key={index}>
                            <div className={styles.booking}>
                              <p className={styles.productTitle}>
                                {t("BL_OR_BOOKING")} {product.bl_number}
                              </p>
                            </div>
                            <div className={styles.quantity}>
                              <p className={styles.productQuantity}>
                                {t("PRODUCT")} {index + 1} ({t("QUANTITY")})
                              </p>
                              <p className={styles.productTitle}>
                                {product.product_quantity}{" "}
                                {product.unit_of_inventory}
                              </p>
                            </div>
                            <div className={styles.balance}>
                              <p className={styles.productQuantity}>
                                {t("PRODUCT")} {index + 1} ({t("BALANCE")})
                              </p>
                              <p className={styles.productTitle}>
                                {product.remaining_quantity}{" "}
                                {product.unit_of_inventory}
                              </p>
                            </div>
                            <div className={styles.balance}>
                              <p className={styles.productQuantity}>
                                {t("ORDER_ID")}
                              </p>
                              <p className={styles.productTitle}>
                                {product.custom_order_id}
                              </p>
                            </div>
                          </div>
                        );
                      })}

                      <div className={styles.devider}></div>
                    </div>

                    <div className={styles.devider}></div>
                  </React.Fragment>
                );
              })}

              <div className={styles.documentHolder}>
                <div className={styles.DocsHeading}>
                  <p>{t("ANNEX_DOCUMENTS")}</p>
                </div>
                <Row className="gx-1 gy-3">
                  {DOCUMENT_KEY?.map((doc, index) => {
                    return (
                      <Col xs={12} sm={6} key={index}>
                        <div
                          className={styles.documentColumn}
                          style={{
                            borderRadius: "5px",
                            boder: "0.5px solid #DDD",
                            background: "#FFF",
                            boxShadow:
                              "0px 4px 9px 0px rgba(130, 130, 130, 0.15)",
                          }}
                        >
                          <div
                            className={styles.imgSet}
                            onClick={() =>
                              window.open(detail?.document[doc.doc_key])
                            }
                          >
                            <img src={FileImg} width="50px" height="50px"></img>
                          </div>
                          <div className={styles.documentName}>
                            <div>
                              <div>{t(doc.doc_name)}</div>
                            </div>
                          </div>
                          <div className={styles.imgSet}>
                            {detail?.document[doc.status_name] ===
                            DOCUMENT_APPROVE.APPROVE ? (
                              <img
                                src={RightImg}
                                width="32px"
                                height="32px"
                              ></img>
                            ) : (
                              <img
                                src={CrossImg}
                                width="32px"
                                height="32px"
                              ></img>
                            )}
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>

              <div className={styles.billDetail}>
                <div className={styles.declaration}>
                  <div style={{ display: "flex" }}>
                    <p className={styles.keyValue}>Declaration number : </p>
                    <p className={styles.keyheading}>
                      {detail?.paymentDetails.declaration_number}
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <p className={styles.keyValue}>Settlement number : </p>
                    <p className={styles.keyheading}>
                      {" "}
                      {detail?.paymentDetails.settlement_number}
                    </p>
                  </div>
                </div>

                <div className={styles.declaration}>
                  <div style={{ display: "flex" }}>
                    <p className={styles.keyValue}>
                      Igra number or routing letter :{" "}
                    </p>
                    <p className={styles.keyheading}>
                      {detail?.paymentDetails.igra_number}
                    </p>
                  </div>
                </div>

                <div className={styles.declaration}>
                  <div style={{ display: "flex" }}>
                    <p className={styles.keyValue}>Shipping clerk : </p>
                    <p className={styles.keyheading}>
                      {detail?.paymentDetails.meta_shipping_clerk}
                    </p>
                  </div>
                </div>

                <div className={styles.declaration}>
                  <div style={{ display: "flex" }}>
                    <p className={styles.keyValue}>
                      Consolidating company, Shipping company :{" "}
                    </p>
                    <p className={styles.keyheading}>
                      {
                        detail?.paymentDetails
                          .meta_consolidating_shipping_company
                      }
                    </p>
                  </div>
                </div>

                <div className={styles.declaration}>
                  <div style={{ display: "flex" }}>
                    <p className={styles.keyValue}>Shipping clerk : </p>
                    <p className={styles.keyheading}>
                      {detail?.paymentDetails.meta_shipping_clerk}
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <p className={styles.keyValue}>
                      Estimated verification date :{" "}
                    </p>
                    <p className={styles.keyheading}>{formattedVerification}</p>
                  </div>
                </div>

                <div className={styles.declaration}>
                  <div style={{ display: "flex" }}>
                    <p className={styles.keyValue}>Vuce :</p>{" "}
                    {detail?.paymentDetails.vuce ===
                    DOCUMENT_APPROVE.APPROVE ? (
                      <p className={styles.keyheading}>yes</p>
                    ) : (
                      <p className={styles.keyheading}>no</p>
                    )}
                  </div>
                  <div style={{ display: "flex" }}>
                    <p className={styles.keyValue}>
                      Apply chassis and container :
                    </p>
                    <p className={styles.keyheading}>
                      {detail?.paymentDetails.chassis_and_container ===
                      DOCUMENT_APPROVE.APPROVE ? (
                        <p className={styles.keyheading}>yes</p>
                      ) : (
                        <p className={styles.keyheading}>no</p>
                      )}
                    </p>
                  </div>
                </div>
              </div>

              <div className={styles.devider}></div>

              <div className={styles.transportationFields}>
                <div className="transportationFields">
                  <Row>
                    <div className="transportationButton">
                      {detail?.shipment_status !==
                        SHIPMENT_STATUS.DELIVERED && (
                        <Button onClick={() => goForTransportation()}>
                          {t("GO_FOR_TRANSPORTATION")}
                        </Button>
                      )}
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </section>
  );
}
