import { Container, Row, Col } from "react-bootstrap";
import styles from "../Home.module.css";
import WeOffer from "../../../Assests/Images/Rectangle19.png";
import TimeTraking from "../../../Assests/Images/Real-Time Tracking.svg";
import Competitive from "../../../Assests/Images/Competitive Pricing.svg";
import Security from "../../../Assests/Images/Security For Cargo.svg";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

function WeOfferPage() {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.WeOfferWrapper}>
        <Container>
          <Row>
            <Col lg={7}>
              <div>
                <h6>
                  <Icon
                    icon="carbon:text-short-paragraph"
                    color="#91C8F2"
                    width="40"
                    height="40"
                    rotate={2}
                  />
                  {t("WHAT_WE_OFFER")}
                </h6>
                <h3>{t("EFFECTIVE_DELIVERY")}</h3>
              </div>
              <div className={styles.WeOfferDesWrapper}>
                <div className={styles.WeOfferDes}>
                  <img src={TimeTraking} />
                  <div>
                    <h5>{t("REAL_TIME_TRACKING")}</h5>
                    <p>
                      Aenean id mi fermentum, tristique felis condimentum augue.
                      Pellentesque
                    </p>
                  </div>
                </div>
                <pre></pre>
              </div>
              <div className={styles.WeOfferDesWrapper}>
                <div className={styles.WeOfferDes}>
                  <img src={Security} />
                  <div>
                    <h5>{t("SECURITY_FOR_CARGO")}</h5>
                    <p>
                      Aenean id mi fermentum, tristique felis condimentum augue.
                      Pellentesque
                    </p>
                  </div>
                </div>
                <pre></pre>
              </div>
              <div className={styles.WeOfferDesWrapper}>
                <div className={styles.WeOfferDes}>
                  <img src={Competitive} />
                  <div>
                    <h5>{t("COMPETITIVE_PRICING")}</h5>
                    <p>
                      Aenean id mi fermentum, tristique felis condimentum augue.
                      Pellentesque
                    </p>
                  </div>
                </div>
                {/* <pre></pre> */}
              </div>
            </Col>
            <Col lg={5}>
              <div className={styles.PosterImg}>
                <img src={WeOffer} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default WeOfferPage;
