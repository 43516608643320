import React from "react";
import LanguageOption from "./language-dropdown";
import i18next from "i18next";
import { useContext, useEffect } from "react";
import { LanguageContext } from "../../Context/LanguageContext";

const ButtonForLanChange = () => {
  const { selectedLanguage, changeLanguage } = useContext(LanguageContext);

  useEffect(() => {
    i18next.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const handleClick = (e) => {
    changeLanguage(e);
  };

  return (
    <div>
      <LanguageOption
        onChange={(e) => handleClick(e)}
        value={selectedLanguage}
      />
    </div>
  );
};

export default ButtonForLanChange;
