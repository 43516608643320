import Modal from "react-bootstrap/Modal";
import { IoMdCloseCircle } from "react-icons/io";

function CustomsTreatment({
  treatmentPopUp,
  handleTreatmentClose,
  customTreat,
  treatvalue,
  setTreatvalue,
  setCustomfield,
}) {
  const handleCheckboxChange = (value, code) => {
    if (code == treatvalue) {
      setCustomfield("");
      setTreatvalue();
      handleTreatmentClose();
    } else {
      setCustomfield(value);
      setTreatvalue(code);
      handleTreatmentClose();
    }
  };

  return (
    <>
      <Modal
        show={treatmentPopUp}
        onHide={() => {
          handleTreatmentClose();
        }}
      >
        <div className="SignupPopup">
          <Modal.Header>
            <Modal.Title>Customs Treatment</Modal.Title>
            <IoMdCloseCircle
              onClick={() => {
                handleTreatmentClose();
              }}
            />
          </Modal.Header>
          <Modal.Body>
            {customTreat?.map((checkbox) => (
              <div
                key={checkbox.value_code}
                className="signupCheckbox"
                onClick={(e) => {
                  handleCheckboxChange(
                    checkbox.value_desc,
                    checkbox.value_code
                  );
                }}
              >
                <label>{checkbox.value_desc}</label>
                <input
                  type="checkbox"
                  checked={treatvalue === checkbox.value_code}
                />
              </div>
            ))}
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default CustomsTreatment;
