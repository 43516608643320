import React, { useEffect, useState } from "react";
import TopHeader from "../TopHeader/TopHeader";
import styles from "./TransportationSubmit.module.css";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import Footer from "../Footer/Footer";
import { Button, Col, Form, Row } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { useLocation } from "react-router-dom";
import {
  companyOptions,
  driverNames,
  locations,
  receiverNames,
} from "../../mockData";

import { useTranslation } from "react-i18next";
import { MetaDetaKey } from "../../metaDataKey/MetaDetaKey";
import CitransApi from "../../helpers/Api";
import Loader from "../../Common/LoadingSpinner";
import { useForm } from "react-hook-form";
// import { validationSchema } from "../../Common/ValidationSchema";
import { validation } from "./validation";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import Toster from "../../Utils/Toster";
import { STATUS_MSG } from "../../Utils/StatusMsg";

export default function TransportationSubmit() {
  const { register, handleSubmit, setValue, formState, reset, control } =
    useForm({
      mode: "onChange", // Trigger validation on onChange
    });
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [phone1, setphone1] = useState("");
  const [phone2, setphone2] = useState("");
  const isAuthenticated = useSelector((state) => state.user.userToken);

  const metaDataApiKey = `${MetaDetaKey.TRANSPORT_RECEIVER},${MetaDetaKey.TRANSPORT_DRIVER},${MetaDetaKey.TRANSPORTATION_COMPANY},${MetaDetaKey.KEY_FOR_LOCATION}`;

  const shipment_id = location.state.shipment_id;

  const [isloading, setIsloading] = useState(true);

  const [driverList, setDriverList] = useState([]);
  const [receiverList, setReceiverList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [locationList, setLocationList] = useState([]);

  const getMetaData = () => {
    let res = CitransApi.getMetaData(metaDataApiKey);
    res.then((data) => {
      setIsloading(false);
      console.log("getMetaData", data);
      setLocationList(data?.data.location);
      setCompanyList(data?.data.transportation_company_name);
      setDriverList(data?.data.transportation_driver_name);
      setReceiverList(data?.data.transportation_receiver_name);
    });
  };

  useEffect(() => {
    getMetaData();
  }, []);

  const handlePayment = (formData) => {
    let postData = new FormData();

    postData.append("first_phone_dial_code", "+91");
    postData.append("first_phone_number", phone1);
    postData.append("second_phone_dial_code", "+91");
    postData.append("second_phone_number", phone2);
    postData.append("meta_transportation_company_name", formData.companyName);
    postData.append("meta_transportation_driver_name", formData.driverName);
    postData.append("meta_transportation_receiver_name", formData.receiverName);
    postData.append("meta_location", formData.location);
    postData.append("vehicle_license_plate", formData.vehicleLicence);
    postData.append("vehicle_label", formData.vehicleLable);
    postData.append("delivery_date", formData.deliveryDate);

    if (phone1?.length < 5 || phone2?.length < 5) {
      Toster(t("Phone number should be min 5 digit."), "error");
    } else {
      setIsloading(true);
      let res = CitransApi.saveTransportationDetails(
        isAuthenticated,
        shipment_id,
        postData
      );

      res.then((data) => {
        setIsloading(false);
        if (data?.code == STATUS_CODES.INVALID_TOKEN) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
        } else {
          const ResponseCode = STATUS_MSG[data.data?.code];
          if (data?.code == STATUS_CODES.SUCCESS) {
            Toster("Transportation details saved successfully!", "success");
            navigate("/dashboard");
            reset();
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      });
    }
  };

  return (
    <section>
      {isloading ? <Loader /> : null}
      <div>
        <TopHeader />
      </div>

      <div className={styles.mainBox}>
        <div className={styles.innerBox}>
          <div className={styles.heading}>
            <div className={styles.iconsClass}>
              <p className={styles.iconsClassBack} onClick={() => navigate(-1)}>
                <Icon icon="ep:back" color="white" width="24" height="24" />
              </p>
            </div>
            <div className={styles.HeadingLabel}>
              <p>{t("TRANSPORTATION_DETAILS")}</p>
            </div>
          </div>
          <div className={styles.sectionWrapper}>
            <div className={styles.sectionContainer}>
              <div className="transportationFields">
                <Form noValidate onSubmit={handleSubmit(handlePayment)}>
                  <Row>
                    <Col lg={6}>
                      <Form.Group className="LoginInput">
                        <Form.Select
                          {...register("companyName", validation.companyName)}
                          isInvalid={!!formState.errors.companyName}
                        >
                          <option value="" disabled selected>
                            {t("SELECT_COMPANY_NAME")}
                          </option>
                          {companyList?.map((val, index) => {
                            return (
                              <option key={index} value={val.value_code}>
                                {val.value_desc}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.companyName?.message)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="LoginInput">
                        <PhoneInput
                          country={"us"}
                          // value={phone}
                          placeholder={t("ENTER_TELEPHONE_NO")}
                          onChange={(phone) => setphone1(phone)}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="LoginInput">
                        <Form.Select
                          {...register("driverName", validation.driverName)}
                          isInvalid={!!formState.errors.driverName}
                        >
                          <option value="" disabled selected>
                            {t("SELECT_DRIVER_NAME")}
                          </option>
                          {driverList?.map((val, index) => {
                            return (
                              <option key={index} value={val.value_code}>
                                {val.value_desc}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.driverName?.message)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="LoginInput">
                        <PhoneInput
                          country={"in"}
                          // value={phone}
                          placeholder={t("ENTER_TELEPHONE_NO")}
                          onChange={(phone) => setphone2(phone)}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="LoginInput">
                        <Form.Control
                          type="text"
                          // name="order"
                          placeholder={t("ENTER_VEHICLE_LICENSE_PLATE")}
                          autoComplete="off"
                          {...register(
                            "vehicleLicence",
                            validation.vehicleLicence
                          )}
                          isInvalid={!!formState.errors.vehicleLicence}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.vehicleLicence?.message)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="LoginInput">
                        <Form.Control
                          type="text"
                          // name="order"
                          placeholder={t("ENTER_VEHICLE_LABEL")}
                          autoComplete="off"
                          {...register("vehicleLable", validation.vehicleLable)}
                          isInvalid={!!formState.errors.vehicleLable}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.vehicleLable?.message)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} style={{ marginTop: "5px" }}>
                      <Form.Group>
                        <Form.Control
                          type="date"
                          placeholder={t("SELECT_DELIVERY_DATE")}
                          autoComplete="off"
                          style={{ height: "50px", color: "#828282" }}
                          {...register("deliveryDate", validation.deliveryDate)}
                          isInvalid={!!formState.errors.deliveryDate}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.deliveryDate?.message)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="LoginInput">
                        <Form.Select
                          {...register("receiverName", validation.receiverName)}
                          isInvalid={!!formState.errors.receiverName}
                        >
                          <option value="" disabled selected>
                            {t("SELECT_RECEIVER_NAME")}
                          </option>
                          {receiverList?.map((val, index) => {
                            return (
                              <option key={index} value={val.value_code}>
                                {val.value_desc}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.receiverName?.message)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="LoginInput">
                        <Form.Select
                          {...register("location", validation.location)}
                          isInvalid={!!formState.errors.location}
                        >
                          <option value="" disabled selected>
                            {t("SELECT_LOCATION")}
                          </option>
                          {locationList?.map((val, index) => {
                            return (
                              <option key={index} value={val.value_code}>
                                {val.value_desc}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.location?.message)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <div className="transportationButton">
                      <Button type="submit">{t("SUBMIT")}</Button>
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </section>
  );
}
