export const STATUS = {
  ACTIVE: 1,
  IN_ACTIVE: 0,
};

export const ISEDIT = {
  ISEDIT_TRUE: 1,
  ISEDIT_FALSE: 0,
};

export const USER_TYPE = {
  IS_STAFF: 2,
  IS_ADMIN: 1,
};

export const LOGGED_IN = {
  IS_LOGED_IN: 1,
  IS_LOGED_OUT: 0,
};

export const PAGINATE = {
  PER_PAGE: 10,
};

// 1-Pending, 2-Document uploaded, 3-Document rejected, 4-Payment pending, 5-Transportation, 6-Delivered

export const SHIPMENT_STATUS = {
  PENDING: 1,
  DOCUMENT_UPLOADED: 2,
  DOCUMENT_REJECTED: 3,
  PAYMENT_PENDING: 4,
  TRANSPORTATION: 5,
  DELIVERED: 6,
};

export const DOCUMENT_KEY = [
  {
    doc_name: "DOCUMENT_ARR_0",
    doc_key: "commercial_invoice_file",
    status_name: "commercial_invoice_approve_status",
  },
  {
    doc_name: "DOCUMENT_ARR_1",
    doc_key: "packing_list_file",
    status_name: "packing_list_approve_status",
  },
  {
    doc_name: "DOCUMENT_ARR_2",
    doc_key: "permit_file",
    status_name: "permit_approve_status",
  },
  {
    doc_name: "DOCUMENT_ARR_3",
    doc_key: "bl_of_booking_file",
    status_name: "bl_of_booking_approve_status",
  },
  {
    doc_name: "DOCUMENT_ARR_4",
    doc_key: "bl_of_shipment_file",
    status_name: "bl_of_shipment_approve_status",
  },
  {
    doc_name: "DOCUMENT_ARR_5",
    doc_key: "certificates_of_origin_file",
    status_name: "certificates_of_origin_approve_status",
  },
  {
    doc_name: "DOCUMENT_ARR_6",
    doc_key: "other_documents_file",
    status_name: "other_documents_approve_status",
  },
];

export const DOCUMENT_APPROVE = {
  APPROVE: 1,
  CANCEL: 2,
  ALL_DOC_UPLOADED: 4,
};
