export const STATUS_CODES = {
  HEADER_IS_MISSING: 100,
  DEVICE_TYPE_NOT_ALLOWED: 101,
  INVALID_API_KEY: 102,
  TOKEN_IS_REQUIRED: 103,
  INVALID_TOKEN: 104,
  VALIDATION_ERROR: 105,
  SUCCESS: 200,
  INTERNAL_SERVER_ERROR: 500,
  INVALID_LOGIN_CREDENTIALS: 111,
  ACCOUNT_NOT_FOUND: 112,
  ACCOUNT_CURRENTLY_INACTIVE: 113,
  ACCOUNT_DELETED: 114,
  OLD_PASSWORD_NOT_MATCH: 115,
  EMAIL_ALREADY_USED: 117,
  FAILED_TO_SEND_EMAIL: 123,
  OTP_NOT_MATCHED: 125,
  TOKEN_EXPIRES: 127,
  INVALID_API_KEY: 401,
  VALIDATION_ERRORS: 400,
  STAFF_ALREADY_LOGED_IN: 143,
  INVENTORY_FILE_EXTENSION: 129,
  INVALID_FILE_SIZE: 130,
  FAILED_TO_SAVE: 141,

  COULD_NOT_SEND_INVITATION: 143,
  INVALID_FILE_SIZE: 144,
  INVENTORY_FILE_EXTENSION: 145,
  INVALID_USER: 146,
  SIGNATURE_IS_REQUIRED: 147,
  INVENTORY_INVALID_OBJECT: 148,
  INVENTORY_INVALID_OBJECT: 149,
  INVALID_MIN_INVEN_QUANTITY: 150,
  ORDER_COULD_NOT_CREATED: 151,
  INVALID_ORDER_ID: 152,
  CUSTOM_ORDER_ID_ALREADY_EXIT: 161,
  PRODUCT_EXIST: 198,
};
