import React, { useEffect, useState } from "react";
import styles from "./DashboardList.module.css";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import CitransApi from "../../../helpers/Api";
import { STATUS_CODES } from "../../../Utils/StatusCode";
import { userLogoutClear } from "../../../Redux/Slices/userSlice";
import Toster from "../../../Utils/Toster";
import { PAGINATE } from "../../../Utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Common/LoadingSpinner";

function DashboardTrafficList() {
  const { t } = useTranslation();

  const timesToRender = 4;

  const itemsPerPage = PAGINATE.PER_PAGE;

  const isAuthenticated = useSelector((state) => state.user.userToken);

  const dispatch = useDispatch();

  const [list, setList] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [totalRecords, setTotalRecords] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const handleGetTrafficList = (pageNumber) => {
    let res = CitransApi.getDashboardTrafficList(isAuthenticated, pageNumber);

    res.then((data) => {
      if (
        data?.code == STATUS_CODES.INVALID_TOKEN ||
        data?.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsloading(false);
      } else {
        setList(data?.data.trafficList);
        setTotalRecords(data?.data.count);
        setIsloading(false);
      }
    });
  };

  useEffect(() => {
    handleGetTrafficList(pageNumber);
  }, [pageNumber]);

  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNumber(newPageNumber);
  };

  return (
    <section>
      {/* <Row>
              <Col lg={6}>
                <Form>
                  <Form.Group className="LoginInput">
                    <Form.Label>{t("SEARCH")}</Form.Label>
                    <Form.Control
                      // className={styles.SearchField}
                      type="search"
                      placeholder={t("SEARCH_BY_ORDER_NO_CUSTOM_ORDER_ID")}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row> */}

      {isloading ? <Loader /> : null}

      <div>
        {Array.isArray(list) &&
          list?.map((item, index) => {
            var dateObject = new Date(item.eta_date);
            var day = dateObject.getUTCDate();
            var month = dateObject.getUTCMonth() + 1; // Month is zero-based, so add 1
            var year = dateObject.getUTCFullYear();
            var FormatDate = `${day}/${month}/${year}`;

            var dateObjectETD = new Date(item.etd_date);
            var dayETD = dateObjectETD.getUTCDate();
            var monthETD = dateObjectETD.getUTCMonth() + 1; // Month is zero-based, so add 1
            var yearETD = dateObjectETD.getUTCFullYear();
            var FormatDateETD = `${dayETD}/${monthETD}/${yearETD}`;

            return (
              <React.Fragment>
                <div className={styles.suppliersCard} key={index}>
                  <div className={styles.suppliersCardHead}>
                    <p>
                      {t("SHIPMENT_QUANTITY")} : <span>{"1"}</span>
                    </p>
                    <p>
                      {t("APPLICANT_AREA")} : <span>{item.applicant_area}</span>
                    </p>
                    <p>
                      {t("POD")} : <span>{item.pod}</span>
                    </p>
                    <p>
                      {t("QUANTITY_OF_CONTAINERS")} :{" "}
                      <span>{item.quantity_of_containers}</span>
                    </p>
                    <p>
                      {t("PRODUCT")} :{" "}
                      <span>
                        {item?.trafficItems.map((product, index) => {
                          return (
                            <React.Fragment key={index}>
                              {product.material_reference}
                              {item?.trafficItems?.length - 1 !== index
                                ? ", "
                                : "."}
                            </React.Fragment>
                          );
                        })}
                      </span>
                    </p>
                    <p>
                      {t("POL_PART_OF_ORIGIN")} : <span>{item.pol}</span>
                    </p>

                    {/* ==================== */}
                    <p>
                      {t("ETD_DATE")} : <span>{FormatDateETD}</span>
                    </p>
                    <p>
                      {t("ETA_DATE")} : <span>{FormatDate}</span>
                    </p>
                    <p>
                      {t("NUMBER_OF_ORDER")} : <span>{item.order_number}</span>
                    </p>
                    <p>
                      {t("ORDER_ID")} : <span>{item.custom_order_id}</span>
                    </p>
                    <p>
                      {t("BL_OR_BOOKING")} : <span>{item.bl_number}</span>
                    </p>
                    <p>
                      {t("CONTAINER_NO")} :{" "}
                      <span>
                        {item?.containers.map((product, index) => {
                          return (
                            <React.Fragment key={index}>
                              {product.container_number}
                              {item?.containers?.length - 1 !== index
                                ? ", "
                                : "."}
                            </React.Fragment>
                          );
                        })}
                      </span>
                    </p>
                  </div>

                  <div className={`${styles.suppliersCardHeadRight}`}>
                    <p>
                      {t("QUANTITY_OF_ORDER_AND_TYPE")} :{" "}
                      <span>{`${item.quantity_of_orders}`}</span>
                    </p>

                    <p>
                      {t("AMOUNT_OF_ORDER")} :{" "}
                      <span>{item.total_amount_of_order}</span>
                    </p>

                    <p>
                      {t("SHIPPING_LINE")} : <span>{item.shipping_line}</span>
                    </p>

                    <p>
                      {t("ORDER_LOCATION")} :{" "}
                      <span>
                        {item?.containers.map((product, index) => {
                          return (
                            <React.Fragment key={index}>
                              {product.location}
                              {item?.containers?.length - 1 !== index
                                ? ", "
                                : "."}
                            </React.Fragment>
                          );
                        })}
                      </span>
                    </p>

                    <p>
                      {t("PRODUCT_QUANTITY")} :{" "}
                      <span>
                        {/* {item?.trafficItems.reduce(
                        (total, product) => total + product.product_quantity,
                        0
                      )} */}
                        {item?.trafficItems.length}
                      </span>
                    </p>

                    {/* <p>
                    {t("POD")} : <span>{item.pod}</span>
                  </p> */}
                  </div>
                </div>

                {list.length - 1 !== index ? (
                  <div className={styles.devider}></div>
                ) : (
                  ""
                )}
              </React.Fragment>
            );
          })}

        {totalRecords == 0 ? <div>{t("RESULT_NOT_FOUND")}</div> : null}

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {totalRecords > itemsPerPage && (
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              pageCount={Math.ceil(totalRecords / itemsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </section>
  );
}

export default DashboardTrafficList;
