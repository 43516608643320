import React, { useEffect, useState, useCallback } from "react";
import styles from "./CustomsList.module.css";
import TopHeader from "../TopHeader/TopHeader";
import Sidebar from "../Sidebar/Sidebar";
import { Icon } from "@iconify/react";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import IconComplete from "../../Assests/Images/IconComplete.svg";
import IconPending from "../../Assests/Images/IconPending.svg";
import { Form, Col, Row } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CitransApi from "../../helpers/Api";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Loader from "../../Common/LoadingSpinner";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import Toster from "../../Utils/Toster";
import { PAGINATE, SHIPMENT_STATUS } from "../../Utils/Constants";
import ReactPaginate from "react-paginate";
import { debounce } from "lodash";
import { STATUS_MSG } from "../../Utils/StatusMsg";

// import { DownloadPDF } from "../PurchaseOrder/PDFDownload";

function CustomsList() {
  const isAuthenticated = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();

  const itemsPerPage = PAGINATE.PER_PAGE;

  const { t } = useTranslation();
  const userDetail = useSelector((state) => state.user.currentUser);

  const [isloading, setIsloading] = useState(true);
  const [customList, setCustomList] = useState();
  const [totalRecords, setTotalRecords] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKey, setSearchKey] = useState("");

  const arr = [1, 2, 3, 4, 5];
  const navigate = useNavigate();

  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNumber(newPageNumber);
  };

  useEffect(() => {
    handleGetCustomList(pageNumber);
  }, [pageNumber]);

  const handleGetCustomList = (pageNumber) => {
    setIsloading(true);
    let res = CitransApi.getCustomList(isAuthenticated, pageNumber);

    res.then((data) => {
      if (
        data?.code == STATUS_CODES.INVALID_TOKEN ||
        data?.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        setCustomList(data?.data.customsList);
        setTotalRecords(data?.data.count);
        setIsloading(false);
      }
    });
  };

  const searchStaffDetail = (event) => {
    let res = CitransApi.searchCustomList(isAuthenticated, event);

    res.then((data) => {
      if (
        data.code == STATUS_CODES.INVALID_TOKEN ||
        data.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        setCustomList(data.data.customsList);

        setIsloading(false);
      }
    });
  };

  const delayedSearch = useCallback(
    debounce((event) => {
      searchStaffDetail(event);
    }, 300),
    []
  );

  const handleChange = (event) => {
    setSearchKey(event);
    delayedSearch(event);
  };

  return (
    <>
      {isloading ? <Loader /> : null}
      <section>
        <div>
          <TopHeader />
        </div>

        <div className="MainPageWrapper">
          <div>
            <Sidebar />
          </div>
          <div className="PageContent">
            <div className="PageContentHead">
              <h3>{t("CUSTOM_LIST")}</h3>
            </div>
            <div className="ContentBody">
              <Row>
                <Col lg={6}>
                  <Form>
                    <Form.Group className="LoginInput">
                      <Form.Label>{t("SEARCH")}</Form.Label>
                      <Form.Control
                        // className={styles.SearchField}
                        type="search"
                        placeholder={t("SEARCH_BY_ORDER_ID_AND_NUMBER")}
                        onChange={(e) => {
                          handleChange(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Form>
                </Col>
              </Row>

              {Array.isArray(customList) &&
                customList?.map((item, index) => {
                  var dateObject = new Date(item.arriving_date);
                  var day = dateObject.getUTCDate();
                  var month = dateObject.getUTCMonth() + 1;
                  var year = dateObject.getUTCFullYear();
                  var formatedDate = `${day}/${month}/${year}`;
                  return (
                    <div key={index}>
                      <div className={styles.suppliersCard}>
                        <div className={styles.suppliersCardHead}>
                          <h3>
                            {t("SHIPMENT")} {index + 1}
                          </h3>
                          <p>
                            {t("ORDER_ID")} :{" "}
                            <span>{item.custom_order_id}</span>
                          </p>
                          <p>
                            {t("APPLICANT_AREA")} :{" "}
                            <span>{item.applicant_area}</span>
                          </p>
                          {/* <p>
                      {t("QUANTITY_UNIT")} : <span>55 kg</span>
                    </p> */}
                          <p>
                            {t("ORDER_NUMBER")} :{" "}
                            <span>{item.order_number}</span>
                          </p>
                        </div>

                        <div className={`${styles.suppliersCardHeadRight}`}>
                          <p>
                            {t("TYPE_OF_ORDER")} :{" "}
                            <span>{item.type_of_order}</span>
                          </p>
                          <p>
                            {t("ORDER_STATUS")} :{" "}
                            {item.shipment_status === SHIPMENT_STATUS.PENDING ||
                            item.shipment_status ===
                              SHIPMENT_STATUS.DOCUMENT_REJECTED ||
                            item.shipment_status ===
                              SHIPMENT_STATUS.PAYMENT_PENDING ||
                            item.shipment_status === null ? (
                              <span className={styles.orderStatusPending}>
                                {t(STATUS_MSG[item.shipment_status])}
                              </span>
                            ) : (
                              <span className={styles.orderStatusComplete}>
                                {t(STATUS_MSG[item.shipment_status])}
                              </span>
                            )}
                          </p>
                          <p>
                            {t("ORDER_AMOUNT")} :{" "}
                            <span>
                              {item.currency_of_order} {item.order_amount}
                            </span>
                          </p>
                          <p>
                            {t("ARRIVING_DATE")} : <span>{formatedDate}</span>
                          </p>
                          <div className={styles.iconsClass}>
                            {item.shipment_status === SHIPMENT_STATUS.PENDING ||
                            item.shipment_status ===
                              SHIPMENT_STATUS.DOCUMENT_REJECTED ||
                            item.shipment_status ===
                              SHIPMENT_STATUS.PAYMENT_PENDING ||
                            item.shipment_status === null ? (
                              <p
                                onClick={() =>
                                  navigate("/customs-detail", {
                                    state: { shipment_id: item.shipment_id },
                                  })
                                }
                              >
                                <img
                                  src={IconPending}
                                  width="40px"
                                  height="40px"
                                ></img>
                              </p>
                            ) : (
                              <p
                                onClick={() =>
                                  navigate("/customs-detail", {
                                    state: { shipment_id: item.shipment_id },
                                  })
                                }
                              >
                                <img
                                  src={IconComplete}
                                  width="40px"
                                  height="40px"
                                ></img>
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      {index !== arr.length - 1 ? (
                        <div className={styles.devider}></div>
                      ) : null}
                    </div>
                  );
                })}
              {totalRecords == 0 ? <div>{t("RESULT_NOT_FOUND")}</div> : null}
              {searchKey === "" ? (
                <div className={styles.tableDetail}>
                  <div className={styles.totalRecord}>
                    {totalRecords !== 0 ? (
                      <p>
                        {t("SHOWING")} {1 + pageNumber * 10 - 10}-
                        {(pageNumber - 1) * 10 + customList?.length} of{" "}
                        {totalRecords} {t("RECORDS")}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    {totalRecords > itemsPerPage && (
                      <ReactPaginate
                        previousLabel="Previous"
                        nextLabel="Next"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        pageCount={Math.ceil(totalRecords / itemsPerPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                      />
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </section>
    </>
  );
}

export default CustomsList;
