import { sanitizeHtmlTags } from "../../Common/ValidationSchema";

export const validation = {
  companyName: {
    required: "COMPANY_NAME",
    ...sanitizeHtmlTags(),
  },
  driverName: {
    required: "SELECT_DRIVER_NAME",
    ...sanitizeHtmlTags(),
  },
  vehicleLicence: {
    required: "VEHICLE_LICENSE_PLATE",
    ...sanitizeHtmlTags(),
  },
  vehicleLable: {
    required: "LABEL",
    ...sanitizeHtmlTags(),
  },
  deliveryDate: {
    required: "DELIVERY_DATE",
    ...sanitizeHtmlTags(),
  },
  receiverName: {
    required: "RECEIVER_NAME",
    ...sanitizeHtmlTags(),
  },
  location: {
    required: "LOCATION_SELECT",
    ...sanitizeHtmlTags(),
  },
};
