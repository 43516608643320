import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "../Customs/Alert.module.css";
import AlertCross from ".././../Assests/Images/AlertCross.svg";
import { useDispatch, useSelector } from "react-redux";
import CitransApi from "../../helpers/Api.js";
import { STATUS_CODES } from "../../Utils/StatusCode.js";
import { STATUS_MSG } from "../../Utils/StatusMsg.js";
import Loader from "../../Common/LoadingSpinner.js";
import Toster from "../../Utils/Toster.js";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { userLogoutClear } from "../../Redux/Slices/userSlice.js";

function DeleteOrder({
  orderId,
  show,
  handleClose,
  handleGetOrderDetail,
  pageNumber,
  setSearchKey,
}) {
  const isAuthenticated = useSelector((state) => state.user.userToken);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isloading, setIsloading] = useState(false);

  const dispatch = useDispatch();

  const handleDeleteOrder = () => {
    setIsloading(true);
    let res = CitransApi.deleteOrderDetails(isAuthenticated, orderId);

    res.then((response) => {
      setIsloading(false);
      const ResponseCode = STATUS_MSG[response.data?.code];
      if (
        response.code == STATUS_CODES.INVALID_TOKEN ||
        response.data?.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        if (response.code === STATUS_CODES.SUCCESS) {
          if (handleGetOrderDetail) {
            Toster(t("ORDER_DELETED"), "success");
            handleGetOrderDetail(pageNumber);
            setSearchKey("");
            handleClose();
            navigate("/purchase");
          } else {
            Toster(t("ORDER_DELETED"), "success");

            handleClose();
            navigate("/purchase");
          }
        } else {
          if (
            response.code == STATUS_CODES.VALIDATION_ERROR ||
            response.data.code == STATUS_CODES.VALIDATION_ERROR
          ) {
            Toster(response.data.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      }
    });
  };

  return (
    <>
      {isloading ? (
        <>
          <Loader />
        </>
      ) : null}
      <Modal show={show} onHide={handleClose}>
        <div className={styles.MainBox}>
          <div className={styles.header}>
            <p>{t("ALERT")}</p>
            <img src={AlertCross} onClick={handleClose}></img>
          </div>

          <div className={styles.devider}></div>

          <div className={styles.alertLabel}>
            <p>{t("ARE_YOU_SURE")}.</p>
          </div>

          <div className={styles.buttonContainer}>
            <button className={styles.buttonNo} onClick={handleClose}>
              {t("NO")}
            </button>
            <button className={styles.buttonYes} onClick={handleDeleteOrder}>
              {t("YES")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DeleteOrder;
