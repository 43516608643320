import "cropperjs/dist/cropper.css";
import { Button, Col, Modal, ModalHeader, Row } from "react-bootstrap";
import { IoMdCloseCircle } from "react-icons/io";
import { Cropper } from "react-cropper";
import { useTranslation } from "react-i18next";

export default function CropImage({
  show,
  signature,
  cropperRef,
  handelCropClose,
  handelCropSubmit,
}) {
  const { t } = useTranslation();
  return (
    <>
      <Modal show={show}>
        <div className="SignupPopup">
          <ModalHeader>
            <Modal.Title>{t("IMAGE_CROPPER")}</Modal.Title>
            <IoMdCloseCircle onClick={() => handelCropClose()} />
          </ModalHeader>
          <Modal.Body>
            <Row>
              <Col lg={12}>
                <Cropper
                  src={signature}
                  aspectRatio={16 / 9}
                  ref={cropperRef}
                  cropBoxResizable={false}
                  viewMode={1}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handelCropClose()}>
              {t("CLOSE")}
            </Button>
            <Button variant="primary" onClick={() => handelCropSubmit()}>
              {t("CROP")}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}
