import React, { useEffect, useRef, useState } from "react";
import TopHeader from "../TopHeader/TopHeader";
import Sidebar from "../Sidebar/Sidebar";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import styles from "./Profile.module.css";
import { Icon } from "@iconify/react";
import AddMore from "./AddMore";
import UploadImg from "../../Assests/Images/UploadImg.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { USER_TYPE } from "../../Utils/Constants";
import "cropperjs/dist/cropper.css";
import CropImage from "./CropImage";
import CitransApi from "../../helpers/Api";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Loader from "../../Common/LoadingSpinner";
import { useForm } from "react-hook-form";
import { validationSchema } from "../../Common/ValidationSchema";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Button, Form } from "react-bootstrap";
import Toster from "../../Utils/Toster";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import UpdateProfile from "./UpdateProfile";
import AccountEmailList from "./AccountEmailList";

function Profile() {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState,
    setError,
    getValues,
  } = useForm({
    mode: "onChange",
  });
  const userDetail = useSelector((state) => state.user.currentUser);
  const { t } = useTranslation();
  const [key, setKey] = useState("first");
  const [profileData, setProfileData] = useState();
  const [showaddMore, setShowaddMore] = useState(false);
  const [signature, setSignature] = useState(null);
  const [fileData, setFileData] = useState();
  const [isCropping, setIsCropping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => setShowaddMore(false);
  const signatureInputRef = useRef(null);
  const cropperRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [reFetch, setReFetch] = useState(false);

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    getProfileSignature();
  }, []);

  const getProfile = () => {
    setIsLoading(true);
    const response = CitransApi.getProfileData(userDetail.token);
    response.then((res) => {
      if (res.code === STATUS_CODES.SUCCESS) {
        setProfileData(res.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const getProfileSignature = () => {
    setIsLoading(true);
    const response = CitransApi.getProfileSignature(userDetail.token);
    response.then((res) => {
      if (res.code === STATUS_CODES.SUCCESS) {
        setSignature(res.data.signature_image);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const postProfileSignature = (croppedFile) => {
    setIsLoading(true);
    var Postdata = new FormData();
    Postdata.append("signature_image", croppedFile);

    const response = CitransApi.postProfileSignature(
      userDetail.token,
      Postdata
    );
    response.then((res) => {
      if (res.code === STATUS_CODES.SUCCESS) {
        getProfileSignature();
      } else {
        setIsLoading(false);
      }
    });
  };

  const handelCropSubmit = (e) => {
    setIsCropping(false);
    setSignature(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
    const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas();

    // Convert the cropped canvas to a Blob
    croppedCanvas.toBlob((blob) => {
      // Create a File object from the Blob
      var croppedFile = new File([blob], fileData.name, {
        type: fileData.type,
      });

      // Now you can use croppedFile as a File object
      postProfileSignature(croppedFile);

      // Save or process the croppedFile as needed
    }, fileData.type);
  };

  const handelCropClose = () => {
    setIsCropping(false);
    setSignature(null);
  };

  const handleUploadSignature = () => {
    signatureInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFileData({ name: selectedFile.name, type: selectedFile.type });
      setIsCropping(true);
      const reader = new FileReader();
      reader.onload = () => {
        setSignature(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
    setSignature(selectedFile);
  };

  const handleOpenFullImage = () => {
    window.open(signature);
  };

  const cardsData = [
    {
      name: "Elijah smith",
      email: "elijahsmith52@gmail.com",
      position: "Sr. UI/UX Designer",
      location: "248 Dickens Key, Lake Jaysonside, MD 96941-4852",
    },
    {
      name: "Michael roy",
      email: "michaelroy789@gmail.com",
      position: "Sr. Apple Designer",
      location: "Apt. 712 5127 Dean CanBrandenville, MD 26913",
    },
    {
      name: "Thomas Admin",
      email: "thomasadmin@gmail.com",
      position: "Jr. UI/UX Designer",
      location: "Suite 898 466 Hansen Spurs, New Wilsonport, ME 33395-6037",
    },
    {
      name: "Elijah smith",
      email: "elijahsmith52@gmail.com",
      position: "Sr. UI/UX Designer",
      location: "248 Dickens Key, Lake Jaysonside, MD 96941-4852",
    },
    {
      name: "Michael roy",
      email: "michaelroy789@gmail.com",
      position: "Sr. Apple Designer",
      location: "Apt. 712 5127 Dean CanBrandenville, MD 26913",
    },
    {
      name: "Thomas Admin",
      email: "thomasadmin@gmail.com",
      position: "Jr. UI/UX Designer",
      location: "Suite 898 466 Hansen Spurs, New Wilsonport, ME 33395-6037",
    },
    {
      name: "Elijah smith",
      email: "elijahsmith52@gmail.com",
      position: "Sr. UI/UX Designer",
      location: "248 Dickens Key, Lake Jaysonside, MD 96941-4852",
    },
    {
      name: "Michael roy",
      email: "michaelroy789@gmail.com",
      position: "Sr. Apple Designer",
      location: "Apt. 712 5127 Dean CanBrandenville, MD 26913",
    },
  ];

  const handelSubmit = (formData) => {
    setIsLoading(true);
    var Postdata = new FormData();
    Postdata.append("old_password", formData.OLD_PASSWORD);
    Postdata.append("new_password", formData.password);

    const response = CitransApi.changePassword(userDetail.token, Postdata);
    response.then((res) => {
      const responseCode = STATUS_MSG[res.data.code];
      if (res.code === STATUS_CODES.SUCCESS) {
        Toster(`${t("PASSWORD")} ${t("UPDATE_SUCCESS")}`, "success");
        setIsLoading(false);
        reset();
      } else if (res.data.code === STATUS_CODES.OLD_PASSWORD_NOT_MATCH) {
        Toster(t(responseCode), "error");
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const updateProfile = (data) => {
    // console.log("updateProfile", data);
    setIsUpdating(true);
  };

  const handelUpdateClose = () => {
    setIsUpdating(false);
  };

  const formateContact = (code, phone) => {
    const number = `${code} ${phone?.slice(code.length - 1).trim()}`;
    return number;
  };

  return (
    <>
      <section>
        <div>
          <TopHeader />
        </div>
        <div className="MainPageWrapper">
          <div>
            <Sidebar />
          </div>
          <div className="PageContent">
            <div className="PageContentHead">
              <h3>{t("PROFILE")}</h3>

              {key === "second" && (
                <React.Fragment>
                  {userDetail.user_type !== USER_TYPE.IS_STAFF ? (
                    <div
                      onClick={() => setShowaddMore(true)}
                      className="ContentAddBtn"
                    >
                      <Icon
                        icon="octicon:feed-plus-16"
                        color="white"
                        width="22"
                        height="22"
                      />
                      <span>{t("ADD_MORE")}</span>
                    </div>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              )}
            </div>
            <div className="ContentBody">
              <Tab.Container
                id="profile-tabs"
                onSelect={(k) => setKey(k)}
                defaultActiveKey="first"
              >
                <Row>
                  <Col>
                    <Nav className="flex-row">
                      <Nav.Item>
                        <Nav.Link
                          className={`${styles.profileTabsLink} ${
                            key === "first" ? styles.profileTabsLinkActive : ""
                          } `}
                          eventKey="first"
                        >
                          {t("BASIC_INFORMATION")}
                        </Nav.Link>
                      </Nav.Item>
                      {userDetail.user_type !== USER_TYPE.IS_STAFF ? (
                        <Nav.Item>
                          <Nav.Link
                            className={`${styles.profileTabsLink} ${
                              key === "second"
                                ? styles.profileTabsLinkActive
                                : ""
                            } `}
                            eventKey="second"
                          >
                            {t("ACCOUNT_EMAIL")}
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        ""
                      )}

                      {/* UPLOAD_SIGNATURE */}
                      {userDetail.user_type !== USER_TYPE.IS_STAFF ? (
                        <Nav.Item>
                          <Nav.Link
                            className={`${styles.profileTabsLink} ${
                              key === "third"
                                ? styles.profileTabsLinkActive
                                : ""
                            } `}
                            eventKey="third"
                          >
                            {t("SIGNATURE")}
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        ""
                      )}
                      <Nav.Item>
                        <Nav.Link
                          className={`${styles.profileTabsLink} ${
                            key === "fourth" ? styles.profileTabsLinkActive : ""
                          } `}
                          eventKey="fourth"
                        >
                          {t("CHANGE_PASSWORD")}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>
                <p className={`${styles.hrLineP}`}></p>
                <Row className={`${styles.basicInfoTabRow}`}>
                  <Col>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        {profileData && !isUpdating && (
                          <>
                            <div className={styles.editBtnSection}>
                              <p
                                className={styles.iconsClassEdit}
                                title={t("UPDATE")}
                              >
                                <Icon
                                  icon="ic:round-edit"
                                  color="#626262"
                                  width="24"
                                  height="24"
                                  onClick={() => updateProfile(profileData)}
                                />
                              </p>
                            </div>
                            <Row className={`${styles.rowBasicInfo}`}>
                              <Col
                                md="auto"
                                className={`${styles.basicInfo} ${styles.leftBasicInfo} `}
                              >
                                {t("COMPANY_NAME")} :{" "}
                                <span className={styles.rightBasicInfo}>
                                  {profileData.company_name}
                                </span>
                              </Col>
                            </Row>
                            <Row className={`${styles.rowBasicInfo}`}>
                              <Col
                                md="auto"
                                className={`${styles.basicInfo} ${styles.leftBasicInfo} `}
                              >
                                {t("USER_NAME")} :{" "}
                                <span className={styles.rightBasicInfo}>
                                  {profileData.user_name}
                                </span>
                              </Col>
                            </Row>
                            <Row className={`${styles.rowBasicInfo}`}>
                              <Col
                                md="auto"
                                className={`${styles.basicInfo} ${styles.leftBasicInfo} `}
                              >
                                {t("EMAIL")} :{" "}
                                <span className={styles.rightBasicInfo}>
                                  {profileData.email}
                                </span>
                              </Col>
                            </Row>
                            <Row className={`${styles.rowBasicInfo}`}>
                              <Col
                                md="auto"
                                className={`${styles.basicInfo} ${styles.leftBasicInfo} `}
                              >
                                {t("PHONE_NUMBER")} :{" "}
                                <span className={styles.rightBasicInfo}>
                                  {formateContact(
                                    profileData.compnay_dial_code,
                                    profileData.compnay_phone_number
                                  )}
                                </span>
                              </Col>
                            </Row>
                            <Row className={`${styles.rowBasicInfo}`}>
                              <Col
                                md="auto"
                                className={`${styles.basicInfo} ${styles.leftBasicInfo} `}
                              >
                                {t("CUSTOMS_TREATMENT")} :{" "}
                                <span className={styles.rightBasicInfo}>
                                  {profileData.customs_treatment}
                                </span>
                              </Col>
                            </Row>
                            <Row className={`${styles.rowBasicInfo}`}>
                              <Col
                                md="auto"
                                className={`${styles.basicInfo} ${styles.leftBasicInfo} `}
                              >
                                {t("CUSTOMS_REGIME")} :{" "}
                                <span className={styles.rightBasicInfo}>
                                  {profileData.customs_regime}
                                </span>
                              </Col>
                            </Row>
                            <Row className={`${styles.rowBasicInfo}`}>
                              <Col
                                md="auto"
                                className={`${styles.basicInfo} ${styles.leftBasicInfo} `}
                              >
                                {t("TAX_ID")} :{" "}
                                <span className={styles.rightBasicInfo}>
                                  {profileData.tax_id}
                                </span>
                              </Col>
                            </Row>
                            <Row className={`${styles.rowBasicInfo}`}>
                              <Col
                                md="auto"
                                className={`${styles.basicInfo} ${styles.leftBasicInfo} `}
                              >
                                {t("OCCUPATION")} :{" "}
                                <span className={styles.rightBasicInfo}>
                                  {profileData.occupation}
                                </span>
                              </Col>
                            </Row>
                            <Row className={`${styles.rowBasicInfo}`}>
                              <Col
                                md="auto"
                                className={`${styles.basicInfo} ${styles.leftBasicInfo} `}
                              >
                                {t("ADDRESS")} :{" "}
                                <span className={styles.rightBasicInfo}>
                                  {profileData.address}
                                </span>
                              </Col>
                            </Row>
                          </>
                        )}
                        {isUpdating && (
                          <UpdateProfile
                            data={profileData}
                            getProfile={getProfile}
                            handelUpdateClose={handelUpdateClose}
                          />
                        )}
                      </Tab.Pane>
                      {/* ACCOUNT EMAIL */}
                      <Tab.Pane eventKey="second">
                        <AccountEmailList
                          reFetch={reFetch}
                          setReFetch={setReFetch}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <div className={styles.uploadWrapper}>
                          <div className={styles.UploadDocs}>
                            <div
                              className={styles.UploadBlock}
                              onClick={handleUploadSignature}
                            >
                              <img
                                alt="Not found"
                                src={UploadImg}
                                width="40px"
                                height="40px"
                              ></img>
                              <p>{`${t("UPLOAD")} ${t("SIGNATURE")}`}</p>
                            </div>
                            <div className={styles.UploadBlockLabel}></div>

                            <input
                              type="file"
                              name="signatureProfile"
                              ref={signatureInputRef}
                              style={{ display: "none" }}
                              accept=".jpg, .jpeg, .png, image/*"
                              onChange={handleFileChange}
                            />
                          </div>
                          <div className={styles.signatureField}>
                            {signature && (
                              <>
                                <img
                                  src={signature}
                                  alt="Uploaded Preview"
                                  title={t("View Image")}
                                  onClick={() => handleOpenFullImage()}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        <Form noValidate onSubmit={handleSubmit(handelSubmit)}>
                          <Row>
                            <Row>
                              <Col lg={6} md={6}>
                                <Form.Group className="LoginInput">
                                  <Form.Label>{t("OLD_PASSWORD")}</Form.Label>
                                  <Form.Control
                                    {...register(
                                      "OLD_PASSWORD",
                                      validationSchema.OLD_PASSWORD
                                    )}
                                    isInvalid={!!formState.errors.OLD_PASSWORD}
                                    placeholder={`${t("ENTER")} ${t(
                                      "OLD_PASSWORD"
                                    )}`}
                                    autoComplete="off"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {t(formState.errors.OLD_PASSWORD?.message)}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6} md={6}>
                                <Form.Group className="LoginInput">
                                  <Form.Label>{t("NEW_PASSWORD")}</Form.Label>
                                  <Form.Control
                                    {...register(
                                      "password",
                                      validationSchema.password
                                    )}
                                    isInvalid={!!formState.errors.password}
                                    type={isVisible ? "text" : "password"}
                                    placeholder={`${t("ENTER")} ${t(
                                      "NEW_PASSWORD"
                                    )}`}
                                    autoComplete="off"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {t(formState.errors.password?.message)}
                                  </Form.Control.Feedback>
                                  {isVisible ? (
                                    <FaRegEye
                                      onClick={() => setIsVisible(false)}
                                    />
                                  ) : (
                                    <FaRegEyeSlash
                                      onClick={() => setIsVisible(true)}
                                    />
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6} md={6}>
                                <div className={styles.changePassBtn}>
                                  <Button
                                    type="submit"
                                    onClick={() => handelSubmit}
                                  >
                                    {t("CHANGE_PASSWORD")}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Row>
                        </Form>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </section>
      <AddMore
        show={showaddMore}
        handleClose={handleClose}
        setReFetch={setReFetch}
      />
      <CropImage
        show={isCropping}
        signature={signature}
        cropperRef={cropperRef}
        handelCropClose={handelCropClose}
        handelCropSubmit={handelCropSubmit}
      />
      {isLoading && <Loader />}
    </>
  );
}
export default Profile;
