import { sanitizeHtmlTags } from "../../Common/ValidationSchema";

export const validation = {
  BL_BOOKING: {
    required: "ENTER_BL_OR_BOOKING",
    minLength: {
      value: 5,
      message: "SHOULD_BE_MIN_5_CHARACTERS",
    },
    maxLength: {
      value: 20,
      message: "SHOULD_BE_MAX_20_CHARACTERS",
    },
    ...sanitizeHtmlTags(),
  },
  POL: {
    required: "ENTER_POL",
    minLength: {
      value: 5,
      message: "SHOULD_BE_MIN_5_CHARACTERS",
    },
    maxLength: {
      value: 20,
      message: "SHOULD_BE_MAX_20_CHARACTERS",
    },
    ...sanitizeHtmlTags(),
  },
  SHIPPING_LINE: {
    required: "ENTER_SHIPPING_LINE",
    minLength: {
      value: 5,
      message: "SHOULD_BE_MIN_5_CHARACTERS",
    },
    maxLength: {
      value: 100,
      message: "SHOULD_BE_MAX_100_CHARACTERS",
    },
    ...sanitizeHtmlTags(),
  },
  VESSEL: {
    required: "ENTER_VESSEL",
    minLength: {
      value: 5,
      message: "SHOULD_BE_MIN_5_CHARACTERS",
    },
    maxLength: {
      value: 100,
      message: "SHOULD_BE_MAX_100_CHARACTERS",
    },
    ...sanitizeHtmlTags(),
  },
  INVOICE_NUMBER: {
    required: "ENTER_INVOICE_NUMBER",
    minLength: {
      value: 5,
      message: "SHOULD_BE_MIN_5_CHARACTERS",
    },
    maxLength: {
      value: 20,
      message: "SHOULD_BE_MAX_20_CHARACTERS",
    },
    ...sanitizeHtmlTags(),
  },
  POD: {
    required: "ENTER_POD",
    minLength: {
      value: 5,
      message: "SHOULD_BE_MIN_5_CHARACTERS",
    },
    maxLength: {
      value: 20,
      message: "SHOULD_BE_MAX_20_CHARACTERS",
    },
    ...sanitizeHtmlTags(),
  },
  ETD_DATE: {
    required: "SELECT_ETD_DATE",
    minLength: {
      value: 5,
      message: "SHOULD_BE_MIN_5_CHARACTERS",
    },
    maxLength: {
      value: 20,
      message: "SHOULD_BE_MAX_20_CHARACTERS",
    },
    ...sanitizeHtmlTags(),
  },
  ETA_DATE: {
    required: "SELECT_ETA_DATE",
    ...sanitizeHtmlTags(),
  },
  DELIVERY_DATE: {
    required: "SELECT_DELIVERY_DATE",
    ...sanitizeHtmlTags(),
  },
};
