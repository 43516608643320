import Modal from "react-bootstrap/Modal";
import styles from "../Customs/Alert.module.css";
import AlertCross from ".././../Assests/Images/AlertCross.svg";

import { useTranslation } from "react-i18next";
function SureToChangeUnit({ show, handleNotChangeUnit, handleChangeUnit }) {
  const { t } = useTranslation();
  return (
    <>
      <Modal show={show} onHide={handleNotChangeUnit}>
        <div className={styles.MainBox}>
          <div className={styles.header}>
            <p>{t("ALERT")}</p>
            <img src={AlertCross} onClick={handleNotChangeUnit}></img>
          </div>

          <div className={styles.devider}></div>

          <div className={styles.alertLabel}>
            <p>{t("ARE_YOU_SURE_TO_CHANGE_INVENTORY_UNIT")}.</p>
          </div>

          <div className={styles.buttonContainer}>
            <button className={styles.buttonNo} onClick={handleNotChangeUnit}>
              {t("NO")}
            </button>
            <button className={styles.buttonYes} onClick={handleChangeUnit}>
              {t("YES")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SureToChangeUnit;
