import { sanitizeHtmlTags } from "../../Common/ValidationSchema";

export const validation = {
  name: {
    required: "PLEASE_PROVIDE_SUPPLIER_NAME",
    maxLength: {
      message: "SUPPLIER_NAME_MUST_BE_LESS_THAN_100_CHARACTERS",
    },
    ...sanitizeHtmlTags(),
  },
  email: {
    required: "PLEASE_PROVIDE_AN_EMAIL_ADDRESS",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: "PLEASE_PROVIDE_A_VALID_EMAIL_ADDRESS",
    },
    maxLength: {
      value: 40,
      message: "EMAIL_MUST_BE_LESS_THAN_40_CHARACTERS",
    },
    ...sanitizeHtmlTags(),
  },
  contactNumber: {
    required: "PLEASE_PROVIDE_CONTACT_NUMBER",
    minLength: {
      value: 5,
      message: "CONTACT_NUMBER_MUST_BE_AT_LEAST_5_CHARACTERS",
    },
    maxLength: {
      value: 15,
      message: "CONTACT_NUMBER_MUST_BE_LESS_THAN_15_CHARACTERS",
    },
  },
  address: {
    required: "PLEASE_PROVIDE_ADDRESS",
    maxLength: {
      value: 250,
      message: "ADDRESS_MUST_BE_LESS_THAN_250_CHARACTERS",
    },
    ...sanitizeHtmlTags(),
  },
  tax_id: {
    required: "PLEASE_PROVIDE_TAX_ID",
    maxLength: {
      value: 20,
      message: "TAX_ID_MUST_BE_LESS_THAN_20_CHARACTERS",
    },
  },
  code_supplier: {
    required: "PLEASE_PROVIDE_CODE_SUPPLIER",
    maxLength: {
      value: 50,
      message: "CODE_SUPPLIER_MUST_BE_LESS_THAN_50_CHARACTERS",
    },
  },
  person_1_name: {
    required: "PLEASE_PROVIDE_PERSON_NAME",
    maxLength: {
      value: 50,
      message: "PERSON_NAME_MUST_BE_LESS_THAN_50_CHARACTERS",
    },
  },
  person_2_name: {
    required: "PLEASE_PROVIDE_PERSON_NAME",
    maxLength: {
      value: 50,
      message: "PERSON_NAME_MUST_BE_LESS_THAN_50_CHARACTERS",
    },
  },
  person_1_email: {
    required: "PLEASE_PROVIDE_AN_EMAIL_ADDRESS",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: "PLEASE_PROVIDE_A_VALID_EMAIL_ADDRESS",
    },
    maxLength: {
      value: 40,
      message: "EMAIL_MUST_BE_LESS_THAN_40_CHARACTERS",
    },
  },
  person_2_email: {
    required: "PLEASE_PROVIDE_AN_EMAIL_ADDRESS",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: "PLEASE_PROVIDE_A_VALID_EMAIL_ADDRESS",
    },
    maxLength: {
      value: 40,
      message: "EMAIL_MUST_BE_LESS_THAN_40_CHARACTERS",
    },
  },
  person_1_phn: {
    required: "PLEASE_PROVIDE_CONTACT_NUMBER",
    minLength: {
      value: 5,
      message: "CONTACT_NUMBER_MUST_BE_AT_LEAST_5_CHARACTERS",
    },
    maxLength: {
      value: 15,
      message: "CONTACT_NUMBER_MUST_BE_LESS_THAN_15_CHARACTERS",
    },
  },
  person_2_phn: {
    required: "PLEASE_PROVIDE_CONTACT_NUMBER",
    minLength: {
      value: 5,
      message: "CONTACT_NUMBER_MUST_BE_AT_LEAST_5_CHARACTERS",
    },
    maxLength: {
      value: 15,
      message: "CONTACT_NUMBER_MUST_BE_LESS_THAN_15_CHARACTERS",
    },
  },
  // Add validation rules for other fields...
};
