import React, { useEffect } from "react";
import { useState } from "react";
import styles from "./CustomsDetail.module.css";
import TopHeader from "../TopHeader/TopHeader";
import Sidebar from "../Sidebar/Sidebar";
import { Icon } from "@iconify/react";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import FileImg from "../../Assests/Images/Rectangle56.png";
import DateImg from "../../Assests/Images/DateImg.svg";
import RightImg from "../../Assests/Images/RightImg.svg";
import CrossImg from "../../Assests/Images/CrossImg.svg";

import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { IoMdCloseCircle } from "react-icons/io";
import { MdOutlineMail } from "react-icons/md";
import { useLocation } from "react-router-dom";

import Alert from "./Alert";
import {
  ShippingClerks,
  companyOptions,
  verificationDeposits,
} from "../../mockData";

import { useTranslation } from "react-i18next";
import Toster from "../../Utils/Toster";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import CitransApi from "../../helpers/Api";
import Loader from "../../Common/LoadingSpinner";
import { DOCUMENT_APPROVE, DOCUMENT_KEY } from "../../Utils/Constants";

function CustomsDetail() {
  const { t } = useTranslation();

  const location = useLocation();

  const isAuthenticated = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();

  const shipment_id = location?.state.shipment_id;
  const arr = [1, 2, 3, 4, 5];
  const documents_arr = [
    t("DOCUMENT_ARR_0"),
    t("DOCUMENT_ARR_1"),
    t("DOCUMENT_ARR_2"),
    t("DOCUMENT_ARR_3"),
    t("DOCUMENT_ARR_4"),
    t("DOCUMENT_ARR_5"),
    t("DOCUMENT_ARR_6"),
  ];
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(true);
  const [detail, setDetail] = useState();
  const [documents, setDocuments] = useState();
  const [docKey, setDocKey] = useState("");
  const [approve, setApprove] = useState();

  // =======FORMAT DELIVERY DATE======
  const originalDeliveryDate = detail?.delivery_date;
  const deliveridate = new Date(originalDeliveryDate);
  const daydelivery = deliveridate.getUTCDate();
  const monthdelivery = deliveridate.getUTCMonth() + 1;
  const yeardelivery = deliveridate.getUTCFullYear();
  const formattedDeliveryDate = `${daydelivery}/${monthdelivery}/${yeardelivery}`;

  // =======FORMAT ETA DATE======
  const originalETADate = detail?.eta_date;
  const ETAdate = new Date(originalETADate);
  const dayETA = ETAdate.getUTCDate();
  const monthETA = ETAdate.getUTCMonth() + 1;
  const yearETA = ETAdate.getUTCFullYear();
  const formattedETADate = `${dayETA}/${monthETA}/${yearETA}`;

  // =======FORMAT ETD DATE======
  const originalETDDate = detail?.etd_date;
  const ETDdate = new Date(originalETDDate);
  const dayETD = ETDdate.getUTCDate();
  const monthETD = ETDdate.getUTCMonth() + 1;
  const yearETD = ETDdate.getUTCFullYear();
  const formattedETDDate = `${dayETD}/${monthETD}/${yearETD}`;

  useEffect(() => {
    handleGetCustomDetail();
  }, []);

  const handleGetCustomDetail = () => {
    setIsloading(false);
    let res = CitransApi.getCustomDetail(isAuthenticated, shipment_id);

    res.then((data) => {
      if (
        data?.code == STATUS_CODES.INVALID_TOKEN ||
        data?.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        setDetail(data?.data.customsDetails);
        setDocuments(data?.data.customsDetails.document);
      }
    });
  };

  return (
    <section>
      <div>
        <TopHeader />
      </div>
      {isloading ? <Loader /> : null}

      <div className={styles.mainBox}>
        <div className={styles.innerBox}>
          <div className={styles.heading}>
            <div className={styles.iconsClass}>
              <p
                className={styles.iconsClassReload}
                onClick={() => navigate("/customs")}
              >
                <Icon icon="ep:back" color="white" width="24" height="24" />
              </p>
            </div>
            <div className={styles.HeadingLabel}>
              <p>{t("CUSTOMS_DETAILS")}</p>
            </div>
          </div>

          <div className={styles.billing}>
            <div className={styles.orderAndMethod}>
              <div className={styles.orderAndMethodBlocks}>
                <div className={styles.orderType}>
                  <p>{t("SHIPPING_METHOD")}</p>
                </div>
                <div className={styles.optionButton}>
                  <Button className={styles.optionButtonButton1}>
                    {t("SEA")}
                  </Button>
                  {/* <Button className={styles.optionButtonButton2}>
                    {t("AIR")}
                  </Button> */}
                </div>
              </div>
            </div>

            <div className={styles.ShippingDetails}>
              <p>{t("SHIPPING_DETAILS")}</p>
            </div>

            {/* First Bill */}

            <div className={styles.billingBox}>
              {/* ++++++++++++++++++++++++++ */}
              <div className={styles.bookingDetail}>
                <div className={styles.bookingDetailLeft}>
                  <div className={styles.BLorbooking}>
                    <p>
                      {t("BL_OR_BOOKING")} : {detail?.bl_number}
                    </p>
                  </div>

                  <Stack direction="horizontal" gap={1}>
                    <p className={styles.BLorbooking_key}>{t("POL")} :</p>
                    <p className={styles.BLorbooking_value}>{detail?.pol}</p>
                  </Stack>
                  <Stack direction="horizontal" gap={1}>
                    <p className={styles.BLorbooking_key}>{t("VESSEL")} : </p>
                    <p className={styles.BLorbooking_value}>{detail?.vessel}</p>
                  </Stack>
                  <Stack direction="horizontal" gap={1}>
                    <p className={styles.BLorbooking_key}>
                      {t("DELEVERY_DATE")} :{" "}
                    </p>
                    <p className={styles.BLorbooking_value}>
                      {formattedDeliveryDate}
                    </p>
                    <img
                      src={DateImg}
                      width="14px"
                      height="14px"
                      className={styles.bookingDetailRightFlexImgLeft}
                    ></img>
                  </Stack>
                  <Stack direction="horizontal" gap={1}>
                    <p className={styles.BLorbooking_key}>{t("ETA_DATE")} :</p>
                    <p className={styles.BLorbooking_value}>
                      {formattedETADate}
                    </p>
                    <img
                      src={DateImg}
                      width="14px"
                      height="14px"
                      className={styles.bookingDetailRightFlexImgLeft}
                    ></img>
                  </Stack>
                </div>

                <div className={styles.bookingDetailRight}>
                  <div className={styles.bookingDetailRightFlex}>
                    <p className={styles.BLorbooking_key}>
                      {t("QUANTITY_OF_CONTAINERS")} :
                    </p>
                    <p className={styles.BLorbooking_value_right}>
                      {" "}
                      {detail?.quantity_of_container}
                    </p>
                  </div>

                  <div className={styles.bookingDetailRightFlex}>
                    <p className={styles.BLorbooking_key}>
                      {t("SHIPPING_LINE")} :
                    </p>
                    <p className={styles.BLorbooking_value_right}>
                      {detail?.shipping_line}
                    </p>
                  </div>

                  <div className={styles.bookingDetailRightFlex}>
                    <p className={styles.BLorbooking_key}>{t("ETD_DATE")} :</p>
                    <p className={styles.BLorbooking_value_right}>
                      {formattedETDDate}
                    </p>
                    <img
                      src={DateImg}
                      width="14px"
                      height="14px"
                      className={styles.bookingDetailRightFlexImgRight}
                    ></img>
                  </div>

                  <div className={styles.bookingDetailRightFlex}>
                    <p className={styles.BLorbooking_key}> {t("POD")} : </p>
                    <p className={styles.BLorbooking_value_right}>
                      {detail?.pod}
                    </p>
                  </div>
                </div>
              </div>

              <div className={styles.devider}></div>
              {detail?.containers.map((item, index) => {
                return (
                  <div className={styles.container_holds_product}>
                    <div className={styles.productBox}>
                      <div className={styles.productId1}>
                        <p>{item.container_number}</p>
                      </div>
                      <div className={styles.productId2}>
                        <Stack direction="horizontal" gap={2}>
                          <p className={styles.BLorbooking_key}>
                            {t("BATCH_NO")}.{" "}
                          </p>
                          <p className={styles.BLorbooking_value}>
                            {item.batch_number}
                          </p>
                        </Stack>
                      </div>
                      <div className={styles.productId3}>
                        <Stack direction="horizontal" gap={2}>
                          <p className={styles.BLorbooking_key}>
                            {t("LOCATION")}
                          </p>
                          <p className={styles.BLorbooking_value}>
                            {item.location}
                          </p>
                        </Stack>
                      </div>
                    </div>

                    {item?.containerItemsDetails.map((product, index) => {
                      return (
                        <div className={styles.productBox2} key={index}>
                          <div className={styles.booking}>
                            <p className={styles.productTitle}>
                              {t("BL_OR_BOOKING")} {product.bl_number}
                            </p>
                          </div>
                          <div className={styles.quantity}>
                            <p className={styles.productQuantity}>
                              {t("PRODUCT")} {index + 1} ({t("QUANTITY")})
                            </p>
                            <p className={styles.productTitle}>
                              {product.product_quantity}{" "}
                              {product.unit_of_inventory}
                            </p>
                          </div>
                          <div className={styles.balance}>
                            <p className={styles.productQuantity}>
                              {t("PRODUCT")} {index + 1} ({t("BALANCE")})
                            </p>
                            <p className={styles.productTitle}>
                              {product.remaining_quantity}{" "}
                              {product.unit_of_inventory}
                            </p>
                          </div>
                          <div className={styles.balance}>
                            <p className={styles.productQuantity}>
                              {t("ORDER_ID")}
                            </p>
                            <p className={styles.productTitle}>
                              {product.custom_order_id}
                            </p>
                          </div>
                          {/* <div className={styles.orderid}>
                                <p className={styles.productQuantity}>{t("ORDER_ID")}</p>
                                <b className={styles.productTitle}>TRYG4534</b>
                              </div> */}
                        </div>
                      );
                    })}

                    <div className={styles.devider}></div>
                  </div>
                );
              })}

              <div className={styles.devider}></div>

              {/* ++++++++++++++++++++++++++++ */}
              <div className={styles.devider}></div>

              <div className={styles.documentHolder}>
                <div className={styles.DocsHeading}>
                  <p>{t("ANNEX_DOCUMENTS")}</p>
                </div>
                <Row className="gx-1 gy-3">
                  {DOCUMENT_KEY?.map((doc, index) => {
                    return (
                      <React.Fragment>
                        <Col xs={12} sm={12}>
                          <div className={styles.documentColumn}>
                            <div className={styles.fileimgSet}>
                              <img
                                src={FileImg}
                                width="50px"
                                height="50px"
                              ></img>
                            </div>
                            <div className={styles.docBlock}>
                              <div>
                                <p className={styles.docname}>
                                  {t(doc.doc_name)}
                                </p>
                                {documents?.[doc.status_name] ===
                                DOCUMENT_APPROVE.APPROVE ? (
                                  <p className={styles.docApprove}>
                                    {t("DOCUMENT_APPROVED")}
                                  </p>
                                ) : documents?.[doc.status_name] ===
                                  DOCUMENT_APPROVE.CANCEL ? (
                                  <p className={styles.docCancel}>
                                    {t("DOCUMENT_CANCEL")}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div className={styles.status}>
                              {/* CANCEL DOCUMENT */}

                              {documents?.[doc.status_name] ===
                              DOCUMENT_APPROVE.CANCEL ? (
                                <div className={styles.imgSet}>
                                  <img
                                    src={CrossImg}
                                    width="32px"
                                    height="32px"
                                  ></img>
                                </div>
                              ) : documents?.[doc.status_name] ===
                                DOCUMENT_APPROVE.APPROVE ? (
                                <div className={styles.imgSet}>
                                  <img
                                    src={RightImg}
                                    width="32px"
                                    height="32px"
                                  ></img>
                                </div>
                              ) : (
                                <React.Fragment>
                                  <div
                                    className={styles.imgSet}
                                    onClick={() => {
                                      setDocKey(doc.doc_key);
                                      setApprove(DOCUMENT_APPROVE.CANCEL);
                                    }}
                                  >
                                    <img
                                      src={CrossImg}
                                      width="32px"
                                      height="32px"
                                      onClick={handleShow}
                                      title="click to approve document"
                                    ></img>
                                  </div>
                                  <div
                                    className={styles.imgSet}
                                    onClick={() => {
                                      setDocKey(doc.doc_key);
                                      setApprove(DOCUMENT_APPROVE.APPROVE);
                                    }}
                                  >
                                    <img
                                      src={RightImg}
                                      width="32px"
                                      height="32px"
                                      onClick={handleShow}
                                      title="click to approve document"
                                    ></img>
                                  </div>
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        </Col>

                        <div className={styles.devider}></div>
                      </React.Fragment>
                    );
                  })}
                </Row>
              </div>

              {detail?.shipment_status === DOCUMENT_APPROVE.ALL_DOC_UPLOADED ? (
                <div className={styles.transportationFields}>
                  <div className="transportationFields">
                    <Form>
                      <Row>
                        <div className="transportationButton">
                          <Button
                            style={{ marginTop: "50px" }}
                            onClick={() =>
                              navigate("/payment", {
                                state: { shipment_id: shipment_id },
                              })
                            }
                          >
                            {t("GO_FOR_PAYMENT")}
                          </Button>
                        </div>
                      </Row>
                    </Form>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <Alert
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        approve={approve}
        docKey={docKey}
        shipment_id={shipment_id}
        handleGetCustomDetail={handleGetCustomDetail}
      />
      <div>
        <Footer />
      </div>
    </section>
  );
}

export default CustomsDetail;
