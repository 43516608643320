import React, { useContext, useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  BlobProvider,
  PDFViewer,
  Image,
  Svg,
  Path,
} from "@react-pdf/renderer";
import { styles } from "./PDFStyled";
import { Icon } from "@iconify/react";
import AlertCross from "../../Assests/Images/AlertCross.svg";
import { LanguageContext } from "../../Context/LanguageContext";
import imageView from "../../Assests/Images/Rectangle19.png";
import { useTranslation } from "react-i18next";
const imageNew =
  "https://api.citrans.website/profile/signature/va5a19rnalrisjkh9.png";

const currentDate = new Date();
const year = currentDate.getFullYear();
const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
const day = ("0" + currentDate.getDate()).slice(-2);
const hours = ("0" + currentDate.getHours()).slice(-2);
const minutes = ("0" + currentDate.getMinutes()).slice(-2);

const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}`;

const Pdf = ({ detail }) => {
  const { t } = useTranslation();
  var total = 0;
  return (
    <>
      {/* <PDFViewer style={{ width: "100%", height: "100vh" }}> */}
      {detail?.map((item, index) => {
        var dateCreated = new Date(item.order_created_at);
        var dayArr = dateCreated.getUTCDate();
        var monthArr = dateCreated.getUTCMonth() + 1; // Month is zero-based, so add 1
        var yearArr = dateCreated.getUTCFullYear();
        var ArrivingDate = `${monthArr}/${dayArr}/${yearArr}`;

        var sigDate = new Date(item.signature_issue_date);
        var sigdayArr = sigDate.getUTCDate();
        var sigmonthArr = sigDate.getUTCMonth() + 1; // Month is zero-based, so add 1
        var sigyearArr = sigDate.getUTCFullYear();
        var formatSignDate = `${sigdayArr}/${sigmonthArr}/${sigyearArr}`;

        return (
          <Document key={index}>
            <Page size="A4" style={styles.page}>
              <View style={styles.topHeader}>
                <Text style={styles.companyName}>{item.company_name}</Text>
                <Text>{item.company_address}</Text>
                <Text>{`Tel : ${item.company_phone_number}`}</Text>
                <Text>{`RNC : ${item.tax_id}`} </Text>
              </View>
              <View style={styles.supplierSection}>
                {/* <Text>{"SUPPLIER INFO"}</Text> */}
                <div style={styles.supplierInfo}>
                  <Text>{item.supplier_name}</Text>
                  <Text>{item.supplier_address}</Text>
                  <Text>{`TEL : ${item.supplier_phone_number}`}</Text>
                  {/* <Text>{item.supplier_address}</Text> */}
                </div>
                <div style={styles.orderInfo}>
                  <Text>{`${t("ORDER_NUMBER")}: ${item.order_number}`}</Text>
                  <Text>{`${t("ISSUE_DATE")} : ${ArrivingDate}`}</Text>
                  <Text>{`${t("ORDER_ID")} : ${item.custom_order_id}`}</Text>
                </div>
              </View>
              <View style={styles.thickDivider}></View>
              <View style={styles.tableHeader}>
                <Text style={{ width: "20%" }}>{t("REF")}</Text>
                <Text style={{ width: "15%" }}>{t("PRODUC_CODE")}</Text>
                <Text style={{ width: "20%" }}>{t("PRODUCT_DES")}</Text>
                <Text style={{ width: "15%" }}>{t("QUANTITY")}</Text>
                <Text style={{ width: "15%" }}>{t("UNIT_PRICE")}</Text>
                {/* <Text>{"Desc."}</Text> */}
                <Text style={{ width: "10%" }}>{t("AMOUNT")}</Text>
              </View>
              <View style={styles.thinDivider}></View>
              {item?.orderItems.map((val, index) => {
                total = total + val.total_quantity * val.per_unit_price;
                return (
                  <View style={styles.tableData} key={index}>
                    <Text
                      break
                      style={{
                        width: "20%",
                      }}
                    >
                      {val.material_reference}
                    </Text>

                    <Text style={{ width: "15%" }}>{val.product_code}</Text>
                    <Text style={{ width: "20%" }}>
                      {val.product_description}
                    </Text>
                    <Text
                      style={{
                        width: "15%",

                        // textAlign: "center",
                      }}
                    >
                      {val.total_quantity} {val.unit_of_inventory}
                    </Text>
                    <Text
                      style={{
                        width: "15%",
                      }}
                    >
                      {val.per_unit_price}
                    </Text>
                    {/* <Text>{val.descount}</Text> */}
                    <Text style={{ width: "10%" }}>
                      {val.total_quantity * val.per_unit_price}
                    </Text>
                  </View>
                );
              })}

              <View style={styles.specialNotes}>
                <Text>{t("SPECIAL_NOTES")}</Text>
                <Text break style={{ width: "50%" }}>
                  {item.special_notes}
                </Text>
              </View>
              <View style={styles.footerSection}>
                <View style={styles.thinDivider}></View>
                <View style={styles.totalFooter}>
                  <Text>{t("SUB_TOTAL")}</Text>
                  <Text>{total}</Text>
                  <Text>{t("DESC")}</Text>
                  <Text>{"0.00"}</Text>
                  <Text>{t("AMOUNT")}</Text>
                  <Text>{total}</Text>
                  <Text>{`${t("TOTAL")} ${item.currency_of_order}`}</Text>
                  <Text>{total}</Text>
                </View>
                <View style={styles.thickDivider}></View>
                <View style={styles.printDate}>
                  <Text>{`${t("PRINTED_ON")} ${formattedDateTime}`}</Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "row",
                    marginTop: "20px",
                    marginBottom: "-50px",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "10px",
                      marginRight: "80px",
                    }}
                  >
                    {/* COMPANY SIGNATURE */}
                    <View
                      style={{
                        height: "60px",
                        width: "60px",
                      }}
                    >
                      <Image
                        src={`${process.env.REACT_APP_MAIN_BASE_URL}/proxy?url=${item.company_signature}`}
                        style={{
                          height: "40px",
                          width: "80px",
                          flex: 1,
                          marginLeft: "-10px",
                        }}
                      />
                    </View>
                    <View>
                      <Text style={{ fontSize: "13px" }}>{formatSignDate}</Text>
                    </View>
                  </View>
                </View>

                <View style={styles.bottomDivider}>
                  <Text style={styles.bottom}></Text>
                  <Text style={styles.bottom}></Text>
                </View>

                <View style={styles.bottomText}>
                  <Text>{t("MADE_BY")}</Text>
                  <Text>{t("AUTHORIZED_BY")}</Text>
                </View>
              </View>
            </Page>
          </Document>
        );
      })}
      {/* </PDFViewer> */}
    </>
  );
};

export const DownloadPDF = () => {
  const { generate, setGenerate, detail } = useContext(LanguageContext);

  const [pdfBlob, setPdfBlob] = useState(null);

  const handleDownload = () => {
    if (pdfBlob) {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(pdfBlob);
      link.download = "Invoice.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    handleDownload();
  }, [generate]);

  return (
    <div>
      <BlobProvider document={<Pdf detail={detail} />}>
        {({ blob }) => setPdfBlob(blob)}
      </BlobProvider>
    </div>
  );
};
