import React from "react";
import styles from "./Footer.module.css";
import { Container, Nav, Row, Col } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import navbarLogo from "../../Assests/Images/footerLogoImg.png";
import footerLocationImg from "../../Assests/Images/footerLocationImg.png";
import footerMailImg from "../../Assests/Images/footerMailImg.png";
import footerPhoneImg from "../../Assests/Images/footerPhoneImg.png";
import footerImg1 from "../../Assests/Images/footerImg1.png";
import footerImg2 from "../../Assests/Images/footerImg2.png";
import footerImg3 from "../../Assests/Images/footerImg3.png";
import footerImg4 from "../../Assests/Images/footerImg4.png";
import footerImg5 from "../../Assests/Images/footerImg5.png";
import footerImg6 from "../../Assests/Images/footerImg6.png";
import { navigate } from "./Navigation";
import "../../Assests/styles/common.css";
import { useTranslation } from "react-i18next";
import { ClientDetail } from "../../clientDetail/ClientDetail";

function Footer() {
  let date = new Date();
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.main}>
        <Container>
          <Row>
            {/* ---------first part of footer---------- */}
            <Col lg={3} md={6} sm={12}>
              <div className={styles.footerFirstPart}>
                <div>
                  <Link to="/">
                    <img
                      className={styles.footerLogo}
                      src={navbarLogo}
                      alt="Logo"
                    ></img>
                  </Link>
                </div>
                <div className={styles.footerText}>
                  {t("DESIRES_TO_OBTAIN_PAIN")}
                </div>
                <div className={styles.footerImageText}>
                  <img
                    alt=""
                    src={footerPhoneImg}
                    className={styles.footerImg}
                  />
                  <div className={styles.footerImgText}>
                    <p>{ClientDetail.ClientContact}</p>
                  </div>
                </div>
                <div className={styles.footerImageText}>
                  <img
                    alt=""
                    src={footerMailImg}
                    className={styles.footerImg}
                  />
                  <div className={styles.footerImgText}>
                    <p>{ClientDetail.ClientEmail}</p>
                  </div>
                </div>
                <div className={styles.footerImageText}>
                  <img
                    alt=""
                    src={footerLocationImg}
                    className={styles.footerImg}
                  />
                  <div className={styles.footerImgText}>
                    <p>Santo Domingo, Dominican Republic.</p>
                  </div>
                </div>
              </div>
            </Col>

            {/* -----second part of footer------ */}
            <Col lg={3} md={6} sm={12}>
              <div className={styles.footerSecondPart}>
                <div className={styles.footerHeading}>
                  {t("OUR_NAVIGATION")}
                </div>
                <div className={styles.navigations}>
                  {navigate.map((item) => (
                    <div className={styles.items} key={item.id}>
                      <NavLink
                        className={styles.footerNavigation}
                        to={item.link}
                      >
                        {t(item.name)}
                      </NavLink>
                    </div>
                  ))}
                </div>
              </div>
            </Col>

            {/* -------------third part of footer-------- */}
            <Col lg={3} md={6} sm={12}>
              <div className={styles.footerThirdPart}>
                <div className={styles.footerHeading}>{t("SUBSCRIBE")}</div>
                <div className="textBtn">
                  <input
                    name="email"
                    type="email"
                    className={styles.subscribeField}
                    placeholder={t("YOUR_EMAIL_ID")}
                  ></input>
                  <button className={styles.subscribeBtn}>
                    {t("SUBSCRIBE")}
                  </button>
                </div>
                <div className={styles.footerTextEnd}>{t("OUR_EXPERTISE")}</div>
              </div>
            </Col>

            {/* ----------fourth part of footer------------ */}
            <Col lg={3} md={6} sm={12}>
              <div className={styles.footerFourthPart}>
                <div className={styles.footerHeading}>{t("OUR_GALLERY")}</div>
                <div className={styles.footerAllImg}>
                  <div className={styles.footerGImg}>
                    <img
                      alt=""
                      src={footerImg1}
                      className={styles.footerGalaryImg}
                    />
                    <img
                      alt=""
                      src={footerImg2}
                      className={styles.footerGalaryImg}
                    />
                    <img
                      alt=""
                      src={footerImg3}
                      className={styles.footerGalaryImg}
                    />
                  </div>
                  <div className={styles.footerGImg}>
                    <img
                      alt=""
                      src={footerImg4}
                      className={styles.footerGalaryImg}
                    />
                    <img
                      alt=""
                      src={footerImg5}
                      className={styles.footerGalaryImg}
                    />
                    <img
                      alt=""
                      src={footerImg6}
                      className={styles.footerGalaryImg}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className={styles.bottomFooter}>
          <p className={styles.footerBottom}>
            {t("COPYRIGHT")} {date.getFullYear()} {t("RIGHTS_RESERVED")}
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
