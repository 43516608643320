import React from "react";
import { useEffect, useContext } from "react";
import TopHeader from "../TopHeader/TopHeader";
import BottomHeader from "../BottomHeader/BottomHeader";
import Footer from "../Footer/Footer";
import styles from "./Home.module.css";
import HomeTopSection from "./HomeTopSection/HomeTopSection";
import AboutCompany from "./AboutCompany/AboutCompany";
import WeOfferPage from "./WeOfferPage/WeOfferPage";
import LogisticService from "./LogisticService/LogisticService";
import ResponsiveTopHeader from "../ResponsiveTopHeader/ResponsiveTopHeader";
import { LanguageContext } from "../../Context/LanguageContext";

function Home() {
  const { setSelectedItem } = useContext(LanguageContext);

  useEffect(() => {
    setSelectedItem("HOME");
  }, []);

  return (
    <>
      {/* <ResponsiveTopHeader /> */}
      <TopHeader />
      <BottomHeader />

      <HomeTopSection />
      <AboutCompany />
      <WeOfferPage />
      <LogisticService />

      <div>
        <Footer />
      </div>
    </>
  );
}

export default Home;
