import TopHeader from "../TopHeader/TopHeader";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import DashboardPurshaseList from "./ListForDashboard/DashboardPurshaseList";
import DashboardCustomList from "./ListForDashboard/DashboardCustomList";
import DashboardTrafficList from "./ListForDashboard/DashboardTrafficList";
import DashboardTransportList from "./ListForDashboard/DashboardTransportList";
import { useTranslation } from "react-i18next";

function Dashboard() {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <TopHeader />
      </div>
      <div className="MainPageWrapper">
        <div>
          <Sidebar />
        </div>
        <div className="PageContent">
          <div className="PageContentHead">
            <h3>{t("DASHBOARD")}</h3>
          </div>
          <div className="ContentBody">
            <div className="tabDesign">
              <Tabs
                defaultActiveKey="purchase"
                id="fill-tab-example"
                className="TabContainer"
                fill
              >
                <Tab eventKey="purchase" title={t("PURCHASE")}>
                  <DashboardPurshaseList />
                </Tab>
                <Tab eventKey="traffic" title={t("TRAFFIC")}>
                  <DashboardTrafficList />
                </Tab>
                <Tab eventKey="custom" title={t("CUSTOMS")}>
                  <DashboardCustomList />
                </Tab>
                <Tab eventKey="transportation" title={t("TRANSPORTATION")}>
                  <DashboardTransportList />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
}

export default Dashboard;
