import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ResetPsw from "../../Assests/Images/resetpassword 1.png";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { validationSchema } from "../../Common/ValidationSchema";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { useState } from "react";
import CitransApi from "../../helpers/Api";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Loader from "../../Common/LoadingSpinner";
import OTPInput from "react-otp-input";
import styles from "../authComponent.module.css";
import Toster from "../../Utils/Toster";

function ResetPassword({ show, setShowReset, resetData, handleClose }) {
  const { register, handleSubmit, formState, reset } = useForm({
    mode: "onChange",
  });
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  const resendCode = () => {
    setIsLoading(true);
    const response = CitransApi.forgotPassword({
      email: resetData.userEmail,
      token: resetData.token,
    });
    response.then((data) => {
      setVerificationCode("");
      setIsLoading(false);
      if (data.code === STATUS_CODES.SUCCESS) {
        Toster("Reset code send successfully!", "success");
      } else if (data.data.code === STATUS_CODES.ACCOUNT_NOT_FOUND) {
        Toster("Account not found, please enter the correct email!", "error");
      } else if (data.data.code === STATUS_CODES.FAILED_TO_SEND_EMAIL) {
        Toster("Failed to send email!", "error");
      }
    });
    response.catch((err) => {
      setIsLoading(false);
    });
  };

  const handelSubmit = (data) => {
    if (data) {
      setIsLoading(true);
      var formateData = {
        code: verificationCode,
        new_password: data.password,
        token: resetData.token,
      };
      CitransApi.resetPassword(formateData).then((res) => {
        setIsLoading(false);
        if (res.code === STATUS_CODES.SUCCESS) {
          toast.success("Password reset successfully!", {
            autoClose: 2000,
          });
          setShowReset(false);
          setVerificationCode("");
          reset();
        } else if (res.data.code === 125) {
          toast.error("Verification code not matched!", {
            autoClose: 2000,
          });
        }
      });
    }
  };

  const closeClick = () => {
    setShowReset(false);
    reset();
    setVerificationCode("");
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <div className="ForgotPsw">
          <Modal.Header>
            <img src={ResetPsw} height={50} width={50} />
            <Modal.Title>{t("UPDATE_YOUR_PASSWORD")}</Modal.Title>
          </Modal.Header>
          <Form noValidate onSubmit={handleSubmit(handelSubmit)}>
            <Modal.Body>
              <p className="resetText">
                {t("NEW_PASSWORD_AND_VERIFICATION_CODE")}
                <b>{resetData.userEmail}</b>
              </p>
              <div className={styles.verifyCode}>{t("VERIFY_CODE")}</div>
              <div className="otpbox">
                <OTPInput
                  className="inputCus"
                  inputStyle="inputStyle"
                  isInputNum={true}
                  value={verificationCode}
                  onChange={(value) => {
                    let numbers = /^[0-9]+$/;
                    if (value.match(numbers) || value == "") {
                      setVerificationCode(value);
                    } else {
                      return false;
                    }
                  }}
                  numInputs={4}
                  isInputSecure={true}
                  placeholder="X"
                  renderInput={(props) => <input placeholder="X" {...props} />}
                />
              </div>
              <div className={styles.resendCode}>
                <span onClick={() => resendCode()}>{t("RESEND")}</span>
              </div>
              <Form.Group className="LoginInput">
                <Form.Label>{t("NEW_PASSWORD")}</Form.Label>
                <Form.Control
                  {...register("password", validationSchema.password)}
                  isInvalid={!!formState.errors.password}
                  required
                  type={isVisible ? "text" : "password"}
                  placeholder={`${t("ENTER")} ${t("NEW_PASSWORD")}`}
                  autoComplete="off"
                />
                <Form.Control.Feedback type="invalid">
                  {t(formState.errors.password?.message)}
                </Form.Control.Feedback>
                {isVisible ? (
                  <FaRegEye onClick={() => setIsVisible(false)} />
                ) : (
                  <FaRegEyeSlash onClick={() => setIsVisible(true)} />
                )}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              {/* <Button variant="secondary" onClick={() => setShowReset(false)}> */}
              <Button variant="secondary" onClick={() => closeClick()}>
                {t("CLOSE")}
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={verificationCode.length !== 4}
              >
                {t("UPDATE")}
              </Button>
            </Modal.Footer>
          </Form>
        </div>
      </Modal>
      {isLoading && <Loader />}
    </>
  );
}

export default ResetPassword;
