import React, { useMemo } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import styles from "./PurchaseOrder.module.css";
import TopHeader from "../TopHeader/TopHeader";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import { useState } from "react";
import { Row, Col, Form, Stack } from "react-bootstrap";
import { currencyTypes } from "../../mockData";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useNavigation } from "react-router-dom";
import { useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import ListGroup from "react-bootstrap/ListGroup";
import { Controller, useForm } from "react-hook-form";
import { validationSchema } from "../../Common/ValidationSchema";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { useDispatch } from "react-redux";
import { STATUS_CODES } from "../../Utils/StatusCode.js";
import CitransApi from "../../helpers/Api.js";
import Toster from "../../Utils/Toster.js";
import SignatureImg from "../../Assests/Images/SignatureImg.svg";
import { MetaDetaKey } from "../../metaDataKey/MetaDetaKey";
import { STATUS_MSG } from "../../Utils/StatusMsg.js";
import Loader from "../../Common/LoadingSpinner.js";
import SureToChangeUnit from "./SureToChangeUnit.js";
import { ISEDIT } from "../../Utils/Constants.js";

function PurchaseOrder() {
  const { register, handleSubmit, setValue, formState, reset, control } =
    useForm({
      mode: "onChange", // Trigger validation on onChange
    });

  const supplierError = formState.errors.Supplier_detail?.message;
  const inventoryError = formState.errors.Inventory_Details?.message;

  const location = useLocation();

  const purchaseOrderList = location.state?.purchaseOrderList;
  const purchaseOrder_isedit = location.state?.purchaseOrder_isedit;

  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.user.userToken);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [randomId, setRandomId] = useState("");
  const [inventoryListarr, setInventoryListarr] = useState([]);
  const [filterInventory, setFilterInventory] = useState([]);
  const [suppliersListarr, setSuppliersListarr] = useState([]);
  const [pageNumberInventory, setPageNumberInventory] = useState(1);
  const [pageNumberSupplier, setPageNumberSupplier] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [supplierName, setSupplierName] = useState("");
  const [supplierId, setSupplierId] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [orderTypes, setOrderTypes] = useState([]);
  const [inventoryUnitarr, setInventoryUnitarr] = useState([]);
  const [inventoryUnit, setInventoryUnit] = useState();
  const [inventoryForChange, setInventoryForChange] = useState();

  const [applicantArea, setApplicantArea] = useState([]);
  const [orderCurrency, setOrderCurrency] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [signature, setSignature] = useState();
  const [showChange, setShowChange] = useState(false);
  const [inventoryCurrency, setInventoryCurrency] = useState();

  const [inventoryAmount, setInventoryAmount] = useState();
  const [inventoryQuantity, setInventoryQuantity] = useState();

  const metaDataApiKey = `${MetaDetaKey.TYPE_OF_ORDER},${MetaDetaKey.APPLICANT_AREA},${MetaDetaKey.CURRENCY_OF_ORDER},${MetaDetaKey.UNIT_OF_INVENTORY}`;

  const handleShowChange = (e) => {
    if (selectedItems.length == 0) {
      setInventoryListarr([]);
      setInventoryUnit(e.target.value);
      setInventoryForChange(e.target.value);
      setValue("Inventory_Unit", e.target.value);
      handleGetInventoryList(e.target.value);
    } else {
      setShowChange(true);
      setInventoryForChange(e.target.value);
    }
  };

  const handleNotChangeUnit = () => {
    setShowChange(false);
    setValue("Inventory_Unit", inventoryUnit);

    setInventoryUnit(inventoryUnit);
  };

  const handleChangeUnit = () => {
    setShowChange(false);
    setValue("Inventory_Unit", inventoryForChange);
    setInventoryListarr([]);
    setSelectedItems([]);
    setPageNumberInventory(1);
    setInventoryUnit(inventoryForChange);

    handleGetInventoryList(inventoryForChange);
  };

  const handleGetSuppliersList = () => {
    let res = CitransApi.getSuppliersData(isAuthenticated, pageNumberSupplier);
    res.then((data) => {
      if (
        data.code == STATUS_CODES.INVALID_TOKEN ||
        data.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        const newSupplierList = data.data.supplierList || [];

        if (Array.isArray(newSupplierList)) {
          setSuppliersListarr([...suppliersListarr, ...newSupplierList]);
        } else {
          console.log("Invalid data format: SupplierList is not an array");
        }
      }
    });
  };

  const handleGetInventoryList = (unit) => {
    let res = CitransApi.getInventoryDataByUnit(
      isAuthenticated,
      pageNumberInventory,
      unit || inventoryUnit
    );
    res.then((data) => {
      if (
        data.code === STATUS_CODES.INVALID_TOKEN ||
        data.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        const newInventoryList = data.data.inventoryList || [];

        if (Array.isArray(newInventoryList)) {
          setInventoryListarr(newInventoryList);
        } else {
          console.log("Invalid data format: inventoryList is not an array");
        }
      }
    });
  };

  const fillRandomId = () => {
    let randomInteger = Math.floor(Math.random() * 1000000); // Generate a number between 0 and 999999

    while (randomInteger < 100000) {
      randomInteger *= 10;
    }

    const formattedId = `ORD${randomInteger.toString().slice(0, 6)}`;
    setRandomId(formattedId);
    return formattedId;
  };

  useEffect(() => {
    fillRandomId();
    handleGetInventoryList();
  }, [pageNumberInventory]);

  useEffect(() => {
    handleGetSuppliersList();
  }, [pageNumberSupplier]);

  const setInventoryData = (data) => {
    setValue("Inventory_Details", data, {
      shouldValidate: true,
    });
  };

  const handleCheckboxChange = (item) => {
    const selectedItem = {
      product_use: item.product_use,
      inventory_id: item.inventory_id,
      meta_unit_of_inventory_desc: item.meta_unit_of_inventory_desc,
      unit_price: item.unit_price,
      product_quantity: 0,
    };

    const isSelected = selectedItems.some(
      (selectedItem) =>
        selectedItem.inventory_id == item.inventory_id &&
        selectedItem.product_use == item.product_use &&
        selectedItem.unit_price == item.unit_price &&
        selectedItem.meta_unit_of_inventory_desc ==
          item.meta_unit_of_inventory_desc
    );

    if (isSelected) {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter(
          (selectedItem) =>
            selectedItem.inventory_id !== item.inventory_id ||
            selectedItem.product_use !== item.product_use ||
            selectedItem.unit_price !== item.unit_price ||
            selectedItem.meta_unit_of_inventory_desc !==
              item.meta_unit_of_inventory_desc
        )
      );
    } else {
      setSelectedItems((prevSelectedItems) => [
        ...prevSelectedItems,
        selectedItem,
      ]);
    }
    const updatedSelectedItems = isSelected
      ? selectedItems.filter(
          (selectedItem) =>
            selectedItem.inventory_id !== item.inventory_id ||
            selectedItem.product_use !== item.product_use ||
            selectedItem.unit_price !== item.unit_price ||
            selectedItem.meta_unit_of_inventory_desc !==
              item.meta_unit_of_inventory_desc
        )
      : [...selectedItems, selectedItem];

    // Now use the variable to call setInventoryData
    setInventoryData(updatedSelectedItems);
  };

  const handleProductQuantityChange = (inventory_id, value) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.map((item) =>
        item.inventory_id === inventory_id
          ? { ...item, product_quantity: value }
          : item
      )
    );
  };

  const handleChangeSupplierName = (name, id) => {
    setSupplierName(name.charAt(0).toUpperCase() + name.slice(1));
    setSupplierId(id);

    setValue("Supplier_detail", name.charAt(0).toUpperCase() + name.slice(1), {
      shouldValidate: true,
    });
  };

  const getMetaData = () => {
    let res = CitransApi.getMetaData(metaDataApiKey);
    res.then((data) => {
      setInventoryUnitarr(data.data.unit_of_inventory);
      setApplicantArea(data.data.applicant_area);
      setOrderCurrency(data.data.currency_of_order);
      setOrderTypes(data.data.type_of_order);
    });
  };

  const handleGetSignature = () => {
    let res = CitransApi.getSignature(isAuthenticated);

    res.then((data) => {
      if (
        data.code == STATUS_CODES.INVALID_TOKEN ||
        data.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        setSignature(data.data.signature_image);
      }
    });
  };

  useEffect(() => {
    getMetaData();
    handleGetSignature();

    fillRandomId();
    setValue("SelectOrderId", fillRandomId());
    const delay = 1000;

    if (
      purchaseOrder_isedit == ISEDIT.ISEDIT_FALSE ||
      purchaseOrder_isedit == ISEDIT.ISEDIT_TRUE
    ) {
      setValue(
        "Supplier_detail",
        purchaseOrderList?.supplier_name.charAt(0).toUpperCase() +
          purchaseOrderList?.supplier_name.slice(1)
      );
      setValue("Inventory_Details", purchaseOrderList?.inventoryItems);
      setSupplierName(
        purchaseOrderList?.supplier_name.charAt(0).toUpperCase() +
          purchaseOrderList?.supplier_name.slice(1)
      );
      setSupplierId(purchaseOrderList?.supplier_id);
      setValue(
        "Registered_user_company",
        purchaseOrderList?.registered_user_company
      );

      // setValue("TotalQuantity", purchaseOrderList?.total_quantity);
      setInventoryQuantity(purchaseOrderList?.total_quantity);
      setInventoryAmount(purchaseOrderList?.order_amount);
      // setValue("OrderAmount", purchaseOrderList?.order_amount);
      setValue("SpecialNote", purchaseOrderList?.special_notes);
      setSelectedItems(purchaseOrderList?.inventoryItems);

      const delayedSetValue = () => {
        setValue("OrderType", purchaseOrderList?.meta_type_of_order);
        setValue("Applicant_area", purchaseOrderList?.meta_applicant_area);
        setValue("OrderCurrency", purchaseOrderList?.meta_currency_of_order);

        handleGetInventoryList(
          purchaseOrderList.inventoryItems[0].meta_unit_of_inventory
        );
        setInventoryUnit(
          purchaseOrderList.inventoryItems[0].meta_unit_of_inventory
        );
        setValue(
          "Inventory_Unit",
          purchaseOrderList.inventoryItems[0].meta_unit_of_inventory
        );

        setInventoryCurrency(purchaseOrderList?.meta_currency_of_order_desc);
      };

      const timeoutId = setTimeout(delayedSetValue, delay);

      return () => clearTimeout(timeoutId);
    }
  }, []);

  useMemo(() => {
    const delay = 1000;

    const delayedSetValue = () => {
      handleGetInventoryList(
        purchaseOrderList?.inventoryItems[0]?.meta_unit_of_inventory
      );
      setInventoryUnit(
        purchaseOrderList?.inventoryItems[0]?.meta_unit_of_inventory
      );
      setValue(
        "Inventory_Unit",
        purchaseOrderList?.inventoryItems[0]?.meta_unit_of_inventory
      );
    };

    const timeoutId = setTimeout(delayedSetValue, delay);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleCreateData = (formData) => {
    setIsLoading(true);

    const inventoryArray = selectedItems.map((item) => ({
      inventory_id: item.inventory_id,
      product_quantity: item.product_quantity,
    }));

    let Postdata = new FormData();
    if (purchaseOrder_isedit == 1) {
      Postdata.append("order_id", purchaseOrderList.order_id);
    }
    Postdata.append("supplier_id", supplierId);
    if (purchaseOrder_isedit == 1) {
      Postdata.append("custom_order_id", purchaseOrderList.custom_order_id);
    } else {
      Postdata.append("custom_order_id", formData.SelectOrderId);
    }

    Postdata.append(
      "registered_user_company",
      formData.Registered_user_company
    );
    Postdata.append("type_of_order", formData.OrderType);
    Postdata.append("applicant_area", formData.Applicant_area);
    Postdata.append("currency_of_order", formData.OrderCurrency);
    Postdata.append("total_quantity", inventoryQuantity);
    Postdata.append("order_amount", inventoryAmount);
    Postdata.append("special_notes", formData.SpecialNote);
    Postdata.append("inventory_details", JSON.stringify(inventoryArray));

    let res = CitransApi.addOrderData(isAuthenticated, Postdata);
    res.then((response) => {
      setIsLoading(false);

      const ResponseCode = STATUS_MSG[response.data?.code];
      if (
        response.code == STATUS_CODES.INVALID_TOKEN ||
        response.data?.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        if (response.code === STATUS_CODES.SUCCESS) {
          if (purchaseOrder_isedit === 1) {
            Toster(t("ORDER_UPDATED"), "success");
          } else {
            Toster(t("ORDER_POST"), "success");
          }

          reset();
          setSelectedItems([]);
          setSupplierName("");
          setSupplierId();
          setSupplierId();

          fillRandomId();
          setValue("SelectOrderId", fillRandomId());
          navigate("/purchase");
        } else {
          if (
            response.code == STATUS_CODES.VALIDATION_ERROR ||
            response.data.code == STATUS_CODES.VALIDATION_ERROR
          ) {
            Toster(response.data.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
      }
    });
  };

  // CALCULATION OF QUANTITY AND AMOUNT

  useEffect(() => {
    const calculatedTotalAmount = selectedItems?.reduce((total, item) => {
      const unitPrice = item.unit_price;
      const productQuantity = item.product_quantity;
      return total + unitPrice * productQuantity;
    }, 0);

    const roundedTotalAmount = calculatedTotalAmount.toFixed(2);

    setInventoryAmount(roundedTotalAmount);

    const calculatedTotalQuantity = selectedItems?.reduce((total, item) => {
      const unitPrice = parseFloat(item.product_quantity);

      return total + unitPrice;
    }, 0);

    const roundedTotalQuantity = calculatedTotalQuantity.toFixed(2);

    setInventoryQuantity(roundedTotalQuantity);
  }, [selectedItems]);

  // console.log("inventoryCurrency ", inventoryCurrency);

  const handleSetInventoryCurrency = (currency) => {
    const selectedValueCode = currency;

    const selectedCurrency = orderCurrency?.find(
      (currency) => currency.value_code === selectedValueCode
    );

    const selectedCurrencyDesc = selectedCurrency?.value_desc;
    setInventoryCurrency(selectedCurrencyDesc);
  };

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <div>
        <TopHeader />
      </div>

      <div className="MainPageWrapper">
        <div>
          <Sidebar />
        </div>
        <div className="PageContent">
          <div className="PageContentHead">
            <h3>
              {purchaseOrder_isedit !== ISEDIT.ISEDIT_TRUE ? (
                <>{t("CREATE")}</>
              ) : (
                <>{t("UPDATE")}</>
              )}{" "}
              {t("PURCHASE_ORDER")}
            </h3>
          </div>
          <div className="ContentBody">
            <Form
              noValidate
              // validated={validated}
              onSubmit={handleSubmit(handleCreateData)}
            >
              <Row>
                {/* SELECT INVENTORY UNIT */}
                <Col lg={6}>
                  <Form.Group className="LoginInput">
                    <Form.Label>{t("UNIT_OF_INVENTORY")}</Form.Label>
                    <Form.Select
                      {...register(
                        "Inventory_Unit",
                        validationSchema.Inventory_Unit
                      )}
                      isInvalid={
                        !!formState.errors.Inventory_Unit &&
                        (!inventoryUnit || inventoryUnit === "")
                      }
                      type="text"
                      onChange={(e) => handleShowChange(e)}
                    >
                      <option value="" defaultValue>
                        {`${t("SELECT")} ${t("UNIT_OF_INVENTORY")}`}
                      </option>
                      {inventoryUnitarr?.map((val) => {
                        return (
                          <option value={val.value_code} key={val.value_code}>
                            {val.value_desc}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.Inventory_Unit?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                {/* SELECT INVENTORY */}
                <Col lg={6}>
                  <Form.Group className={styles.multiSelectInput}>
                    <Form.Label className={styles.selectLabel}>
                      {t("INVENTORY_DETAILS")}
                    </Form.Label>
                    <Controller
                      name="Inventory_Details"
                      control={control}
                      rules={validationSchema.Inventory_Details}
                      render={({ field }) => (
                        <>
                          <Dropdown
                            className={`dropdownPurchaseOrder ${
                              inventoryError ? "inValid" : ""
                            }`}
                          >
                            <Dropdown.Toggle
                              style={{
                                width: "100%",
                                color: "#959495",
                                backgroundColor: "#FFFFFF",
                                height: "50px",
                                border: "0.5px solid #ddd",
                                display: "flex",
                                justifyContent: "space-between",
                                textAlign: "center",
                                alignItems: "center",
                              }}
                              disabled={inventoryUnit === undefined}
                            >
                              {t("SELECT")} {t("INVENTORY_DETAILS")}
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                              style={{
                                width: "100%",
                                maxHeight: "200px",
                                overflowY: "auto",
                              }}
                              onScroll={(e) => {
                                const element = e.target;
                                if (
                                  element.scrollHeight - element.scrollTop ===
                                    element.clientHeight &&
                                  inventoryListarr.length > 0 //check karlena ki data or ha ki nhi
                                ) {
                                  setPageNumberInventory(
                                    (prevPageNumber) => prevPageNumber + 1
                                  );
                                }
                              }}
                            >
                              {inventoryListarr?.length !== 0 ? (
                                <ListGroup variant="flush">
                                  {inventoryListarr?.map((item, index) => {
                                    return (
                                      <React.Fragment>
                                        {item.status === 1 ? (
                                          <ListGroup.Item
                                            className={styles.listView}
                                            key={index}
                                            onClick={() =>
                                              handleCheckboxChange(item)
                                            }
                                          >
                                            {item.material_reference}
                                            <input
                                              type="checkbox"
                                              checked={selectedItems.some(
                                                (selectedItem) =>
                                                  selectedItem.inventory_id ===
                                                    item.inventory_id &&
                                                  selectedItem.product_use ===
                                                    item.product_use
                                              )}
                                            />
                                          </ListGroup.Item>
                                        ) : (
                                          ""
                                        )}
                                      </React.Fragment>
                                    );
                                  })}
                                </ListGroup>
                              ) : (
                                t("NO_RESULT_FOUND")
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </>
                      )}
                    />
                    <p className="validateError">
                      {t(formState.errors.Inventory_Details?.message)}
                    </p>
                  </Form.Group>
                </Col>

                {/* SELECT SUPPLIER */}
                <Col lg={6}>
                  <Form.Group className={styles.multiSelectInput}>
                    <Form.Label className={styles.selectLabel}>
                      {t("SUPPLIER_DETAIL")}
                    </Form.Label>
                    <Controller
                      name="Supplier_detail"
                      control={control}
                      rules={validationSchema.Supplier_detail}
                      render={({ field }) => (
                        <>
                          <Dropdown
                            className={`dropdownPurchaseOrder ${
                              supplierError ? "inValid" : ""
                            }`}
                            onSelect={() => setDropdownOpen(false)}
                            show={dropdownOpen}
                            {...field}
                          >
                            <Dropdown.Toggle
                              style={{
                                width: "100%",
                                color: "#959495",
                                backgroundColor: "#FFFFFF",
                                height: "50px",
                                border: "0.5px solid #ddd",
                                display: "flex",
                                justifyContent: "space-between",
                                textAlign: "center",
                                alignItems: "center",
                              }}
                              onClick={() => setDropdownOpen(!dropdownOpen)}
                            >
                              {supplierName ? (
                                <>{supplierName}</>
                              ) : (
                                <>
                                  {t("SELECT")} {t("SUPPLIER_DETAIL")}
                                </>
                              )}
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                              style={{
                                width: "100%",
                                maxHeight: "200px",
                                overflowY: "auto",
                              }}
                              onScroll={(e) => {
                                const element = e.target;
                                if (
                                  element.scrollHeight - element.scrollTop ===
                                    element.clientHeight &&
                                  suppliersListarr.length > 0 //check karlena ki data or ha ki nhi
                                ) {
                                  setPageNumberSupplier(
                                    (prevPageNumber) => prevPageNumber + 1
                                  );
                                }
                              }}
                            >
                              <ListGroup variant="flush">
                                {suppliersListarr?.map((person, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <ListGroup.Item
                                        className={styles.listView}
                                        onClick={() => {
                                          handleChangeSupplierName(
                                            person.name,
                                            person.supplier_id
                                          );
                                          setDropdownOpen(false);
                                        }}
                                      >
                                        {person.name.charAt(0).toUpperCase() +
                                          person.name.slice(1)}
                                      </ListGroup.Item>
                                    </React.Fragment>
                                  );
                                })}
                              </ListGroup>
                            </Dropdown.Menu>
                          </Dropdown>
                        </>
                      )}
                    />
                    <p className="validateError">
                      {t(formState.errors.Supplier_detail?.message)}
                    </p>
                  </Form.Group>
                </Col>
              </Row>

              {/* INVENTORY LIST */}
              {selectedItems?.map((item, index) => {
                const fieldName = `productQuantity_${index}`;
                return (
                  <React.Fragment key={index}>
                    <Row style={{ marginTop: "2%" }} key={index + 1}>
                      <Col lg={6}>
                        <Stack direction="horizontal" gap={3}>
                          <div className="p-2">
                            <p className={styles.ProctLabel}>Product Name</p>
                            <p className={styles.ProctUnit}>
                              {item.product_use}
                            </p>
                          </div>
                          <div className="p-2  ms-auto">
                            <p className={styles.ProctLabel}>
                              Product quantity
                            </p>
                            <p className={styles.ProctUnit}>
                              <input
                                style={{
                                  width: "50px",
                                  minWidth: "50px", // Ensure a minimum width of 80px
                                  maxWidth: "130px",
                                  borderRadius: "6px",
                                  border: "1px solid #EEE",
                                  background: "#FFF",
                                  boxShadow:
                                    "0px 2px 9px 0px rgba(130, 130, 130, 0.17)",
                                  boxSizing: "border-box",
                                }}
                                type="number"
                                step="0.01"
                                value={item.product_quantity}
                                onChange={(e) =>
                                  handleProductQuantityChange(
                                    item.inventory_id,
                                    e.target.value
                                  )
                                }
                                onInput={(e) => {
                                  const value = e.target.value;
                                  const parts = value.split(".");

                                  if (parts.length > 1 && parts[1].length > 2) {
                                    // Limit to two digits after the decimal point
                                    e.target.value = `${
                                      parts[0]
                                    }.${parts[1].slice(0, 2)}`;
                                  }

                                  // Set the width based on the content length
                                  const minWidth = 50;
                                  const contentWidth = Math.max(
                                    e.target.scrollWidth,
                                    minWidth
                                  );
                                  e.target.style.width = `${contentWidth}px`;
                                }}
                              />{" "}
                              {item.meta_unit_of_inventory_desc}
                            </p>
                          </div>
                        </Stack>
                      </Col>

                      <Col lg={1}></Col>

                      <Col lg={5}>
                        <Stack direction="horizontal" gap={3}>
                          <div className="p-2">
                            <p className={styles.ProctLabel}>Per unit price</p>
                            <p className={styles.ProctUnit}>
                              {inventoryCurrency} {item.unit_price}
                            </p>
                          </div>
                          <div className="p-2  ms-auto">
                            <div
                              className={styles.iconsClass}
                              onClick={() => handleCheckboxChange(item)}
                            >
                              <p className={styles.iconsClassDelete}>
                                <Icon
                                  icon="ic:baseline-delete"
                                  color="red"
                                  width="24"
                                  height="24"
                                />
                              </p>
                            </div>
                          </div>
                        </Stack>
                      </Col>
                    </Row>

                    {selectedItems.length - 1 !== index ? (
                      <div className={styles.devider}></div>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                );
              })}

              {/* ORDER DETAIL */}
              <h5 style={{ marginTop: "4%" }}>{t("ORDER_DETAILS")}</h5>
              <Row>
                <Col lg={6}>
                  <Form.Group className={styles.LoginInput}>
                    <Form.Label>{t("ORDER_ID")}</Form.Label>
                    {purchaseOrder_isedit === 1 ? (
                      <Form.Control
                        type="text"
                        value={purchaseOrderList?.custom_order_id}
                        style={{ height: "50px" }}
                      />
                    ) : (
                      <>
                        <Form.Control
                          {...register(
                            "SelectOrderId",
                            validationSchema.SelectOrderId
                          )}
                          required
                          isInvalid={!!formState.errors.SelectOrderId}
                          type="text"
                          name="ORDER_ID"
                          placeholder={`${t("ENTER")} ${t("ORDER_ID")}`}
                          value={randomId}
                          onChange={(e) => {
                            setRandomId(e.target.value);
                            setValue("SelectOrderId", e.target.value, {
                              shouldValidate: true,
                            });
                          }}
                          style={{ height: "50px" }}
                          autoComplete="off"
                        />
                        <Icon
                          icon="icon-park-outline:reload"
                          color="rgba(130, 130, 130, 0.8509803921568627)"
                          width="22"
                          height="22"
                          onClick={() => fillRandomId()}
                        />
                      </>
                    )}

                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.SelectOrderId?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className={styles.LoginInput}>
                    <Form.Label>{t("REGISTERED_USER_COMPANY")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="registered"
                      {...register(
                        "Registered_user_company",
                        validationSchema.Registered_user_company
                      )}
                      required
                      isInvalid={!!formState.errors.Registered_user_company}
                      placeholder={`${t("ENTER")} ${t(
                        "REGISTERED_USER_COMPANY"
                      )}`}
                      onChange={(e) => {
                        setValue("Registered_user_company", e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      autoComplete="off"
                      style={{ height: "50px" }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.Registered_user_company?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="LoginInput">
                    <Form.Label>{t("TYPE_OF_ORDER")}</Form.Label>
                    <Form.Select
                      {...register("OrderType", validationSchema.OrderType)}
                      isInvalid={!!formState.errors.OrderType}
                      type="text"
                    >
                      <option value={""} defaultValue>{`${t("SELECT")} ${t(
                        "TYPE_OF_ORDER"
                      )}`}</option>
                      {orderTypes?.map((val, index) => (
                        <option value={val.value_code} key={index}>
                          {val.value_desc}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.OrderType?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="LoginInput">
                    <Form.Label>{t("APPLICANT_AREA")}</Form.Label>
                    <Form.Select
                      {...register(
                        "Applicant_area",
                        validationSchema.Applicant_area
                      )}
                      // required
                      isInvalid={!!formState.errors.Applicant_area}
                      onChange={(e) => {
                        setValue("Applicant_area", e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                    >
                      <option value="" defaultValue>
                        {`${t("SELECT")} ${t("APPLICANT_AREA")}`}
                      </option>
                      {applicantArea.map((val, index) => {
                        return (
                          <option key={index} value={val.value_code}>
                            {val.value_desc}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.Applicant_area?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className={styles.LoginInput}>
                    <Form.Label>{t("TOTAL_QUANTITY")}</Form.Label>
                    <Form.Control
                      disabled={true}
                      type="number"
                      min={1}
                      step="0.01"
                      value={inventoryQuantity}
                      name="TotalQuantity"
                      placeholder={`${t("ENTER")} ${t("QUANTITY")}`}
                      style={{ height: "50px" }}
                    />
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="LoginInput">
                    <Form.Label>{t("CURRENCY_OF_THE_ORDER")}</Form.Label>
                    <Form.Select
                      {...register(
                        "OrderCurrency",
                        validationSchema.OrderCurrency
                      )}
                      // required
                      isInvalid={!!formState.errors.OrderCurrency}
                      onChange={(e) => {
                        setValue("OrderCurrency", e.target.value, {
                          shouldValidate: true,
                        });
                        handleSetInventoryCurrency(e.target.value);
                      }}
                    >
                      <option value="" defaultValue>
                        {`${t("SELECT")} ${t("CURRENCY_OF_THE_ORDER")}`}
                      </option>
                      {orderCurrency.map((val, index) => {
                        return (
                          <option key={index} value={val.value_code}>
                            {val.value_desc}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.OrderCurrency?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Row>
                    <Col lg={12}>
                      <Form.Group className={styles.LoginInput}>
                        <Form.Label>{t("ORDER_AMOUNT")}</Form.Label>
                        <Form.Control
                          type="number"
                          name="OrderAmount"
                          disabled={true}
                          value={inventoryAmount}
                          step="0.01"
                          placeholder={`${t("ENTER")} ${t("ORDER_AMOUNT")}`}
                          style={{ height: "50px" }}
                        />
                      </Form.Group>
                    </Col>

                    {signature ? (
                      <Col lg={12}>
                        <Form.Group className="LoginInputimg">
                          <Form.Label>{t("Signature")}</Form.Label>
                          <div className={styles.showSignature}>
                            <img
                              src={signature}
                              alt="signature"
                              className={styles.signatureImg}
                              onClick={() => window.open(signature)}
                            ></img>
                          </div>
                        </Form.Group>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </Col>

                <Col lg={6}>
                  <Form.Group className="LoginInput">
                    <Form.Label>{t("SPECIAL_NOTES")}</Form.Label>
                    <Form.Control
                      {...register("SpecialNote", validationSchema.SpecialNote)}
                      required
                      isInvalid={!!formState.errors.SpecialNote}
                      as="textarea"
                      rows={4}
                      style={{ height: "200px" }}
                      onChange={(e) => {
                        setValue("SpecialNote", e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formState.errors.SpecialNote?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Row>
                  <Col lg={6} md={6} sm={6} className="my-2">
                    <button
                      className={styles.BackToLgnBtn}
                      type="button"
                      onClick={() => navigate(-1)}
                    >
                      {t("BACK")}
                    </button>
                  </Col>
                  <Col lg={6} md={6} sm={6} className="my-2">
                    <button className={styles.CreateBtn} type="submit">
                      {purchaseOrder_isedit !== ISEDIT.ISEDIT_TRUE ? (
                        <>{t("CREATE_ORDER")}</>
                      ) : (
                        <>{t("UPDATE_ORDER")}</>
                      )}
                    </button>
                  </Col>
                </Row>
              </Row>
            </Form>
          </div>
        </div>
      </div>
      <SureToChangeUnit
        show={showChange}
        setInventoryUnit={setInventoryUnit}
        handleNotChangeUnit={handleNotChangeUnit}
        handleChangeUnit={handleChangeUnit}
      />
      <div>
        <Footer />
      </div>
    </>
  );
}

export default PurchaseOrder;
