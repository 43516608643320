import React, { useCallback, useEffect, useState } from "react";
import TopHeader from "../TopHeader/TopHeader";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import styles from "./Transportation.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Col, Row } from "react-bootstrap";
import CitransApi from "../../helpers/Api";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Toster from "../../Utils/Toster";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import Loader from "../../Common/LoadingSpinner";
import { PAGINATE, SHIPMENT_STATUS } from "../../Utils/Constants";
import ReactPaginate from "react-paginate";
import { debounce } from "lodash";
import { STATUS_MSG } from "../../Utils/StatusMsg";

export default function Transportation() {
  const { t } = useTranslation();
  const arr = [1, 2, 3, 4];
  const navigate = useNavigate();

  const isAuthenticated = useSelector((state) => state.user.userToken);

  const dispatch = useDispatch();

  const itemsPerPage = PAGINATE.PER_PAGE;

  const [isloading, setIsloading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [totalRecords, setTotalRecords] = useState();
  const [transportList, setTransportList] = useState();

  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNumber(newPageNumber);
  };

  const handleGetTransportList = (pageNumber) => {
    setIsloading(true);
    let res = CitransApi.getTransportList(isAuthenticated, pageNumber);

    res.then((data) => {
      if (
        data?.code == STATUS_CODES.INVALID_TOKEN ||
        data?.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        setTransportList(data?.data.transportationList);
        setTotalRecords(data?.data.count);
        setIsloading(false);
      }
    });
  };

  const searchTransportList = (event) => {
    let res = CitransApi.searchTransportList(isAuthenticated, event);

    res.then((data) => {
      if (
        data?.code == STATUS_CODES.INVALID_TOKEN ||
        data?.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        setTransportList(data.data.transportationList);

        setIsloading(false);
      }
    });
  };

  useEffect(() => {
    handleGetTransportList(pageNumber);
  }, [pageNumber]);

  const delayedSearch = useCallback(
    debounce((event) => {
      searchTransportList(event);
    }, 300),
    []
  );

  const handleChange = (event) => {
    setSearchKey(event);
    delayedSearch(event);
  };

  return (
    <section>
      {isloading ? <Loader /> : null}
      <div>
        <TopHeader />
      </div>
      <div className="MainPageWrapper">
        <div>
          <Sidebar />
        </div>
        <div className="PageContent">
          <div className="PageContentHead">
            <h3>{t("TRANSPORTATION_LIST")}</h3>
          </div>
          <div className="ContentBody">
            <Row>
              <Col lg={6}>
                <Form>
                  <Form.Group className="LoginInput">
                    <Form.Label>{t("SEARCH")}</Form.Label>
                    <Form.Control
                      // className={styles.SearchField}
                      type="search"
                      placeholder={t("SEARCH_BY_ORDER_ID_AND_NUMBER")}
                      onChange={(e) => {
                        handleChange(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>

            {Array.isArray(transportList) &&
              transportList?.map((item, index) => {
                var dateObject = new Date(item.arriving_date);
                var day = dateObject.getUTCDate();
                var month = dateObject.getUTCMonth() + 1;
                var year = dateObject.getUTCFullYear();
                var formatedDate = `${day}/${month}/${year}`;
                return (
                  <div key={index}>
                    <div
                      onClick={() =>
                        navigate("/transportation-details", {
                          state: { shipment_id: item.shipment_id },
                        })
                      }
                      className={styles.transportCard}
                    >
                      <div className={styles.transportCardHead}>
                        <h3>
                          {t("SHIPMENT")} {index + 1}
                        </h3>
                        <p>
                          {t("ORDER_ID")} : <span>{item.custom_order_id}</span>
                        </p>
                        <p>
                          {t("APPLICANT_AREA")} :{" "}
                          <span>{item.applicant_area}</span>
                        </p>
                        <p>
                          {t("QUANTITY_UNIT")} :{" "}
                          <span>
                            {item.total_quantity} {item.unit_of_inventory}
                          </span>
                        </p>
                        <p>
                          {t("ORDER_NUMBER")} : <span>{item.order_number}</span>
                        </p>
                      </div>
                      <div className={`${styles.transportCardHeadRight}`}>
                        <p>
                          {t("TYPE_OF_ORDER")} :{" "}
                          <span>{item.type_of_order}</span>
                        </p>
                        <p>
                          {t("ORDER_STATUS")} :{" "}
                          {/* {item == 1 || item == 4 ? (
                          <span className={styles.orderStatusPending}>
                            Pending
                          </span>
                        ) : (
                          <span className={styles.orderStatusComplete}>
                            Complete
                          </span>
                        )} */}
                          {item.shipment_status === SHIPMENT_STATUS.PENDING ||
                          item.shipment_status ===
                            SHIPMENT_STATUS.DOCUMENT_REJECTED ||
                          item.shipment_status ===
                            SHIPMENT_STATUS.PAYMENT_PENDING ||
                          item.shipment_status === null ? (
                            <span className={styles.orderStatusPending}>
                              {t(STATUS_MSG[item.shipment_status])}
                            </span>
                          ) : (
                            <span className={styles.orderStatusComplete}>
                              {t(STATUS_MSG[item.shipment_status])}
                            </span>
                          )}
                        </p>
                        <p>
                          {t("ORDER_AMOUNT")} :{" "}
                          <span>
                            {item.order_amount} {item.currency_of_order}
                          </span>
                        </p>
                        <p>
                          {t("ARRIVING_DATE")} : <span>{formatedDate}</span>
                        </p>
                      </div>
                    </div>
                    {index !== arr.length - 1 ? (
                      <div className={styles.divider}></div>
                    ) : null}
                  </div>
                );
              })}

            {totalRecords == 0 ? <div>{t("RESULT_NOT_FOUND")}</div> : null}
            {searchKey === "" ? (
              <div className={styles.tableDetail}>
                <div className={styles.totalRecord}>
                  {totalRecords !== 0 ? (
                    <p>
                      {t("SHOWING")} {1 + pageNumber * 10 - 10}-
                      {(pageNumber - 1) * 10 + transportList?.length} of{" "}
                      {totalRecords} {t("RECORDS")}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {totalRecords > itemsPerPage && (
                    <ReactPaginate
                      previousLabel="Previous"
                      nextLabel="Next"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      pageCount={Math.ceil(totalRecords / itemsPerPage)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </section>
  );
}
