// AddShipmentSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newshipments: [],
};

const AddShipmentSlice = createSlice({
  name: "addShipment",
  initialState,
  reducers: {
    addShipment: (state, action) => {
      state.newshipments.push(action.payload);
    },
    clearShipments: (state) => {
      state.newshipments = [];
    },
  },
});

export const { addShipment, clearShipments } = AddShipmentSlice.actions;

export default AddShipmentSlice.reducer;
