export const STATUS_MSG = {
  100: "HEADER_IS_MISSING",
  101: "DEVICE_TYPE_NOT_ALLOWED",
  102: "INVALID_API_KEY",
  103: "TOKEN_REQUIRE_AUTHENTICATION",
  104: "INVALID_TOKEN",
  105: "VALIDATION_ERROR_CODE",
  200: "Success",
  500: "Internal server error",
  111: "INVALID_LOGIN_CREDENTIAL",
  112: "ACCOUNT_NOT_FOUND",
  113: "ACCOUNT_INACTIVE",
  114: "ACCOUNT_DELETED",
  115: "OLD_PASSWORD_DOES_NOT_MATCH",
  117: "EMAIL_ALREADY_IN_USE",
  123: "FAILED_TO_SEND_EMAIL",
  125: "OTP_NOT_MATCHED",
  127: "TOKEN_EXPIRES",
  401: "INVALID_API_KEY",
  400: "VALIDATION_ERROR",
  143: "STAFF_ALREADY_LOGED_IN",
  129: "INVENTORY_FILE_EXTENSION",
  130: "INVALID_FILE_SIZE",
  141: "FAILED_TO_SAVE",

  143: "COULD_NOT_SEND_INVITATION",
  144: "INVALID_FILE_SIZE",
  145: "INVENTORY_FILE_EXTENSION",
  146: "INVALID_USER",
  147: "SIGNATURE_IS_REQUIRED",
  148: "INVENTORY_INVALID_OBJECT",
  149: "INVENTORY_INVALID_OBJECT",
  150: "INVALID_MIN_INVEN_QUANTITY",
  151: "ORDER_COULD_NOT_CREATED",
  152: "INVALID_ORDER_ID",
  161: "CUSTOM_ORDER_ID_ALREADY_EXIT",
  171: "USER_NOT_VALID",
  180: "INVALID_SHIPMENT_ID",
  182: "INVALID_FILE_SIZE",
  183: "INVALID_FILE_EXTENSION",
  184: "DOCUMENT_REQUIRED",
  191: "SHIPMENT_DOC_STATUS_ALREADY_EXIST_IN_DB",
  199: "TRANSPORTATION_DETAILS_ALREADY_EXISTS",
  1: "PENDING",
  2: "DOCUMENT_UPLOADED",
  3: "DOCUMENT_REJECTED",
  4: "PAYMENT_PENDING",
  5: "TRANSPORTATION",
  6: "DELIVERED",
  null: "PENDING",
  178: "CONTAINER_ALREADY_ADDED",
  198: "PRODUCT_EXIST",
  177: "SHIPMENT_ALREADY_ASSIGNED",
  178: "CONTAINER_ALREADY_ASSIGNED",
  179: "SHIPMENT_COULD_NOT_DELETED",

  106: "ACCESS_DENIED",
  153: "ORDER_ALREADY_SUBMITED",
  158: "INVALID_EMAIL",
  160: "ORDER_NOT_SUBMITTED",
  172: "INVALID_ACC_EMAIL_ID",
  173: "STAFF_NOT_PERFORM_ACTION",
  176: "INVALID_MIN_QUANTITY",
  181: "INVALID_DOC",
  192: "INVALID_BREAK_DOWN",
  195: "INVALID_BREAK_DOWN_TYPE",
  196: "PAYMENT_ALREADY_DONE",
};
