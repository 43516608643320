import React, { useState, useEffect, useCallback, useContext } from "react";
import styles from "./PurchaseOrderList.module.css";
import TopHeader from "../TopHeader/TopHeader";
import Sidebar from "../Sidebar/Sidebar";
import { Icon } from "@iconify/react";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import { purchaseOrderLists } from "../../mockData";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import { useTranslation } from "react-i18next";
import ConfirmModal from "../../Common/ConfirmModal";
import Toster from "../../Utils/Toster";
import { STATUS_CODES } from "../../Utils/StatusCode";
import CitransApi from "../../helpers/Api.js";
import Loader from "../../Common/LoadingSpinner.js";
import ReactPaginate from "react-paginate";
import { useForm } from "react-hook-form";
import { ISEDIT, PAGINATE } from "../../Utils/Constants";
import { debounce } from "lodash";
import { LanguageContext } from "../../Context/LanguageContext";
import { STATUS_MSG } from "../../Utils/StatusMsg.js";
import DeleteOrder from "./DeleteOrder.js";
import { setInitialShipments } from "../../Redux/Slices/shipmentSlice.js";
// import { DownloadPDF } from "./PDFDownload.js";

function PurchaseOrderList() {
  const { setValue } = useForm({
    mode: "onChange", // Trigger validation on onChange
  });
  const isAuthenticated = useSelector((state) => state.user.userToken);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const itemsPerPage = PAGINATE.PER_PAGE;
  let date = new Date().getTime();

  const [isDeleting, setIsDeleting] = useState({
    status: false,
    deleteId: "",
  });

  const [orderList, setOrderList] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [isloading, setIsloading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [orderId, setOrderId] = useState();

  const { generate, setGenerate, setDetail } = useContext(LanguageContext);

  const handleCloseDelete = () => {
    setShowDelete(false);
  };

  const handleDeleteItem = () => {
    setShowDelete(false);
  };

  const handleShowDelete = () => setShowDelete(true);

  const handelClickIcon = (id, key) => {
    let res = CitransApi.getSingleOrderDetails(isAuthenticated, id);

    res.then((data) => {
      if (
        data.code == STATUS_CODES.INVALID_TOKEN ||
        data.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        if (key == 0) {
          navigate("/purchase-order", {
            state: {
              purchaseOrderList: data.data,
              purchaseOrder_isedit: ISEDIT.ISEDIT_FALSE,
            },
          });
        } else {
          navigate("/purchase-order", {
            state: {
              purchaseOrderList: data.data,
              purchaseOrder_isedit: ISEDIT.ISEDIT_TRUE,
            },
          });
        }
      }
    });
  };

  const confirmClose = () => setIsDeleting(false);

  const handleCloseConfirm = () => {
    setIsDeleting({ ...isDeleting, status: false });
  };

  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNumber(newPageNumber);
  };

  const handleGetOrderDetail = (pageNumber) => {
    setIsloading(true);
    let res = CitransApi.getOrderDetails(isAuthenticated, pageNumber);

    res.then((data) => {
      if (
        data.code == STATUS_CODES.INVALID_TOKEN ||
        data.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        setOrderList(data.data.orderList);
        setTotalRecords(data.data.count);
        setIsloading(false);
      }
    });
  };

  const searchOrderDetail = (event) => {
    let res = CitransApi.searchOrderDetails(isAuthenticated, event);

    res.then((data) => {
      if (
        data.code == STATUS_CODES.INVALID_TOKEN ||
        data.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        setOrderList(data.data.orderList);
      }
    });
  };

  useEffect(() => {
    handleGetOrderDetail(pageNumber);
  }, [pageNumber]);

  const delayedSearch = useCallback(
    debounce((event) => {
      searchOrderDetail(event);
    }, 300),
    []
  );

  const handleChange = (event) => {
    setSearchKey(event);
    delayedSearch(event);
  };

  const goForOrderDetails = (id) => {
    dispatch(setInitialShipments([{ id: date, isSubmitted: false, data: {} }]));
    navigate("/purchase-order-details", {
      state: { orderid: id },
    });
  };

  const handleGetBill = (order_id) => {
    // console.log("handleGetBill", order_id);
    setIsloading(true);
    let res = CitransApi.getOrderBill(isAuthenticated, order_id);

    res.then((data) => {
      if (
        data?.code == STATUS_CODES.INVALID_TOKEN ||
        data?.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        setDetail(data?.data.orderReport);
        setTimeout(() => {
          setIsloading(false);
          setGenerate(!generate);
        }, 1000);
      }
    });
  };

  // console.log("purchaseOrderLists ", purchaseOrderLists);

  return (
    <section>
      {isloading ? <Loader /> : null}

      <div>
        <TopHeader />
      </div>
      <div className="MainPageWrapper">
        <div>
          <Sidebar />
        </div>
        <div className="PageContent">
          <div className="PageContentHead">
            <h3>{t("PURCHASE_ORDER_LIST")}</h3>
          </div>
          <div className="ContentBody">
            <Row>
              <Col lg={6}>
                <Form.Group className="LoginInput">
                  <Form.Label>{t("SEARCH")}</Form.Label>
                  <Form.Control
                    type="search"
                    name="searchBox"
                    placeholder={t("SEARCH_BY_ORDER_ID")}
                    onChange={(e) => {
                      handleChange(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            {Array.isArray(orderList) &&
              orderList?.map((item, index) => {
                var order_no = item.order_number;
                var uppercaseOrder_no = order_no.toUpperCase();
                return (
                  <div
                    key={index}
                    className={styles.suppliersCard}
                    onClick={() => goForOrderDetails(item.order_id)}
                  >
                    <div className={styles.suppliersCardHead}>
                      <h3>{item.registered_user_company}</h3>
                      <p>
                        {t("ORDER_ID")} : <span>#{item.custom_order_id}</span>
                      </p>
                      <p>
                        {t("APPLICANT_AREA")} :{" "}
                        <span>{item.meta_applicant_area_desc}</span>
                      </p>
                      {/* <p>
                    {t("QUANTITY_UNIT")} : <span>{item.quantityUnit}</span>
                  </p> */}
                      <p>
                        {t("ORDER_NUMBER")} : <span>{uppercaseOrder_no}</span>
                      </p>
                    </div>
                    <div className={`${styles.suppliersCardHeadRight}`}>
                      <p>
                        {t("TYPE_OF_ORDER")} :{" "}
                        <span>{item.meta_type_of_order_desc}</span>
                      </p>

                      <p>
                        {t("ORDER_AMOUNT")} :{" "}
                        <span>
                          {item.meta_currency_of_order_desc} {item.order_amount}
                        </span>
                      </p>
                      <div className={styles.iconsClass}>
                        {/* <p className={styles.iconsClassReload}>
                          <Icon
                            icon="material-symbols:download"
                            color="#3270a0"
                            width="24"
                            height="24"
                          />
                        </p> */}
                        <p
                          className={styles.iconsClassReload}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleGetBill(item.order_id);
                          }}
                        >
                          <Icon
                            icon="material-symbols:download"
                            color="#3270a0"
                            width="24"
                            height="24"
                          />
                        </p>
                        <p
                          className={styles.iconsClassReload}
                          onClick={(e) => {
                            e.stopPropagation();
                            handelClickIcon(item.order_id, ISEDIT.ISEDIT_FALSE);
                          }}
                        >
                          <Icon
                            icon="ooui:reload"
                            color="#3270a0"
                            width="24"
                            height="24"
                          />
                        </p>
                        {orderList?.status !== 1 ? (
                          <>
                            <p
                              className={styles.iconsClassEdit}
                              onClick={(e) => {
                                e.stopPropagation();
                                handelClickIcon(
                                  item.order_id,
                                  ISEDIT.ISEDIT_TRUE
                                );
                              }}
                            >
                              <Icon
                                icon="ic:round-edit"
                                color="#626262"
                                width="24"
                                height="24"
                              />
                            </p>
                            <p
                              className={styles.iconsClassDelete}
                              onClick={(e) => {
                                e.stopPropagation();

                                handleShowDelete();
                                setOrderId(item.order_id);
                              }}
                            >
                              <Icon
                                icon="ic:baseline-delete"
                                color="red"
                                width="24"
                                height="24"
                              />
                            </p>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            {totalRecords == 0 ? <div>{t("RESULT_NOT_FOUND")}</div> : null}
            {searchKey === "" ? (
              <div className={styles.tableDetail}>
                <div className={styles.totalRecord}>
                  {totalRecords !== 0 ? (
                    <p>
                      {t("SHOWING")} {1 + pageNumber * 10 - 10}-
                      {(pageNumber - 1) * 10 + orderList.length} of{" "}
                      {totalRecords} {t("RECORDS")}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {totalRecords > itemsPerPage && (
                    <ReactPaginate
                      previousLabel="Previous"
                      nextLabel="Next"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      pageCount={Math.ceil(totalRecords / itemsPerPage)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
      <ConfirmModal
        title={`${t("SURE_TO_DELETE")}!`}
        show={isDeleting.status}
        handleCloseConfirm={handleCloseConfirm}
        confirmClose={confirmClose}
      />
      <DeleteOrder
        show={showDelete}
        handleClose={handleCloseDelete}
        handleDeleteItem={handleDeleteItem}
        handleGetOrderDetail={handleGetOrderDetail}
        setSearchKey={setSearchKey}
        pageNumber={pageNumber}
        orderId={orderId}
      />
    </section>
  );
}

export default PurchaseOrderList;
