import React, { useCallback, useEffect, useState } from "react";
import TopHeader from "../TopHeader/TopHeader";
import Sidebar from "../Sidebar/Sidebar";
import { Icon } from "@iconify/react";
import styles from "./Suppliers.module.css";
import SupplierModal from "./SupplierModal";
import Footer from "../Footer/Footer";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import CitransApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../Utils/Toster";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import Loader from "../../Common/LoadingSpinner";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { PAGINATE, STATUS } from "../../Utils/Constants";
import ReactPaginate from "react-paginate";
import _, { debounce } from "lodash";

function Suppliers() {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.user.userToken);
  const [suppliersList, setSuppliersList] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [filteredLists, setFilteredLists] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [show, setShow] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState();
  const itemsPerPage = PAGINATE.PER_PAGE;

  const fetchSuppliersList = (page_no) => {
    setIsloading(true);
    let res = CitransApi.getSuppliersData(userToken, page_no);
    res.then((data) => {
      if (
        data.code == STATUS_CODES.INVALID_TOKEN ||
        data.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsloading(false);
      } else {
        setSuppliersList(data.data.supplierList);
        setFilteredLists(data.data.supplierList);
        setTotalRecords(data.data.count);
        setIsloading(false);
      }
    });
  };

  useEffect(() => {
    fetchSuppliersList(pageNumber);
  }, [pageNumber]);

  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNumber(newPageNumber);
  };

  const handleClose = () => {
    setShow(false);
    setIsEdit(false);
    setIsView(false);
    setSelectedItem(null);
  };

  const handleShow = () => setShow(true);

  const searchSuppliersData = (event) => {
    let res = CitransApi.searchSuppliersData(userToken, event);

    res.then((data) => {
      if (
        data.code == STATUS_CODES.INVALID_TOKEN ||
        data.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        setSuppliersList(data.data.supplierList);
        setTotalRecords(data.data.count);
        const keyIsEmpty = _.isEmpty(data.data.supplierList);
        setFilteredLists(!keyIsEmpty ? data.data.supplierList : []);
        setIsloading(false);
      }
    });
  };

  const delayedSearch = useCallback(
    debounce((event) => {
      searchSuppliersData(event);
    }, 600),
    []
  );

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    delayedSearch(term);
  };

  const handelView = (supplier_id) => {
    setIsloading(true);
    let res = CitransApi.getSuppliersDetails(userToken, supplier_id);
    res.then((data) => {
      setIsView(true);
      setSelectedItem(data.data);
      setShow(true);
      setIsloading(false);
    });
  };

  const handelEdit = (supplier_id) => {
    setIsloading(true);
    let res = CitransApi.getSuppliersDetails(userToken, supplier_id);
    res.then((data) => {
      setIsEdit(true);
      setSelectedItem(data.data);
      setShow(true);
      setIsloading(false);
    });
  };

  const changeSupplierStatus = (id, status) => {
    setIsloading(true);
    let postData = new FormData();
    postData.append("status", status);
    let res = CitransApi.updateSuppliersStatus(userToken, postData, id);
    res.then((response) => {
      setIsloading(false);
      const responseCode = STATUS_MSG[response.data.code];
      if (response.code === STATUS_CODES.SUCCESS) {
        if (status) {
          Toster(`${t("SUPPLIER")} ${t("ACTIVE_SUCCESS")}`, "success");
        } else {
          Toster(`${t("SUPPLIER")} ${t("IN_ACTIVE_SUCCESS")}`, "success");
        }
        fetchSuppliersList(pageNumber);
      } else {
        Toster(t(responseCode), "error");
        fetchSuppliersList(pageNumber);
      }
    });
  };

  const formateContact = (code, phone) => {
    const number = `${code} ${phone.slice(code.length - 1).trim()}`;
    return number;
  };

  return (
    <>
      {isloading && <Loader />}
      <section>
        <div>
          <TopHeader />
        </div>
        <div className="MainPageWrapper">
          <div>
            <Sidebar />
          </div>
          <div className="PageContent">
            <div className="PageContentHead">
              <h3>{t("SUPPLIERS_LIST")}</h3>
              <div className="ContentAddBtn" style={{ cursor: "pointer" }}>
                <div onClick={handleShow} className="ContentAddBtn">
                  <Icon
                    icon="octicon:feed-plus-16"
                    color="white"
                    width="22"
                    height="22"
                  />
                  <span>{t("ADD_SUPPLIER")}</span>
                </div>
              </div>
            </div>
            <div className="ContentBody">
              <Row>
                <Col lg={6}>
                  <Form.Group className="LoginInput">
                    <Form.Label>{t("SEARCH")}</Form.Label>
                    <Form.Control
                      type="search"
                      name="searchBox"
                      placeholder="Search by suppliers name/email/phone number"
                      autoComplete="off"
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {Array.isArray(filteredLists) &&
                filteredLists.map((value, ind) => (
                  <div key={ind} className={styles.suppliersCard}>
                    <div className={styles.suppliersCardHead}>
                      <h3>{value.name}</h3>
                      <p>{value.email}</p>
                      <p>
                        {t("ADDRESS")} : <span>{value.address}</span>
                      </p>
                    </div>
                    <div
                      className={`${styles.cardFlex} ${styles.suppliersCardHead}`}
                    >
                      <div className={styles.contactBox}>
                        <p>{t("CONTACT_NUMBER")} : </p>
                        <span>
                          {formateContact(
                            value.phone_dial_code,
                            value.phone_number
                          )}
                        </span>
                      </div>

                      <div className={styles.contactBox}>
                        <p>{t("COUNTRY_NAME")} : </p>
                        <span>{value.country_name}</span>
                      </div>

                      <div className={styles.iconsClass}>
                        <p className={styles.iconsClassDetails}>
                          <Icon
                            icon="tabler:list-details"
                            color="#626262"
                            width="24"
                            height="24"
                            onClick={() => handelView(value.supplier_id)}
                          />
                        </p>
                        <p className={styles.iconsClassEdit}>
                          <Icon
                            icon="ic:round-edit"
                            color="#626262"
                            width="24"
                            height="24"
                            onClick={() => handelEdit(value.supplier_id)}
                          />
                        </p>
                        {value.status === STATUS.ACTIVE ? (
                          <p
                            className={styles.iconsClassDetails}
                            title={t("IN_ACTIVE")}
                          >
                            <Icon
                              icon="charm:tick"
                              title="Inactive"
                              color="green"
                              width="25"
                              height="25"
                              onClick={() =>
                                changeSupplierStatus(
                                  value.supplier_id,
                                  STATUS.IN_ACTIVE
                                )
                              }
                            />
                          </p>
                        ) : (
                          <p
                            className={styles.iconsClassDelete}
                            title={t("ACTIVE")}
                          >
                            <Icon
                              icon="maki:cross"
                              color="red"
                              width="24"
                              height="24"
                              onClick={() =>
                                changeSupplierStatus(
                                  value.supplier_id,
                                  STATUS.ACTIVE
                                )
                              }
                            />
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                ))}

              {totalRecords == 0 ? <div>{t("RESULT_NOT_FOUND")}</div> : null}

              {totalRecords > itemsPerPage && (
                <div className="paginateWrapper">
                  <div>
                    {t("SHOWING")} {1 + pageNumber * 10 - 10}-
                    {(pageNumber - 1) * 10 + filteredLists.length} of{" "}
                    {totalRecords} {t("RECORDS")}
                  </div>
                  <div>
                    <ReactPaginate
                      previousLabel="Previous"
                      nextLabel="Next"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      pageCount={Math.ceil(totalRecords / itemsPerPage)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <Footer />
        </div>
        <SupplierModal
          isEdit={isEdit}
          isView={isView}
          handleClose={handleClose}
          show={show}
          data={selectedItem}
          fetchSuppliersList={fetchSuppliersList}
          pageNumber={pageNumber}
          setSearchTerm={setSearchTerm}
        />
      </section>
    </>
  );
}

export default Suppliers;
