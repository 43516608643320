import Modal from "react-bootstrap/Modal";
import { IoMdCloseCircle } from "react-icons/io";
import { useTranslation } from "react-i18next";

export default function ShippingMethod({
  show,
  handleClose,
  selectedMethod,
  setSelectedMethod,
  methods,
}) {
  const { t } = useTranslation();

  const handleChangeCheckBox = (value) => {
    if (value == selectedMethod) {
      setSelectedMethod("");
      handleClose();
    } else {
      setSelectedMethod(value);
      handleClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <div className="SignupPopup">
        <Modal.Header>
          <Modal.Title>{t("SHIPPING_METHOD")}</Modal.Title>
          <IoMdCloseCircle onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          {methods?.map((checkbox, index) => (
            <div
              key={index}
              className="signupCheckbox"
              onClick={() => {
                handleChangeCheckBox(checkbox);
              }}
            >
              <label htmlFor={checkbox.value}>{checkbox.value_desc}</label>
              <input
                type="checkbox"
                checked={selectedMethod?.value_desc === checkbox.value_desc}
              />
            </div>
          ))}
        </Modal.Body>
      </div>
    </Modal>
  );
}
