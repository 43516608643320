import { Button, Col, Form, Row } from "react-bootstrap";
import { validationSchema } from "../../Common/ValidationSchema";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdOutlineMail } from "react-icons/md";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { BsBuildings, BsFileEarmarkBarGraph } from "react-icons/bs";
import { FiUser } from "react-icons/fi";
import PhoneInput from "react-phone-input-2";
import CitransApi from "../../helpers/Api";
import { MetaDetaKey } from "../../metaDataKey/MetaDetaKey";
import CustomsTreatment from "../../authComponent/TreatmentPopUp/TreatmentPopUp";
import CustomsRegime from "../../authComponent/RegimePopUp/RegimePopUp";
import styles from "./Profile.module.css";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Loader from "../../Common/LoadingSpinner";
import Toster from "../../Utils/Toster";

export default function UpdateProfile({ data, getProfile, handelUpdateClose }) {
  const { t } = useTranslation();
  const userDetail = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    getMetaData();
    setDataValues();
  }, []);

  const { register, handleSubmit, setValue, formState, setError, getValues } =
    useForm({
      mode: "onChange",
    });

  const setDataValues = () => {
    // setPhone(data.compnay_phone_number);
    setValue("phoneNumber", data.compnay_phone_number);
    setValue("companyName", data.company_name);
    setValue("userName", data.user_name);
    setValue("email", data.email);
    setValue("taxId", data.tax_id);
    setValue("occupation", data.occupation);
    setValue("address", data.address);
    setPhone(data.compnay_phone_number);
    setDialCode(data.compnay_dial_code);
  };

  const [customs, setCustoms] = useState({ treatment: [], regime: [] });
  const [dialCode, setDialCode] = useState();
  const [phone, setPhone] = useState("");
  const [treatmentPopUp, setTreatmentPopUp] = useState(false);
  const [regimePopUp, setRegimePopUp] = useState(false);
  const [customRegime, setCustomRegime] = useState([]);
  const [customTreat, setCustomTreat] = useState([]);
  const handleTreatmentClose = () => setTreatmentPopUp(false);
  const handleRegimeClose = () => setRegimePopUp(false);
  const [regimefield, setRegimefield] = useState("");
  const [customfield, setCustomfield] = useState("");
  const [regimevalue, setRegimevalue] = useState();
  const [treatvalue, setTreatvalue] = useState();
  const [isLoading, setIsloading] = useState(false);

  const getMetaData = () => {
    const metaDataApiKey = `${MetaDetaKey.CUSTOM_TREATMENT},${MetaDetaKey.customs_regime}`;
    let res = CitransApi.getMetaData(metaDataApiKey);
    res.then((data) => {
      setCustomTreat(data.data.customs_treatment);
      setCustomRegime(data.data.customs_regime);
      setDataValues();
    });
  };

  useEffect(() => {
    let getTreatment = customTreat.find(
      (val) => val.value_desc == data.customs_treatment
    );
    setTreatvalue(getTreatment?.value_code);
    setCustomfield(getTreatment?.value_desc);

    let getRegime = customRegime.find(
      (val) => val.value_desc == data.customs_regime
    );
    setRegimevalue(getRegime?.value_code);
    setRegimefield(getRegime?.value_desc);
  }, [customTreat, customRegime]);

  const handelPhoneChange = (phone, data) => {
    setPhone(phone);
    setValue("phoneNumber", phone);
    setDialCode("+" + data.dialCode);
  };

  const handelSubmit = (Inputdata) => {
    let Postdata = new FormData();
    Postdata.append("cname", Inputdata.companyName);
    Postdata.append("user_name", Inputdata.userName);
    Postdata.append("email", Inputdata.email);
    Postdata.append("dial_code", dialCode);
    Postdata.append("phone_number", Inputdata.phoneNumber);
    Postdata.append("ctreatment", treatvalue);
    Postdata.append("cregime", regimevalue);
    Postdata.append("txid", Inputdata.taxId);
    Postdata.append("occupation", Inputdata.occupation);
    Postdata.append("address", Inputdata.address);

    if (regimefield !== "" && customfield !== "") {
      setIsloading(true);
      const response = CitransApi.updateProfileData(userDetail.token, Postdata);
      response.then((res) => {
        if (res.code === STATUS_CODES.SUCCESS) {
          Toster(`${t("PROFILE")} ${t("UPDATE_SUCCESS")}`, "success");
          getProfile();
          handelUpdateClose();
          setIsloading(false);
        } else {
          setIsloading(false);
        }
      });
    }
  };

  const HandlegetMetaData = (data) => {
    const dataKey = data;
    let res = CitransApi.getMetaData(dataKey);
    res.then((data) => {
      if (dataKey == MetaDetaKey.CUSTOM_TREATMENT) {
        // setCustomTreat(data.data.customs_treatment);
        setTreatmentPopUp(true);
      } else if (dataKey == MetaDetaKey.customs_regime) {
        // setCustomRegime(data.data.customs_regime);
        setRegimePopUp(true);
      }
    });
  };

  return (
    <div className="SignupPopup AddPopUp">
      <div
        onClick={() => {
          handelUpdateClose();
        }}
        className={styles.BackBtn}
      >
        <IoArrowBackCircleSharp />
        <span>{t("BACK")}</span>
      </div>
      <Form noValidate onSubmit={handleSubmit(handelSubmit)}>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <Form.Group className="LoginInput">
              <Form.Label>{t("COMPANY_NAME")}</Form.Label>
              <Form.Control
                {...register("companyName", validationSchema.companyName)}
                type="text"
                placeholder={`${t("ENTER")} ${t("COMPANY_NAME")}`}
                autoComplete="off"
                isInvalid={!!formState.errors.companyName}
              />
              <Form.Control.Feedback type="invalid">
                {t(formState.errors.companyName?.message)}
              </Form.Control.Feedback>
              <BsBuildings />
            </Form.Group>
          </Col>

          <Col lg={6} md={6} sm={12}>
            <Form.Group className="LoginInput">
              <Form.Label>{t("USER_NAME")}</Form.Label>
              <Form.Control
                {...register("userName", validationSchema.userName)}
                isInvalid={!!formState.errors.userName}
                type="text"
                placeholder={`${t("ENTER")} ${t("USER_NAME")}`}
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {t(formState.errors.userName?.message)}
              </Form.Control.Feedback>
              <FiUser />
            </Form.Group>
          </Col>

          <Col lg={6} md={6} sm={12}>
            <Form.Group className="LoginInput">
              <Form.Label>{t("EMAIL_ID")}</Form.Label>
              <Form.Control
                {...register("email", validationSchema.email)}
                isInvalid={!!formState.errors.email}
                placeholder={`${t("ENTER")} ${t("EMAIL_ID")}`}
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {t(formState.errors.email?.message)}
              </Form.Control.Feedback>
              <MdOutlineMail />
            </Form.Group>
          </Col>

          <Col lg={6} md={6} sm={12}>
            <Form.Group className="LoginInput" required>
              <Form.Label>{t("PHONE_NUMBER")}</Form.Label>
              <PhoneInput
                {...register("phoneNumber", validationSchema.phoneNumber)}
                country={"us"}
                value={data?.compnay_phone_number}
                onChange={(phone, data) => handelPhoneChange(phone, data)}
              />
              <div className="validateError">
                {!phone && t(formState.errors.phoneNumber?.message)}
                {phone && phone.length <= 4 && t("PHONE_ATLEAST_5_CH")}
              </div>
              <Icon
                icon="solar:phone-outline"
                color="rgba(130, 130, 130, 0.8509803921568627)"
                width="22"
                height="22"
              />
            </Form.Group>
          </Col>

          <Col lg={6} md={6} sm={12}>
            <Form.Group className="LoginInput">
              <div className={"styles.customsSection"}>
                <Form.Label>{t("CUSTOMS_TREATMENT")}</Form.Label>

                <p
                  onClick={() => {
                    HandlegetMetaData(MetaDetaKey.CUSTOM_TREATMENT);
                  }}
                >
                  {customfield === "" ? (
                    <>
                      {t("SELECT")} {t("CUSTOMS_TREATMENT")}
                    </>
                  ) : (
                    customfield
                  )}
                </p>
                {customfield === "" && formState.isSubmitted && (
                  <div className="validateError">
                    {t("SELECT_CUSTOM_TREATMENT")}
                  </div>
                )}
                <Icon
                  icon="bx:up-arrow"
                  color="rgba(130, 130, 130, 0.8509803921568627)"
                  width="22"
                  height="22"
                  vFlip={true}
                />
              </div>
            </Form.Group>
          </Col>

          <Col lg={6} md={6} sm={12}>
            <Form.Group className="LoginInput">
              <div className={"styles.customsSection"}>
                <Form.Label>{t("CUSTOMS_REGIME")}</Form.Label>
                <p
                  onClick={() => {
                    HandlegetMetaData(MetaDetaKey.customs_regime);
                  }}
                >
                  {regimefield === "" ? (
                    <>
                      {t("SELECT")} {t("CUSTOMS_REGIME")}
                    </>
                  ) : (
                    regimefield
                  )}
                </p>
                {regimefield === "" && formState.isSubmitted && (
                  <div className="validateError">
                    {t("SELECT_CUSTOM_REGIME")}
                  </div>
                )}
                <Icon
                  icon="bx:up-arrow"
                  color="rgba(130, 130, 130, 0.8509803921568627)"
                  width="22"
                  height="22"
                  vFlip={true}
                />
              </div>
            </Form.Group>
          </Col>

          <Col lg={6} md={6} sm={12}>
            <Form.Group className="LoginInput">
              <Form.Label>{t("TAX_ID")}</Form.Label>
              <Form.Control
                {...register("taxId", validationSchema.taxId)}
                isInvalid={!!formState.errors.taxId}
                required
                type="text"
                placeholder={`${t("ENTER")} ${t("TAX_ID")}`}
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {t(formState.errors.taxId?.message)}
              </Form.Control.Feedback>
              <BsFileEarmarkBarGraph />
            </Form.Group>
          </Col>

          <Col lg={6} md={6} sm={12}>
            <Form.Group className="LoginInput">
              <Form.Label>{t("OCCUPATION")}</Form.Label>
              <Form.Control
                {...register("occupation", validationSchema.occupation)}
                isInvalid={!!formState.errors.occupation}
                required
                type="text"
                placeholder={`${t("ENTER")} ${t("OCCUPATION")}`}
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {t(formState.errors.occupation?.message)}
              </Form.Control.Feedback>
              <Icon
                icon="mdi:user-search-outline"
                color="rgba(130, 130, 130, 0.8509803921568627)"
                width="25"
                height="25"
              />
            </Form.Group>
          </Col>

          <Col lg={6} md={6} sm={12}>
            <Form.Group className="LoginInput">
              <Form.Label>{t("ADDRESS")}</Form.Label>
              <Form.Control
                {...register("address", validationSchema.address)}
                isInvalid={!!formState.errors.address}
                required
                type="text"
                placeholder={`${t("ENTER")} ${t("ADDRESS")}`}
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {t(formState.errors.address?.message)}
              </Form.Control.Feedback>
              <Icon
                icon="carbon:location"
                color="rgba(130, 130, 130, 0.8509803921568627)"
                width="25"
                height="25"
              />
            </Form.Group>
          </Col>
        </Row>
        <div className={styles.updateFooter}>
          <Button
            variant="secondary"
            onClick={() => {
              handelUpdateClose();
            }}
          >
            {t("BACK")}
          </Button>
          <Button type="submit">{t("UPDATE")}</Button>
        </div>
      </Form>
      <CustomsTreatment
        customs={customs}
        setCustoms={setCustoms}
        treatmentPopUp={treatmentPopUp}
        handleTreatmentClose={handleTreatmentClose}
        customTreat={customTreat}
        treatvalue={treatvalue}
        setTreatvalue={setTreatvalue}
        customfield={customfield}
        setCustomfield={setCustomfield}
      />
      <CustomsRegime
        customs={customs}
        setCustoms={setCustoms}
        regimePopUp={regimePopUp}
        handleRegimeClose={handleRegimeClose}
        customRegime={customRegime}
        regimevalue={regimevalue}
        setRegimevalue={setRegimevalue}
        regimefield={regimefield}
        setRegimefield={setRegimefield}
      />
      {isLoading && <Loader />}
    </div>
  );
}
