export const HeaderData = [
  {
    id: "1",
    name: "HOME",
    link: "/",
  },
  {
    id: "2",
    name: "SERVICES",
    link: "",
  },
  {
    id: "3",
    name: "ABOUT US",
    link: "",
  },
  {
    id: "4",
    name: "CONTACT US",
    link: "",
  },
  {
    id: "5",
    name: "LOGIN | CREATE ACCOUNT",
    link: "/login",
  },
];
