import React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import styles from "./BottomHeader.module.css";
import { HeaderData } from "./HeaderData";
import navbarLogo from "../../Assests/Images/navbarLogo.png";
import "../../Assests/styles/common.css";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../Context/LanguageContext";

function BottomHeader() {
  const { selectedItem } = useContext(LanguageContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.navBg}>
        {/* <Container> */}
        <Navbar expand="lg" className={styles.test} data-bs-theme="dark">
          <div className={styles.showLogo}>
            <div className={styles.topNavLogo} onClick={() => navigate("/")}>
              <img alt="" src={navbarLogo} className={styles.navbarLogoImg} />
            </div>
          </div>
          <div className="test">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {/* <div className={styles.navItems}> */}
              {HeaderData.map((item) => (
                <div key={item.id} className={styles.navLinks}>
                  {item.name === selectedItem ? (
                    <NavLink to={item.link} className={styles.navItemSelected}>
                      {t(item.name)}
                    </NavLink>
                  ) : (
                    <NavLink to={item.link} className={styles.navItems}>
                      {t(item.name)}
                    </NavLink>
                  )}
                </div>
              ))}
              {/* </div> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {/* </Container> */}
      </div>
    </>
  );
}

export default BottomHeader;
