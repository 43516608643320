import { createSlice } from "@reduxjs/toolkit";

let date = new Date().getTime();

const initialState = {
  shipments: [{ id: date, isSubmitted: false, data: {} }],
  type: "Complete",
  items: [],
};

const shipmentSlice = createSlice({
  name: "shipment",
  initialState,
  reducers: {
    addShipments: (state, action) => {
      if (state.shipments.length < 4) {
        state.shipments.push({
          id: action.payload.id,
          isSubmitted: false,
          data: {},
        });
      }
    },
    updateShipment: (state, action) => {
      const { id, data } = action.payload;

      // Find the index of the shipment with the matching ID
      const index = state.shipments.findIndex((shipment) => shipment.id === id);

      if (index !== -1) {
        // If the shipment with the ID is found, update it
        state.shipments[index] = { id: id, isSubmitted: true, data: data };
      }
    },
    setInitialShipments: (state, action) => {
      state.shipments = action.payload;
    },
    deleteShipmentById: (state, action) => {
      const shipmentId = action.payload;
      state.shipments = state.shipments.filter(
        (shipment) => shipment.id !== shipmentId
      );
    },
    updateOrderType: (state, action) => {
      state.type = action.payload;
    },

    setInitialItems: (state, action) => {
      state.items = action.payload;
    },

    updateItems: (state, action) => {
      const { productName, newQuantity } = action.payload;
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.product_use == productName) {
            const remainingQuantity =
              item.product_quantity - Number(newQuantity);
            return {
              ...item,
              remaining_quantity: remainingQuantity,
            };
          }
          return item;
        }),
      };
    },
  },
});

export const {
  addShipments,
  updateShipment,
  setInitialShipments,
  deleteShipmentById,
  updateOrderType,
  setInitialItems,
  updateItems,
} = shipmentSlice.actions;
export default shipmentSlice.reducer;
