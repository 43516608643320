import styles from "./Staff.module.css";
import TopHeader from "../TopHeader/TopHeader";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import { Icon } from "@iconify/react";
import { useState, useEffect, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import AddStaff from "./AddStaff/AddStaff";
import { useTranslation } from "react-i18next";
import DeleteAlert from "./AddStaff/DeleteAlert";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Stack } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import CitransApi from "../../helpers/Api.js";
import { STATUS_CODES } from "../../Utils/StatusCode.js";
import { STATUS_MSG } from "../../Utils/StatusMsg.js";
import Toster from "../../Utils/Toster.js";
import Loader from "../../Common/LoadingSpinner.js";
import { LOGGED_IN, PAGINATE } from "../../Utils/Constants.js";
import ReactPaginate from "react-paginate";
import { debounce } from "lodash";

function StaffList() {
  const isAuthenticated = useSelector((state) => state.user.userToken);

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const itemsPerPage = PAGINATE.PER_PAGE;

  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [dataForUpdate, setDataForUpdate] = useState();

  const [staffList, setStaffList] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKey, setSearchKey] = useState("");

  const [editIndex, setEditIndex] = useState();
  const [isView, setIsView] = useState(false);
  const [staffId, setStaffId] = useState();
  const [totalRecords, setTotalRecords] = useState();

  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNumber(newPageNumber);
  };

  const handleClose = () => {
    setShow(false);
    setEditIndex();
    setDataForUpdate();
  };

  const handleCloseDelete = () => {
    setShowDelete(false);
  };

  const handleShowDelete = () => setShowDelete(true);

  const handleDeleteItem = () => {
    setShowDelete(false);
    setEditIndex(-1);
  };

  const handleEditItem = (data) => {
    setEditIndex(-1);
    setShow(true);
    setIsView(false);
    setDataForUpdate(data);
  };

  const handleShowDetail = (data) => {
    setEditIndex(0);
    setShow(true);
    setIsView(true);
    setDataForUpdate(data);
  };

  const handleAdd = () => {
    setEditIndex(1);
    setShow(true);
    setIsView(false);
  };

  const handleGetStaffDetail = (pageNumber) => {
    setIsloading(true);
    let res = CitransApi.getStaffDetails(isAuthenticated, pageNumber);

    res.then((data) => {
      if (
        data.code == STATUS_CODES.INVALID_TOKEN ||
        data.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        setStaffList(data.data.staffList);
        setTotalRecords(data.data.count);
        setIsloading(false);
      }
    });
  };

  const searchStaffDetail = (event) => {
    let res = CitransApi.searchStaffDetails(isAuthenticated, event);

    res.then((data) => {
      if (
        data.code == STATUS_CODES.INVALID_TOKEN ||
        data.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        setStaffList(data.data.staffList);

        setIsloading(false);
      }
    });
  };

  const handleResendInvitation = (id) => {
    setIsloading(true);

    let res = CitransApi.resendInvitation(isAuthenticated, id);
    res.then((response) => {
      setIsloading(false);
      const ResponseCode = STATUS_MSG[response.data?.code];
      if (response.code === STATUS_CODES.SUCCESS) {
        setIsloading(false);
        Toster(t("RESEND_INVITATION"), "success");
        handleGetStaffDetail(pageNumber);
        handleClose();
      } else {
        setIsloading(false);
        Toster(t(ResponseCode), "error");
        handleClose();
      }
    });
  };

  useEffect(() => {
    handleGetStaffDetail(pageNumber);
  }, [pageNumber]);

  const delayedSearch = useCallback(
    debounce((event) => {
      searchStaffDetail(event);
    }, 300),
    []
  );

  const handleChange = (event) => {
    setSearchKey(event);
    delayedSearch(event);
  };

  return (
    <>
      {isloading ? <Loader /> : null}
      <div>
        <TopHeader />
      </div>
      <div className="MainPageWrapper">
        <div>
          <Sidebar />
        </div>
        <div className="PageContent">
          <div className="PageContentHead">
            <h3>{t("STAFF_LIST")}</h3>
            <div onClick={handleAdd} className="ContentAddBtn">
              <Icon
                icon="octicon:feed-plus-16"
                color="white"
                width="22"
                height="22"
              />
              <span>{t("ADD_STAFF")}</span>
            </div>
          </div>
          {/* <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                className={styles.SearchField}
                type="search"
                placeholder="Search by name, email, or contact"
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
              />
            </Form.Group>
          </Form> */}

          <div className="ContentBody">
            <Row>
              <Col lg={6}>
                <Form>
                  <Form.Group className="LoginInput">
                    <Form.Label>{t("SEARCH")}</Form.Label>
                    <Form.Control
                      // className={styles.SearchField}
                      type="search"
                      placeholder={t("SEARCH_BY_EMAIL_NAME")}
                      onChange={(e) => {
                        handleChange(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>

            <Row>
              {Array.isArray(staffList) &&
                staffList?.map((val, index) => {
                  return (
                    <Col lg={6} key={index}>
                      <div className={styles.CardBody}>
                        <h5>{val.name}</h5>
                        <p>{val.email}</p>
                        <p>
                          {t("CONTACT_NUMBER")} : <span>+{val.phn}</span>
                        </p>

                        <div className={styles.iconsClass}>
                          <Stack direction="horizontal" gap={0}>
                            <div className="p-2">
                              <p className={styles.iconsClassEdit}>
                                <Icon
                                  icon="bxs:user-detail"
                                  color="#626262"
                                  width="24"
                                  height="24"
                                  onClick={() => {
                                    handleShowDetail(val);
                                  }}
                                />
                              </p>
                            </div>

                            <div className="p-2">
                              <p className={styles.iconsClassDelete}>
                                <Icon
                                  icon="ic:baseline-delete"
                                  color="red"
                                  onClick={() => {
                                    handleShowDelete();
                                    setStaffId(val.id);
                                  }}
                                />
                              </p>
                            </div>

                            {val.is_logged_in !== LOGGED_IN.IS_LOGED_IN ? (
                              <div className="p-2 ms-auto">
                                <Button
                                  className={styles.ResendEmail}
                                  onClick={() => {
                                    handleResendInvitation(val.id);
                                  }}
                                >
                                  {t("RESEND")}
                                </Button>
                              </div>
                            ) : (
                              ""
                            )}
                          </Stack>
                        </div>
                      </div>
                    </Col>
                  );
                })}

              {totalRecords == 0 ? <div>{t("RESULT_NOT_FOUND")}</div> : null}
              {searchKey === "" ? (
                <div className={styles.tableDetail}>
                  <div className={styles.totalRecord}>
                    {totalRecords !== 0 ? (
                      <p>
                        {t("SHOWING")} {1 + pageNumber * 10 - 10}-
                        {(pageNumber - 1) * 10 + staffList.length} of{" "}
                        {totalRecords} {t("RECORDS")}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    {totalRecords > itemsPerPage && (
                      <ReactPaginate
                        previousLabel="Previous"
                        nextLabel="Next"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        pageCount={Math.ceil(totalRecords / itemsPerPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                      />
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </Row>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
      <AddStaff
        show={show}
        handleClose={handleClose}
        editIndex={editIndex}
        dataForUpdate={dataForUpdate}
        isView={isView}
        handleGetStaffDetail={handleGetStaffDetail}
        setSearchKey={setSearchKey}
        pageNumber={pageNumber}
      />
      <DeleteAlert
        show={showDelete}
        handleClose={handleCloseDelete}
        handleDeleteItem={handleDeleteItem}
        staffId={staffId}
        handleGetStaffDetail={handleGetStaffDetail}
        setSearchKey={setSearchKey}
        pageNumber={pageNumber}
      />
    </>
  );
}

export default StaffList;
