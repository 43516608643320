import React, { useEffect } from "react";
import { useState } from "react";
import styles from "./PaymentDetail.module.css";
import TopHeader from "../TopHeader/TopHeader";
import { Icon } from "@iconify/react";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import { useLocation } from "react-router-dom";

import { Form } from "react-bootstrap";

import { useTranslation } from "react-i18next";

import { MetaDetaKey } from "../../metaDataKey/MetaDetaKey";
import CitransApi from "../../helpers/Api";
import { useForm } from "react-hook-form";
import { validationSchema } from "../../Common/ValidationSchema";
import Toster from "../../Utils/Toster";
import Loader from "../../Common/LoadingSpinner";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { useSelector } from "react-redux";

function CustomsDetail() {
  const { register, handleSubmit, setValue, formState, reset } = useForm({
    mode: "onChange",
  });
  const { t } = useTranslation();

  const location = useLocation();

  const isAuthenticated = useSelector((state) => state.user.userToken);

  const shipment_id = location.state.shipment_id;

  const metaDataApiKey = `${MetaDetaKey.CONSOLIDATING_COMPANY},${MetaDetaKey.VERIFICATION_DEPOSIT},${MetaDetaKey.SHIPPING_CLERK},${MetaDetaKey.CURRENCY_OF_ORDER}`;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const [metaConsolidating, setMetaConsolidating] = useState([]);
  const [metaShippingClerk, setMetaShippingClerk] = useState([]);
  const [metaVerification, setMetaVerification] = useState([]);
  const [orderCurrency, setOrderCurrency] = useState([]);
  const [breakdown, setBreakdown] = useState([]);

  const [isVace, setIsVace] = useState(2);
  const [isChassis, setIsChassis] = useState(2);
  const [isLoading, setIsLoading] = useState(false);

  const getMetaData = () => {
    let res = CitransApi.getMetaData(metaDataApiKey);
    res.then((data) => {
      setMetaConsolidating(data?.data.consolidating_shipping_company);
      setMetaShippingClerk(data?.data.shipping_clerk);
      setMetaVerification(data?.data.verification_deposit);
      setOrderCurrency(data.data.currency_of_order);
    });
  };

  useEffect(() => {
    getMetaData();
  }, []);

  const handelSubmit = (formData) => {
    alert("chala");
    setBreakdown([
      {
        breakdown_type: 1,
        amount: formData.CustomAmount,
        meta_currency_of_amount: formData.CustomCurrency,
        pin: formData.CustomPin,
        quotation: formData.CustomQuotation,
        invoice_to_pay: formData.CustomPay,
      },
      {
        breakdown_type: 2,
        amount: formData.VerificationAmount,
        meta_currency_of_amount: formData.VerificationCurrency,
        pin: formData.VerificationPin,
        quotation: formData.VerificationQuotation,
        invoice_to_pay: formData.VerificationPay,
      },
      {
        breakdown_type: 3,
        amount: formData.ClerkAmount,
        meta_currency_of_amount: formData.ShippingClerkCurrency,
        pin: formData.ClerkPin,
        quotation: formData.ClerkQuotation,
        invoice_to_pay: formData.ClerkPay,
      },
      {
        breakdown_type: 4,
        amount: formData.ConsolidatingAmount,
        meta_currency_of_amount: formData.ConsolidatingCurrency,
        pin: formData.ConsolidatingPin,
        quotation: formData.ConsolidatingQuotation,
        invoice_to_pay: formData.ConsolidatingPay,
      },
      {
        breakdown_type: 5,
        amount: formData.VaceAmount,
        meta_currency_of_amount: formData.VaceCurrency,
        pin: formData.VacePin,
        quotation: formData.VaceQuotation,
        invoice_to_pay: formData.VacePay,
      },
      {
        breakdown_type: 6,
        amount: formData.ChassisAmount,
        meta_currency_of_amount: formData.ChassisCurrency,
        pin: formData.ChassisPin,
        quotation: formData.ChassisQuotation,
        invoice_to_pay: formData.ChassisPay,
      },
    ]);
  };

  const handleGenerateBill = (formData) => {
    const break_point1 = [
      {
        breakdown_type: 1,
        amount: parseFloat(formData.CustomAmount),
        meta_currency_of_amount: formData.CustomCurrency,
        pin: formData.CustomPin,
        quotation: formData.CustomQuotation,
        invoice_to_pay: formData.CustomPay,
      },
      {
        breakdown_type: 2,
        amount: parseFloat(formData.VerificationAmount),
        meta_currency_of_amount: formData.VerificationCurrency,
        pin: formData.VerificationPin,
        quotation: formData.VerificationQuotation,
        invoice_to_pay: formData.VerificationPay,
      },
      {
        breakdown_type: 3,
        amount: parseFloat(formData.ClerkAmount),
        meta_currency_of_amount: formData.ShippingClerkCurrency,
        pin: formData.ClerkPin,
        quotation: formData.ClerkQuotation,
        invoice_to_pay: formData.ClerkPay,
      },
      {
        breakdown_type: 4,
        amount: parseFloat(formData.ConsolidatingAmount),
        meta_currency_of_amount: formData.ConsolidatingCurrency,
        pin: formData.ConsolidatingPin,
        quotation: formData.ConsolidatingQuotation,
        invoice_to_pay: formData.ConsolidatingPay,
      },
      {
        breakdown_type: 5,
        amount: parseFloat(formData.VaceAmount),
        meta_currency_of_amount: formData.VaceCurrency,
        pin: formData.VacePin,
        quotation: formData.VaceQuotation,
        invoice_to_pay: formData.VacePay,
      },
      {
        breakdown_type: 6,
        amount: parseFloat(formData.ChassisAmount),
        meta_currency_of_amount: formData.ChassisCurrency,
        pin: formData.ChassisPin,
        quotation: formData.ChassisQuotation,
        invoice_to_pay: formData.ChassisPay,
      },
    ];
    const break_point2 = [
      {
        breakdown_type: 1,
        amount: parseFloat(formData.CustomAmount),
        meta_currency_of_amount: formData.CustomCurrency,
        pin: formData.CustomPin,
        quotation: formData.CustomQuotation,
        invoice_to_pay: formData.CustomPay,
      },
      {
        breakdown_type: 2,
        amount: parseFloat(formData.VerificationAmount),
        meta_currency_of_amount: formData.VerificationCurrency,
        pin: formData.VerificationPin,
        quotation: formData.VerificationQuotation,
        invoice_to_pay: formData.VerificationPay,
      },
      {
        breakdown_type: 3,
        amount: parseFloat(formData.ClerkAmount),
        meta_currency_of_amount: formData.ShippingClerkCurrency,
        pin: formData.ClerkPin,
        quotation: formData.ClerkQuotation,
        invoice_to_pay: formData.ClerkPay,
      },
      {
        breakdown_type: 4,
        amount: parseFloat(formData.ConsolidatingAmount),
        meta_currency_of_amount: formData.ConsolidatingCurrency,
        pin: formData.ConsolidatingPin,
        quotation: formData.ConsolidatingQuotation,
        invoice_to_pay: formData.ConsolidatingPay,
      },

      {
        breakdown_type: 6,
        amount: parseFloat(formData.ChassisAmount),
        meta_currency_of_amount: formData.ChassisCurrency,
        pin: formData.ChassisPin,
        quotation: formData.ChassisQuotation,
        invoice_to_pay: formData.ChassisPay,
      },
    ];
    const break_point3 = [
      {
        breakdown_type: 1,
        amount: parseFloat(formData.CustomAmount),
        meta_currency_of_amount: formData.CustomCurrency,
        pin: formData.CustomPin,
        quotation: formData.CustomQuotation,
        invoice_to_pay: formData.CustomPay,
      },
      {
        breakdown_type: 2,
        amount: parseFloat(formData.VerificationAmount),
        meta_currency_of_amount: formData.VerificationCurrency,
        pin: formData.VerificationPin,
        quotation: formData.VerificationQuotation,
        invoice_to_pay: formData.VerificationPay,
      },
      {
        breakdown_type: 3,
        amount: parseFloat(formData.ClerkAmount),
        meta_currency_of_amount: formData.ShippingClerkCurrency,
        pin: formData.ClerkPin,
        quotation: formData.ClerkQuotation,
        invoice_to_pay: formData.ClerkPay,
      },
      {
        breakdown_type: 4,
        amount: parseFloat(formData.ConsolidatingAmount),
        meta_currency_of_amount: formData.ConsolidatingCurrency,
        pin: formData.ConsolidatingPin,
        quotation: formData.ConsolidatingQuotation,
        invoice_to_pay: formData.ConsolidatingPay,
      },
      {
        breakdown_type: 5,
        amount: parseFloat(formData.VaceAmount),
        meta_currency_of_amount: formData.VaceCurrency,
        pin: formData.VacePin,
        quotation: formData.VaceQuotation,
        invoice_to_pay: formData.VacePay,
      },
    ];

    const break_point4 = [
      {
        breakdown_type: 1,
        amount: parseFloat(formData.CustomAmount),
        meta_currency_of_amount: formData.CustomCurrency,
        pin: formData.CustomPin,
        quotation: formData.CustomQuotation,
        invoice_to_pay: formData.CustomPay,
      },
      {
        breakdown_type: 2,
        amount: parseFloat(formData.VerificationAmount),
        meta_currency_of_amount: formData.VerificationCurrency,
        pin: formData.VerificationPin,
        quotation: formData.VerificationQuotation,
        invoice_to_pay: formData.VerificationPay,
      },
      {
        breakdown_type: 3,
        amount: parseFloat(formData.ClerkAmount),
        meta_currency_of_amount: formData.ShippingClerkCurrency,
        pin: formData.ClerkPin,
        quotation: formData.ClerkQuotation,
        invoice_to_pay: formData.ClerkPay,
      },
      {
        breakdown_type: 4,
        amount: parseFloat(formData.ConsolidatingAmount),
        meta_currency_of_amount: formData.ConsolidatingCurrency,
        pin: formData.ConsolidatingPin,
        quotation: formData.ConsolidatingQuotation,
        invoice_to_pay: formData.ConsolidatingPay,
      },
    ];

    setIsLoading(true);
    let Postdata = new FormData();
    Postdata.append("declaration_number", formData.DeclarationNo);
    Postdata.append("settlement_number", formData.SettlementNo);
    Postdata.append("igra_number", formData.IgraNo);
    Postdata.append("meta_shipping_clerk", formData.ShippingClerk);
    Postdata.append("meta_verification_deposit", formData.VerificationDeposit);
    Postdata.append(
      "meta_consolidating_shipping_company",
      formData.Consolidating
    );
    Postdata.append("est_verification_date", formData.VerificationDate);
    Postdata.append("vuce", isVace);
    Postdata.append("chassis_and_container", isChassis);

    if (isVace === 1 && isChassis === 1) {
      Postdata.append("payment_breakdown", JSON.stringify(break_point1));
    } else if (isVace === 2 && isChassis === 1) {
      Postdata.append("payment_breakdown", JSON.stringify(break_point2));
    } else if (isVace === 1 && isChassis === 2) {
      Postdata.append("payment_breakdown", JSON.stringify(break_point3));
    } else if (isVace === 2 && isChassis === 2) {
      Postdata.append("payment_breakdown", JSON.stringify(break_point4));
    }

    let res = CitransApi.generateBill(isAuthenticated, Postdata, shipment_id);
    res.then((response) => {
      const ResponseCode = STATUS_MSG[response.data?.code];
      if (response.code === STATUS_CODES.SUCCESS) {
        setIsLoading(false);
        Toster(t("GENERATE_BILL"), "success");
        navigate("/transportation");
        reset();
      } else {
        if (
          response.code == STATUS_CODES.VALIDATION_ERROR ||
          response.data.code == STATUS_CODES.VALIDATION_ERROR
        ) {
          setIsLoading(false);
          Toster(response.data.message, "error");
          // reset();
        } else {
          setIsLoading(false);
          Toster(t(ResponseCode), "error");
          // reset();
        }
      }
    });
  };

  return (
    <section>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <div>
        <TopHeader />
      </div>

      <div className={styles.mainBox}>
        <div className={styles.innerBox}>
          <div className={styles.heading}>
            <div className={styles.iconsClass}>
              <p
                className={styles.iconsClassReload}
                onClick={() => navigate("/customs")}
              >
                <Icon icon="ep:back" color="white" width="24" height="24" />
              </p>
            </div>
            <div className={styles.HeadingLabel}>
              <p>{t("PAYMENT_DETAIL")}</p>
            </div>
          </div>

          <div className={styles.billing}>
            <Form noValidate onSubmit={handleSubmit(handleGenerateBill)}>
              <div className={styles.billingBox}>
                <Row xs={1} md={2}>
                  <Col lg={3} className="mb-3">
                    <Form.Group>
                      <Form.Control
                        type="text"
                        placeholder={t("ENTER_DECLARATION_NO")}
                        style={{ height: "45px" }}
                        {...register(
                          "DeclarationNo",
                          validationSchema.DeclarationNo
                        )}
                        isInvalid={!!formState.errors.DeclarationNo}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.DeclarationNo?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={3} className="mb-3">
                    <Form.Group>
                      <Form.Control
                        type="text"
                        placeholder={t("ENTER_SETTLEMENT_NO")}
                        style={{ height: "45px" }}
                        {...register(
                          "SettlementNo",
                          validationSchema.SettlementNo
                        )}
                        isInvalid={!!formState.errors.SettlementNo}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.SettlementNo?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={3} className="mb-3">
                    <Form.Group>
                      <Form.Control
                        type="text"
                        placeholder={t("ENTER_IGRA_NO")}
                        style={{ height: "45px" }}
                        {...register("IgraNo", validationSchema.IgraNo)}
                        isInvalid={!!formState.errors.IgraNo}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.IgraNo?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={3} className="mb-3">
                    <Form.Group>
                      <Form.Control
                        type="date"
                        name="order"
                        placeholder="Enter Estimated verification date"
                        autoComplete="off"
                        style={{
                          width: "100%",
                          height: "50px",
                          color: "#828282",
                        }}
                        {...register(
                          "VerificationDate",
                          validationSchema.VerificationDate
                        )}
                        isInvalid={!!formState.errors.VerificationDate}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.VerificationDate?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                {/* BREAKDOWN 1 ============================================== */}
                <div className={styles.labels}>
                  <p>{t("ARR_0")}</p>
                </div>

                <Row xs={1} md={2}>
                  <Col lg={3} className="mb-3">
                    <div style={{ display: "flex" }}>
                      <Form.Group>
                        <Form.Control
                          aria-label="Dollar amount (with dot and two decimal places)"
                          placeholder={t("ENTER_AMOUNT")}
                          type="number"
                          style={{
                            height: "52px",
                            color: "#828282",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            border: "none", // Remove input border
                            boxShadow: "none", // Remove input shadow if any
                          }}
                          {...register(
                            "CustomAmount",
                            validationSchema.CustomAmount
                          )}
                          isInvalid={!!formState.errors.CustomAmount}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.CustomAmount?.message)}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <Form.Select
                          aria-label="Default select example"
                          className="border-0"
                          style={{
                            width: "107px",
                            height: "52px",
                            paddingRight: "0.5rem",
                            flex: "0.5",
                            marginLeft: "-10px",
                          }}
                          {...register(
                            "CustomCurrency",
                            validationSchema.CustomCurrency
                          )}
                          // required
                          isInvalid={!!formState.errors.CustomCurrency}
                        >
                          <option value="" defaultValue>
                            Currency
                          </option>
                          {orderCurrency.map((val, index) => (
                            <option key={index} value={val.value_code}>
                              {val.value_desc}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.CustomCurrency?.message)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </Col>

                  <Col lg={3} className="mb-3">
                    <Form.Group>
                      <Form.Control
                        type="text"
                        placeholder={t("ENTER_PIN")}
                        style={{
                          height: "52px",
                          color: "#828282",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                        {...register("CustomPin", validationSchema.CustomPin)}
                        isInvalid={!!formState.errors.CustomPin}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.CustomPin?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={3} className="mb-3">
                    <Form.Group>
                      <Form.Control
                        type="text"
                        placeholder={t("ENTER_QUOTATION")}
                        style={{
                          height: "52px",
                          color: "#828282",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                        {...register(
                          "CustomQuotation",
                          validationSchema.CustomQuotation
                        )}
                        isInvalid={!!formState.errors.CustomQuotation}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.CustomQuotation?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={3} className="mb-3">
                    <Form.Group>
                      <Form.Control
                        type="text"
                        placeholder={t("ENTER_INVOICE")}
                        style={{
                          height: "52px",
                          color: "#828282",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                        {...register("CustomPay", validationSchema.CustomPay)}
                        isInvalid={!!formState.errors.CustomPay}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.CustomPay?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                {/* BREAKDOWN 2 ============================================== */}
                <div className={styles.labels}>
                  <p>{t("ARR_1")}</p>
                </div>

                <Row xs={1} md={2}>
                  <Col lg={3}>
                    <Form.Select
                      {...register(
                        "VerificationDeposit",
                        validationSchema.VerificationDeposit
                      )}
                      isInvalid={!!formState.errors.VerificationDeposit}
                      type="text"
                    >
                      <option value="" disabled selected>
                        {t("SELECT_VERIFICATION_DEPOSIT")}
                      </option>
                      {metaVerification?.map((val, index) => {
                        return (
                          <option value={val.value_code} key={index}>
                            {val.value_desc}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.VerificationDeposit?.message)}
                    </Form.Control.Feedback>
                  </Col>

                  <Col lg={3} className="mb-3">
                    <div style={{ display: "flex" }}>
                      <Form.Group>
                        <Form.Control
                          aria-label="Dollar amount (with dot and two decimal places)"
                          placeholder={t("ENTER_AMOUNT")}
                          type="number"
                          style={{
                            height: "52px",
                            color: "#828282",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            border: "none", // Remove input border
                            boxShadow: "none", // Remove input shadow if any
                          }}
                          {...register(
                            "VerificationAmount",
                            validationSchema.VerificationAmount
                          )}
                          isInvalid={!!formState.errors.VerificationAmount}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.VerificationAmount?.message)}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <Form.Select
                          aria-label="Default select example"
                          className="border-0"
                          style={{
                            width: "107px",
                            height: "52px",
                            paddingRight: "0.5rem",
                            flex: "0.5",
                            marginLeft: "-10px",
                          }}
                          {...register(
                            "VerificationCurrency",
                            validationSchema.VerificationCurrency
                          )}
                          // required
                          isInvalid={!!formState.errors.VerificationCurrency}
                        >
                          <option value="" defaultValue>
                            Currency
                          </option>
                          {orderCurrency.map((val, index) => (
                            <option key={index} value={val.value_code}>
                              {val.value_desc}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.VerificationCurrency?.message)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </Col>

                  <Col lg={3} className="mb-3">
                    <Form.Group>
                      <Form.Control
                        type="text"
                        placeholder={t("ENTER_PIN")}
                        style={{
                          height: "52px",
                          color: "#828282",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                        {...register(
                          "VerificationPin",
                          validationSchema.VerificationPin
                        )}
                        isInvalid={!!formState.errors.VerificationPin}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.VerificationPin?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={3} className="mb-3">
                    <Form.Group>
                      <Form.Control
                        type="text"
                        placeholder={t("ENTER_QUOTATION")}
                        style={{
                          height: "52px",
                          color: "#828282",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                        {...register(
                          "VerificationQuotation",
                          validationSchema.VerificationQuotation
                        )}
                        isInvalid={!!formState.errors.VerificationQuotation}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.VerificationQuotation?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={3} className="mb-3">
                    <Form.Group>
                      <Form.Control
                        type="text"
                        placeholder={t("ENTER_INVOICE")}
                        style={{
                          height: "52px",
                          color: "#828282",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                        {...register(
                          "VerificationPay",
                          validationSchema.VerificationPay
                        )}
                        isInvalid={!!formState.errors.VerificationPay}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.VerificationPay?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                {/* BREAKDOWN 3 ============================================== */}
                <div className={styles.labels}>
                  <p>{t("ARR_2")}</p>
                </div>

                <Row xs={1} md={2}>
                  <Col lg={3}>
                    <Form.Select
                      {...register(
                        "ShippingClerk",
                        validationSchema.ShippingClerk
                      )}
                      isInvalid={!!formState.errors.ShippingClerk}
                      type="text"
                    >
                      <option value="" disabled selected>
                        {t("SELECT_SHIPPING_CLERL")}
                      </option>
                      {metaShippingClerk?.map((val, index) => {
                        return (
                          <option value={val.value_code} key={index}>
                            {val.value_desc}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.ShippingClerk?.message)}
                    </Form.Control.Feedback>
                  </Col>

                  <Col lg={3} className="mb-3">
                    <div style={{ display: "flex" }}>
                      <Form.Group>
                        <Form.Control
                          aria-label="Dollar amount (with dot and two decimal places)"
                          placeholder={t("ENTER_AMOUNT")}
                          type="number"
                          style={{
                            height: "52px",
                            color: "#828282",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            border: "none", // Remove input border
                            boxShadow: "none", // Remove input shadow if any
                          }}
                          {...register(
                            "ClerkAmount",
                            validationSchema.ClerkAmount
                          )}
                          isInvalid={!!formState.errors.ClerkAmount}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.ClerkAmount?.message)}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <Form.Select
                          aria-label="Default select example"
                          className="border-0"
                          style={{
                            width: "107px",
                            height: "52px",
                            paddingRight: "0.5rem",
                            flex: "0.5",
                            marginLeft: "-10px",
                          }}
                          {...register(
                            "ShippingClerkCurrency",
                            validationSchema.ShippingClerkCurrency
                          )}
                          // required
                          isInvalid={!!formState.errors.ShippingClerkCurrency}
                        >
                          <option value="" defaultValue>
                            Currency
                          </option>
                          {orderCurrency.map((val, index) => (
                            <option key={index} value={val.value_code}>
                              {val.value_desc}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.ShippingClerkCurrency?.message)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </Col>

                  <Col lg={3} className="mb-3">
                    <Form.Group>
                      <Form.Control
                        type="text"
                        placeholder={t("ENTER_PIN")}
                        style={{
                          height: "52px",
                          color: "#828282",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                        {...register("ClerkPin", validationSchema.ClerkPin)}
                        isInvalid={!!formState.errors.ClerkPin}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.ClerkPin?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={3} className="mb-3">
                    <Form.Group>
                      <Form.Control
                        type="text"
                        placeholder={t("ENTER_QUOTATION")}
                        style={{
                          height: "52px",
                          color: "#828282",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                        {...register(
                          "ClerkQuotation",
                          validationSchema.ClerkQuotation
                        )}
                        isInvalid={!!formState.errors.ClerkQuotation}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.ClerkQuotation?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={3} className="mb-3">
                    <Form.Group>
                      <Form.Control
                        type="text"
                        placeholder={t("ENTER_INVOICE")}
                        style={{
                          height: "52px",
                          color: "#828282",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                        {...register("ClerkPay", validationSchema.ClerkPay)}
                        isInvalid={!!formState.errors.ClerkPay}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.ClerkPay?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                {/* BREAKDOWN 4 ============================================== */}
                <div className={styles.labels}>
                  <p>{t("ARR_3")}</p>
                </div>

                <Row xs={1} md={2}>
                  <Col lg={3}>
                    <Form.Select
                      {...register(
                        "Consolidating",
                        validationSchema.Consolidating
                      )}
                      isInvalid={!!formState.errors.Consolidating}
                      type="text"
                    >
                      <option value="" disabled selected>
                        {t("SELECT_CONSOLIDATING_COMPANY")}
                      </option>
                      {metaConsolidating?.map((val, index) => {
                        return (
                          <option value={val.value_code} key={index}>
                            {val.value_desc}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.Consolidating?.message)}
                    </Form.Control.Feedback>
                  </Col>

                  <Col lg={3} className="mb-3">
                    <div style={{ display: "flex" }}>
                      <Form.Group>
                        <Form.Control
                          aria-label="Dollar amount (with dot and two decimal places)"
                          placeholder={t("ENTER_AMOUNT")}
                          type="number"
                          style={{
                            height: "52px",
                            color: "#828282",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            border: "none", // Remove input border
                            boxShadow: "none", // Remove input shadow if any
                          }}
                          {...register(
                            "ConsolidatingAmount",
                            validationSchema.ConsolidatingAmount
                          )}
                          isInvalid={!!formState.errors.ConsolidatingAmount}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.ConsolidatingAmount?.message)}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <Form.Select
                          aria-label="Default select example"
                          className="border-0"
                          style={{
                            width: "107px",
                            height: "52px",
                            paddingRight: "0.5rem",
                            flex: "0.5",
                            marginLeft: "-10px",
                          }}
                          {...register(
                            "ConsolidatingCurrency",
                            validationSchema.ConsolidatingCurrency
                          )}
                          // required
                          isInvalid={!!formState.errors.ConsolidatingCurrency}
                        >
                          <option value="" defaultValue>
                            Currency
                          </option>
                          {orderCurrency.map((val, index) => (
                            <option key={index} value={val.value_code}>
                              {val.value_desc}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.ConsolidatingCurrency?.message)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </Col>

                  <Col lg={3} className="mb-3">
                    <Form.Group>
                      <Form.Control
                        type="text"
                        placeholder={t("ENTER_PIN")}
                        style={{
                          height: "52px",
                          color: "#828282",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                        {...register(
                          "ConsolidatingPin",
                          validationSchema.ConsolidatingPin
                        )}
                        isInvalid={!!formState.errors.ConsolidatingPin}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.ConsolidatingPin?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={3} className="mb-3">
                    <Form.Group>
                      <Form.Control
                        type="text"
                        placeholder={t("ENTER_QUOTATION")}
                        style={{
                          height: "52px",
                          color: "#828282",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                        {...register(
                          "ConsolidatingQuotation",
                          validationSchema.ConsolidatingQuotation
                        )}
                        isInvalid={!!formState.errors.ConsolidatingQuotation}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.ConsolidatingQuotation?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={3} className="mb-3">
                    <Form.Group>
                      <Form.Control
                        type="text"
                        placeholder={t("ENTER_INVOICE")}
                        style={{
                          height: "52px",
                          color: "#828282",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                        {...register(
                          "ConsolidatingPay",
                          validationSchema.ConsolidatingPay
                        )}
                        isInvalid={!!formState.errors.ConsolidatingPay}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.ConsolidatingPay?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                {/* BREAKDOWN 5 ============================================== */}
                <div className={styles.labels}>
                  <p>{t("ARR_4")}</p>
                  <div key={`inline-${"radio"}`} className="mb-3">
                    <Form.Check
                      inline
                      label="yes"
                      name="group1"
                      type={"radio"}
                      id={`inline-${"radio"}-3`}
                      onChange={() => setIsVace(1)}
                    />
                    <Form.Check
                      inline
                      label="no"
                      name="group1"
                      type={"radio"}
                      id={`inline-${"radio"}-3`}
                      defaultChecked
                      onChange={() => setIsVace(2)}
                    />
                  </div>
                </div>

                {isVace === 1 ? (
                  <Row xs={1} md={2}>
                    <Col lg={3} className="mb-3">
                      <div style={{ display: "flex" }}>
                        <Form.Group>
                          <Form.Control
                            aria-label="Dollar amount (with dot and two decimal places)"
                            placeholder={t("ENTER_AMOUNT")}
                            type="number"
                            style={{
                              height: "52px",
                              color: "#828282",
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              border: "none", // Remove input border
                              boxShadow: "none", // Remove input shadow if any
                            }}
                            {...register(
                              "VaceAmount",
                              validationSchema.VaceAmount
                            )}
                            isInvalid={!!formState.errors.VaceAmount}
                          />
                          <Form.Control.Feedback type="invalid">
                            {t(formState.errors.VaceAmount?.message)}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                          <Form.Select
                            aria-label="Default select example"
                            className="border-0"
                            style={{
                              width: "107px",
                              height: "52px",
                              paddingRight: "0.5rem",
                              flex: "0.5",
                              marginLeft: "-10px",
                            }}
                            {...register(
                              "VaceCurrency",
                              validationSchema.VaceCurrency
                            )}
                            // required
                            isInvalid={!!formState.errors.VaceCurrency}
                          >
                            <option value="" defaultValue>
                              Currency
                            </option>
                            {orderCurrency.map((val, index) => (
                              <option key={index} value={val.value_code}>
                                {val.value_desc}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {t(formState.errors.VaceCurrency?.message)}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </Col>

                    <Col lg={3} className="mb-3">
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder={t("ENTER_PIN")}
                          style={{
                            height: "52px",
                            color: "#828282",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }}
                          {...register("VacePin", validationSchema.VacePin)}
                          isInvalid={!!formState.errors.VacePin}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.VacePin?.message)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={3} className="mb-3">
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder={t("ENTER_QUOTATION")}
                          style={{
                            height: "52px",
                            color: "#828282",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }}
                          {...register(
                            "VaceQuotation",
                            validationSchema.VaceQuotation
                          )}
                          isInvalid={!!formState.errors.VaceQuotation}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.VaceQuotation?.message)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={3} className="mb-3">
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder={t("ENTER_INVOICE")}
                          style={{
                            height: "52px",
                            color: "#828282",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }}
                          {...register("VacePay", validationSchema.VacePay)}
                          isInvalid={!!formState.errors.VacePay}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.VacePay?.message)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                {/* BREAKDOWN 6 ============================================== */}
                <div className={styles.labels}>
                  <p>{t("ARR_5")}</p>
                  <div key={`inline-${"radio"}`} className="mb-3">
                    <Form.Check
                      inline
                      label="yes"
                      name="group2"
                      type={"radio"}
                      id={`inline-${"radio"}-2`}
                      onChange={() => setIsChassis(1)}
                    />
                    <Form.Check
                      inline
                      label="no"
                      name="group2"
                      type={"radio"}
                      defaultChecked
                      id={`inline-${"radio"}-2`}
                      onChange={() => setIsChassis(2)}
                    />
                  </div>
                </div>
                {isChassis === 1 ? (
                  <Row xs={1} md={2}>
                    <Col lg={3} className="mb-3">
                      <div style={{ display: "flex" }}>
                        <Form.Group>
                          <Form.Control
                            aria-label="Dollar amount (with dot and two decimal places)"
                            placeholder={t("ENTER_AMOUNT")}
                            type="number"
                            style={{
                              height: "52px",
                              color: "#828282",
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                              border: "none", // Remove input border
                              boxShadow: "none", // Remove input shadow if any
                            }}
                            {...register(
                              "ChassisAmount",
                              validationSchema.ChassisAmount
                            )}
                            isInvalid={!!formState.errors.ChassisAmount}
                          />
                          <Form.Control.Feedback type="invalid">
                            {t(formState.errors.ChassisAmount?.message)}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                          <Form.Select
                            aria-label="Default select example"
                            className="border-0"
                            style={{
                              width: "107px",
                              height: "52px",
                              paddingRight: "0.5rem",
                              flex: "0.5",
                              marginLeft: "-10px",
                            }}
                            {...register(
                              "ChassisCurrency",
                              validationSchema.ChassisCurrency
                            )}
                            // required
                            isInvalid={!!formState.errors.ChassisCurrency}
                          >
                            <option value="" defaultValue>
                              Currency
                            </option>
                            {orderCurrency.map((val, index) => (
                              <option key={index} value={val.value_code}>
                                {val.value_desc}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {t(formState.errors.ChassisCurrency?.message)}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </Col>

                    <Col lg={3} className="mb-3">
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder={t("ENTER_PIN")}
                          style={{
                            height: "52px",
                            color: "#828282",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }}
                          {...register(
                            "ChassisPin",
                            validationSchema.ChassisPin
                          )}
                          isInvalid={!!formState.errors.ChassisPin}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.ChassisPin?.message)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={3} className="mb-3">
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder={t("ENTER_QUOTATION")}
                          style={{
                            height: "52px",
                            color: "#828282",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }}
                          {...register(
                            "ChassisQuotation",
                            validationSchema.ChassisQuotation
                          )}
                          isInvalid={!!formState.errors.ChassisQuotation}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.ChassisQuotation?.message)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col lg={3} className="mb-3">
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder={t("ENTER_INVOICE")}
                          style={{
                            height: "52px",
                            color: "#828282",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }}
                          {...register(
                            "ChassisPay",
                            validationSchema.ChassisPay
                          )}
                          isInvalid={!!formState.errors.ChassisPay}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t(formState.errors.ChassisPay?.message)}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                <Stack direction="horizontal">
                  <div className="ms-auto">
                    <Button
                      style={{
                        width: "250px",
                        height: "49px",
                        borderRadius: "5px",
                        border: "0.5px solid #3270A0",
                        background: "#3270A0",
                        color: "#FFF",
                      }}
                      type="submit"
                    >
                      {t("PAYMENT_NOW")}
                    </Button>
                  </div>
                </Stack>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </section>
  );
}

export default CustomsDetail;
