import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import styles from "./Shipment.module.css";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import { useForm } from "react-hook-form";
import { validation } from "./validation";
import Toster from "../../Utils/Toster";
import CitransApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/LoadingSpinner";
import {
  setInitialItems,
  updateItems,
  updateShipment,
} from "../../Redux/Slices/shipmentSlice";
import { STATUS_CODES } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";

export default function Shipment({
  show,
  handleClose,
  // purchaseOrderList,
  order_id,
  // inventoryItems,
  selectedShipment,
}) {
  const [isView, setIsView] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, formState, reset } = useForm({
    mode: "onChange",
  });
  const userToken = useSelector((state) => state.user.userToken);
  const shipmentData = useSelector((state) => state.shipment.items);
  const getShipment = useSelector((state) => state.shipment);

  const isOneSubmitted = getShipment.shipments.some((val) => val.isSubmitted);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [inventoryItems, setInventoryItems] = useState([]);
  const [pro, setPro] = useState();
  const [qun, setQun] = useState();
  const [ball, setBall] = useState();
  const [containerId, setContainerId] = useState();

  useEffect(() => {
    let res = shipmentData?.find((ele) => ele.product_use == pro);
    setBall(res?.remaining_quantity);
  }, [pro, qun]);

  const getUpdateBalance = () => {
    return ball;
  };

  useEffect(() => {
    updateContainer();
  }, [ball, pro, containerId]);

  const isRequired = () => {
    return isView ? null : <span className={styles.requiredAsterisk}>*</span>;
  };

  const handelIconClose = () => {
    resetStates();
    handleClose();
    // setIsView(false);
  };

  useEffect(() => {
    const res = CitransApi.getOrderItems(userToken, order_id);
    res.then((data) => {
      // setOrderedItems(data.data.orderItems);
      setInventoryItems(data.data.orderItems);
      setSelectedItems(data.data.orderItems);
      dispatch(setInitialItems(data.data.orderItems));
    });
  }, [show]);

  useEffect(() => {
    if (!isView) {
      resetStates();
    }
  }, [show, isView]);

  useEffect(() => {
    setIsView(selectedShipment?.isSubmitted);
  }, [selectedShipment]);

  useEffect(() => {
    if (isView) {
      updateContainersState(selectedShipment.data);
    }
  }, [isView, show]);

  useEffect(() => {
    if (isView) {
      setViewValues();
    }
  }, [isView, show]);

  useEffect(() => {
    let arr = [];
    inventoryItems?.map((val) => arr.push(val.product_use));
    setSelectedItems(arr);
  }, [inventoryItems]);

  let date = new Date().getTime();
  const today = new Date().toISOString().split("T")[0];
  const [products, setProducts] = useState([
    { id: date, product: "", quantity: "", balance: "" },
  ]);

  const [container, setContainer] = useState({
    containerNumber: "",
    batchNumber: "",
    location: "",
  });

  const [containers, setContainers] = useState([
    { id: date, container, product: products },
  ]);

  const updateContainer = () => {
    setContainers(() => {
      return containers.map((container) => {
        const updatedProducts = container.product.map((product) => {
          const matchedShipmentProduct = shipmentData.find(
            (shipmentProduct) => shipmentProduct.product_use === product.product
          );
          if (matchedShipmentProduct) {
            return {
              ...product,
              balance: matchedShipmentProduct.remaining_quantity,
            };
          } else {
            return product;
          }
        });

        return { ...container, product: updatedProducts };
      });
    });
  };

  // console.log(containers, "containers...");
  const getProductName = (id) => {
    let res = inventoryItems?.find((val) => val.order_item_id == id);
    return res?.product_use;
  };

  const updateContainersState = (data) => {
    // console.log(data, "data in update state");
    let mainArr = [];

    data.container_items?.map((element) => {
      let proArr = [];

      element.container_items.map((val) => {
        const getTotal = inventoryItems.find(
          (ele) => ele.order_item_id === val.order_item_id
        );
        var totalQuantity = getTotal?.remaining_quantity;
        let proObj = {
          product: getProductName(val.order_item_id),
          quantity: val.quantity,
          balance: totalQuantity,
        };
        proArr.push(proObj);
      });
      let mainObj = {
        container: {
          batchNumber: element.batch_number,
          containerNumber: element.container_number,
          location: element.location,
        },
        product: proArr,
      };
      mainArr.push(mainObj);
      setContainers(mainArr);
    });
  };

  const setViewValues = () => {
    setValue("BL_BOOKING", selectedShipment.data.bl_number);
    setValue("POL", selectedShipment.data.pol);
    setValue("SHIPPING_LINE", selectedShipment.data.shipping_line);
    setValue("VESSEL", selectedShipment.data.vessel);
    setValue("INVOICE_NUMBER", selectedShipment.data.invoice_number);
    setValue("POD", selectedShipment.data.pod);
    setValue("ETD_DATE", selectedShipment.data.etd_date);
    setValue("ETA_DATE", selectedShipment.data.eta_date);
    setValue("DELIVERY_DATE", selectedShipment.data.delivery_date);
  };

  let modifiedArray = [];

  containers.forEach((val) => {
    let container_item = []; // Reset container_item array for each container

    val.product.forEach((item) => {
      let getProductId = inventoryItems.find(
        (pro) => pro.product_use === item.product
      );

      let itemObject = {
        order_item_id: getProductId?.order_item_id,
        quantity: item.quantity,
      };
      container_item.push(itemObject);
    });

    let containerObject = {
      container_number: val.container.containerNumber,
      batch_number: val.container.batchNumber,
      location: val.container.location,
      container_items: container_item,
    };
    modifiedArray.push(containerObject);
  });

  const handelSubmit = (formData) => {
    let postData = new FormData();
    postData.append("bl_number", formData.BL_BOOKING);
    postData.append("pod", formData.POD);
    postData.append("pol", formData.POL);
    postData.append("shipping_line", formData.SHIPPING_LINE);
    postData.append("vessel", formData.VESSEL);
    postData.append("invoice_number", formData.INVOICE_NUMBER);
    postData.append("etd_date", formData.ETD_DATE);
    postData.append("eta_date", formData.ETA_DATE);
    postData.append("delivery_date", formData.DELIVERY_DATE);
    postData.append("container_items", JSON.stringify(modifiedArray));
    // console.log(modifiedArray, "modifiedArray...");

    // Prepare payload data
    const payloadData = {
      bl_number: formData.BL_BOOKING,
      pod: formData.POD,
      pol: formData.POL,
      shipping_line: formData.SHIPPING_LINE,
      vessel: formData.VESSEL,
      invoice_number: formData.INVOICE_NUMBER,
      etd_date: formData.ETD_DATE,
      eta_date: formData.ETA_DATE,
      delivery_date: formData.DELIVERY_DATE,
      container_items: modifiedArray, // Assuming modifiedArray contains the container items
    };

    // Check if all container details are filled
    const isValid = containers.every((val) => {
      return (
        val.container.containerNumber &&
        val.container.batchNumber &&
        val.container.location
      );
    });

    if (!isValid) {
      Toster("Please fill container details for all containers!", "error");
      return;
    }

    const isValidDateFormat = (dateString) => {
      // Regular expression to match YYYY-MM-DD format
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      return regex.test(dateString);
    };

    const isDateValid =
      isValidDateFormat(formData.ETD_DATE) &&
      isValidDateFormat(formData.ETA_DATE) &&
      isValidDateFormat(formData.DELIVERY_DATE);

    if (!isDateValid) {
      Toster("Date must be a valid format (YYYY-MM-DD)!", "error");
      return;
    }

    // Check if all product details are filled
    const areProductsValid = containers.every((val) => {
      return val.product.every((values) => {
        return values.product && values.quantity && values.balance >= 0;
      });
    });

    if (!areProductsValid) {
      Toster(
        "Please fill all product details and ensure balance is non-negative for all products!",
        "error"
      );
      return;
    }

    // Perform API call to save shipment
    setIsLoading(true);
    const res = CitransApi.saveTemporaryShipment(userToken, order_id, postData);
    res
      .then((data) => {
        // console.log(data, "response data of saveTemporaryShipment");
        setIsLoading(false);

        const ResponseCode = STATUS_MSG[data.data?.code];
        if (data.code === STATUS_CODES.SUCCESS) {
          handelIconClose();
          const res2 = CitransApi.getOrderItems(userToken, order_id);
          res2.then((data) => {
            dispatch(setInitialItems(data.data.orderItems));
          });
          dispatch(
            updateShipment({
              id: selectedShipment.id,
              isSubmitted: true,
              data: payloadData,
            })
          );
          setPro("");
          setQun("");
          Toster("Shipment saved successfully!", "success");
        } else {
          Toster(t(ResponseCode), "error");
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const resetStates = () => {
    // setProducts([{ id: date, product: "", quantity: "", balance: "" }]);
    setContainer({ containerNumber: "", batchNumber: "", location: "" });
    setContainers([{ id: date, container, product: products }]);
    reset();
  };

  useEffect(() => {
    setContainers((prevContainers) => {
      return prevContainers.map((container) => {
        if (true) {
          return { ...container, product: products };
        }
      });
    });
  }, [products]);

  const addProduct = (field) => {
    const newProductId = date + 1;
    setContainers((prevContainers) =>
      prevContainers.map((container) => {
        if (container.id === field.id) {
          return {
            ...container,
            product: [
              ...container.product,
              { id: newProductId, product: "", quantity: "", balance: "" },
            ],
          };
        }
        return container;
      })
    );
  };

  const addContainer = () => {
    const newContainerId = date + 1;
    setContainers((prevContainers) => [
      ...prevContainers,
      { id: newContainerId, container, product: products },
    ]);
  };

  const removeProduct = (id1, id2) => {
    const updatedContainers = [...containers];

    // Find the container with the given containerId
    const containerIndex = updatedContainers.findIndex(
      (container) => container.id === id1
    );

    if (containerIndex !== -1) {
      // If the container exists, find the product with the given productId
      const updatedProducts = updatedContainers[containerIndex].product.filter(
        (product) => product.id !== id2
      );

      // Update the container's product array without the product to be deleted
      updatedContainers[containerIndex].product = updatedProducts;

      // Update the state with the modified containers array
      setContainers(updatedContainers);
    }
  };

  useEffect(() => {
    if (!isOneSubmitted) {
      getTotalProductQuantity(pro);
    }
  }, [containers]);

  // useEffect(() => {
  //   getTotalProductBalance(pro);
  // }, [containers]);

  const getTotalProductQuantity = (productname) => {
    var totalQuantity2 = 0;
    containers.map((ele) => {
      ele.product.map((elee) => {
        if (productname == elee.product) {
          totalQuantity2 = totalQuantity2 + Number(elee.quantity);
          // dispatch(
          //   updateItems({
          //     productName: productname,
          //     newQuantity: Number(totalQuantity2),
          //   })
          // );
          return totalQuantity2;
        } else {
          return 0;
        }
      });
    });
    setQun(totalQuantity2);
    dispatch(
      updateItems({
        productName: productname,
        newQuantity: Number(totalQuantity2),
      })
    );
    return Number(totalQuantity2);
  };

  // const getTotalProductBalance = (product) => {
  //   const getTotal = inventoryItems.find((val) => val.product_use === product);
  //   const totalQuantity = Number(getTotal?.remaining_quantity);
  //   var totalBal = totalQuantity - getTotalProductQuantity(product);
  //   console.log(totalBal, "getTotalProductBalance........");
  //   return Number(totalBal);
  // };

  const handelProductChange = (value, containerId, productId, productName) => {
    setPro(value);
    setContainerId(containerId);
    // getTotalProductQuantity(productname);
    // getTotalProductQuantity(productName);
    // console.log(value, "value in handelProductChange...");
    // dispatch(
    //   updateItems({
    //     productName,
    //     newQuantity: getTotalProductQuantity(productName),
    //   })
    // );
    setSelectedItems((pre) => pre.filter((el) => el.product_use !== value));

    containers.map((el) => {
      const shouldAllowUpdate = el.product.every((obj) => {
        // Check if obj meets the condition
        if (el.id === containerId) {
          if (obj.product == value) {
            Toster("Same product is not allowed in container!", "error");
          }
          return obj.product !== value;
        } else {
        }
      });

      if (shouldAllowUpdate) {
        // console.log("Allow update");
        setContainers((prevContainers) => {
          return prevContainers.map((container) => {
            if (container.id === containerId) {
              const updatedProducts = container.product.map((product) => {
                if (product.id === productId) {
                  const getTotal = inventoryItems.find(
                    (val) => val.product_use === value
                  );
                  const totalQuantity = getTotal?.remaining_quantity;
                  return {
                    ...product,
                    product: value,
                    // balance: getTotalProductBalance(productname),
                    balance: totalQuantity,
                  };
                } else {
                  return product;
                }
              });
              return { ...container, product: updatedProducts };
            } else {
              return container;
            }
          });
        });
      } else {
        // console.log("Not allow update");
        // Toster("Same items are not allowed in container!", "error");
      }
    });

    // console.log(hasSameProduct, "hasSameProduct...");
    // setContainers((prevContainers) => {
    //   return prevContainers.map((container) => {
    //     // Check if this is the container we want to update
    //     if (container.id === containerId) {
    //       // Update the product array within this container
    //       // const hasSameProduct = containers.map.every(
    //       //   (obj, index, arr) => obj[key] === arr[0][key]
    //       // );
    //       const key = "product";

    //       const updatedProducts = container.product.map((product) => {
    //         // Check if this is the product we want to update
    //         if (product.id === productId) {
    //           const getTotal = inventoryItems.find(
    //             (val) => val.product_use === value
    //           );
    //           var totalQuantity = getTotal?.remaining_quantity;
    //           // Update the product value
    //           return { ...product, product: value, balance: totalQuantity };
    //         } else {
    //           return product; // Return unchanged product
    //         }
    //       });

    //       // Return the updated container object with the updated product array
    //       return { ...container, product: updatedProducts };
    //     } else {
    //       return container; // Return unchanged container
    //     }
    //   });
    // });
  };

  const handleProductQuantityChange = (
    value,
    containerId,
    productId,
    productName
  ) => {
    // console.log(value, "value in handleProductQuantityChange");
    setPro(productName);
    // dispatch(
    //   updateItems({
    //     productName,
    //     newQuantity: getTotalProductQuantity(productName),
    //   })
    // );
    getTotalProductQuantity(productName);

    containers.map((container) => {
      container.product.map((pro) => {
        if (pro.product == productName) {
          setContainers((prevContainers) => {
            return prevContainers.map((container) => {
              // Check if this is the container we want to update
              // if (container.id === containerId) {
              if (true) {
                // Update the product array within this container
                var tt = 0;
                const updatedProducts = container.product.map((product) => {
                  tt = tt + Number(product.quantity);
                  // Check if this is the product we want to update
                  if (product.product === productName) {
                    const getTotal = inventoryItems.find(
                      (val) => val.product_use === product.product
                    );
                    // console.log(getTotal, "ggtt");
                    var bal = getTotal?.remaining_quantity - value;
                    let res = shipmentData.filter(
                      (ele) => ele.product_use == productName
                    );
                    // Update the quantity value
                    const matchedShipmentProduct = inventoryItems.find(
                      (shipmentProduct) =>
                        shipmentProduct.product_use === product.product
                    );
                    var ss = matchedShipmentProduct.remaining_quantity - qun;
                    return {
                      ...product,
                      quantity:
                        container.id === containerId ? value : product.quantity,
                      balance: getUpdateBalance(),
                    };
                  } else {
                    return product; // Return unchanged product
                  }
                });

                // Return the updated container object with the updated product array
                return { ...container, product: updatedProducts };
              } else {
                return container; // Return unchanged container
              }
            });
          });
        } else {
          setContainers((prevContainers) => {
            return prevContainers.map((container) => {
              // Check if this is the container we want to update
              if (container.id === containerId) {
                // if (true) {
                // Update the product array within this container
                const updatedProducts = container.product.map((product) => {
                  // Check if this is the product we want to update
                  if (product.product === productName) {
                    const getTotal = inventoryItems.find(
                      (val) => val.product_use === product.product
                    );
                    // console.log(getTotal, "ggtt");
                    var bal = getTotal?.remaining_quantity - value;
                    let res = shipmentData.filter(
                      (ele) => ele.product_use == productName
                    );
                    // Update the quantity value
                    const matchedShipmentProduct = shipmentData.find(
                      (shipmentProduct) =>
                        shipmentProduct.product_use === product.product
                    );
                    return {
                      ...product,
                      quantity: value,
                      balance: ball,
                    };
                  } else {
                    return product; // Return unchanged product
                  }
                });

                // Return the updated container object with the updated product array
                return { ...container, product: updatedProducts };
              } else {
                return container; // Return unchanged container
              }
            });
          });
        }
      });
    });
    // setContainers((prevContainers) => {
    //   return prevContainers.map((container) => {
    //     // Check if this is the container we want to update
    //     if (container.id === containerId) {
    //       // if (true) {
    //       // Update the product array within this container
    //       const updatedProducts = container.product.map((product) => {
    //         console.log(product, "pppppppppp");
    //         // Check if this is the product we want to update
    //         if (product.product === productName) {
    //           const getTotal = inventoryItems.find(
    //             (val) => val.product_use === product.product
    //           );
    //           // console.log(getTotal, "ggtt");
    //           var bal = getTotal?.remaining_quantity - value;
    //           let res = shipmentData.filter(
    //             (ele) => ele.product_use == productName
    //           );
    //           console.log(res, "res.............");
    //           // Update the quantity value
    //           const matchedShipmentProduct = shipmentData.find(
    //             (shipmentProduct) =>
    //               shipmentProduct.product_use === product.product
    //           );
    //           console.log(
    //             matchedShipmentProduct,
    //             "matchedShipmentProduct12323"
    //           );
    //           return {
    //             ...product,
    //             quantity: value,
    //             balance: bal,
    //           };
    //         } else {
    //           return product; // Return unchanged product
    //         }
    //       });

    //       // Return the updated container object with the updated product array
    //       return { ...container, product: updatedProducts };
    //     } else {
    //       return container; // Return unchanged container
    //     }
    //   });
    // });
    // updateContainer();
  };

  // useEffect(() => {
  //   updateContainer();
  // }, [pro, qun, handleProductQuantityChange]);

  // console.log("containers ", containers);

  const deleteContainer = (container_id) => {
    setContainers((prevContainers) => {
      return prevContainers.filter(
        (container) => container.id !== container_id
      );
    });
  };

  const handleContainerChange = (value, propertyName, containerId) => {
    setContainers((prevContainers) => {
      return prevContainers.map((container) => {
        // Check if this is the container we want to update
        if (container.id === containerId) {
          // Update the container object's property
          return {
            ...container,
            container: { ...container.container, [propertyName]: value },
          };
        } else {
          return container; // Return unchanged container
        }
      });
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      <Modal size="lg" show={show} onHide={handleClose}>
        <div className="ShipmentPopup">
          <Modal.Header>
            <Modal.Title>{t("NUMBER_OF_SHIPMENT")}</Modal.Title>
            <IoMdCloseCircle onClick={handelIconClose} />
          </Modal.Header>
          <Form noValidate onSubmit={handleSubmit(handelSubmit)}>
            <Modal.Body>
              <div className={styles.shipmentWrapper}>
                <div className={styles.shipmentHeader}>{t("SHIPMENT")} 1</div>
                <Row className="">
                  <Col lg={3} className="mb-4">
                    <Form.Group className="LoginInput">
                      <Form.Label>{t("ENTER_BL_OR_BOOKING")}</Form.Label>
                      <Form.Control
                        disabled={isView}
                        {...register("BL_BOOKING", validation.BL_BOOKING)}
                        isInvalid={!!formState.errors.BL_BOOKING}
                        type="text"
                        placeholder={t("ENTER_BL_OR_BOOKING")}
                        style={{
                          height: "45px",
                          color: "#828282",
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.BL_BOOKING?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={3} className="mb-3">
                    <Form.Group className="LoginInput">
                      <Form.Label>{t("ENTER_POL")}</Form.Label>
                      <Form.Control
                        disabled={isView}
                        {...register("POL", validation.POL)}
                        isInvalid={!!formState.errors.POL}
                        placeholder={t("ENTER_POL")}
                        style={{
                          height: "45px",
                          color: "#828282",
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.POL?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={3} className="mb-3">
                    <Form.Group className="LoginInput">
                      <Form.Label>{t("ENTER_SHIPPING_LINE")}</Form.Label>
                      <Form.Control
                        disabled={isView}
                        {...register("SHIPPING_LINE", validation.SHIPPING_LINE)}
                        isInvalid={!!formState.errors.SHIPPING_LINE}
                        placeholder={t("ENTER_SHIPPING_LINE")}
                        style={{
                          height: "45px",
                          color: "#828282",
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.SHIPPING_LINE?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={3} className="mb-3">
                    <Form.Group className="LoginInput">
                      <Form.Label>{t("ENTER_VESSEL")}</Form.Label>
                      <Form.Control
                        disabled={isView}
                        {...register("VESSEL", validation.VESSEL)}
                        isInvalid={!!formState.errors.VESSEL}
                        placeholder={t("ENTER_VESSEL")}
                        style={{
                          height: "45px",
                          color: "#828282",
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.VESSEL?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="">
                  <Col lg={3} className="mb-3">
                    <Form.Group className="LoginInput">
                      <Form.Label>{t("ENTER_INVOICE_NUMBER")}</Form.Label>
                      <Form.Control
                        disabled={isView}
                        {...register(
                          "INVOICE_NUMBER",
                          validation.INVOICE_NUMBER
                        )}
                        isInvalid={!!formState.errors.INVOICE_NUMBER}
                        placeholder={t("ENTER_INVOICE_NUMBER")}
                        style={{
                          height: "45px",
                          color: "#828282",
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.INVOICE_NUMBER?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={3} className="mb-3">
                    <Form.Group className="LoginInput">
                      <Form.Label>{t("ENTER_POD")}</Form.Label>
                      <Form.Control
                        disabled={isView}
                        {...register("POD", validation.POD)}
                        isInvalid={!!formState.errors.POD}
                        placeholder={t("ENTER_POD")}
                        style={{
                          height: "45px",
                          color: "#828282",
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.POD?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={3} className="mb-3">
                    <Form.Group className="LoginInput">
                      <Form.Label>{t("SELECT_ETD_DATE")}</Form.Label>
                      <Form.Control
                        disabled={isView}
                        type="date"
                        // min={today}
                        {...register("ETD_DATE", validation.ETD_DATE)}
                        isInvalid={!!formState.errors.ETD_DATE}
                        placeholder={t("SELECT_ETD_DATE")}
                        style={{
                          height: "45px",
                          color: "#828282",
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.ETD_DATE?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="">
                  <Col lg={3} className="mb-3">
                    <Form.Group className="LoginInput">
                      <Form.Label>{t("SELECT_ETA_DATE")}</Form.Label>
                      <Form.Control
                        disabled={isView}
                        type="date"
                        // min={today}
                        {...register("ETA_DATE", validation.ETA_DATE)}
                        isInvalid={!!formState.errors.ETA_DATE}
                        placeholder={t("SELECT_ETA_DATE")}
                        style={{
                          height: "45px",
                          color: "#828282",
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.ETA_DATE?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={3} className="mb-3">
                    <Form.Group className="LoginInput">
                      <Form.Label>{t("SELECT_DELIVERY_DATE")}</Form.Label>
                      <Form.Control
                        disabled={isView}
                        type="date"
                        // min={today}
                        {...register("DELIVERY_DATE", validation.DELIVERY_DATE)}
                        isInvalid={!!formState.errors.DELIVERY_DATE}
                        placeholder={t("SELECT_DELIVERY_DATE")}
                        style={{
                          height: "45px",
                          color: "#828282",
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.DELIVERY_DATE?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <div className={styles.addContainerSection}>
                  {!isView && (
                    <Button onClick={addContainer}>Add Container</Button>
                  )}
                </div>
                {containers.map((fields, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Row className="mb-3">
                        <Col className="mb-3" lg={3}>
                          <Form.Group className="LoginInput">
                            <Form.Label>
                              {t("CONTAINER_NUMBER")}
                              {isRequired()}
                            </Form.Label>
                            <Form.Control
                              disabled={isView}
                              type="text"
                              placeholder={`${t("ENTER")} ${t(
                                "CONTAINER_NUMBER"
                              )}`}
                              style={{
                                height: "45px",
                                color: "#828282",
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                              }}
                              value={fields.container.containerNumber}
                              onChange={(e) =>
                                handleContainerChange(
                                  e.target.value,
                                  "containerNumber",
                                  fields.id
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col className="mb-3" lg={3}>
                          <Form.Group className="LoginInput">
                            <Form.Label>
                              {t("BATCH_NUMBER")}
                              {isRequired()}
                            </Form.Label>
                            <Form.Control
                              disabled={isView}
                              type="text"
                              placeholder={`${t("ENTER")} ${t("BATCH_NUMBER")}`}
                              style={{
                                height: "45px",
                                color: "#828282",
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                              }}
                              value={fields.container.batchNumber}
                              onChange={(e) =>
                                handleContainerChange(
                                  e.target.value,
                                  "batchNumber",
                                  fields.id
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col className="mb-3" lg={5}>
                          <Form.Group className="LoginInput">
                            <Form.Label>
                              {t("LOCATION")}
                              {isRequired()}
                            </Form.Label>
                            <Form.Control
                              disabled={isView}
                              type="text"
                              placeholder={`${t("ENTER")} ${t("LOCATION")}`}
                              style={{
                                height: "45px",
                                color: "#828282",
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                              }}
                              value={fields.container.location}
                              onChange={(e) =>
                                handleContainerChange(
                                  e.target.value,
                                  "location",
                                  fields.id
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          {containers.length > 1 && !isView && (
                            <p className={styles.iconsClassDelete}>
                              <Icon
                                icon="fluent:delete-12-filled"
                                color="red"
                                width="24"
                                height="24"
                                onClick={() => deleteContainer(fields.id)}
                                disabled
                              />
                            </p>
                          )}
                        </Col>
                      </Row>
                      {fields.product.map((product, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className={styles.shipmentProduct}>
                              <div>
                                <div className={styles.productCount}>
                                  {t("MATERIAL_REFERENCE")}
                                  {isRequired()}
                                </div>
                                <div className={styles.productCountsdd}>
                                  <Form.Group className="LoginInputs">
                                    <Form.Select
                                      disabled={isView}
                                      style={{
                                        height: "40px",
                                        marginTop: "12px",
                                      }}
                                      onChange={(e) =>
                                        handelProductChange(
                                          e.target.value,
                                          fields.id,
                                          product.id,
                                          product.product
                                        )
                                      }
                                      value={product.product}
                                    >
                                      <option value="" disabled selected>
                                        {`${t("Select")} ${t(
                                          "MATERIAL_REFERENCE"
                                        )}`}
                                      </option>
                                      {inventoryItems?.map((val, index) => {
                                        return (
                                          <option
                                            value={val.product_use}
                                            key={index}
                                          >
                                            {val.material_reference}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>
                                  </Form.Group>
                                </div>
                              </div>
                              <div className={styles.quantityWrapper}>
                                <div className={styles.productQuantity}>
                                  {t("PRODUCT")} 1 ({t("QUANTITY")})
                                  {isRequired()}
                                </div>
                                <p className={styles.ProctUnit}>
                                  <input
                                    style={{
                                      width: "159px",
                                      height: "40px",
                                      borderRadius: "6px",
                                      border: "1px solid #EEE",
                                      background: "#FFF",
                                      boxShadow:
                                        "0px 2px 9px 0px rgb(236 230 230)",
                                      boxSizing: "border-box",
                                      marginTop: "10px",
                                    }}
                                    placeholder={" Enter quantity"}
                                    type="number"
                                    step="0.01"
                                    value={product.quantity}
                                    onChange={(e) =>
                                      handleProductQuantityChange(
                                        e.target.value,
                                        fields.id,
                                        product.id,
                                        product.product
                                      )
                                    }
                                    disabled={!product.product || isView}
                                    // onInput={(e) => {
                                    //   const value = e.target.value;
                                    //   const parts = value.split(".");

                                    //   if (parts.length > 1 && parts[1].length > 2) {
                                    //     // Limit to two digits after the decimal point
                                    //     e.target.value = `${parts[0]}.${parts[1].slice(
                                    //       0,
                                    //       2
                                    //     )}`;
                                    //   }

                                    //   // Set the width based on the content length
                                    //   const minWidth = 50;
                                    //   const contentWidth = Math.max(
                                    //     e.target.scrollWidth,
                                    //     minWidth
                                    //   );
                                    //   e.target.style.width = `${contentWidth}px`;
                                    // }}
                                  />
                                  {"  "}
                                  {
                                    inventoryItems?.find(
                                      (val) =>
                                        val.product_use == product.product
                                    )?.meta_unit_of_inventory_desc
                                  }
                                </p>
                              </div>
                              <div className={styles.subQuantityWrapper}>
                                <div className={styles.productQuantity}>
                                  {t("PRODUCT")} 1 ({t("BALANCE")})
                                </div>
                                <div className={styles.productBalance}>
                                  {product.balance &&
                                    Number(product.balance).toFixed(2)}{" "}
                                  {
                                    inventoryItems?.find(
                                      (val) =>
                                        val.product_use == product.product
                                    )?.meta_unit_of_inventory_desc
                                  }
                                </div>
                                <div className="validateError">
                                  {product.balance < 0 &&
                                    "Balance can't be negative!"}
                                </div>
                              </div>
                              {fields.product.length > 1 ? (
                                <div className={styles.deleteWrapper}>
                                  {!isView && (
                                    <p className={styles.iconsClassDelete}>
                                      <Icon
                                        icon="fluent:delete-12-filled"
                                        color="red"
                                        width="24"
                                        height="24"
                                        onClick={() =>
                                          removeProduct(fields.id, product.id)
                                        }
                                      />
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <div className={styles.deleteWrapper}>
                                  {!isView && (
                                    <p className={styles.iconsClassNotDelete}>
                                      <Icon
                                        icon="fluent:delete-12-filled"
                                        color="grey"
                                        width="24"
                                        height="24"
                                        disabled
                                      />
                                    </p>
                                  )}
                                </div>
                              )}
                            </div>
                          </React.Fragment>
                        );
                      })}
                      <div className={styles.addMoreSection}>
                        {!isView && (
                          <Button onClick={() => addProduct(fields)}>
                            Add More
                          </Button>
                        )}
                      </div>
                      <div>
                        <hr />
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </Modal.Body>
            <Modal.Footer className={styles.shipmentFooter}>
              <Button variant="secondary" onClick={handelIconClose} size="lg">
                {t("CLOSE")}
              </Button>
              {!isView && (
                <Button type="submit" size="lg" variant="primary">
                  {t("SUBMIT")}
                </Button>
              )}
            </Modal.Footer>
          </Form>
        </div>
      </Modal>
    </>
  );
}
