import React, { useEffect, useState } from "react";
import styles from "./DashboardList.module.css";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { STATUS_CODES } from "../../../Utils/StatusCode";
import { userLogoutClear } from "../../../Redux/Slices/userSlice";
import Toster from "../../../Utils/Toster";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Common/LoadingSpinner";
import CitransApi from "../../../helpers/Api";
import { PAGINATE } from "../../../Utils/Constants";

function DashboardPurshaseList() {
  const { t } = useTranslation();

  const timesToRender = 4;
  const itemsPerPage = PAGINATE.PER_PAGE;

  const isAuthenticated = useSelector((state) => state.user.userToken);

  const dispatch = useDispatch();

  const [list, setList] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [totalRecords, setTotalRecords] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const handleGetPurchaseList = (pageNumber) => {
    let res = CitransApi.getDashboardPurchaseList(isAuthenticated, pageNumber);

    res.then((data) => {
      if (
        data?.code == STATUS_CODES.INVALID_TOKEN ||
        data?.data.code == STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        setIsloading(false);
      } else {
        setList(data?.data.purchaseList);
        setTotalRecords(data?.data.count);
        setIsloading(false);
      }
    });
  };

  useEffect(() => {
    handleGetPurchaseList(pageNumber);
  }, [pageNumber]);

  const handlePageChange = (selectedPage) => {
    const newPageNumber = selectedPage.selected + 1;
    setPageNumber(newPageNumber);
  };

  return (
    <section>
      {/* <Row>
              <Col lg={6}>
                <Form>
                  <Form.Group className="LoginInput">
                    <Form.Label>{t("SEARCH")}</Form.Label>
                    <Form.Control
                      // className={styles.SearchField}
                      type="search"
                      placeholder={t("SEARCH_BY_ORDER_NO_CUSTOM_ORDER_ID")}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row> */}
      {isloading ? <Loader /> : null}
      <div>
        {Array.isArray(list) &&
          list?.map((item, index) => {
            return (
              <React.Fragment>
                <div className={styles.suppliersCard} key={index}>
                  <div className={styles.suppliersCardHead}>
                    <p>
                      {t("SUPPLIER")} : <span>{item.supplier_name}</span>
                    </p>
                    <p>
                      {t("TYPE_OF_ORDER")} : <span>{item.type_of_order}</span>
                    </p>
                    <p>
                      {t("APPLICANT_AREA")} : <span>{item.applicant_area}</span>
                    </p>
                    <p>
                      {t("PRODUCT")} :{" "}
                      <span>
                        {item?.purchaseItems.map((product, index) => {
                          return (
                            <React.Fragment key={index}>
                              {product.material_reference}
                              {item?.purchaseItems.length - 1 !== index
                                ? ", "
                                : "."}
                            </React.Fragment>
                          );
                        })}
                      </span>
                    </p>
                  </div>

                  <div className={`${styles.suppliersCardHeadRight}`}>
                    <p>
                      {t("ORDER_QUANTITY")} :{" "}
                      <span>{item.quantity_of_order}</span>
                    </p>

                    <p>
                      {t("NUMBER_OF_ORDER")} : <span>{item.order_number}</span>
                    </p>

                    <p>
                      {t("AMOUNT_OF_ORDER")} :{" "}
                      <span>{`${item.total_amount_of_order} ${item.currency_of_order}`}</span>
                    </p>
                    <p>
                      {t("ORDER_ID")} : <span>{item.custom_order_id}</span>
                    </p>
                  </div>
                </div>

                {list.length - 1 !== index ? (
                  <div className={styles.devider}></div>
                ) : (
                  ""
                )}
              </React.Fragment>
            );
          })}

        {totalRecords == 0 ? <div>{t("RESULT_NOT_FOUND")}</div> : null}

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {totalRecords > itemsPerPage && (
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              pageCount={Math.ceil(totalRecords / itemsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </section>
  );
}

export default DashboardPurshaseList;
