import { useMemo } from "react";
import styles from "../Inventory.module.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, Row, Col } from "react-bootstrap";
import { IoMdCloseCircle } from "react-icons/io";
import { useEffect, useRef, useState } from "react";
import UploadImg from "../../../Assests/Images/UploadImg.svg";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { validationSchema } from "../../../Common/ValidationSchema.js";
import { toast } from "react-toastify";
import CitransApi from "../../../helpers/Api.js";
import { useSelector } from "react-redux";
import Loader from "../../../Common/LoadingSpinner.js";
import Toster from "../../../Utils/Toster.js";
import { STATUS_CODES } from "../../../Utils/StatusCode.js";
import { STATUS_MSG } from "../../../Utils/StatusMsg.js";
import { Icon } from "@iconify/react";
import { MetaDetaKey } from "../../../metaDataKey/MetaDetaKey.js";

function InventoryModal({
  show,
  handleClose,
  isEdit,
  isView,
  data,
  pageNumber,
  handleGetInventoryList,
  setSearchKey,
}) {
  const isAuthenticated = useSelector((state) => state.user.userToken);

  const { register, handleSubmit, setValue, formState, reset } = useForm({
    mode: "onChange",
  });

  const isRequired = () => {
    return isView ? null : <span className={styles.requiredAsterisk}>*</span>;
  };
  const { t } = useTranslation();
  const [dataSheet, setDataSheet] = useState("");
  const [msds, setMsds] = useState("");

  const [dataSheetupdate, setDataSheetupdate] = useState("");
  const [msdsupdate, setMsdsupdate] = useState("");

  const [dataSheetpreview, setDataSheetpreview] = useState("");
  const [msdsSheetpreview, setMsdsSheetpreview] = useState("");
  const [msdspreview, setMsdspreview] = useState("");
  const dataSheetInputRef = useRef(null);
  const msdsfileInputRef = useRef(null);
  const fileInputRef = useRef(null);
  const msdsUpdateRef = useRef(null);

  const [inventoryId, setInventoryId] = useState();

  const [location, setLocation] = useState();
  const [materialType, setMaterialType] = useState();
  const [inventoryUnit, setInventoryUnit] = useState();

  const [locationList, setLocationList] = useState([]);
  const [materialTypeList, setMaterialTypeList] = useState([]);
  const [inventoryUnitList, setInventoryUnitList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [isDataSheetImg, SetIsDataSheetImg] = useState(false);
  const [isMsdsSheetImg, SetIsMsdsSheetImg] = useState(false);

  const [isDatasheetUpload, setIsDatasheetUpload] = useState(false);
  const [isMsdsUpload, setIsMsdsUpload] = useState(false);

  const validExtensions = [".png", ".jpeg", ".jpg"];

  // const MetadataKeys = "material_type,location,unit_of_inventory";

  const handleUploadDataSheet = () => {
    dataSheetInputRef.current.click();
  };

  const handleFileSelectforMsds = () => {
    msdsUpdateRef.current.click();
  };

  useMemo(() => {
    let res = CitransApi.getMetaData(
      MetaDetaKey.KEY_FOR_MATERIAL_LOCATION_UNIT
    );
    res.then((data) => {
      setLocationList(data.data.location);
      setMaterialTypeList(data.data.material_type);
      setInventoryUnitList(data.data.unit_of_inventory);
    });
  }, []);

  useEffect(() => {
    if (isEdit || isView) {
      setValue("PRODUCT_USE", data.product_use);
      setValue("MATERIAL_TYPE", data.meta_material_type);
      setValue("MATERIAL_REFERENCE", data.material_reference);
      setValue("PRODUCT_CODE", data.product_code);
      setValue("Location", data.meta_location);
      setValue("Inventory_Unit", data.meta_unit_of_inventory);
      setValue("perUnitPrice", data.unit_price);
      setValue("PRODUCT_DESCRIPTION", data.product_description);
      setLocation(data.meta_location);
      setMaterialType(data.meta_material_type);
      setInventoryUnit(data.meta_unit_of_inventory);
      setInventoryId(data.inventory_id);

      setDataSheet(data.attachment_data_sheet);
      setMsds(data.attachment_msds);

      setDataSheetpreview(data.attachment_data_sheet);
      setMsdspreview(data.attachment_msds);

      setDataSheetupdate(data.attachment_data_sheet);
      setMsdsupdate(data.attachment_msds);

      const extensionForSheet = data.attachment_data_sheet
        ?.substring(data.attachment_data_sheet.lastIndexOf("."))
        .toLowerCase();
      const isValidExtensionForSheet =
        validExtensions.includes(extensionForSheet);

      const extensionForMsds = data.attachment_msds
        ?.substring(data.attachment_msds.lastIndexOf("."))
        .toLowerCase();
      const isValidExtensionForMsds =
        validExtensions.includes(extensionForMsds);

      SetIsDataSheetImg(isValidExtensionForSheet);
      SetIsMsdsSheetImg(isValidExtensionForMsds);
    }
  }, [data, isView, isEdit]);

  const handleUploadMsds = () => {
    msdsfileInputRef.current.click();
  };
  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const handleFileChangeForDataSheet = (e) => {
    setIsDatasheetUpload(true);
    const file = e.target.files[0];

    if (file) {
      setDataSheetupdate(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setDataSheetpreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileChangeforMsds = (e) => {
    setIsMsdsUpload(true);
    const file = e.target.files[0];

    if (file) {
      setMsdsupdate(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setMsdsSheetpreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const HandlePrviewImageForDataSheet = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setDataSheetpreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const HandlePrviewImageForMsds = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setMsdsSheetpreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const renderTitle = () => {
    if (isView) {
      return t("INVENTORY_DETAILS");
    } else if (isEdit) {
      return t("EDIT_INVENTORY");
    } else {
      return t("Add_INVENTORY");
    }
  };

  const renderSubmitButton = () => {
    if (isView) {
      return t("CLOSE");
    } else if (isEdit) {
      return t("UPDATE_INVENTORY");
    } else {
      return t("Add_INVENTORY");
    }
  };

  const handleFileChange = (event) => {
    const labelName = event.target.name;
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      const allowedExtensions = [
        "jpg",
        "jpeg",
        "gif",
        "png",
        "pdf",
        "doc",
        "docx",
      ];

      if (allowedExtensions.includes(fileExtension)) {
        if (labelName === "dataSheet") {
          setDataSheet(selectedFile);
        } else if (labelName === "msds") {
          setMsds(selectedFile);
        }
      } else {
        toast.info("Please upload an image, pdf, or doc file.");
      }
    }
  };

  const handelSubmit = (formData) => {
    if (isEdit) {
      handleUpdateData(formData);
      const updatedData = {
        ...data,
        ...formData,
      };

      // handleClose();
    } else if (isView) {
      handleIconClose();
    } else {
      handlePostData(formData);
    }
    // handleClose();
  };

  const handlePostData = (formData) => {
    setIsLoading(true);
    let Postdata = new FormData();
    Postdata.append("inventory_id", "");
    Postdata.append("product_use", formData.PRODUCT_USE);
    Postdata.append("material_type", materialType);
    Postdata.append("material_reference", formData.MATERIAL_REFERENCE);
    Postdata.append("product_code", formData.PRODUCT_CODE);
    Postdata.append("location", location);
    Postdata.append("unit_of_inventory", inventoryUnit);
    Postdata.append("product_description", formData.PRODUCT_DESCRIPTION);
    Postdata.append("unit_price", formData.perUnitPrice);
    Postdata.append("attachment_data_sheet", dataSheet);
    Postdata.append("attachment_msds", msds);

    let res = CitransApi.postInventoryData(isAuthenticated, Postdata);
    res.then((response) => {
      setIsLoading(false);

      const ResponseCode = STATUS_MSG[response.data.code];
      if (response.code === STATUS_CODES.SUCCESS) {
        Toster(t("POST"), "success");
        handleGetInventoryList(pageNumber);
        handleClose();
        setMsdsSheetpreview("");
        setDataSheetpreview("");
        setDataSheet("");
        setIsDatasheetUpload(false);
        setSearchKey("");
        setMsds("");
        reset();
      } else {
        if (
          response.code == STATUS_CODES.VALIDATION_ERROR ||
          response.data.code == STATUS_CODES.VALIDATION_ERROR
        ) {
          Toster(response.data.message, "error");
          // handleGetInventoryList(pageNumber);
          // setSearchKey("");
          // handleClose();
        } else {
          Toster(t(ResponseCode), "error");
          // handleGetInventoryList(pageNumber);
          // setSearchKey("");
          // handleClose();
        }
      }
    });
  };

  const handleUpdateData = (formData) => {
    setIsLoading(true);
    let Postdata = new FormData();
    Postdata.append("inventory_id", inventoryId);
    Postdata.append("product_use", formData.PRODUCT_USE);
    Postdata.append("material_type", materialType);
    Postdata.append("material_reference", formData.MATERIAL_REFERENCE);
    Postdata.append("product_code", formData.PRODUCT_CODE);
    Postdata.append("location", location);
    Postdata.append("unit_of_inventory", inventoryUnit);
    Postdata.append("unit_price", formData.perUnitPrice);
    Postdata.append("product_description", formData.PRODUCT_DESCRIPTION);
    Postdata.append("attachment_data_sheet", dataSheetupdate);
    Postdata.append("attachment_msds", msdsupdate);

    let res = CitransApi.updateInventoryData(isAuthenticated, Postdata);
    res.then((response) => {
      setIsLoading(false);
      setMsds("");
      setDataSheet("");
      setMsdspreview("");
      setDataSheetpreview("");
      setDataSheetupdate();
      setMsdsupdate();
      setDataSheet(null);
      setSearchKey("");
      setIsDatasheetUpload(false);
      const ResponseCode = STATUS_MSG[response.data.code];
      if (response.code === STATUS_CODES.SUCCESS) {
        Toster(t("INVENTORY_UPDATE"), "success");
        handleGetInventoryList(pageNumber);
        setSearchKey("");
        handleClose();
        reset();
      } else {
        if (
          response.code == STATUS_CODES.VALIDATION_ERROR ||
          response.data.code == STATUS_CODES.VALIDATION_ERROR
        ) {
          Toster(response.data.message, "error");
          handleGetInventoryList(pageNumber);
          setSearchKey("");
          // handleClose();
        } else {
          Toster(t(ResponseCode), "error");
          handleGetInventoryList(pageNumber);
          setSearchKey("");
          // handleClose();
        }
      }
    });
  };

  const handleIconClose = () => {
    reset();
    handleClose();
  };

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          handleIconClose();
          setDataSheet("");
          setMsdsupdate("");
          setMsds("");
        }}
      >
        <div className="SignupPopup AddPopUp">
          <Modal.Header>
            <Modal.Title>{renderTitle()}</Modal.Title>
            <IoMdCloseCircle
              onClick={() => {
                handleIconClose();
                setDataSheet("");
                setMsdsupdate("");
                setMsds("");
              }}
            />
          </Modal.Header>
          <Form noValidate onSubmit={handleSubmit(handelSubmit)}>
            <Modal.Body>
              <Row>
                <Col lg={6}>
                  <Form.Group className="LoginInput">
                    <Form.Label>
                      {t("PRODUCT_USE")}
                      {isRequired()}
                    </Form.Label>
                    <Form.Control
                      {...register("PRODUCT_USE", validationSchema.PRODUCT_USE)}
                      isInvalid={!!formState.errors.PRODUCT_USE}
                      type="text"
                      placeholder={`${t("ENTER")} ${t("PRODUCT_USE")}`}
                      disabled={isView}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.PRODUCT_USE?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="LoginInput">
                    <Form.Label>
                      {t("MATERIAL_TYPE")}
                      {isRequired()}
                    </Form.Label>
                    <Form.Select
                      {...register(
                        "MATERIAL_TYPE",
                        validationSchema.MATERIAL_TYPE
                      )}
                      isInvalid={
                        !!formState.errors.MATERIAL_TYPE &&
                        (!materialType || materialType === "")
                      }
                      type="text"
                      disabled={isView}
                      onChange={(e) => setMaterialType(e.target.value)}
                    >
                      <option value="" defaultValue>
                        {`${t("SELECT")} ${t("MATERIAL_TYPE")}`}
                      </option>
                      {materialTypeList?.map((val) => {
                        return (
                          <option value={val.value_code} key={val.value_code}>
                            {val.value_desc}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.MATERIAL_TYPE?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="LoginInput">
                    <Form.Label>
                      {t("MATERIAL_REFERENCE")}
                      {isRequired()}
                    </Form.Label>
                    <Form.Control
                      {...register(
                        "MATERIAL_REFERENCE",
                        validationSchema.MATERIAL_REFERENCE
                      )}
                      isInvalid={!!formState.errors.MATERIAL_REFERENCE}
                      type="text"
                      name="MATERIAL_REFERENCE"
                      placeholder={`${t("ENTER")} ${t("MATERIAL_REFERENCE")}`}
                      disabled={isView}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.MATERIAL_REFERENCE?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="LoginInput">
                    <Form.Label>
                      {t("PRODUCT_CODE")}
                      {isRequired()}
                    </Form.Label>
                    <Form.Control
                      {...register(
                        "PRODUCT_CODE",
                        validationSchema.PRODUCT_CODE
                      )}
                      isInvalid={!!formState.errors.PRODUCT_CODE}
                      type="text"
                      placeholder={`${t("ENTER")} ${t("PRODUCT_CODE")}`}
                      disabled={isView}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.PRODUCT_CODE?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="LoginInput">
                    <Form.Label>
                      {t("LOCATION")}
                      {isRequired()}
                    </Form.Label>
                    <Form.Select
                      disabled={isView}
                      {...register("Location", validationSchema.Location)}
                      isInvalid={
                        !!formState.errors.Location &&
                        (!location || location === "")
                      }
                      type="text"
                      onChange={(e) => setLocation(e.target.value)}
                    >
                      <option value="" defaultValue>
                        {`${t("SELECT")} ${t("LOCATION")}`}
                      </option>
                      {locationList?.map((val) => {
                        return (
                          <option value={val.value_code} key={val.value_code}>
                            {val.value_desc}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.Location?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="LoginInput">
                    <Form.Label>
                      {t("UNIT_OF_INVENTORY")}
                      {isRequired()}
                    </Form.Label>
                    <Form.Select
                      disabled={isView}
                      {...register(
                        "Inventory_Unit",
                        validationSchema.Inventory_Unit
                      )}
                      isInvalid={
                        !!formState.errors.Inventory_Unit &&
                        (!inventoryUnit || inventoryUnit === "")
                      }
                      type="text"
                      onChange={(e) => setInventoryUnit(e.target.value)}
                    >
                      <option value="" defaultValue>
                        {`${t("SELECT")} ${t("CALLED")}  ${t(
                          "UNIT_OF_INVENTORY"
                        )}`}
                      </option>
                      {inventoryUnitList?.map((val) => {
                        return (
                          <option value={val.value_code} key={val.value_code}>
                            {val.value_desc}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.Inventory_Unit?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="LoginInput">
                    <Form.Label>
                      {t("PER_UNIT_PRICE")}
                      {isRequired()}
                    </Form.Label>
                    <Form.Control
                      {...register(
                        "perUnitPrice",
                        validationSchema.perUnitPrice
                      )}
                      isInvalid={!!formState.errors.perUnitPrice}
                      type="number"
                      step="0.01"
                      placeholder={`${t("ENTER")} ${t("PER_UNIT_PRICE")}`}
                      disabled={isView}
                      onInput={(e) => {
                        const value = e.target.value;
                        const parts = value.split(".");

                        if (parts.length > 1 && parts[1].length > 2) {
                          // Limit to two digits after the decimal point
                          e.target.value = `${parts[0]}.${parts[1].slice(
                            0,
                            2
                          )}`;
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.perUnitPrice?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={12}>
                  <Form.Group className="LoginInput">
                    <Form.Label>
                      {t("PRODUCT_DESCRIPTION")}
                      {isRequired()}
                    </Form.Label>
                    <Form.Control
                      {...register(
                        "PRODUCT_DESCRIPTION",
                        validationSchema.PRODUCT_DESCRIPTION
                      )}
                      isInvalid={!!formState.errors.PRODUCT_DESCRIPTION}
                      name="PRODUCT_DESCRIPTION"
                      as="textarea"
                      rows={3}
                      placeholder={`${t("ENTER")} ${t("PRODUCT_DESCRIPTION")}`}
                      disabled={isView}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.PRODUCT_DESCRIPTION?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={12}>
                  <div className={styles.uploadAttachments}>
                    <p>{`${t("UPLOAD")} ${t("ATTACHMENTS")}`}</p>
                  </div>
                  <div className={styles.UploadFile}>
                    {isView ? (
                      <div className={styles.UploadDocs}>
                        <div className={styles.UploadBlock}>
                          {dataSheetpreview === null ? (
                            <>
                              <img
                                alt="Not found"
                                src={UploadImg}
                                width="40px"
                                height="40px"
                                title="no preview available"
                              ></img>
                            </>
                          ) : (
                            <>
                              {isDataSheetImg ? (
                                <img
                                  alt="Not found"
                                  src={dataSheetpreview}
                                  width="100%"
                                  height="100%"
                                  title="Click to preview"
                                  onClick={() => window.open(dataSheetpreview)}
                                ></img>
                              ) : (
                                <Icon
                                  icon="solar:document-bold"
                                  color="black"
                                  width="100"
                                  height="100"
                                  onClick={() => window.open(dataSheetpreview)}
                                  // vFlip={true}
                                />
                                // <img
                                //   alt="Not found"
                                //   src={UploadImg}
                                //   width="40px"
                                //   height="40px"
                                //   title="Click to download"
                                //   onClick={() => window.open(dataSheetpreview)}
                                // ></img>
                              )}
                            </>
                          )}
                        </div>

                        <div className={styles.UploadBlockLabel}>
                          <p>{`${t("DATA_SHEETS")}`}</p>
                        </div>
                      </div>
                    ) : (
                      <>
                        {isEdit ? (
                          <>
                            {dataSheet === null || dataSheet === undefined ? (
                              <>
                                {isDatasheetUpload ? (
                                  <div>
                                    {dataSheetupdate.type === "image/png" ||
                                    dataSheetupdate.type === "image/jpeg" ||
                                    dataSheetupdate.type === "image/jpg" ? (
                                      <div>
                                        <div className={styles.EditDocs}>
                                          <div
                                            className={styles.CrossIcon}
                                            onClick={() => {
                                              setDataSheet(null);
                                              setDataSheetupdate("");
                                              setIsDatasheetUpload(false);
                                            }}
                                          >
                                            <Icon
                                              icon="akar-icons:cross"
                                              color="black"
                                              width="20"
                                              height="20"
                                            />
                                          </div>
                                        </div>
                                        <div
                                          className={styles.UploadBlockPreview}
                                        >
                                          <img
                                            alt="img preview"
                                            src={dataSheetpreview}
                                            width="100%"
                                            height="100%"
                                          ></img>
                                        </div>
                                      </div>
                                    ) : (
                                      <div>
                                        <div
                                          className={styles.EditDocs}
                                          onClick={() => {
                                            setDataSheet(null);
                                            setDataSheetupdate("");
                                            setIsDatasheetUpload(false);
                                          }}
                                        >
                                          <div className={styles.CrossIcon}>
                                            <Icon
                                              icon="akar-icons:cross"
                                              color="black"
                                              width="20"
                                              height="20"
                                            />
                                          </div>
                                        </div>
                                        <div
                                          className={styles.UploadBlockPreview}
                                        >
                                          <Icon
                                            icon="solar:document-bold"
                                            color="black"
                                            width="100"
                                            height="100"
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    <div
                                      className={styles.UploadBlock}
                                      onClick={handleFileSelect}
                                    >
                                      <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: "none" }}
                                        accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
                                        onChange={handleFileChangeForDataSheet}
                                      />
                                      <img
                                        alt="Not found"
                                        src={UploadImg}
                                        width="40px"
                                        height="40px"
                                      ></img>
                                    </div>
                                    <div className={styles.UploadBlockLabel}>
                                      <p>{`Re-${t("UPLOAD")} ${t(
                                        "DATA_SHEETS"
                                      )}`}</p>
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <div>
                                <div className={styles.EditDocs}>
                                  <div
                                    className={styles.CrossIcon}
                                    onClick={() => {
                                      setDataSheet(null);
                                      setDataSheetupdate("");
                                    }}
                                  >
                                    <Icon
                                      icon="akar-icons:cross"
                                      color="black"
                                      width="20"
                                      height="20"
                                    />
                                  </div>
                                </div>
                                <div className={styles.UploadBlockPreview}>
                                  <>
                                    {isDataSheetImg ? (
                                      <>
                                        <img
                                          alt="Not found"
                                          src={dataSheetpreview}
                                          width="100%"
                                          height="100%"
                                        ></img>
                                      </>
                                    ) : (
                                      <Icon
                                        icon="solar:document-bold"
                                        color="black"
                                        width="80"
                                        height="80"
                                      />
                                    )}
                                  </>
                                </div>
                                {/* <div className={styles.UploadBlockLabel}>
                                  <p>{`Re-${t("UPLOAD")} ${t(
                                    "DATA_SHEETS"
                                  )}`}</p>
                                </div> */}
                              </div>
                            )}
                          </>
                        ) : (
                          // ====Add======
                          <div className={styles.UploadDocs}>
                            <div
                              className={styles.UploadBlock}
                              onClick={handleUploadDataSheet}
                            >
                              {dataSheet ? (
                                <>
                                  {dataSheet.type === "image/png" ||
                                  dataSheet.type === "image/jpeg" ||
                                  dataSheet.type === "image/jpg" ? (
                                    <img
                                      alt="image"
                                      src={dataSheetpreview}
                                      width="100%"
                                      height="100%"
                                    ></img>
                                  ) : (
                                    <Icon
                                      icon="solar:document-bold"
                                      color="black"
                                      width="100"
                                      height="100"
                                      // vFlip={true}
                                    />
                                  )}
                                </>
                              ) : (
                                <>
                                  <img
                                    alt="Not found"
                                    src={UploadImg}
                                    width="40px"
                                    height="40px"
                                  ></img>
                                </>
                              )}
                            </div>
                            <div className={styles.UploadBlockLabel}>
                              <p>{`${t("UPLOAD")} ${t("DATA_SHEETS")}`}</p>
                            </div>
                            <input
                              type="file"
                              name="dataSheet"
                              ref={dataSheetInputRef}
                              style={{ display: "none" }}
                              onChange={(e) => {
                                handleFileChange(e);
                                HandlePrviewImageForDataSheet(e);
                              }}
                              disabled={isView}
                            />
                          </div>
                        )}
                      </>
                    )}

                    {/* ======MSDS======= */}
                    {isView ? (
                      <div className={styles.UploadDocs}>
                        <div className={styles.UploadBlock}>
                          {msdspreview === null ? (
                            <>
                              <img
                                alt="Not found"
                                src={UploadImg}
                                width="40px"
                                height="40px"
                                title="no preview available"
                              ></img>
                            </>
                          ) : (
                            <>
                              {isMsdsSheetImg ? (
                                <img
                                  alt="Not found"
                                  src={msdspreview}
                                  width="100%"
                                  height="100%"
                                  title="Click to preview"
                                  onClick={() => window.open(msdspreview)}
                                ></img>
                              ) : (
                                <Icon
                                  icon="solar:document-bold"
                                  color="black"
                                  width="100"
                                  height="100"
                                  onClick={() => window.open(msdspreview)}
                                />
                              )}
                            </>
                          )}
                        </div>

                        <div className={styles.UploadBlockLabel}>
                          <p>{`${t("MSDS")}`}</p>
                        </div>
                      </div>
                    ) : (
                      <>
                        {isEdit ? (
                          <>
                            {msds === null ||
                            msds === undefined ||
                            msds === "" ? (
                              <>
                                {isMsdsUpload ? (
                                  <div>
                                    {msdsupdate?.type === "image/png" ||
                                    msdsupdate?.type === "image/jpeg" ||
                                    msdsupdate?.type === "image/jpg" ? (
                                      <div>
                                        <div className={styles.EditDocs}>
                                          <div
                                            className={styles.CrossIcon}
                                            onClick={() => {
                                              setMsds(null);
                                              setMsdsupdate("");
                                              setIsMsdsUpload(false);
                                            }}
                                          >
                                            <Icon
                                              icon="akar-icons:cross"
                                              color="black"
                                              width="20"
                                              height="20"
                                            />
                                          </div>
                                        </div>
                                        <div
                                          className={styles.UploadBlockPreview}
                                        >
                                          <img
                                            alt="img preview"
                                            src={msdsSheetpreview}
                                            width="100%"
                                            height="100%"
                                          ></img>
                                        </div>
                                      </div>
                                    ) : (
                                      <div>
                                        <div
                                          className={styles.EditDocs}
                                          onClick={() => {
                                            setMsds(null);
                                            setMsdsupdate("");
                                            setIsMsdsUpload(false);
                                          }}
                                        >
                                          <div className={styles.CrossIcon}>
                                            <Icon
                                              icon="akar-icons:cross"
                                              color="black"
                                              width="20"
                                              height="20"
                                            />
                                          </div>
                                        </div>
                                        <div
                                          className={styles.UploadBlockPreview}
                                        >
                                          <Icon
                                            icon="solar:document-bold"
                                            color="black"
                                            width="100"
                                            height="100"
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    <div
                                      className={styles.UploadBlock}
                                      onClick={handleFileSelectforMsds}
                                      style={{ marginLeft: "15px" }}
                                    >
                                      <input
                                        type="file"
                                        ref={msdsUpdateRef}
                                        style={{ display: "none" }}
                                        accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
                                        onChange={handleFileChangeforMsds}
                                      />
                                      <img
                                        alt="Not found"
                                        src={UploadImg}
                                        width="40px"
                                        height="40px"
                                      ></img>
                                    </div>
                                    <div
                                      className={styles.UploadBlockLabel}
                                      style={{ marginLeft: "15px" }}
                                    >
                                      <p>{`Re-${t("UPLOAD")} ${t("MSDS")}`}</p>
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <div style={{ marginLeft: "10px" }}>
                                <div className={styles.EditDocs}>
                                  <div
                                    className={styles.CrossIcon}
                                    onClick={() => {
                                      setMsds(null);
                                      setMsdsupdate("");
                                    }}
                                  >
                                    <Icon
                                      icon="akar-icons:cross"
                                      color="black"
                                      width="20"
                                      height="20"
                                    />
                                  </div>
                                </div>
                                <div
                                  className={styles.UploadBlockPreview}
                                  style={{ marginLeft: "10px" }}
                                >
                                  <>
                                    {isMsdsSheetImg ? (
                                      <>
                                        <img
                                          alt="Not found"
                                          src={msdspreview}
                                          width="100%"
                                          height="100%"
                                        ></img>
                                      </>
                                    ) : (
                                      <Icon
                                        icon="solar:document-bold"
                                        color="black"
                                        width="80"
                                        height="80"
                                      />
                                    )}
                                  </>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className={styles.UploadDocs}>
                            <div
                              className={styles.UploadBlock}
                              onClick={handleUploadMsds}
                            >
                              {msds ? (
                                <>
                                  {msds.type === "image/png" ||
                                  msds.type === "image/jpeg" ||
                                  msds.type === "image/jpg" ? (
                                    <img
                                      alt="Not found"
                                      src={msdsSheetpreview}
                                      width="100%"
                                      height="100%"
                                    ></img>
                                  ) : (
                                    <Icon
                                      icon="solar:document-bold"
                                      color="black"
                                      width="100"
                                      height="100"
                                      // vFlip={true}
                                    />
                                  )}
                                </>
                              ) : (
                                <img
                                  alt="Not found"
                                  src={UploadImg}
                                  width="40px"
                                  height="40px"
                                ></img>
                              )}
                            </div>
                            <div className={styles.UploadBlockLabel}>
                              <p>{`${t("UPLOAD")} ${t("MSDS")}`}</p>
                            </div>
                            <input
                              type="file"
                              name="msds"
                              ref={msdsfileInputRef}
                              style={{ display: "none" }}
                              onChange={(e) => {
                                handleFileChange(e);
                                HandlePrviewImageForMsds(e);
                              }}
                              disabled={isView}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                className={styles.InventoryFooterBtn}
              >
                {renderSubmitButton()}
              </Button>
            </Modal.Footer>
          </Form>
        </div>
      </Modal>
    </>
  );
}

export default InventoryModal;
