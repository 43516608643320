import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    backgroundColor: "#E4E4E4",
    padding: "12px",
  },
  signatureImage: {
    width: 50, // Set the desired width
    height: 20, // Set the desired height
    marginLeft: 10, // Adjust as needed
  },
  topHeader: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "1.3px",
    fontSize: "15px",
  },
  companyName: {
    fontSize: "20px",
    fontWeight: "ultrabold",
    textTransform: "uppercase",
  },
  supplierSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "12px",
    marginTop: "8px",
  },
  supplierInfo: {
    display: "flex",
    flexDirection: "column",
  },
  orderInfo: {
    display: "flex",
    flexDirection: "column",
  },
  thickDivider: {
    width: "100%",
    height: 2,
    backgroundColor: "black",
    marginVertical: 10,
  },
  thinDivider: {
    width: "100%",
    height: 1,
    backgroundColor: "black",
    marginVertical: 10,
  },
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    fontSize: "12px",
    width: "100%",
  },
  tableData: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    fontSize: "12px",
    paddingBottom: "10px",
  },
  specialNotes: {
    fontSize: "14px",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  totalFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "12px",
  },
  printDate: {
    fontSize: "12px",
  },
  bottomDivider: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: "50px",
  },
  bottom: {
    height: 1,
    width: "40%",
    backgroundColor: "black",
  },
  signatureDate: {
    fontSize: "20px",
  },
  signatureImg: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginBottom: "-50px",
    marginTop: "50px",
    fontSize: "15px",
  },
  bottomText: {
    fontSize: "12px",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "75%",
    alignSelf: "center",
    marginTop: "4px",
  },
  footerSection: {
    alignSelf: "center",
    width: "100%",
    display: "flex",
    position: "absolute",
    bottom: 0,
    marginBottom: "50px",
  },
});
