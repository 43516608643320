import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styles from "./Suppliers.module.css";
import { Form, Row, Col } from "react-bootstrap";
import { IoMdCloseCircle } from "react-icons/io";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { validation } from "./validation";
import { useSelector } from "react-redux";
import CitransApi from "../../helpers/Api";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import { STATUS_CODES } from "../../Utils/StatusCode";
import Loader from "../../Common/LoadingSpinner";
import Toster from "../../Utils/Toster";
import ReactFlagsSelect from "react-flags-select";
import countryList from "react-select-country-list";

function SupplierModal({
  show,
  handleClose,
  isEdit,
  isView,
  data,
  fetchSuppliersList,
  pageNumber,
  setSearchTerm,
}) {
  const { register, handleSubmit, setValue, formState, reset } = useForm({
    mode: "onChange",
  });
  const { t } = useTranslation();
  const [phone, setPhone] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [dialCode, setDialCode] = useState({
    dialcd: "",
    person_1_dialcd: "",
    person_2_dialcd: "",
  });
  const userToken = useSelector((state) => state.user.userToken);
  const [isloading, setIsloading] = useState(false);
  const [countryCode, setCountryCode] = useState("" || "US");
  const countryLists = countryList().getData();
  var selectedCountry = countryLists.find((val) => val?.value == countryCode);

  useEffect(() => {
    if (isEdit || isView) {
      setDialCode({
        dialcd: data.phone_dial_code,
        person_1_dialcd: data.authorized_persons[0].phone_dial_code,
        person_2_dialcd: data.authorized_persons[1].phone_dial_code,
      });
      setCountryCode(data.country_code || "US");
      const fieldValues = Object.entries(data).map(([name, value]) => ({
        name,
        value,
      }));

      // Loop through the array and set values using setValue
      fieldValues.forEach(({ name, value }) => {
        if (name == "authorized_persons") {
          setValue("person_1_name", value[0].name);
          setValue("person_2_name", value[1].name);
          setValue("person_1_email", value[0].email);
          setValue("person_2_email", value[1].email);
          setValue("person_1_phn", value[0].phone_number);
          setPhone1(value[0].phone_number);
          setValue("person_2_phn", value[1].phone_number);
          setPhone2(value[1].phone_number);
        } else if (name == "telephone_number") {
          setValue("telephoneNumber", t(value));
        } else if (name == "phone_number") {
          setValue("contactNumber", t(value));
          setPhone(value);
        } else {
          setValue(name, value);
        }
      });
    }
  }, [data, isView, isEdit]);

  const renderTitle = () => {
    if (isView) {
      return t("SUPPLIERS_DETAILS");
    } else if (isEdit) {
      return t("EDIT_SUPPLIER");
    } else {
      return t("ADD_SUPPLIER");
    }
  };

  const renderSubmitButton = () => {
    if (isView) {
      return t("CLOSE");
    } else if (isEdit) {
      return t("UPDATE_SUPPLIER");
    } else {
      return t("ADD_SUPPLIER");
    }
  };

  const addSuppliersData = (formData) => {
    setIsloading(true);
    let postData = new FormData();
    {
      isEdit && postData.append("supplier_id", data.supplier_id);
    }
    postData.append("supplier_name", formData.name);
    postData.append("email", formData.email);
    postData.append("address", formData.address);
    postData.append("tax_id", formData.tax_id);
    postData.append("code_supplier", formData.code_supplier);
    postData.append("phn", formData.contactNumber);
    postData.append("dialcd", dialCode.dialcd);
    postData.append("country_code", selectedCountry?.value);
    postData.append("country_name", selectedCountry?.label);
    postData.append("person_1_name", formData.person_1_name);
    postData.append("person_2_name", formData.person_2_name);
    postData.append("person_1_email", formData.person_1_email);
    postData.append("person_2_email", formData.person_2_email);
    postData.append("person_1_dialcd", dialCode.person_1_dialcd);
    postData.append("person_2_dialcd", dialCode.person_2_dialcd);
    postData.append("person_1_phn", formData.person_1_phn);
    postData.append("person_2_phn", formData.person_2_phn);

    let res = CitransApi.addSuppliersData(userToken, postData);
    res.then((response) => {
      setIsloading(false);
      const responseCode = STATUS_MSG[response.data.code];
      if (response.code === STATUS_CODES.SUCCESS) {
        setSearchTerm("");

        setDialCode({});
        setCountryCode("US");
        if (isEdit) {
          Toster(`${t("SUPPLIER")} ${t("UPDATE_SUCCESS")}`, "success");
        } else {
          Toster(`${t("SUPPLIER")} ${t("ADDED_SUCCESS")}`, "success");
        }
        fetchSuppliersList(pageNumber);
        handleIconClose();
      } else {
        if (
          response.code == STATUS_CODES.VALIDATION_ERROR ||
          response.data.code == STATUS_CODES.VALIDATION_ERROR
        ) {
          Toster(response.data.message, "error");
          fetchSuppliersList(pageNumber);
          // handleIconClose();
        } else {
          Toster(t(responseCode), "error");
          fetchSuppliersList(pageNumber);
          // handleIconClose();
        }
      }
    });
  };

  const handelSubmit = (formData) => {
    if (isEdit) {
      addSuppliersData(formData);
    } else if (isView) {
      handleIconClose();
    } else {
      addSuppliersData(formData);
    }
    // handleIconClose();
  };

  const handleIconClose = () => {
    reset();
    handleClose();
    setPhone("");
    setValue("contactNumber", "");
    setPhone1("");
    setPhone2("");
    setDialCode({});
    setCountryCode("US");
  };

  const handelNumberChange = (phone, data) => {
    setDialCode({ ...dialCode, dialcd: "+" + data.dialCode });
    setPhone(phone);
    setValue("contactNumber", phone, {
      shouldValidate: true,
    });
  };

  const handelNumberChange1 = (phone, data) => {
    setDialCode({ ...dialCode, person_1_dialcd: "+" + data.dialCode });
    setPhone1(phone);
    setValue("person_1_phn", phone, {
      shouldValidate: true,
    });
  };

  const handelNumberChange2 = (phone, data) => {
    setDialCode({ ...dialCode, person_2_dialcd: "+" + data.dialCode });
    setPhone2(phone);
    setValue("person_2_phn", phone, {
      shouldValidate: true,
    });
  };

  const isRequired = () => {
    return isView ? null : <span className={styles.requiredAsterisk}>*</span>;
  };

  return (
    <>
      {isloading && <Loader />}
      <Modal size="lg" show={show} onHide={handleIconClose}>
        <div className="SignupPopup AddPopUp">
          <Modal.Header>
            <Modal.Title>{renderTitle()}</Modal.Title>
            <IoMdCloseCircle onClick={handleIconClose} />
          </Modal.Header>
          <Form noValidate onSubmit={handleSubmit(handelSubmit)}>
            <Modal.Body>
              <Row className="formInputSpace">
                <Col lg={6}>
                  <Form.Group className="LoginInput">
                    <Form.Label>
                      {t("SUPPLIER_NAME")}
                      {isRequired()}
                    </Form.Label>
                    <Form.Control
                      {...register("name", validation.name)}
                      isInvalid={!!formState.errors.name}
                      placeholder={`${t("ENTER")} ${t("SUPPLIER_NAME")}`}
                      disabled={isView}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.name?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="LoginInput">
                    <Form.Label>
                      {t("EMAIL")}
                      {isRequired()}
                    </Form.Label>
                    <Form.Control
                      {...register("email", validation.email)}
                      isInvalid={!!formState.errors.email}
                      type="text"
                      placeholder={`${t("ENTER")} ${t("EMAIL")}`}
                      disabled={isView}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.email?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="LoginInput">
                    <Form.Label>
                      {t("CONTACT_NUMBER")}
                      {isRequired()}
                    </Form.Label>
                    <PhoneInput
                      disabled={isView}
                      {...register("contactNumber", validation.contactNumber)}
                      value={phone}
                      country={"us"}
                      onChange={(phone, data) =>
                        handelNumberChange(phone, data)
                      }
                    />
                    <div className="validateError">
                      {!phone && t(formState.errors.contactNumber?.message)}
                      {phone &&
                        phone.length <= 4 &&
                        t("CONTACT_NUMBER_MUST_BE_AT_LEAST_5_CHARACTERS")}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="LoginInput">
                    <div className={styles.countryField}>
                      <Form.Label>
                        {t("COUNTRY_NAME")}
                        {isRequired()}
                      </Form.Label>
                      <ReactFlagsSelect
                        searchable
                        disabled={isView}
                        className={styles.flagSelect}
                        selected={countryCode}
                        onSelect={(code) => setCountryCode(code)}
                      />
                    </div>
                  </Form.Group>
                </Col>{" "}
                <Col lg={6}>
                  <Form.Group className="LoginInput">
                    <Form.Label>
                      {t("ADDRESS")}
                      {isRequired()}
                    </Form.Label>
                    <Form.Control
                      {...register("address", validation.address)}
                      isInvalid={!!formState.errors.address}
                      type="text"
                      placeholder={`${t("ENTER")} ${t("ADDRESS")}`}
                      disabled={isView}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.address?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="LoginInput">
                    <Form.Label>
                      {t("TAX_ID")}
                      {isRequired()}
                    </Form.Label>
                    <Form.Control
                      {...register("tax_id", validation.tax_id)}
                      isInvalid={!!formState.errors.tax_id}
                      type="text"
                      placeholder={`${t("ENTER")} ${t("TAX_ID")}`}
                      disabled={isView}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.tax_id?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="LoginInput">
                    <Form.Label>
                      {t("CODE_SUPPLIER")}
                      {isRequired()}
                    </Form.Label>
                    <Form.Control
                      {...register("code_supplier", validation.code_supplier)}
                      isInvalid={!!formState.errors.code_supplier}
                      type="text"
                      placeholder={`${t("ENTER")} ${t("CODE_SUPPLIER")}`}
                      disabled={isView}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t(formState.errors.code_supplier?.message)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <div className={styles.AuthorizedClass}>
                <h3>{t("AUTHORIZED_PERSON")} 1</h3>
                <Row className="formInputSpace">
                  <Col lg={6}>
                    <Form.Group className="LoginInput">
                      <Form.Label>
                        {t("PERSON_NAME")}
                        {isRequired()}
                      </Form.Label>
                      <Form.Control
                        {...register("person_1_name", validation.person_1_name)}
                        isInvalid={!!formState.errors.person_1_name}
                        type="text"
                        placeholder={`${t("ENTER")} ${t("PERSON_NAME")}`}
                        disabled={isView}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.person_1_name?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="LoginInput">
                      <Form.Label>
                        {t("EMAIL")}
                        {isRequired()}
                      </Form.Label>
                      <Form.Control
                        {...register(
                          "person_1_email",
                          validation.person_1_email
                        )}
                        isInvalid={!!formState.errors.person_1_email}
                        type="text"
                        placeholder={`${t("ENTER")} ${t("EMAIL")}`}
                        disabled={isView}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.person_1_email?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="LoginInput">
                      <Form.Label>
                        {t("CONTACT_NUMBER")}
                        {isRequired()}
                      </Form.Label>
                      <PhoneInput
                        {...register("person_1_phn", validation.person_1_phn)}
                        country={"us"}
                        value={phone1}
                        onChange={(phone, data) =>
                          handelNumberChange1(phone, data)
                        }
                        disabled={isView}
                      />
                      <div className="validateError">
                        {!phone1 && t(formState.errors.person_1_phn?.message)}
                        {phone1 &&
                          phone1.length <= 4 &&
                          t("CONTACT_NUMBER_MUST_BE_AT_LEAST_5_CHARACTERS")}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div className={styles.AuthorizedClass}>
                <h3>{t("AUTHORIZED_PERSON")} 2</h3>
                <Row className="formInputSpace">
                  <Col lg={6}>
                    <Form.Group className="LoginInput">
                      <Form.Label>
                        {t("PERSON_NAME")}
                        {isRequired()}
                      </Form.Label>
                      <Form.Control
                        {...register("person_2_name", validation.person_2_name)}
                        isInvalid={!!formState.errors.person_2_name}
                        type="text"
                        placeholder={`${t("ENTER")} ${t("PERSON_NAME")}`}
                        disabled={isView}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.person_2_name?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="LoginInput">
                      <Form.Label>
                        {t("EMAIL")}
                        {isRequired()}
                      </Form.Label>
                      <Form.Control
                        {...register(
                          "person_2_email",
                          validation.person_2_email
                        )}
                        isInvalid={!!formState.errors.person_2_email}
                        type="text"
                        placeholder={`${t("ENTER")} ${t("EMAIL")}`}
                        disabled={isView}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t(formState.errors.person_2_email?.message)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="LoginInput">
                      <Form.Label>
                        {t("CONTACT_NUMBER")}
                        {isRequired()}
                      </Form.Label>
                      <PhoneInput
                        {...register("person_2_phn", validation.person_2_phn)}
                        country={"us"}
                        value={phone2}
                        onChange={(phone, data) =>
                          handelNumberChange2(phone, data)
                        }
                        disabled={isView}
                      />
                      <div className="validateError">
                        {!phone2 && t(formState.errors.person_2_phn?.message)}
                        {phone2 &&
                          phone2.length <= 4 &&
                          t("CONTACT_NUMBER_MUST_BE_AT_LEAST_5_CHARACTERS")}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                className={styles.supplierBtn}
              >
                {renderSubmitButton()}
              </Button>
            </Modal.Footer>
          </Form>
        </div>
      </Modal>
    </>
  );
}

export default SupplierModal;
