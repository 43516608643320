import { Icon } from "@iconify/react";
import { useContext, useState } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import styles from "./Language.module.css";
import { LanguageContext } from "../../Context/LanguageContext";

const LanguageOption = (props) => {
  const languages = [
    { id: "en", code: "en", label: "English", icon: "circle-flags:us" },
    { id: "es", code: "es", label: "Spanish", icon: "circle-flags:es-variant" },
    // Add more languages as needed
  ];

  const { selectedLanguage } = useContext(LanguageContext);

  const handleSelect = (eventKey) => {
    props.onChange(eventKey);
  };

  return (
    <div className="languageModule">
      <DropdownButton
        as={ButtonGroup}
        title={languages.find((lang) => lang.code === props.value).label}
        onSelect={(eventKey) => handleSelect(eventKey)}
      >
        {languages.map((lang) => {
          return (
            <Dropdown.Item
              key={lang.id}
              eventKey={lang.code}
              className={`${styles.languageOptions} ${
                lang.code === selectedLanguage ? styles.selectedLanguage : ""
              }`}
              style={{
                backgroundColor:
                  lang.code === selectedLanguage ? "#E3E5E7" : "",
              }}
            >
              <span className={styles.langIcons}>
                <Icon icon={lang.icon} color="" width="22" height="22" />
              </span>
              {lang.label}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
    </div>
  );
};

export default LanguageOption;
