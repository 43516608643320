import styles from "./Sidebar.module.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink, useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Button, Offcanvas } from "react-bootstrap";
import { useEffect, useState } from "react";
import navbarLogo from "../../Assests/Images/navbarLogo.png";
import { useDispatch } from "react-redux";
import { userLogoutClear } from "../../Redux/Slices/userSlice";
import Toster from "../../Utils/Toster";
import { useSelector } from "react-redux";
import { USER_TYPE } from "../../Utils/Constants";

function Sidebar() {
  const userDetail = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
  }, []);

  var isExpand = windowSize > 991;
  const handelLogout = () => {
    dispatch(userLogoutClear());
    Toster(t("LOGOUT_SUCCESSFULLY"), "success");
    navigate("/");
  };

  return (
    <>
      <div className="SidebarWrapper">
        <div className={styles.TopNavLogo} onClick={() => navigate("/")}>
          <img alt="" src={navbarLogo} className={styles.NavbarLogoImg} />
        </div>
        <Navbar data-bs-theme="dark" expand={isExpand}>
          <div className="test">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${"expand"}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${"expand"}`}
            placement="start"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${"expand"}`}>
                CITRANS
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="nav-items">
                <NavLink to="/dashboard" className="nav-link">
                  {t("DASHBOARD")}
                  <FaArrowRight />
                </NavLink>
                <NavLink to="/inventory" className="nav-link">
                  {t("INVENTORY")}
                  <FaArrowRight />
                </NavLink>
                <NavLink to="/suppliers" className="nav-link">
                  {t("SUPPLIERS")}
                  <FaArrowRight />
                </NavLink>
                {userDetail.user_type !== USER_TYPE.IS_STAFF ? (
                  <NavLink to="/staff" className="nav-link">
                    {t("STAFF")}
                    <FaArrowRight />
                  </NavLink>
                ) : (
                  ""
                )}

                <NavLink to="/purchase-order" className="nav-link">
                  {t("CREATE_PURCHASE_ORDER")}
                  <FaArrowRight />
                </NavLink>
                <NavLink to="/purchase" className="nav-link">
                  {t("PURCHASE_ORDER_LIST")}
                  <FaArrowRight />
                </NavLink>
                <NavLink to="/traffic" className="nav-link">
                  {t("TRAFFIC")}
                  <FaArrowRight />
                </NavLink>
                <NavLink to="/customs" className="nav-link">
                  {t("CUSTOMS")}
                  <FaArrowRight />
                </NavLink>
                <NavLink to="/transportation" className="nav-link">
                  {t("TRANSPORTATION_receipt")}
                  <FaArrowRight />
                </NavLink>
                <NavLink to="/profile" className={"nav-link"}>
                  {t("PROFILE")}
                  <FaArrowRight />
                </NavLink>
                <Button onClick={() => handelLogout()}>Logout</Button>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Navbar>
      </div>
    </>
  );
}

export default Sidebar;
